import {
	GET_CALENDAR_EVENTS,
	RECEIVE_CALENDAR_EVENTS,
} from "actions/consts";
import { getDateTime } from "components/utils";


export const formatEventForCalendar = (event, id) => {
	const {start_datetime, end_datetime, parent, capacity_left} = event;
	const [startDate, startTime] = start_datetime.split("T");
	const [endDate, endTime] = end_datetime.split("T");
	return {
		id,
		title: parent.title,
		start: getDateTime(startDate, startTime),
		end: getDateTime(endDate, endTime),
		allDay: !(startTime && endTime),
		desc: parent.description,
		totalPlaces: parent.capacity,
		freePlaces: capacity_left
	};
};


const initialState = {
	isFetching: false,
	events: [],
	defaultDate: new Date()
};


export default function calendarReducer(state = initialState, action){
	switch(action.type){
	case GET_CALENDAR_EVENTS:
		return {
			...state,
			isFetching: true
		};
	case RECEIVE_CALENDAR_EVENTS:{
		const formatedEvents = action.events.map((event) => formatEventForCalendar(event, event.id));
		return {
			...state,
			isFetching: false,
			events: formatedEvents,
			defaultDate: formatedEvents.length > 0 ? formatedEvents[0].start : state.defaultDate
		};
	}
	default:
		return state;
	}
}