import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Col, Row, Container } from "reactstrap";
import { BsTagFill, BsCalendar } from "react-icons/bs";
import { AiOutlineShoppingCart } from "react-icons/ai";

import { getCategoryNameForPackage, getMembershipTitle } from "components/utils";
import { limits } from "backend_constants/limitChoice";
import { categoryLimits } from "backend_constants/categories";
import frontendUrls from "routes/frontend_routes";


export const MembershipSessions = ({item, isMyOrdersPage, usageLeft}) => {
	const { usage_limits, usage_limit } = item;

	return(
		<>
			{
				isMyOrdersPage ?
					<p className="font_16_medium_2c2c2d_list">Sessions left:&nbsp;&nbsp;
						<span className="font_16_regular_2c2c2d_list">
							{
								item.usage_limits === limits.unlimited ? "unlimited." :
									usageLeft > 0 ? usageLeft : "all used"
							}
						</span>
					</p>:
					<p className="font_16_medium_2c2c2d_list">
						Sessions:&nbsp;&nbsp;
						{usage_limits === limits.unlimited && <span className="font_16_regular_2c2c2d_list">unlimited</span>}
						{usage_limits === limits.limited && <span className="font_16_regular_2c2c2d_list">{usage_limit}</span>}
					</p>
			}
		</>
	);
};

export const MembershipCategories = ({item, size: {p, span}}) => {
	const { category_limits, categories } = item;

	return(
		<p className={`font_${p}_medium_2c2c2d_list ${ item.categories.length > 2 && "overflow"}`}>
			{category_limits === categoryLimits.unlimited && "All categories"}
			{category_limits === categoryLimits.limited &&
				<Fragment>
					Category:&nbsp;&nbsp;&nbsp;&nbsp;
					<span className={`font_${span}_regular_2c2c2d_list`}>
						{getCategoryNameForPackage(categories)}
					</span>
				</Fragment>
			}
		</p>
	);
};

export const MembershipItem = ({item, buy = true, open, isMyOrdersPage = false, usageLeft = 0}) => {
	return(
		<div className="membership_item_wrap shadow_form">
			<div className="membership_item">
				<Row className="mbshp_title">
					<p className="font_18_medium_2c2c2d_list mb-0">
						{getMembershipTitle(item.title)}
					</p>
				</Row>

				<Row className="mbshp_details">
					<Col xs="2">
						<BsCalendar className="gliph_grey gliph_15px" />
					</Col>
					<Col xs="10">
						<p className="font_16_medium_2c2c2d_list">
							Monthly subscription
						</p>
					</Col>
				</Row>

				<Row className="mbshp_details">
					{ !isMyOrdersPage &&
						<Col xs="2">
							<AiOutlineShoppingCart className="gliph_grey gliph_18px"/>
						</Col>
					}
					<Col xs={{size: 10, offset: `${isMyOrdersPage ? 2 : 0}`}}>
						<MembershipSessions item={item}
							isMyOrdersPage={isMyOrdersPage}
							usageLeft={usageLeft}/>
					</Col>
				</Row>

				<Row className="mbshp_category">
					<Col xs={{size: 10, offset: 2}}>
						<MembershipCategories item={item} size={{p:14, span: 14}}/>
					</Col>
				</Row>
			</div>

			<Row className="mbshp_control bg_row_btn border border_row_btn">
				<Col xs="2" className="margin_top_12px">
					<BsTagFill className="gliph_grey" />
				</Col>
				<Col xs="6" className="margin_top_12px">
					<p className=" display_inline_block font_14_medium_2c2c2d_list">
						Price:&nbsp;
						<span className="font_14_regular_2c2c2d_list"> &pound;{item.price}</span>
					</p>
				</Col>
				<Col xs="4">
					{
						buy &&
						<Link to={frontendUrls.urlBookingMembershipId.replace(":id", item.id)}
							className="btn mbshp_btn font_16_regular_white_list">
							Buy
						</Link>
					}
					{
						open &&
						<Link to={{
							pathname: frontendUrls.urlBookingMembershipId.replace(":id", item.id),
							state: { buy: false }
						}}
						className="btn mbshp_btn font_16_regular_white_list">
							Open
						</Link>
					}
				</Col>
			</Row>
		</div>
	);
};

const BookingMembershipsComponent = ({ bookingMemberships, isFetching }) => {
	return (
		<Container className="mt-5">
			<p className="font_24_regular_black_list">Membership</p>
			{isFetching &&
				<div className="margin-top20p margin-left_auto margin-right_auto width20 height100 margin_bottom210">
					<img className="text-center" src="/images/ajax-loader.gif" alt="Loading..." height={150} />
				</div>
			}
			<div className="membership_list">
				{
					!isFetching &&
					bookingMemberships.map((membership) => <MembershipItem key={membership.id} item={membership}/>)
				}
			</div>
		</Container>
	);
};

BookingMembershipsComponent.propTypes = {
	bookingMemberships: PropTypes.arrayOf(PropTypes.object).isRequired,
	isFetching: PropTypes.bool.isRequired,
};

MembershipItem.propTypes = {
	item: PropTypes.object.isRequired,
	buy: PropTypes.bool,
	open: PropTypes.bool,
	isMyOrdersPage: PropTypes.bool,
	usageLeft: PropTypes.number
};

MembershipSessions.propTypes = {
	item: PropTypes.object.isRequired,
	isMyOrdersPage: PropTypes.bool,
	usageLeft: PropTypes.number
};

MembershipCategories.propTypes = {
	item: PropTypes.object.isRequired,
	size: PropTypes.object.isRequired,
};

export default BookingMembershipsComponent;
