import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import { Link } from "react-router-dom";

import { BsHouseDoor, BsGrid } from "react-icons/bs";
import { TimeTableIcon, MembershipCardIcon } from "components/utils/icons";
import frontendUrls from "routes/frontend_routes";
import {fetchFacilityAdditionalServices} from "actions/facilities/additional_services";


const MenuComponent = ({dispatch, services}) => {

	useEffect(() => {dispatch(fetchFacilityAdditionalServices());}, []);

	return (
		<div className="px-0">
			<div className="btn_menu">
				<div className="mx-auto mt-3 logo-img-wrapper w-75 h-50">
					<img
						className="logo-img"
						src="/static/logo_img.png"
						alt="Pozen logo" />
				</div>
				<div className="mt-5">
					<Link to={frontendUrls.urlBookingMyOrders} className="menu_item">
						<BsHouseDoor className="gliph_blue"/>
						<p className="font_16_medium_2c2c2d_list">Dashboard</p>
					</Link>
					<Link to={frontendUrls.urlBookingEvents} className="menu_item">
						<TimeTableIcon/>
						<p className="font_16_medium_2c2c2d_list">Classes</p>
					</Link>
					<Link to={frontendUrls.urlBookingPackages} className="menu_item">
						<BsGrid className="gliph_blue gliph_18px"/>
						<p className="font_16_medium_2c2c2d_list">Packages</p>
					</Link>
					<Link to={frontendUrls.urlBookingMemberships} className="menu_item">
						<MembershipCardIcon/>
						<p className="font_16_medium_2c2c2d_list">Membership</p>
					</Link>
					{
						services.show_events_workshop_url &&
						<a className="menu_item" target="_blank" rel="noreferrer" href={services.events_workshop_url}>
							<BsGrid className="gliph_blue gliph_18px"/>
							<p className="font_16_medium_2c2c2d_list">Event - Workshops</p>
						</a>
					}
					{
						services.show_massage_url &&
						<a className="menu_item" target="_blank" rel="noreferrer" href={services.massage_url}>
							<BsGrid className="gliph_blue gliph_18px"/>
							<p className="font_16_medium_2c2c2d_list">Massage</p>
						</a>
					}
					{
						services.show_shop_url &&
						<a className="menu_item" target="_blank" rel="noreferrer" href={services.shop_url}>
							<BsGrid className="gliph_blue gliph_18px"/>
							<p className="font_16_medium_2c2c2d_list">Shop</p>
						</a>
					}
				</div>
			</div>
		</div>
	);
};


MenuComponent.propTypes = {
	dispatch: PropTypes.func.isRequired,
	services: PropTypes.object.isRequired
};


const mapStateToProps = ({facilityAdditionalServicesRoot: {services}}) => {
	return {services};
};


export default connect(mapStateToProps)(MenuComponent);
