import {
	START_GET_PROMO,
	FINISHED_GET_PROMO,
	START_CREATE_FACILITY_PROMO,
	FINISHED_CREATE_FACILITY_PROMO,
	FAILED_CREATE_FACILITY_PROMO,
	START_UPDATE_FACILITY_PROMO,
	FINISHED_UPDATE_FACILITY_PROMO,
	FAILED_UPDATE_FACILITY_PROMO,
	START_DELETE_FACILITY_PROMO,
	FINISHED_DELETE_FACILITY_PROMO,
	FAILED_DELETE_FACILITY_PROMO,
} from "actions/consts";
import apiUrls from "routes/api";
import {request, parseJson, checkStatus} from "actions/utils";



const startGetPromo = () => {
	return {type: START_GET_PROMO};
};


const finishedGetPromo = (data) => {
	return{
		type: FINISHED_GET_PROMO,
		promo: data,
		receiveAt: Date.now()
	};
};


export const fetchFacilityPromo = () => {
	return (dispatch, getState) => {
		dispatch(startGetPromo());
		const { root: { authToken }} = getState();
		const config = {
			method: "GET",
			isAuth: authToken
		};

		const response = request(apiUrls.facilityPromoUrl, config);
		return response
			.then((data) => {
				dispatch(finishedGetPromo(data));
			})
			.catch((error) => {
				console.log("An error occured: ", error);
			});
	};
};


export const startCreateFacilityPromo = () => {
	return {type: START_CREATE_FACILITY_PROMO};
};


export const finishedCreateFacilityPromo = (data) => {
	return {
		type: FINISHED_CREATE_FACILITY_PROMO,
		promo: data,
		receiveAt: Date.now()
	};
};


export const failedCreateFacilityPromo = (errors) => {
	return {
		type: FAILED_CREATE_FACILITY_PROMO,
		errors: errors,
		receiveAt: Date.now()
	};
};


export const fetchCreateFacilityPromo = (promoForm) => {
	return (dispatch, getState) => {
		dispatch(startCreateFacilityPromo());
		const { root: {authToken}} = getState();

		const config = {
			method: "POST",
			headers: {
				"Authorization": `Token ${authToken}`
			},
			body: promoForm
		};

		const response = fetch(apiUrls.facilityPromoUrl, config);
		return response
			.then(checkStatus)
			.then(parseJson)
			.then((data) => {
				dispatch(finishedCreateFacilityPromo(data));
			})
			.catch((error) => {
				error.response.json()
					.then((json) => dispatch(failedCreateFacilityPromo(json)));
			});
	};
};


export const startUpdateFacilityPromo = () => {
	return {type: START_UPDATE_FACILITY_PROMO};
};


export const finishedUpdateFacilityPromo = (data) => {
	return {
		type: FINISHED_UPDATE_FACILITY_PROMO,
		promo: data,
		receiveAt: Date.now()
	};
};


export const failedUpdateFacilityPromo = (errors) => {
	return {
		type: FAILED_UPDATE_FACILITY_PROMO,
		errors: errors,
		receiveAt: Date.now()
	};
};


export const fetchUpdateFacilityPromo = (promoId, promoForm) => {
	return (dispatch, getState) => {
		dispatch(startUpdateFacilityPromo());
		const { root: {authToken}} = getState();

		const config = {
			method: "PATCH",
			headers: {
				"Authorization": `Token ${authToken}`
			},
			body: promoForm
		};

		const response = fetch(apiUrls.facilityPromoDetailUrl.replace(":id", promoId), config);
		return response
			.then(checkStatus)
			.then(parseJson)
			.then((data) => {
				dispatch(finishedUpdateFacilityPromo(data));
			})
			.catch((error) => {
				error.response.json()
					.then((json) => dispatch(failedUpdateFacilityPromo(json)));
			});
	};
};


export const startDeleteFacilityPromo = () => {
	return {type: START_DELETE_FACILITY_PROMO};
};


export const finishedDeleteFacilityPromo = () => {
	return {type: FINISHED_DELETE_FACILITY_PROMO};
};


export const failedDeleteFacilityPromo = (errors) => {
	return {
		type: FAILED_DELETE_FACILITY_PROMO,
		errors: errors,
		receiveAt: Date.now()
	};
};



export const fetchDeleteFacilityPromo = (promoId) => {
	return (dispatch, getState) => {
		dispatch(startDeleteFacilityPromo());
		const { root: {authToken}} = getState();

		const config = {
			method: "DELETE",
			isAuth: authToken
		};

		const response = request(apiUrls.facilityPromoDetailUrl.replace(":id", promoId), config, {}, true);
		return response
			.then((data) => {
				dispatch(finishedDeleteFacilityPromo(data));
			})
			.catch((error) => {
				error.response.json()
					 .then((json) => {dispatch(failedDeleteFacilityPromo(json))});
			});
	};
};
