export let UNLIMITED = 0,
	HARD_EXPIRY = 1,
	SOFT_EXPIRY = 2;

export const timeLimitsChoices = [
	[
		UNLIMITED, "Unlimited"
	],
	[
		HARD_EXPIRY, "Expire at the preselected date"
	],
	[
		SOFT_EXPIRY, "Expire after some amount of days after purchase"
	],
];

export const packageLimits = {
	unlimited: UNLIMITED,
	hardExpiry: HARD_EXPIRY,
	softExpiry: SOFT_EXPIRY
};