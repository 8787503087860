import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ConfirmResetPasswordComponent from "components/auth/confirm_reset_password";
import { fetchResetPasswordConfirmation } from "actions/auth/reset_password";


const ConfirmResetPasswordContainer = ({ errors, dispatch, match, isFetching }) => {

	const createPasswordForAccount = ({password, confirmPassword}) => {
		const { token, uid } = match.params;
		dispatch(fetchResetPasswordConfirmation(password, confirmPassword, token, uid));
	};

	const props = {
		errors,
		dispatch,
		onSubmit: createPasswordForAccount,
		isFetching
	};

	return <ConfirmResetPasswordComponent {...props}/>;
};


ConfirmResetPasswordContainer.propTypes = {
	errors: PropTypes.object,
	isFetching: PropTypes.bool,
	dispatch: PropTypes.func.isRequired,
	match: PropTypes.object.isRequired
};


const mapStateToProps = ({resetPasswordRoot: {errors, isFetching}}) => {
	return { errors, isFetching };
};


export default connect(mapStateToProps)(ConfirmResetPasswordContainer);