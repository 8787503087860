import {
	GET_ACTIVE_PACKAGES,
	RECEIVE_ACTIVE_PACKAGES,
	GET_EXPIRED_PACKAGES,
	RECEIVE_EXPIRED_PACKAGES,
} from "actions/consts";


const initialState = {
	packagesRoot: {
		isFetching: false,
		active: [],
		expired: []
	}
};

export default function packagesReducer(state = initialState, action) {
	switch (action.type) {
	// REDUCER PACKAGES

	case GET_ACTIVE_PACKAGES:
		return {
			...state,
			isFetching: true
		};

	case RECEIVE_ACTIVE_PACKAGES:
		return {
			...state,
			isFetching: false,
			active: action.packages,
			lastUpdated: action.receivedAt
		};
	
	case GET_EXPIRED_PACKAGES:
		return {
			...state,
			isFetching: true
		};

	case RECEIVE_EXPIRED_PACKAGES:
		return {
			...state,
			isFetching: false,
			expired: action.packages,
			lastUpdated: action.receivedAt
		};

	default:
		return state;
	}
}
