import {
	GET_BOOKING_EVENTS,
	RECEIVE_BOOKING_EVENTS
} from "actions/consts";
import getDateFromJS, { request } from "actions/utils";
import apiUrls from "routes/api";


// Processing getting events list
const getBookingEvents = () => {
	return {
		type: GET_BOOKING_EVENTS,
		bookingEvents: []
	};
};

const receiveBookingEvents = (data, page) => {
	return {
		type: RECEIVE_BOOKING_EVENTS,
		page: page,
		bookingEvents: data,			//["results"].map(child => child)
		receivedAt: Date.now()
	};
};

export const fetchBookingEvents = (page_size=10, page=1) => {
	return (dispatch, getState) => {
		dispatch(getBookingEvents());
		const { root: { authToken }} = getState();
		const config = {
			method: "GET",
			isAuth: authToken,
			params:{
				mode: "from_now",
				date: getDateFromJS(),
				page_size,
				page
			}
		};

		const response = request(apiUrls.filterEventsUrl, config);

		return response
			.then(json => dispatch(receiveBookingEvents(json, page)))
			.catch(error => console.log("An error occured.", error));
	};
};
