export const loadState = () => {
	try {
		const serializedState = localStorage.getItem("state");
		if (serializedState === null) {
			return {
				root: {
					authToken: "",
					first_name: "",
					last_name: "",
					role: "",
					lastUpdated: null
				}
			};
		}
		return JSON.parse(serializedState);
	}
	catch (err) {
		return {
			root: {
				authToken: "",
				first_name: "",
				last_name: "",
				role: "",
				lastUpdated: null
			}
		};
	}
};

//this is function that saves state to localStorage
export const saveState = (state) => {
	try {
		const serializedState = JSON.stringify(state);
		localStorage.setItem("state", serializedState);
	}
	catch (err) {
		// Ignore write errors.
	}
};
