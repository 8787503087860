import {
	GET_BOOKING_MEMBERSHIPS,
	RECEIVE_BOOKING_MEMBERSHIPS
} from "../../../actions/consts";


const initialState = {
	isFetching: false,
	bookingMemberships: []
};

export default function bookingMembershipsReducer(state = initialState, action) {
	switch (action.type) {
	// REDUCER BOOKING MEMBERSHIP

	case GET_BOOKING_MEMBERSHIPS:
		return {
			...state,
			isFetching: true
		};

	case RECEIVE_BOOKING_MEMBERSHIPS:
		return {
			...state,
			isFetching: false,
			bookingMemberships: action.bookingMemberships,
			lastUpdated: action.receivedAt
		};

	default:
		return state;
	}
}
