import React, {useState} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { history } from "store";

import { Button } from "reactstrap";
import ResetPasswordComponent from "components/auth/reset_password";
import ModalComponent from "components/utils/modal";
import { isEmpty } from "components/utils";
import frontendUrls from "routes/frontend_routes";
import { fetchResetPasswordRequest } from "actions/auth/reset_password";


const ResetPasswordContainer = ({ errors, dispatch, isFetching, isEmailSended }) => {

	const [show, showModal] = useState(false);

	const sendResetPasswordEmail = (values) => {
		dispatch(fetchResetPasswordRequest(values.email));
		showModal(true);
	};


	const handleClose = () => {
		showModal(false);
		history.push(frontendUrls.urlLogin);
	};

	const props = {
		errors,
		dispatch,
		onSubmit: sendResetPasswordEmail,
		isFetching
	};

	return(
		<> 
			<ResetPasswordComponent {...props}/>
			<ModalComponent isOpen={show && isEmpty(errors) && isEmailSended}
				header="Reset password confirmation."
				body="We send a reset password link to your email.">
				<Button className="btn close_btn" onClick={handleClose}>Close</Button>
			</ModalComponent>
		</>
	);
};


ResetPasswordContainer.propTypes = {
	errors: PropTypes.object,
	isFetching: PropTypes.bool,
	isEmailSended: PropTypes.bool,
	dispatch: PropTypes.func.isRequired
};


const mapStateToProps = ({resetPasswordRoot: {errors, isFetching, isEmailSended}}) => {
	return { errors, isFetching, isEmailSended };
};


export default connect(mapStateToProps)(ResetPasswordContainer);