import moment from "moment";
import { momentLocalizer } from "react-big-calendar";


export const localizer = momentLocalizer(moment);


const dateRangeFormat = ({start, end}, culture, local) => {
	const isMonthEquealDates = start.getMonth() === end.getMonth();
	const isYearEqualDates = start.getFullYear() === end.getFullYear();

	const startDateFormat = isYearEqualDates ? "MMM DD" : "DD MMM YYYY";
	const endDateFormat = isYearEqualDates && isMonthEquealDates ? "DD" : startDateFormat;

	return(
		local.format(start, startDateFormat, culture) + 
		" - " + 
		local.format(end, endDateFormat, culture)
	);
};


const timeRangeFormat = ({start, end}, culture, local) => {
	const timeFormat = "kk:mm";
	return(
		local.format(start, timeFormat, culture) + 
		" - " + 
		local.format(end, timeFormat, culture)
	);
};


export const formats = {
	dateFormat: "D",
	timeGutterFormat: "kk:mm",
	dayRangeHeaderFormat: dateRangeFormat,
	dayHeaderFormat: "DD MMM YYYY",
	agendaHeaderFormat: dateRangeFormat,
	agendaDateFormat: "MMM DD, ddd",
	agendaTimeRangeFormat: timeRangeFormat,
	eventTimeRangeFormat: timeRangeFormat
};


export const navigate = {
	PREVIOUS: "PREV",
	NEXT: "NEXT",
	TODAY: "TODAY",
	DATE: "DATE",
};


export const views = {
	MONTH: "month",
	WEEK: "week",
	WORK_WEEK: "work_week",
	DAY: "day",
	AGENDA: "agenda",
};


export const styles = {
	month: {
		backgroundColor: "transparent",
		padding: "2px 7px",
	},
	week: {
		backgroundColor: "#00C3B3",
		borderColor: "#00C3B3",
		flexDirection: "row"
	}, 
	day:{
		backgroundColor: "#00C3B3",
		borderColor: "#00C3B3",
	},
};


export const getAgendaDetailEventData = (datetime) => {
	return moment(datetime).format("MMM D, ddd");
};


export const getAgendaDetailEventTimeRange = (start, end) => {
	if(start.getHours() < 12 && end.getHours >= 12){
		return `${moment(start).format("h:mm a")} - ${moment(end).format("h:mm a")}`;
	}
	return `${moment(start).format("h:mm ")} - ${moment(end).format("h:mm a")}`;
};
