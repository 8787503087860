import {
	START_GET_ORDERS,
	FINISHED_GET_ORDERS,
	FINISHED_GET_ACTIVE_EVENTS_ORDERS,
	FINISHED_GET_ACTIVE_MEMBERSHIP_ORDERS,
	FINISHED_GET_ACTIVE_PACKAGES_ORDERS,
	FINISHED_GET_EXPIRED_EVENTS_ORDERS,
	FINISHED_GET_EXPIRED_MEMBERSHIP_ORDERS,
	FINISHED_GET_EXPIRED_PACKAGES_ORDERS,
	START_GETTING_ORDERS_LIST,
	RECEIVED_ORDERS_LIST,
	FAILED_RECEIVE_ORDERS_LIST
} from "actions/orders/consts";
import apiUrls from "routes/api";
import {request} from "actions/utils";


const getOrders = () => {
	return { type: START_GET_ORDERS };
};


const finishedGetOrders = (orders) => {
	return {
		type: FINISHED_GET_ORDERS,
		orders: orders,
		receiveAt: Date.now()
	};
};


const receivedExpiredPackagesOrders = (data) => {
	return {
		type: FINISHED_GET_EXPIRED_PACKAGES_ORDERS,
		orders: data,
		receiveAt: Date.now()
	};
};


const receivedActivePackagesOrders = (data) => {
	return {
		type: FINISHED_GET_ACTIVE_PACKAGES_ORDERS,
		orders: data,
		receiveAt: Date.now()
	};
};


export const fetchPackageOrders = (expired = false, cancelled = false) => {
	return (dispatch, getState) => {
		dispatch(getOrders());
		const { root: { authToken } } = getState();
		const config = {
			isAuth: authToken,
			params: {expired, cancelled}
		};
		const response = request(apiUrls.myOrdersPackagesUrl, config);
		return response
			.then((json) => {
				if(expired){
					dispatch(receivedExpiredPackagesOrders(json));
				} else {
					dispatch(receivedActivePackagesOrders(json));
				}
			}).catch(error => console.log(error));
	};
};


const receivedExpiredMembershipOrders = (data) => {
	return {
		type: FINISHED_GET_EXPIRED_MEMBERSHIP_ORDERS,
		orders: data,
		receiveAt: Date.now()
	};
};


const receivedActiveMembershipOrders = (data) => {
	return {
		type: FINISHED_GET_ACTIVE_MEMBERSHIP_ORDERS,
		orders: data,
		receiveAt: Date.now()
	};
};


export const fetchMembershipOrders = (expired = false, cancelled = false) => {
	return (dispatch, getState) => {
		dispatch(getOrders());
		const { root: { authToken } } = getState();
		const config = {
			isAuth: authToken,
			params: {expired, cancelled}
		};
		const response = request(apiUrls.myOrdersMembershipsUrl, config);
		return response
			.then((json) => {
				if(expired){
					dispatch(receivedExpiredMembershipOrders(json));
				} else {
					dispatch(receivedActiveMembershipOrders(json));
				}
			}).catch(error => console.log(error));
	};
};

const receivedExpiredEventOrders = (data) => {
	return {
		type: FINISHED_GET_EXPIRED_EVENTS_ORDERS,
		orders: data,
		receiveAt: Date.now()
	};
};


const receivedActiveEventOrders = (data) => {
	return {
		type: FINISHED_GET_ACTIVE_EVENTS_ORDERS,
		orders: data,
		receiveAt: Date.now()
	};
};


export const fetchEventsOrders = (expired = false, cancelled = false) => {
	return (dispatch, getState) => {
		dispatch(getOrders());
		const { root: { authToken } } = getState();
		const config = {
			isAuth: authToken,
			params: {expired, cancelled}
		};
		const response = request(apiUrls.myOrdersEventsUrl, config);
		return response
			.then((json) => {
				if(expired){
					dispatch(receivedExpiredEventOrders(json));
				} else {
					dispatch(receivedActiveEventOrders(json));
				}
			}).catch(error => console.log(error));
	};
};


export default function fetchMyOrders (expired, cancelled){
	return (dispatch) => {
		dispatch(fetchEventsOrders(expired, cancelled));
		dispatch(fetchPackageOrders(expired, cancelled));
		dispatch(fetchMembershipOrders(expired, cancelled));
	};
}


const startGettingOrdersList = () => {
	return { type: START_GETTING_ORDERS_LIST };
};


const receivedOrdersList = (data) => {
	return {
		type: RECEIVED_ORDERS_LIST,
		orders: data,
		receiveAt: Date.now()
	};
};


const failedReceiveOrdersList = (errors) => {
	return {
		type: FAILED_RECEIVE_ORDERS_LIST,
		errors: errors,
		receiveAt: Date.now()
	};
};


export const fetchOrdersDetailInfo = (params) => {
	return (dispatch, getState) => {
		dispatch(startGettingOrdersList());
		const { root: { authToken }} = getState();
		const config = {
			method: "GET",
			isAuth: authToken,
			params
		};

		const response = request(apiUrls.orderDetailListUrl, config);

		return response
			.then((data) => {
				dispatch(receivedOrdersList(data));
			})
			.catch((error) => {
				error.response.json()
					.then((json) => dispatch(failedReceiveOrdersList(json)));
			});
	};
};


export const fetchCustomerOrders = () => {
	return (dispatch, getState) => {
		dispatch(getOrders());
		const {root: {authToken}} = getState();
		const config = {
			isAuth: authToken
		};
		const response = request(apiUrls.myOrdersUrl, config);
		return response
			.then((data) => dispatch(finishedGetOrders(data)))
			.catch((error) => console.log("An error occured: ", error));
	};
};