import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
	fetchCreationMembership,
	clearMembershipErrors
} from "actions/memberships/create_membership";
import { LIMITED } from "backend_constants/limitChoice";
import CreateMembershipComponent from "components/memberships/create_membership";
import { withCategories } from "components/utils/hoc";
import { Spinner } from "components/utils/spinner";


class CreateMembershipContainer extends React.Component {

	componentDidMount(){
		this.resetMembershipErrors();
	}

	resetMembershipErrors = () => {
		this.props.dispatch(clearMembershipErrors());
	}

	onSubmit = (values) => {

		let sendData = {
			title: values.title,
			description: values.description,
			price: values.price,
			category_limits: values.categoryLimitsChoice,
			usage_limits: values.usageLimitsChoice,
			cancellation_policy: values.cancellation_policy
		};

		if (parseInt(values.categoryLimitsChoice) === LIMITED) {
			sendData.categories = this.props.categories.map((category) => {
				if (values[category[1]]){
					return category[0];
				}
				return -1;
			}).filter((value) => value > -1);
		}

		if (parseInt(values.usageLimitsChoice) === LIMITED) {
			sendData.usage_limit = values.usageLimit;
		}

		this.props.dispatch(fetchCreationMembership(sendData));
	}

	render() {
		const {isFetching, errors} = this.props;
		let sendProps = {
			errors,
			onSubmit: this.onSubmit,
			resetMembershipErrors: this.resetMembershipErrors
		};
		return isFetching ? <Spinner/> : <CreateMembershipComponent {...sendProps} />;
	}
}

CreateMembershipContainer.propTypes = {
	dispatch: PropTypes.func.isRequired,
	errors: PropTypes.object.isRequired,
	categories: PropTypes.array.isRequired,
	isFetching: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
	return {
		errors: state.creationMembershipRoot.errors || {},
		isFetching: state.creationMembershipRoot.isFetching || false,
	};
};

const mapDispatchToProps = (dispatch) => {
	return { dispatch };
};

export default connect(
	mapStateToProps, mapDispatchToProps
)(withCategories(CreateMembershipContainer));
