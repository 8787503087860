import {
	SET_TO_ZERO_CREATEDORDER,
	TOGGLE_MENU,
	CLOSE_MENU,
	DASHBOARD_SELECTED_EVENT,
	GET_CATEGORIES,
	RECEIVED_CATEGORIES,
	RESET_EDIT_ERRORS,
	INTERNAL_SERVER_ERROR,
	RESET_HTTP_ERRORS,
	GET_FACILITY_STRIPE_ACCOUNT,
	RECEIVE_FACILITY_STRIPE_ACCOUNT
} from "actions/consts";
import {request} from "actions/utils";
import apiUrls from "routes/api";


// Set to zero createdOrder
export const setToZeroCreatedOrder = () => {
	return { type: SET_TO_ZERO_CREATEDORDER };
};

// Pending menu
export const setToMenu = () => ({
	type: TOGGLE_MENU,
	receivedAt: Date.now()
});

export const setToMenuClose = () => ({
	type: CLOSE_MENU,
	receivedAt: Date.now()
});

// Dashboard selected event
export const setDashboardSelectedEvent = (data) => ({
	type: DASHBOARD_SELECTED_EVENT,
	data: data,
	receivedAt: Date.now()
});


// Get categories list
export const getCategories = () =>{
	return{
		type: GET_CATEGORIES,
		categories: []
	};
};

export const receiveCategories = (data) => {
	return{
		type: RECEIVED_CATEGORIES,
		categories: data,
		receivedAt: Date.now()
	};
};


export const fetchCategories = () => {
	return (dispatch) => {
		dispatch(getCategories());
		const response = request(apiUrls.categoriesUrl, {});
		return response.then((categories) => {
			const categoriesChoices = categories.map((category) => Object.values(category));
			dispatch(receiveCategories(categoriesChoices));
		})
			.catch((error) => console.log(error));
	};
};


export const resetEditErrors = () => {
	return { type: RESET_EDIT_ERRORS };
};


export const raiseInternalServerError = () => {
	return{
		type: INTERNAL_SERVER_ERROR,
		error: "Sorry, unexpected server error happened. Please contact administrator"
	};
};

export const resetHttpErrors = () => {
	return {type: RESET_HTTP_ERRORS};
};


const getFacilityStripeAccount = () => {
	return {type: GET_FACILITY_STRIPE_ACCOUNT};
};


const recieveFacilityStripeAccount = (data) => {
	return {
		type: RECEIVE_FACILITY_STRIPE_ACCOUNT,
		account: data,
		receivedAt: Date.now()
	};
};


export const fetchFacilityStripeAccount = () => {
	return (dispatch) => {
		dispatch(getFacilityStripeAccount());
		const response = request(apiUrls.stripeAccountUrl, {});
		return response
			.then((data) => dispatch(recieveFacilityStripeAccount(data)))
			.catch((error) => console.log("An error occured: ", error));
	};
};