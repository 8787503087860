import {
	START_EDIT_MEMBERSHIP,
	FINISHED_EDIT_MEMBERSHIP,
	FAILED_EDIT_MEMBERSHIP,
	START_FETCHING_MEMBERSHIP,
	FINISHED_FETCHING_MEMBERSHIP,
	FAILED_FETCHING_MEMBERSHIP,
	START_DELETE_MEMBERSHIP,
	FINISHED_DELETE_MEMBERSHIP,
	FAILED_DELETE_MEMBERSHIP,
} from "../../actions/consts";


const initialState = {
	isFetching: false,
	errors: {},
	selectedMembership: {}
};

export default function editMembershipReducer(state = initialState, action) {
	switch (action.type) {

	// REDUCER GET MEMBERSHIP
	case START_FETCHING_MEMBERSHIP:
		return {
			...state,
			isFetching: true
		};

	case FINISHED_FETCHING_MEMBERSHIP:
		return {
			...state,
			isFetching: false,
			selectedMembership: action.data,
			errors: {}
		};

	case FAILED_FETCHING_MEMBERSHIP:
		return {
			...state,
			isFetching: false,
			errors: action.errors
		};

		// REDUCER EDIT MEMBERSHIP
	case START_EDIT_MEMBERSHIP:
		return {
			...state,
			isFetching: true
		};

	case FINISHED_EDIT_MEMBERSHIP:
		return {
			...state,
			isFetching: false,
			errors: {}
		};

	case FAILED_EDIT_MEMBERSHIP:
		return {
			...state,
			isFetching: false,
			errors: action.errors
		};

	case START_DELETE_MEMBERSHIP:
		return {
			...state,
			isFetching: true
		};

	case FINISHED_DELETE_MEMBERSHIP:
		return {
			...state,
			isFetching: false,
			errors: {}
		};

	case FAILED_DELETE_MEMBERSHIP:
		return {
			...state,
			isFetching: false,
			errors: action.errors
		};

	default:
		return state;
	}
}
