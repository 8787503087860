import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button, ButtonToolbar, Col, Row, Container, Collapse } from "reactstrap";
import { FaRegTrashAlt, FaEdit, FaAngleRight, FaAngleLeft } from "react-icons/fa";
import { BsFillXCircleFill } from "react-icons/bs";

import { Package } from "components/booking/packages/packageDetail";
import ModalComponent from "components/utils/modal";
import RelatedCustomers from "components/customers/customers_related";
import { withAuth, withCustomers } from "components/utils/hoc";

import frontendUrls from "routes/frontend_routes";
import fetchCustomers from "actions/customers/get_customers_list";


export const ManageItem = ({item, submitDelete, editUrl, color}) => {

	return(
		<Row className="h-100">
			<Col xs={6} className="d-flex justify-content-center align-items-center">
				<Link to={editUrl}>
					<FaEdit className={`gliph_${color} gliph_18px`}/>
				</Link>
			</Col>
			<Col xs={6} className="d-flex justify-content-center align-items-center">
				<FaRegTrashAlt className={`gliph_${color} gliph_18px dash_item_delete`} onClick={() => submitDelete(item)}/>
			</Col>
		</Row>
	);
};


const PackageRow = ({item, submitDelete, active, openItem, dispatch, authToken, customers}) => {

	const editUrl = frontendUrls.urlDashboardPackageEdit.replace(":id", item.id);

	const [details, openDetails] = useState(false);
	const [customer, openCustomers] = useState(false);
	const [hasCustomers, showCustomers] = useState(true);

	useEffect(() => {
		if(item !== active){
			openDetails(false);
			openCustomers(false);
		} else {
			openItem(item);
		}
	});
	useEffect(() => showCustomers(customers.length > 0));

	const showDetailsInfo = () => {
		if(details){
			openDetails(false);
			openItem(null);
		} else { 
			openDetails(true);
			openItem(item);
		}
	};

	const showCustomersInfo = () => {
		if (customer){
			openCustomers(false);
		} else {
			openCustomers(true);
			dispatch(fetchCustomers(authToken, {package_id: item.id}));
			openItem(item);
		}
	};

	return(
		<>
			<Row className={`dash_package ${details || customer ? "border-bottom bg_hover" : ""}`}>
				<Col xs={{size: 10, order: details ? 2 : 1}} md={7} lg={7}
					className="dash_pkg_title" onClick={showDetailsInfo}>
					<p className="m-0">{item.title}</p>
				</Col>
				<Col md={{size: 3, order: 2}} className="justify-content-end align-items-center d-none d-sm-none d-md-flex">
					<Button className="rel_customers_btn" onClick={showCustomersInfo}>
							Customers { customer && <BsFillXCircleFill className="gliph_15px"/>}
					</Button>
				</Col>
				<Col xs={{size: 2, order: 3}}
					className={`dash_pkg_manage d-none d-sm-none d-md-block ${ details || customer ? "border-left-0": ""}`}>
					<ManageItem item={item} 
						submitDelete={submitDelete}
						editUrl={editUrl}
						color={ details || customer ? "blue": "grey"}/>
				</Col>
				<Col xs={{size: 2, order: details ? 1 : 2}} className="d-flex justify-content-center align-items-center d-flex d-sm-flex d-md-none">
					{
						details ?
							<FaAngleLeft className="gliph_grey gliph_18px"/>:
							<FaAngleRight className="gliph_grey gliph_18px"/>
					}
				</Col>
			</Row>
			<Collapse isOpen={details} className="pt-3">
				<Package item={item} isBuyPage={false} columnOffset={1}/>
				<div className="d-block d-sm-block d-md-none">
					<Row className="dash_item_manage_vide">
						<Col xs={4} md={2}>
							<ManageItem item={item}
								submitDelete={submitDelete}
								editUrl={editUrl}
								color="grey"/>
						</Col>
						<Col xs={8} className="d-flex justify-content-end">
							<Button className="rel_customers_btn" onClick={showCustomersInfo}>
							Customers { details && customer && <BsFillXCircleFill className="gliph_15px"/>}
							</Button>
						</Col>
					</Row>
				</div>
			</Collapse>
			<Collapse isOpen={customer && hasCustomers}>
				<RelatedCustomers customers={customers}/>
			</Collapse>
		</>
	);
};


const AuthPackageRow = withCustomers(withAuth(PackageRow));


const PackagesTable = ({packages, submitDelete}) => {

	const [active, openItem] = useState(null);

	return(
		<Container className="mt-3 bg_white shadow_form">
			<Row className="dash_packages d-none d-sm-none d-md-flex">
				<Col xs={10} className="dash_package_title">
					<p className="font_16_medium_a0a2a7_list m-0">Title</p>
				</Col>
				<Col xs={2} className="dash_package_manage">
					<p className="font_16_medium_a0a2a7_list m-0">Manage</p>
				</Col>
			</Row>
			<div className="dash_packages_list">
				{
					packages.map((item) => {
						const rowProps = { item, submitDelete, active, openItem };
						return <AuthPackageRow {...rowProps} key={item.id}/>;
					})
				}
			</div>
		</Container>
	);
};


const PackagesComponent = ({ state, activePackages, expiredPackages, handleShow, handleClose, onDelete }) => {

	const { packageToDelete, show, error } = state;
	const activeTableProps = {
		packages: activePackages,
		submitDelete: handleShow,
	};
	const expiredTableProps = {
		packages: expiredPackages,
		submitDelete: handleShow
	};

	const [showExpired, setShowExpired] = useState(false);

	return (
		<>
			<Container className="mt-5 mb-5">
				<p className="font_24_regular_black">Packages</p>
				<ButtonToolbar className="justify-content-end mt-3 p-0">
					<div>
						<Link to={frontendUrls.urlDashboardCreatePackage}>
							<Button className="btn_customers font_16_regular_21AEF5">
								Create Package
							</Button>
						</Link>
					</div>
				</ButtonToolbar>
				<PackagesTable {...activeTableProps}/>
				<Container className="mt-3 mb-3 p-0">
					<Row className="m-0 mb-3">
						<a href="#collapse-expired"
							className="btn-link font_14_regular_2c2c2d_list p-0 m-0"
							onClick={() => {setShowExpired(!showExpired);}}>
							{showExpired ? "Hide" : "Show"} expired packages.
						</a>
					</Row>
					<Collapse isOpen={showExpired} id="collapse-expired">
						<PackagesTable {...expiredTableProps}/>
					</Collapse>
				</Container>
			</Container>
			<ModalComponent isOpen={show || error}
				header={`DELETE ${packageToDelete.title}`}
				body={error || <p>Are you sure you want to delete this package?</p>}>
				<Button className="btn btn-secondary" onClick={handleClose}>Close</Button>
				{
					!error &&
					<Button className="btn btn-danger"
						onClick={() => onDelete(packageToDelete.id)}>
						Delete Package
					</Button>
				}
			</ModalComponent>
		</>
	);
};

PackagesComponent.propTypes = {
	state: PropTypes.object.isRequired,
	onDelete: PropTypes.func.isRequired,
	handleShow: PropTypes.func.isRequired,
	handleClose: PropTypes.func.isRequired,
	activePackages: PropTypes.arrayOf(PropTypes.object),
	expiredPackages: PropTypes.arrayOf(PropTypes.object),
};

PackagesTable.propTypes = {
	packages: PropTypes.arrayOf(PropTypes.object),
	submitDelete: PropTypes.func.isRequired,
};

PackageRow.propTypes = {
	item: PropTypes.object.isRequired,
	submitDelete: PropTypes.func.isRequired,
	active: PropTypes.object.isRequired,
	openItem: PropTypes.func.isRequired,
	dispatch: PropTypes.func.isRequired,
	authToken: PropTypes.object.isRequired,
	customers: PropTypes.arrayOf(PropTypes.object).isRequired,
};

ManageItem.propTypes = {
	item: PropTypes.object.isRequired,
	submitDelete: PropTypes.func,
	editUrl: PropTypes.string.isRequired,
	color: PropTypes.string.isRequired,
};


export default PackagesComponent;
