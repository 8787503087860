import React, {useState, Fragment} from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import frontendUrls from "../../routes/frontend_routes";

import {
	Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Col, InputGroupAddon
} from "reactstrap";
import { FaRegBell } from "react-icons/fa";


const UserAuthComponent = ({ state, onClickLogout }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggle = () => setDropdownOpen(prevState => !prevState);
	return (
		<Fragment>
			{state.authToken &&
			<Row>
				<Col xs={1} className="mt-4 display_inline_block">
					<InputGroupAddon className="">
						<FaRegBell className="text-center height_20px width_20px bell_color cursor-pointer" />
					</InputGroupAddon>
				</Col>
				<Col xs={6} className="d-none d-sm-block">
					<p className="mt-4 text-center display_inline_block font_14_regular_5e6977">
						{state.first_name} {state.last_name}
					</p>
				</Col>
				<Col xs={2}>
					<Dropdown className="mt-3 cursor-pointer" direction="left" isOpen={dropdownOpen} toggle={toggle}>
						<DropdownToggle className="user_avatar rounded-circle" caret/>
						<DropdownMenu className="margin_top_10px">
							<DropdownItem onClick={onClickLogout}>
						Log out
							</DropdownItem>
						</DropdownMenu>
					</Dropdown>
				</Col>
			</Row>
			}
			{!state.authToken &&
			<Row className="mt-xs-3 mt-sm-3 mt-md-4 mt-lg-4 justify-content-end">
				<Col xs={12} md={5} lg={4}>
					<Link to={frontendUrls.urlLogin} className="float-right">Log in</Link>
				</Col>
				<Col xs={12} md={5} lg={4}>
					<Link to={frontendUrls.urlRegister} className="float-right" >Register</Link>
				</Col>
			</Row>
			}
		</Fragment>
	);
};

UserAuthComponent.propTypes = {
	state: PropTypes.object.isRequired,
	onClickLogout: PropTypes.func.isRequired
};

export default UserAuthComponent;