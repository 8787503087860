import {
	START_BUY_BOOKING_EVENT,
	FINISH_BUY_BOOKING_EVENT,
	FAIL_BUY_BOOKING_EVENT,
	SET_TO_ZERO_CREATEDORDER,
	START_CHECKIN_BOOKING_EVENT,
	FINISH_CHECKIN_BOOKING_EVENT,
	FAIL_CHECKIN_BOOKING_EVENT,
	START_BUY_FREE_BOOKING_EVENT,
	FINISH_BUY_FREE_BOOKING_EVENT,
	FAIL_BUY_FREE_BOOKING_EVENT,
	START_PAY_AT_VENUE_BOOKING_EVENT,
	FINISH_PAY_AT_VENUE_BOOKING_EVENT,
	FAIL_PAY_AT_VENUE_BOOKING_EVENT,
} from "../../../actions/consts";


const initialState = {
	isFetching: true,
	errors: {},
	createdOrder: {},
	// selectedBookingEvent: {}
};

export default function orderEventReducer(state = initialState, action) {
	switch (action.type) {

	// REDUCER BUY EVENT
	case START_BUY_BOOKING_EVENT:
		return {
			...state,
			isFetching: true
		};

	case FINISH_BUY_BOOKING_EVENT:
		return {
			...state,
			isFetching: false,
			createdOrder: action.data,
			// selectedBookingEvent: action.data,
			errors: {}
		};

	case FAIL_BUY_BOOKING_EVENT:
		return {
			...state,
			isFetching: false,
			errors: action.errors
		};

	case SET_TO_ZERO_CREATEDORDER:
		return {
			...state,
			isFetching: false,
			createdOrder: {},
			errors: {}
		};

		// REDUCER BUY FREE EVENT
	case START_BUY_FREE_BOOKING_EVENT:
		return {
			...state,
			isFetching: true
		};

	case FINISH_BUY_FREE_BOOKING_EVENT:
		return {
			...state,
			isFetching: false,
			createdOrder: action.data,
			errors: {}
		};

	case FAIL_BUY_FREE_BOOKING_EVENT:
		return {
			...state,
			isFetching: false,
			errors: action.errors
		};

		// REDUCER PAY AT VENUE EVENT
	case START_PAY_AT_VENUE_BOOKING_EVENT:
		return {
			...state,
			isFetching: true
		};

	case FINISH_PAY_AT_VENUE_BOOKING_EVENT:
		return {
			...state,
			isFetching: false,
			createdOrder: action.data,
			errors: {}
		};

	case FAIL_PAY_AT_VENUE_BOOKING_EVENT:
		return {
			...state,
			isFetching: false,
			errors: action.errors
		};

		// REDUCER CHECKIN EVENT
	case START_CHECKIN_BOOKING_EVENT:
		return {
			...state,
			isFetching: true
		};

	case FINISH_CHECKIN_BOOKING_EVENT:
		return {
			...state,
			isFetching: false,
			createdOrder: action.data,
			errors: {}
		};

	case FAIL_CHECKIN_BOOKING_EVENT:
		return {
			...state,
			isFetching: false,
			errors: action.errors
		};

	default:
		return state;
	}
}
