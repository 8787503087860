import {
	GET_CUSTOMERS,
	RECEIVE_CUSTOMERS
} from "actions/consts";


const initialState = {
	customersRoot:{
		isFetching: false,
		customers: []
	}
};


const customersReducer = (state = initialState, action) => {
	switch(action.type){

	case GET_CUSTOMERS:
		return {
			...state,
			isFetching: true,
		};

	case RECEIVE_CUSTOMERS:
		return {
			...state,
			isFetching: false,
			customers: action.customers,
			lastUpdated: action.receivedAt
		};

	default:
		return state;
	}
};


export default customersReducer;