import urls from "routes/frontend_routes";


const BookingRoutes = [
	{ path: urls.urlRoot, breadcrumb: "Home"},
	{ path: urls.urlBookingMyOrders, breadcrumb: "Dashboard"},
	{ path: urls.urlBookingFaq, breadcrumb: "FAQ"},

	{ path: urls.urlBookingEvents, breadcrumb: "Events"},
	{ path: urls.urlBookingEventId, breadcrumb: "Details"},
	{ path: urls.urlBookingOrderEvent, breadcrumb: "Buy"},

	{ path: urls.urlBookingPackages, breadcrumb: "Packages"},
	{ path: urls.urlBookingPackagesId, breadcrumb: "Details"},
	{ path: urls.urlBookingPackagesOrder, breadcrumb: "Buy"},

	{ path: urls.urlBookingMemberships, breadcrumb: "Memberships"},
	{ path: urls.urlBookingMembershipId, breadcrumb: "Details"},
	{ path: urls.urlBookingOrderMembership, breadcrumb: "Buy"},

];


export default BookingRoutes;