import { DO_LOGOUT, DESTROY_SESSION } from "actions/consts";


export const logoutUser = () => {
	return { type: DO_LOGOUT };
};

export const destroySession = () => {
	return { type: DESTROY_SESSION };
};


export const doLogout = () => {
	return (dispatch) => {
		dispatch(logoutUser());
		dispatch(destroySession());
	};
};