import {
	START_CREATION_CUSTOMER,
	FINISHED_CREATION_CUSTOMER,
	FAILED_CREATION_CUSTOMER
} from "actions/consts";


const initialState = {
	isFetching: false,
	data: {},
	errors: {}
};


const creationCustomerReducer = (state = initialState, action) => {
	switch(action.type){
	case START_CREATION_CUSTOMER:
		return {
			...state,
			isFetching: true,
			errors: {}
		};

	case FINISHED_CREATION_CUSTOMER:
		return {
			...state,
			data: action.data,
			isFetching: false
		};

	case FAILED_CREATION_CUSTOMER:
		return {
			...state,
			errors: action.errors,
			isFetching: false
		};

	default:
		return state;
	}
};


export default creationCustomerReducer;