import {
	SEND_RESET_PASSWORD_REQUEST,
	SUCCESS_RESET_PASSWORD_REQUEST,
	FAILED_RESET_PASSWORD_REQUEST,
	START_CONFIRM_RESET_PASSWORD,
	FAILED_CONFIRM_RESET_PASSWORD,
	FINISHED_CONFIRM_RESET_PASSWORD,
	RESET_ERRORS_FROM_PASSWORD
} from "actions/consts";


const initialState = {
	errors: {},
	isFetching: {},
	isEmailSended: false
};


export default function resetPasswordReducer(state=initialState, action){
	switch(action.type){
	case SEND_RESET_PASSWORD_REQUEST:
		return{
			...state,
			isFetching: true
		};
	case SUCCESS_RESET_PASSWORD_REQUEST:
		return{
			...state,
			errors: {},
			isFetching: false,
			isEmailSended: true
		};
	case FAILED_RESET_PASSWORD_REQUEST:
		return{
			...state,
			errors: action.errors,
			isFetching: false
		};
	case START_CONFIRM_RESET_PASSWORD:
		return{
			...state,
			errors: {},
			isFetching: true
		};
	case FINISHED_CONFIRM_RESET_PASSWORD:
		return{
			...state,
			errors: {},
			isFetching: false
		};
	case FAILED_CONFIRM_RESET_PASSWORD:
		return{
			...state,
			errors: action.errors,
			isFetching: false
		};
	case RESET_ERRORS_FROM_PASSWORD:
		return{
			...state,
			errors: {},
			isEmailSended: false
		};
	default:
		return state;
	}
}