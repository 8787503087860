import {
	START_CREATION_EVENT,
	FINISHED_CREATION_EVENT,
	FAILED_CREATION_EVENT,
	CLEAR_EVENT_ERRORS
} from "actions/consts";
import apiUrls from "routes/api";
import { history } from "store";
import frontendUrls from "routes/frontend_routes";
import { request } from "actions/utils";

// Processing register
const startCreationEvent = () => {
	return { type: START_CREATION_EVENT };
};

const finishCreationEvent = (data) => {
	return {
		type: FINISHED_CREATION_EVENT,
		data: data,
		receivedAt: Date.now()
	};
};

const failedCreationEvent = (data) => {
	return {
		type: FAILED_CREATION_EVENT,
		errors: data,
		receivedAt: Date.now()
	};
};

export const fetchCreationEvent = (sendData) => {
	return (dispatch, getState) => {
		dispatch(startCreationEvent());
		const { root: { authToken }} = getState();
		const config = {
			isAuth: authToken,
			method: "POST"
		};

		const response = request(apiUrls.creatEvent, config, sendData);
		return response
			.then((json) => {
				dispatch(finishCreationEvent(json));
				history.push(frontendUrls.urlDashboardEvents);
			})
			.catch((error) => {
				error.response.json()
					.then((json) => {
						dispatch(failedCreationEvent(json));
					});
			});
	};
};


export const clearEventErrors = () => {
	return {type: CLEAR_EVENT_ERRORS};
};