import {
	START_GET_BOOKING_EVENT,
	FINISH_GET_BOOKING_EVENT,
	FAIL_GET_BOOKING_EVENT
} from "actions/consts";
import { request } from "actions/utils";


// Processing fetching event
const startFetchingBookingEvent = () => {
	return { type: START_GET_BOOKING_EVENT };
};

const finishFetchingBookingEvent = (data) => {
	return {
		type: FINISH_GET_BOOKING_EVENT,
		data: data,
		receivedAt: Date.now()
	};
};

const failedFetchingBookingEvent = (data) => {
	return {
		type: FAIL_GET_BOOKING_EVENT,
		errors: data,
		receivedAt: Date.now()
	};
};

export const fetchSelectedBookingEvent = (apiUrlSelectedEvent) => {
	return (dispatch, getState) => {
		dispatch(startFetchingBookingEvent);
		const { root: { authToken }} = getState();
		const config = {
			method: "GET",
			isAuth: authToken
		};

		const response = request(apiUrlSelectedEvent, config);

		return response
			.then((json) => {
				dispatch(finishFetchingBookingEvent(json));
			})
			.catch((error) => {
				error.response.json()
					.then((json) => {
						dispatch(failedFetchingBookingEvent(json));
					});
			});
	};
};