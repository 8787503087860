import {
	START_BUY_BOOKING_MEMBERSHIP,
	FINISH_BUY_BOOKING_MEMBERSHIP,
	FAIL_BUY_BOOKING_MEMBERSHIP
} from "actions/consts";
import {request} from "actions/utils";


// Processing fetching membership
const startBuyBookingMembership = () => {
	return { type: START_BUY_BOOKING_MEMBERSHIP };
};

const finishBuyBookingMembership = (data) => {
	return {
		type: FINISH_BUY_BOOKING_MEMBERSHIP,
		data: data,
		receivedAt: Date.now()
	};
};

export const failBuyBookingMembership = (data) => {
	return {
		type: FAIL_BUY_BOOKING_MEMBERSHIP,
		errors: data,
		receivedAt: Date.now()
	};
};

export const buyBookingMembership = (apiUrlBuyMembership, stripeToken) => {
	return (dispatch, getState) => {
		dispatch(startBuyBookingMembership);
		const { root: { authToken }} = getState();
		const config = {
			method: "POST",
			isAuth: authToken
		};
		const body = {stripe_token: stripeToken};

		const response = request(apiUrlBuyMembership, config, body);

		return response
			.then((json) => {
				dispatch(finishBuyBookingMembership(json));
			})
			.catch((error) => {
				error.response.json()
					.then((json) => {
						dispatch(failBuyBookingMembership(json));
					});
			});
	};
};