import React, {useRef, useState} from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { FaCheck } from "react-icons/fa";
import { PozenEventFullPopover } from "components/calendar/config/views/utils";

export const WeekHeader = ({label}) => {
	return <div className="rbc-header-item">{label}</div>;
};


WeekHeader.propTypes = {
	label: PropTypes.string.isRequired
};


export const WeekEvent = ({event, title}) =>{

	const [show, showEventDetails] = useState(false);
	const pozenEvent = useRef(null);

	const getTarget = () => {
		return ReactDOM.findDOMNode(pozenEvent.current); //eslint-disable-line
	};

	const toggle = () => {
		showEventDetails(!show);
	};

	return (
		<div ref={pozenEvent} className="rbc-week-event" onClick={toggle}>
			<span className="rbc-week-event-title">{title}</span>
			<FaCheck className="rbc-week-event-more"/>
			<PozenEventFullPopover isOpen={show} event={event} target={getTarget} toggle={toggle}/>
		</div>
	);
};


WeekEvent.propTypes = {
	event: PropTypes.object.isRequired,
	title: PropTypes.string.isRequired
};