import {
	START_BUY_BOOKING_MEMBERSHIP,
	FINISH_BUY_BOOKING_MEMBERSHIP,
	FAIL_BUY_BOOKING_MEMBERSHIP,
	SET_TO_ZERO_CREATEDORDER
} from "../../../actions/consts";


const initialState = {
	isFetching: true,
	errors: {},
	createdOrder: {}
};

export default function orderMembershipReducer(state = initialState, action) {
	switch (action.type) {

	// REDUCER GET MEMBERSHIP
	case START_BUY_BOOKING_MEMBERSHIP:
		return {
			...state,
			isFetching: true
		};

	case FINISH_BUY_BOOKING_MEMBERSHIP:
		return {
			...state,
			isFetching: false,
			createdOrder: action.data,
			errors: {}
		};

	case FAIL_BUY_BOOKING_MEMBERSHIP:
		return {
			...state,
			isFetching: false,
			errors: action.errors
		};
	case SET_TO_ZERO_CREATEDORDER:
		return {
			...state,
			isFetching: false,
			createdOrder: {},
			errors: {}
		};

	default:
		return state;
	}
}
