import {
	START_LOGIN,
	FINISHED_LOGIN,
	FAILED_LOGIN,
	HTTP_308_PERMANENT_REDIRECT
} from "actions/consts";
import frontendUrls from "routes/frontend_routes";
import apiUrls from "routes/api";
import { history } from "store";
import { request } from "actions/utils";
import { fetchCategories } from "actions/util_actions";


// Processing login
const startLogin = () => {
	return {
		type: START_LOGIN,
		authToken: "",
		first_name: "",
		last_name: "",
		role: ""
	};
};

const finishLogin = (data, status) => {
	return {
		type: FINISHED_LOGIN,
		authToken: data.token,
		first_name: data.first_name,
		last_name: data.last_name,
		role: data.role,
		isFirstOwnerLogin: data.is_first_owner_login,
		asGuest: status === HTTP_308_PERMANENT_REDIRECT,
		receivedAt: Date.now()
	};
};

const failedLogin = (data) => {
	return {
		type: FAILED_LOGIN,
		errors: data,
		receivedAt: Date.now()
	};
};

export const checkLogin = (dispatch, json, status) => {
	if (json) {
		dispatch(finishLogin(json, status));
		dispatch(fetchCategories());
		if (status === HTTP_308_PERMANENT_REDIRECT) {
			history.push(frontendUrls.urlMyOrder);
		} else {
			if(json.is_first_owner_login){
				history.push(frontendUrls.urlDashboardBanner);
			}else{
				history.push(frontendUrls.urlDashboard);
			}
		}
	} else {
		dispatch(failedLogin(json));
	}
};

export const fetchLogin = (emailOrUsername, password) => {
	return (dispatch) => {
		dispatch(startLogin());
		const config = {
			method: "POST"
		};
		const body = {
			username: emailOrUsername,
			password: password
		};

		const response = request(apiUrls.loginUrl, config, body, true)

		return response
			.then(
				response => {
					const status = response.status;
					response.json().then( json => checkLogin(dispatch, json, status))
				}
			).catch(error => {
				error.response.json()
					.then(error => {dispatch(failedLogin(error));});
			});
	};
};
