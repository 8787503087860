import {
	START_BUY_BOOKING_EVENT,
	FINISH_BUY_BOOKING_EVENT,
	FAIL_BUY_BOOKING_EVENT,
	SET_TO_ZERO_CREATEDORDER
} from "actions/consts";
import { request } from "actions/utils";


// Processing fetching event
const startBuyBookingEvent = () => {
	return { type: START_BUY_BOOKING_EVENT };
};

const finishBuyBookingEvent = (data) => {
	return {
		type: FINISH_BUY_BOOKING_EVENT,
		data: data,
		receivedAt: Date.now()
	};
};

export const failBuyBookingEvent = (data) => {
	return {
		type: FAIL_BUY_BOOKING_EVENT,
		errors: data,
		receivedAt: Date.now()
	};
};

export const buyBookingEvent = (apiUrlBuyEvent, stripeToken) => {
	return (dispatch, getState) => {
		dispatch(startBuyBookingEvent);
		const { root: { authToken }} = getState();
		const config = {
			method: "POST",
			isAuth: authToken
		};
		const body = {stripe_token: stripeToken};
		
		const response = request(apiUrlBuyEvent, config, body);
		return response
			.then((json) => {
				dispatch(finishBuyBookingEvent(json));
			})
			.catch((error) => {
				error.response.json()
					.then((json) => {
						dispatch(failBuyBookingEvent(json));
					});
			});
	};
};

// UTIL Set to zero createdOrder
export const setToZeroCreatedOrder = () => {
	return { type: SET_TO_ZERO_CREATEDORDER };
};