import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setToMenu, setToMenuClose } from "actions/util_actions";
import { doLogout } from "../../../actions/auth/logout";
import HeaderComponent from "../../../components/elements/booking_pages/header";


class HeaderContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			search: "",
			first_name: props.first_name,
			last_name: props.last_name
		};
		this.onClickLogout = this
			.onClickLogout
			.bind(this);
	}

	onClickLogout() {
		this
			.props
			.dispatch(doLogout());
	}

	render() {
		const { isMenuOpen, setToMenuOpenDispatched, setToMenuCloseDispatched} = this.props;
		let sendProps = {
			state: this.state,
			isMenuOpen,
			setToMenuOpenDispatched,
			onClickLogout: this.onClickLogout,
			setToMenuCloseDispatched,
		};
		return (<HeaderComponent {...sendProps} />);
	}
}

HeaderContainer.propTypes = {
	dispatch: PropTypes.func.isRequired,
	first_name: PropTypes.string.isRequired,
	last_name: PropTypes.string.isRequired,
	isMenuOpen: PropTypes.bool.isRequired,
	setToMenuOpenDispatched: PropTypes.func.isRequired,
	setToMenuCloseDispatched: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
	return {
		first_name: state.root.first_name,
		last_name: state.root.last_name,
		isMenuOpen: state.utilRoot.isMenuOpen,
	};
};

export default connect(mapStateToProps,  {
	setToMenuOpenDispatched: setToMenu,
	setToMenuCloseDispatched: setToMenuClose,
})(HeaderContainer);
