import React, { Component } from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import { Container, Button } from "reactstrap";
import ModalComponent from "components/utils/modal";
import { withAuth } from "components/utils/hoc";
import { isEmpty } from "components/utils";

import { history } from "store";
import { setToZeroCreatedOrder } from "actions/util_actions";


class OrderContainer extends Component {

	state = {
		show: false
	};

	componentDidUpdate(prevProps){
		const { token, errors } = this.props;
		const hasErrors = !isEmpty(errors) && isEmpty(prevProps.errors);
		if( token !== prevProps.token || hasErrors){
			this.setState({ show: true });
		}
	}

	handleClose = () => {
		const { dispatch, redirect, isFirstPurchase, services } = this.props;
		this.setState({show: false});
		dispatch(setToZeroCreatedOrder());
		if(isFirstPurchase && !isEmpty(services) && services.show_parq_form_url){
			window.open(services.parq_form_url, "PARQ form");
		}
		history.push(redirect);
	}

	render() {
		const { show } = this.state;
		const { orderType, errors } = this.props;
		return (
			<>
				<Container className="mt-5">
					<p className="font_24_regular_black_list mt-3">Check out via Credit Card</p>
					{this.props.children}
				</Container>
				<ModalComponent isOpen={show}
					header={<>Buy <span className="text-title">{orderType}</span></>}
					body={
						isEmpty(errors)?
							<p>You have bought {orderType} successfully</p>:
							<p>{errors.detail || errors.message || errors.non_field_errors[0]}</p>

					}>
					<Button className="btn close_btn" onClick={this.handleClose}>Close</Button>
				</ModalComponent>
			</>
		);
	}
}


OrderContainer.propTypes = {
	dispatch: PropTypes.func.isRequired,
	errors: PropTypes.array,
	token: PropTypes.string,
	redirect: PropTypes.string.isRequired,
	children: PropTypes.element.isRequired,
	orderType: PropTypes.string.isRequired,
	isFirstPurchase: PropTypes.bool.isRequired,
	services: PropTypes.object.isRequired
};


const mapStateToProps = ({facilityAdditionalServicesRoot: {services}}) => {
	return {services};
};


export default connect(mapStateToProps)(withAuth(OrderContainer));