import {
	GET_BOOKING_PACKAGES,
	RECEIVE_BOOKING_PACKAGES
} from "actions/consts";
import apiUrls from "routes/api";
import { request } from "actions/utils";


// Processing getting packages list
const getBookingPackages = () => {
	return {
		type: GET_BOOKING_PACKAGES,
		bookingPackages: []
	};
};

const receiveBookingPackages = (data) => {
	return {
		type: RECEIVE_BOOKING_PACKAGES,
		bookingPackages: data,
		receivedAt: Date.now()
	};
};

export const fetchBookingPackages = () => {
	return (dispatch) => {
		dispatch(getBookingPackages());
		const config = {
			method: "GET",
			params: {
				expired: false
			}
		};
		const response = request(apiUrls.bookPackagesUrl, config);
		return response
			.then(json => dispatch(receiveBookingPackages(json)))
			.catch(error => console.log("An error occured.", error));
	};
};
