import {
	START_BUY_FREE_BOOKING_EVENT,
	FINISH_BUY_FREE_BOOKING_EVENT,
	FAIL_BUY_FREE_BOOKING_EVENT
} from "actions/consts";
import { request } from "actions/utils";


// Processing fetching event
const startBuyFreeBookingEvent = () => {
	return { type: START_BUY_FREE_BOOKING_EVENT };
};

const finishBuyFreeBookingEvent = (data) => {
	return {
		type: FINISH_BUY_FREE_BOOKING_EVENT,
		data: data,
		receivedAt: Date.now()
	};
};

const failBuyFreeBookingEvent = (data) => {
	return {
		type: FAIL_BUY_FREE_BOOKING_EVENT,
		errors: data,
		receivedAt: Date.now()
	};
};

export const fetchBuyFreeBookingEvent = (buyFreeUrl) => {
	return (dispatch, getState) => {
		dispatch(startBuyFreeBookingEvent);
		const { root: { authToken }} = getState();
		const config = {
			method: "POST",
			isAuth: authToken
		};

		const response = request(buyFreeUrl, config);

		return response
			.then((json) => {
				dispatch(finishBuyFreeBookingEvent(json));
			})
			.catch((error) => {
				error.response.json()
					.then((json) => {
						dispatch(failBuyFreeBookingEvent(json));
					});
			});
	};
};