import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "reactstrap";

import LoginForm from "../forms/auth/login";


const LoginComponent = ({ onSubmit, errors }) => {
	return (
		<Container fluid className="px-0">
			<Row className="width_320px mx-auto">
				<Col className="px-sm-1">
					<img
						className="ml-sm-3 height_90px margin_top_25"
						src="/static/logo_full.png"
						alt="Pozen logo" />
					<div className="login_field bg_white shadow border_radius_03rem margin_top_40px">
						<p className="text-center padding_top_20px font_18_medium_616976">SIGN IN</p>
						<hr className="margin_0" />
						<div className="padding_left_7 padding_right_7">
							<p className="margin_0 padding_top_25px font_14_regular_86939e">Hello there, haven&#39;t we seen you before</p>
							<LoginForm onSubmit={onSubmit} errors={errors} />
						</div>
					</div>
					<div className="text-center fixed-bottom">
						<p className="font_14_regular_666f78">© 2020 POZEN. All Rights Reserved</p>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

LoginComponent.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	errors: PropTypes.object
};

export default LoginComponent;
