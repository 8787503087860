import React from "react";
import PropTypes from "prop-types";
import {Col} from "reactstrap";

import { NONE, WEEKLY, MONTHLY } from "backend_constants/eventRepeat";
import EventForm from "components/forms/events/event_form";
import weekDays from "backend_constants/weekDays";


const EditEventComponent = ({ showModal, onSubmit, onDelete, handleShow, handleClose, errors, initialValues, isTimetableEditable }) => {
	const sendValues = {
		title: initialValues.title,
		description: initialValues.description,
		category: initialValues.category,
		capacity: initialValues.capacity,
		repeats: Boolean(initialValues.repeat) && initialValues.repeat.toString() !== NONE,
		free: initialValues.price === "0.00",
		cancellationPolicy: initialValues.cancellation_policy,
		payAtVenue: initialValues.pay_at_venue,
		repeatChoice: initialValues.repeat && initialValues.repeat.toString(),
		fromDate: initialValues.from_date,
		toDate: initialValues.until_date,
		price: initialValues.price,
		location: initialValues.location,
		instructor: initialValues.instructor
	};
	if (initialValues.repeat && initialValues.repeat.toString() === WEEKLY) {
		let weeklyDays = initialValues.repeat_times[0].weekly_days;
		// eslint-disable-next-line
		weekDays.map(function (weeklyArray) {
			if (weeklyDays.indexOf(weeklyArray[0]) !== -1) {
				sendValues[weeklyArray[1]] = true;
			}
		});
	} else if (initialValues.repeat && initialValues.repeat.toString() === MONTHLY) {
		sendValues.daysOfTheMonth = initialValues.repeat_times[0].days.join();
	}

	if (initialValues.repeat_times) {
		sendValues.fromTime = initialValues.repeat_times[0].start_time;

		if (initialValues.duration) {
			let now = new Date();
			let [startHour,
				startMinute] = sendValues
				.fromTime
				.split(":");
			let startDateTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), startHour, startMinute);
			var newDateObj = new Date(startDateTime.getTime() + initialValues.duration * 60000);
			let minutes = newDateObj.getMinutes();
			if (minutes.toString().length < 2) {
				minutes = "0" + minutes;
			}
			let hours = newDateObj.getHours();
			if (hours.toString().length < 2) {
				hours = "0" + hours;
			}
			sendValues.toTime = hours + ":" + minutes;
		}
	}
	return (
		<Col sm="12" md="11" className="mx-auto">
			<p className="margin_top_15px margin_bottom_20px font_24_regular_black_list">Edit Event</p>
			<EventForm showModal={showModal} onSubmit={onSubmit} 
				onDelete={onDelete} handleShow={handleShow} 
				handleClose={handleClose} errors={errors} 
				initialValues={sendValues} edit={true} 
				isTimetableEditable={isTimetableEditable}/>
		</Col>
	);
};

EditEventComponent.propTypes = {
	showModal: PropTypes.bool.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	handleShow: PropTypes.func.isRequired,
	handleClose: PropTypes.func.isRequired,
	errors: PropTypes.object,
	initialValues: PropTypes.object,
	isTimetableEditable: PropTypes.bool.isRequired
};

export default EditEventComponent;
