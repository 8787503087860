import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "reactstrap";

import FacilityPromoForm from "components/forms/facilities/promo";


const FacilityPromoComponent = ({isEditPage, promo, errors, submitChanges, submitDelete}) => {
	return (
		<Container className="mt-5">
			<p className="font_24_regular_black_list">Banner</p>
			<Row>
				<Col xs={12} className="d-flex justify-content-center">
					<FacilityPromoForm
						isEditForm={isEditPage}
						initialValues={promo}
						errors={errors}
						onSubmit={submitChanges}
						onDelete={submitDelete}/>
				</Col>
			</Row>
		</Container>
	);
};


FacilityPromoComponent.propTypes = {
	isEditPage: PropTypes.bool.isRequired,
	promo: PropTypes.object.isRequired,
	errors: PropTypes.object,
	submitChanges: PropTypes.func.isRequired,
	submitDelete: PropTypes.func.isRequired
};


export default FacilityPromoComponent;