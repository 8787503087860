import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import LoginComponent from "../../components/auth/login";
import { fetchLogin } from "../../actions/auth/login";


class LoginContainer extends React.Component {

	constructor(props) {
		super(props);
		// This binding is necessary to make `this` work in the callback
		this.onSubmit = this
			.onSubmit
			.bind(this);
	}

	onSubmit(values) {
		this
			.props
			.dispatch(fetchLogin(values.emailOrUsername, values.password));
	}

	render() {
		let sendProps = {
			onSubmit: this.onSubmit,
			errors: this.props.errors
		};
		return (<LoginComponent {...sendProps} />);
	}
}

LoginContainer.propTypes = {
	dispatch: PropTypes.func.isRequired,
	errors: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
	return {
		errors: state.loginRoot.errors || {}
	};
};

const mapDispatchToProps = (dispatch) => {
	return { dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
