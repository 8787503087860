import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import {
	Container, Row, Col, Button,
	ButtonToolbar, Collapse, Spinner,
} from "reactstrap";
import { 
	BsChevronRight, BsTrash, BsCalendar,
	BsTagFill, BsFillXCircleFill,
	BsPencilSquare
} from "react-icons/bs";

import RelatedCustomers from "components/customers/customers_related";
import { withAuth, withCustomers } from "components/utils/hoc";
import ModalComponent from "components/utils/modal";

import frontendUrls from "routes/frontend_routes";
import fetchCustomers from "actions/customers/get_customers_list";
import { getDateRange, getCategoryName } from "components/utils";


const EventRow = ({event, handleShow, openedEvent, handleOpenEvent, setDashboardSelectedEventDispatched,
	customers, dispatch, authToken}) => {

	const [details, openDetails] = useState(false);
	const [customer, openCustomers] = useState(false);
	const [hasCustomers, showCustomers] = useState(true);

	useEffect(() => showCustomers(customers.length > 0));
	useEffect(() => {
		if(event !== openedEvent){
			openDetails(false);
			openCustomers(false);
		} else {
			handleOpenEvent(event);
		}
	});

	const showDetailsInfo = () => {
		if(details){
			openDetails(false);
		} else { 
			handleOpenEvent(event);
			openDetails(true);
		}
	};

	const showCustomersInfo = () => {
		if (customer){
			openCustomers(false);
		} else {
			handleOpenEvent(event);
			openCustomers(true);
			dispatch(fetchCustomers(authToken, {event_id: event.id}));
		}
	};

	return(
		<>
			<Row className={`eventDashboard ${customer || details ? "border-bottom bg_hover": ""}`}>
				<Col xs={12}>
					<Row className="event-row">
						<Col xs={10} md={7} lg={7}
							onClick={showDetailsInfo}
							className="title font_16_medium_black_list order-sm-1 order-md-1 order-lg-2 cursor-pointer">
							{event.title}
						</Col>
						<Col md={{size: 3, order: 2}} lg={{size: 3, order: 2}} className="justify-content-end align-items-center d-none d-sm-none d-md-flex">
							<Button className="rel_customers_btn" onClick={showCustomersInfo}>
									Customers { customer && <BsFillXCircleFill className="gliph_15px"/>}
							</Button>
						</Col>
						<Col xs={2} md={2} lg={2} className={`event-manage d-none d-sm-none d-md-flex order-md-4 ${ details || customer ? "border-left-0": ""}`}>
							<Container>
								<Row>
									<Col xs={6} className="text-center">
										<Link to={frontendUrls.urlDashboardEventEdit.replace(":id", event.id)}>
											<BsPencilSquare className="gliph_blue gliph_16px"/>
										</Link>
									</Col>
									<Col xs={6} className="text-center">
										<BsTrash className="gliph_blue gliph_16px cursor-pointer"
											onClick={() => { handleShow(event); }}
										/>
									</Col>
								</Row>
							</Container>
						</Col>
						<Col xs={2} className="event-control d-flex d-sm-flex d-md-none order-3 order-sm-3 order-5 cursor-pointer">
							<BsChevronRight onClick={() => {setDashboardSelectedEventDispatched(event);}} className="gliph_15px"/>
						</Col>
					</Row>
				</Col>
			</Row>
			<Collapse isOpen={details}>
				<EventDetail event={event}
					handleShow={handleShow}
					handleCustomers={showCustomersInfo}
					customer={customer}/>
			</Collapse>
			<Collapse isOpen={customer && hasCustomers}>
				<RelatedCustomers customers={customers}/>
			</Collapse>
		</>
	);
};

const AuthEventRow = withCustomers(withAuth(EventRow));

const EventsTable = ({events, handleShow, setDashboardSelectedEventDispatched}) => {
	
	const [active, handleOpenEvent] = useState(null);
	
	return(
		<Container className="shadow_form mt-2 mb-3 bg_white">
			<Row className="events-dash d-none d-sm-none d-md-flex">
				<Col xs={10} className="events_title font_13_medium_a0a2a7_list d-none d-sm-none d-md-flex">TITLE</Col>
				<Col xs={2} className="events_title d-none d-sm-none d-md-flex">
					<Container>
						<Row>
							<Col className="text-center font_13_medium_a0a2a7_list">MANAGE</Col>
						</Row>
					</Container>
				</Col>
			</Row>
			<div className="events-list">
				{
					events.map((event) =>
						<AuthEventRow
							handleOpenEvent={handleOpenEvent}
							key={event.id}
							openedEvent={active}
							event={event}
							handleShow={handleShow}
							setDashboardSelectedEventDispatched={setDashboardSelectedEventDispatched} />)
				}
			</div>
		</Container>
	);
};

const EventsComponent = ({events, onDelete, handleShow,	handleClose, isFetching, handleOpenEvent,
	setDashboardSelectedEventDispatched, show, openedEvent, eventToDelete,
}) => {
	return (
		<div className="mt-5">
			{isFetching
				?	<Spinner />
				: <Container>
					<p className="font_24_regular_black_list">Events</p>
					<ButtonToolbar className="justify-content-end margin_bottom_25px padding_0">
						<Link to={frontendUrls.urlDashboardCreateEvent}>
							<Button className="btn_customers width_170px font_16_regular_21AEF5" bsSize="large">
							Create Event
							</Button>
						</Link>
					</ButtonToolbar>
					<EventsTable
						handleOpenEvent={handleOpenEvent}
						events={events}
						handleShow={handleShow}
						onDelete={onDelete}
						openedEvent={openedEvent}
						setDashboardSelectedEventDispatched={setDashboardSelectedEventDispatched}
					/>
				</Container>
			}
			<ModalComponent isOpen={show}
				header={`DELETE ${eventToDelete.title}`}
				body={<p>Are you sure you want to delete this event?</p>}>
				<Button className="btn-secondary" onClick={handleClose}>Close</Button>
				<Button className="btn-danger" onClick={function () { onDelete(eventToDelete.id); }}>
					Delete Event
				</Button>
			</ModalComponent>
		</div>
	);
};

export const EventDetail = ({ event, handleShow, handleCustomers, customer }) => {

	return (
		<div>
			<Row className="padding_top_25px">
				<Col xs={1} className="text-right">
					<BsCalendar className="gliph_grey gliph_18px" />
				</Col>
				<Col xs={10}>
					<p className="font_16_regular_black_list">
						{getDateRange(event.from_date, event.until_date)}
					</p>
				</Col>
			</Row>
			<Row className="mb-3">
				<Col xs={{size: 10, offset: 1}}>
					<p className="mb-0 display_inline_block font_14_medium_black_list">Instructor:&nbsp;</p>
					<p className="mb-0 display_inline_block font_14_regular_black_list">
						{event.instructor}
					</p>
				</Col>
			</Row>
			<Row className="mb-3">
				<Col xs={{size: 10, offset: 1}}>
					<p className="mb-0 display_inline_block font_14_medium_black_list">Location:&nbsp;</p>
					<p className="mb-0 display_inline_block font_14_regular_black_list">
						{event.location}
					</p>
				</Col>
			</Row>
			<Row>
				<Col xs={{size: 10, offset: 1}}>
					<p className="mb-0 display_inline_block font_14_medium_black_list">Category:&nbsp;</p>
					<p className="mb-0 display_inline_block font_14_regular_black_list">
						{getCategoryName(event.category)}
					</p>
				</Col>
			</Row>
			<Row className="padding_top_25px">
				<Col xs={{size: 10, offset: 1}}>
					<p className="display_inline_block font_14_medium_black_list">
				Description: <span className="font_14_regular_black_list">
							{event.description}
						</span>
					</p>
				</Col>
			</Row>
			<Row>
				<Col xs={{size: 10, offset: 1}}>
					<p className="display_inline_block font_14_medium_black_list">
						Cancellation policy: <span className="font_14_regular_black_list">
							{event.cancellation_policy}
						</span>
					</p>
				</Col>
			</Row>
			<Row>
				<Col xs={1} className="text-right">
					<BsTagFill className="gliph_grey gliph_18px" />
				</Col>
				<Col xs={10}>
					<div>
						<p className="display_inline_block font_14_medium_black_list">Price:</p>
						<p className="display_inline_block margin_left_10px font_14_medium_black_list">
						£{event.price}
						</p>
					</div>
				</Col>
			</Row>
			<Row className="d-md-none detail-buttons bg_row_btn border_row_btn">
				<Col xs={2} className="d-flex justify-content-center align-items-center">
					<Link className="sprite_pencil"
						to={frontendUrls.urlDashboardEventEdit.replace(":id", event.id)}>
					</Link>
				</Col>
				<Col xs={2} className="d-flex justify-content-center align-items-center">
					<BsTrash className="gliph_light_blue gliph_16px cursor-pointer"
						onClick={function () { handleShow(event); }}
					/>
				</Col>
				<Col xs={8} className="d-flex justify-content-end align-items-center">
					<Button className="rel_customers_btn" onClick={handleCustomers}>
							Customers { customer && <BsFillXCircleFill className="gliph_15px"/>}
					</Button>
				</Col>
			</Row>
		</div>
	);
};

EventsComponent.propTypes = {
	bookingEvents: PropTypes.array.isRequired,
	handleGetNextPage: PropTypes.func.isRequired,
	next: PropTypes.bool.isRequired,
	isFetching: PropTypes.bool.isRequired,
	events: PropTypes
		.arrayOf(PropTypes.object),
	handleShow: PropTypes.func,
	handleClose: PropTypes.func.isRequired,
	onDelete: PropTypes.func,
	handleOpenEvent: PropTypes.func,
	setDashboardSelectedEventDispatched: PropTypes.func,
	show: PropTypes.bool,
	openedEvent: PropTypes.number,
	eventToDelete: PropTypes.object,
};

EventsTable.propTypes = {
	events: PropTypes.array.isRequired,
	handleShow: PropTypes.func,
	handleOpenEvent: PropTypes.func,
	setDashboardSelectedEventDispatched: PropTypes.func,
	openedEvent: PropTypes.number,
};

EventRow.propTypes = {
	event: PropTypes.object.isRequired,
	handleShow: PropTypes.func.isRequired,
	handleOpenEvent: PropTypes.func,
	setDashboardSelectedEventDispatched: PropTypes.func,
	openedEvent: PropTypes.number,
	customers: PropTypes.arrayOf(PropTypes.object),
	dispatch: PropTypes.func.isRequired,
	authToken: PropTypes.string.isRequired,
};

EventDetail.propTypes = {
	event: PropTypes.object.isRequired,
	handleShow: PropTypes.func,
	handleCustomers: PropTypes.func,
	customer: PropTypes.bool,
};

export default EventsComponent;

