import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import MenuComponent from "../../../components/elements/booking_pages/menu";


class MenuContainer extends React.Component {
	render() {
		return (<MenuComponent />);
	}
}

MenuContainer.propTypes = {
	dispatch: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return { dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuContainer);
