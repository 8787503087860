import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Elements } from "react-stripe-elements";

import {Package} from "components/booking/packages/packageDetail";
import OrderCard from "components/booking/orders/order";
import OrderContainer from "containers/booking/orders/order";

import apiUrls from "routes/api";
import frontendUrls from "routes/frontend_routes";
import { buyBookingPackage } from "actions/booking/packages/packageBuy";
import { fetchSelectedBookingPackage } from "actions/booking/packages/packageDetail";
import { failBuyBookingPackage } from "actions/booking/packages/packageBuy";
import { fetchCustomerOrders } from "actions/orders/orders";
import { stripeLocale } from "consts";


class PackageCheckout extends Component {

	componentDidMount(){
		const { orderPackage, dispatch, match } = this.props;
		const id = match.params.id;
		dispatch(fetchCustomerOrders());
		if(orderPackage.id !== id) {
			dispatch(fetchSelectedBookingPackage(apiUrls.packageDetailUrl.replace(":id", id)));
		}
	}

	render(){
		const { authToken, stripeToken, dispatch, orderPackage, errors, loading, isFirstPurchase } = this.props;
		const apiUrlBooking = apiUrls.orderPackagesUrl.replace(":id", orderPackage.id);

		const orderProps = {
			isFirstPurchase,
			authToken,
			dispatch,
			errors,
			token: stripeToken,
			redirect: frontendUrls.urlMyOrder,
			orderType: "package",
		};

		const orderCardProps = {
			item: orderPackage,
			dispatch: dispatch,
			token: stripeToken,
			order:{
				url: apiUrlBooking,
				action: buyBookingPackage,
				fail: failBuyBookingPackage
			},
			loading,
			errors,
			isFirstPurchase
		};

		return (
			<OrderContainer {...orderProps}>
				<Elements locale={stripeLocale}>
					<OrderCard {...orderCardProps}>
						<Package item={orderPackage} buy={false} isPayPage={true}/>
					</OrderCard>
				</Elements>
			</OrderContainer>
		);
	}
}


PackageCheckout.propTypes = {
	authToken: PropTypes.string.isRequired,
	match: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	errors: PropTypes.array,
	orderPackage: PropTypes.object.isRequired,
	stripeToken: PropTypes.string,
	loading: PropTypes.bool.isRequired,
	isFirstPurchase: PropTypes.bool.isRequired
};


const mapStateToProps = (state) => {
	return {
		authToken: state.root.authToken || undefined,
		isFirstPurchase: state.ordersRoot.isFirstPurchase,
		errors: state.orderPackageRoot.errors || [],
		orderPackage: state.bookPackageDetailRoot.selectedBookingPackage || {},
		loading: state.bookPackageDetailRoot.isFetching || false,
		stripeToken: state.orderPackageRoot.createdOrder.stripe_token || "",
	};
};

const mapDispatchToProps = (dispatch) => {
	return { dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(PackageCheckout);