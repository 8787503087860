import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { fetchBookingEvents } from "actions/booking/events/events";
import BookingEventsComponent from "components/booking/events/events";
import { Spinner } from "components/utils/spinner";


class BookingEventsContainer extends React.Component {

	componentDidMount() {
		this.props.dispatch(fetchBookingEvents());
	}

	handleGetNextPage = () => {
		const { dispatch, bookingEvents } = this.props;
		if(bookingEvents.next) {
			let pageNumber = bookingEvents.next.split("&page=")[1].split("&")[0];
			dispatch(fetchBookingEvents(10, pageNumber));
		}
	}

	render() {
		const { eventListToShow, bookingEvents, isFetching } = this.props;
		let sendProps = {
			bookingEvents: eventListToShow || [],
			next: bookingEvents.next || null,
			handleGetNextPage: this.handleGetNextPage,
		};
		return (isFetching ? <Spinner/> : <BookingEventsComponent {...sendProps} />);
	}
}

BookingEventsContainer.propTypes = {
	dispatch: PropTypes.func.isRequired,
	bookingEvents: PropTypes.object,
	eventListToShow: PropTypes.array,
	isFetching: PropTypes.bool,
};

const mapStateToProps = (state) => {
	return {
		bookingEvents: state.bookingEventsRoot.bookingEvents || {},
		eventListToShow: state.bookingEventsRoot.eventListToShow || [],
		isFetching: state.bookingEventsRoot.isFetching || false,
	};
};

const mapDispatchToProps = (dispatch) => {
	return { dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingEventsContainer);
