import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { fetchCreationEvent, clearEventErrors } from "actions/events/create_event";
import weekDays from "backend_constants/weekDays";
import { WEEKLY, MONTHLY, DAILY, NONE } from "backend_constants/eventRepeat";
import CreateEventComponent from "components/events/create_event";
import { formatDate } from "components/utils";
import { Spinner } from "components/utils/spinner";


class CreateEventContainer extends React.Component {

	componentDidMount(){
		this.resetEventErrors();
	}

	resetEventErrors = () => {
		this.props.dispatch(clearEventErrors());
	}

	onSubmit = (values) => {
		// we need to calculate duration
		let now = new Date();
		let [startHour, startMinute] = values.fromTime.split(":");
		let startDateTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), startHour, startMinute);
		let [finishHour, finishMinute] = values.toTime.split(":");
		let finishDateTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), finishHour, finishMinute);
		// we need duration in minutes, but difference between datetimes is in the
		// milliseconds
		let duration = (finishDateTime - startDateTime) / 1000 / 60;

		let sendData = {
			title: values.title,
			category: values.category,
			capacity: values.capacity,
			pay_at_venue: values.payAtVenue,
			cancellation_policy: values.cancellationPolicy,
			description: values.description,
			from_date: formatDate(values.fromDate),
			until_date: formatDate(values.toDate),
			duration: duration,
			instructor: values.instructor,
			location: values.location
		};
		if (!values.free) {
			sendData.price = values.price;
		} else {
			sendData.price = 0;
		}
		if (!values.repeats) {
			sendData.repeat = NONE;
			sendData.repeat_times = [
				{
					start_time: values.fromTime
				}
			];
		} else {
			sendData.repeat = values.repeatChoice;
			if (values.repeatChoice === WEEKLY) {
				sendData.repeat_times = [
					{
						start_time: values.fromTime,
						weekly_days: weekDays.map(function (weeklyArray) {
							if (values[weeklyArray[1]]) {
								return weeklyArray[0];
							}
							return -1;
						})
							.filter(function (value) {
								return value > -1;
							})
					}
				];
			} else if (values.repeatChoice === MONTHLY) {
				let days = values.daysOfTheMonth.split(",");
				sendData.repeat_times = [
					{
						start_time: values.fromTime,
						days: days.map((day) => parseInt(day))
					}
				];
			} else if (values.repeatChoice === DAILY) {
				sendData.repeat_times = [
					{
						start_time: values.fromTime
					}
				];
			}
		}

		this.props.dispatch(fetchCreationEvent(sendData));
	}

	render() {
		const { errors, isFetching } = this.props;
		let sendProps = { errors, onSubmit: this.onSubmit, resetEventErrors: this.resetEventErrors };
		return isFetching ? <Spinner/> : <CreateEventComponent {...sendProps} />;
	}
}

CreateEventContainer.propTypes = {
	dispatch: PropTypes.func.isRequired,
	errors: PropTypes.object.isRequired,
	isFetching: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
	return {
		errors: state.creationEventRoot.errors || {},
		isFetching: state.creationEventRoot.isFetching || false
	};
};

const mapDispatchToProps = (dispatch) => {
	return { dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateEventContainer);
