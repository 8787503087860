import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "react-router-redux";
import { createBrowserHistory } from "history";

import Reducer from "./reducers";
import { loadState, saveState } from "./localStorage";
import { fetchCategories, fetchFacilityStripeAccount } from "actions/util_actions";


const persistedState = loadState();

export const history = createBrowserHistory();

const loggerMiddleware = createLogger();

const initialState = {
	root: persistedState.root,
	eventsRoot: {
		events: [],
	},
	loginRoot: {
		isFetching: false,
		errors: {},
	},
	registerRoot: {
		isFetching: false,
		errors: {},
	},
};

const enhancers = [];

const middleware = [thunkMiddleware, loggerMiddleware, routerMiddleware(history)];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const composed = composeEnhancers(applyMiddleware(...middleware), ...enhancers);

const store = createStore(Reducer, initialState, composed);

// Listen for state changes and everytime the state changes, save the state to localStorage
store.subscribe(() => {
	saveState({
		root: store.getState().root
	});
});

store.dispatch(fetchCategories());
store.dispatch(fetchFacilityStripeAccount());

export default store;
