import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { doLogout } from "actions/auth/logout";
import BodyComponent from "components/elements/dashboard_pages/dashboard_body";
import { withAdminRole } from "components/utils/hoc";
import { setToMenuClose } from "actions/util_actions";


class DashboardBodyContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			search: "",
		};
		this.onClickDropdown = this
			.onClickDropdown
			.bind(this);
		this.handleSearchChange = this
			.handleSearchChange
			.bind(this);
		this.onClickLogout = this
			.onClickLogout
			.bind(this);
	}

	onClickDropdown() {
		this.setState({
			open: !this.state.open
		});
	}

	onClickLogout() {
		this
			.props
			.dispatch(doLogout());
	}

	handleSearchChange(e) {
		this.setState({ search: e.target.value });
	}


	render() {
		const { isMenuOpen, setToMenuCloseDispatched } = this.props;
		let sendProps = {
			state: this.state,
			isMenuOpen,
			setToMenuCloseDispatched,
			onClickDropdown: this.onClickDropdown,
			handleSearchChange: this.handleSearchChange,
			onClickLogout: this.onClickLogout
		};
		return (<BodyComponent {...sendProps} />);
	}
}

DashboardBodyContainer.propTypes = {
	dispatch: PropTypes.func.isRequired,
	isMenuOpen: PropTypes.bool.isRequired,
	setToMenuCloseDispatched: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
	return {
		isMenuOpen: state.utilRoot.isMenuOpen,
	};
};


const AdminDashboardContainer = withAdminRole(DashboardBodyContainer);

export default connect(mapStateToProps, {
	setToMenuCloseDispatched: setToMenuClose,
})(AdminDashboardContainer);
