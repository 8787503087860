// MyStoreCheckout.js
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Elements } from "react-stripe-elements";

import { Event } from "components/booking/events/eventDetail";
import OrderCard from "components/booking/orders/order";
import OrderContainer from "containers/booking/orders/order";

import apiUrls from "routes/api";
import frontendUrls from "routes/frontend_routes";
import { buyBookingEvent, failBuyBookingEvent  } from "actions/booking/events/eventBuy";
import { fetchSelectedBookingEvent } from "actions/booking/events/eventDetail";
import { fetchCustomerOrders } from "actions/orders/orders";
import { stripeLocale } from "consts";


class EventCheckout extends Component {

	componentDidMount(){
		const { orderEvent, dispatch, match } = this.props;
		const id = match.params.id;
		dispatch(fetchCustomerOrders());
		if(orderEvent.id !== id) {
			dispatch(fetchSelectedBookingEvent(apiUrls.eventDetailUrl.replace(":id", id)));
		}
	}

	render(){
		const { authToken, stripeToken, dispatch, orderEvent, errors, loading, isFirstPurchase } = this.props;
		const apiUrlBooking = apiUrls.orderEventsUrl.replace(":id", orderEvent.id);

		const orderProps = {
			isFirstPurchase,
			authToken,
			dispatch,
			errors,
			token: stripeToken,
			redirect: frontendUrls.urlMyOrder,
			orderType: "event",
		};

		const orderCardProps = {
			item: orderEvent,
			dispatch: dispatch,
			token: stripeToken,
			order:{
				url: apiUrlBooking,
				action: buyBookingEvent,
				fail: failBuyBookingEvent
			},
			loading,
			errors,
			isFirstPurchase
		};

		return (
			<OrderContainer {...orderProps}>
				<Elements locale={stripeLocale}>
					<OrderCard {...orderCardProps}>
						<Event item={orderEvent} buy={false}/>
					</OrderCard>
				</Elements>
			</OrderContainer>
		);
	}
}

EventCheckout.propTypes = {
	authToken: PropTypes.string.isRequired,
	match: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	errors: PropTypes.object,
	orderEvent: PropTypes.object.isRequired,
	stripeToken: PropTypes.string,
	loading: PropTypes.bool.isRequired,
	isFirstPurchase: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
	return {
		authToken: state.root.authToken || undefined,
		isFirstPurchase: state.ordersRoot.isFirstPurchase,
		errors: state.orderEventRoot.errors || {},
		orderEvent: state.bookEventDetailRoot.selectedBookingEvent || {},
		stripeToken: state.orderEventRoot.createdOrder.stripe_token || "",
		loading: state.bookEventDetailRoot.isFetching || false,
	};
};

const mapDispatchToProps = (dispatch) => {
	return { dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventCheckout);
