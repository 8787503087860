import {
	GET_EVENTS,
	RECEIVE_EVENTS,
	DELETE_EVENT_FROM_STORE,
} from "../../actions/consts";


const initialState = {
	isFetching: false,
	events: []
};

export default function eventsReducer(state = initialState, action) {
	switch (action.type) {
	// REDUCER EVENTS

	case GET_EVENTS:
		return {
			...state,
			isFetching: true
		};

	case RECEIVE_EVENTS:
		return {
			...state,
			isFetching: false,
			events: action.events,
			lastUpdated: action.receivedAt
		};

	case DELETE_EVENT_FROM_STORE:
		return {
			...state,
			isFetching: false,
			events: action.data,
			lastUpdated: action.receivedAt
		};

	default:
		return state;
	}
}
