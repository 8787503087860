import {
	START_GET_FACILITY_SERVICES,
	FINISHED_GET_FACILITY_SERVICES ,
	START_CREATE_FACILITY_SERVICES,
	FAILED_GET_FACILITY_SERVICES ,
	FAILED_CREATE_FACILITY_SERVICES,
	FINISHED_CREATE_FACILITY_SERVICES,
	FINISHED_UPDATE_FACILITY_SERVICES,
	FAILED_UPDATE_FACILITY_SERVICES,
	START_UPDATE_FACILITY_SERVICES,
	FINISHED_DELETE_FACILITY_SERVICES,
	START_DELETE_FACILITY_SERVICES,
	FAILED_DELETE_FACILITY_SERVICES
} from "actions/consts";
import api from "routes/api";
import {request} from "actions/utils";


const startGetFacilityAdditionalServices = () => {
	return {type: START_GET_FACILITY_SERVICES};
};


const finishedGetFacilityAdditionalServices = (services) => {
	return{
		type: FINISHED_GET_FACILITY_SERVICES,
		services: services,
		receiveAt: Date.now()
	};
};


const failedGetFacilityAdditionalServices = (errors) => {
	return{
		type: FAILED_GET_FACILITY_SERVICES,
		errors: errors,
		receiveAt: Date.now()
	};
};


export const fetchFacilityAdditionalServices = () => {
	return (dispatch, getState) => {
		dispatch(startGetFacilityAdditionalServices());
		const { root: {authToken}} = getState();
		const config = {
			isAuth: authToken
		};
		const response = request(api.facilityAdditionalServicesUrl, config);
		return response
			.then((data) => dispatch(finishedGetFacilityAdditionalServices(data)))
			.catch((error) =>
				error.response.json()
					.then((json) => dispatch(failedGetFacilityAdditionalServices(json)))
			);
	};
};


const startCreateFacilityAdditionalServices = () => {
	return {type: START_CREATE_FACILITY_SERVICES};
};


const finishedCreateFacilityAdditionalServices = (services) => {
	return{
		type: FINISHED_CREATE_FACILITY_SERVICES,
		services: services,
		receiveAt: Date.now()
	};
};


const failedCreateFacilityAdditionalServices = (errors) => {
	return{
		type: FAILED_CREATE_FACILITY_SERVICES,
		errors: errors,
		receiveAt: Date.now()
	};
};


export const fetchCreateFacilityAdditionalServices = (sendData) => {
	return (dispatch, getState) => {
		dispatch(startCreateFacilityAdditionalServices());
		const { root: {authToken}} = getState();
		const config = {
			isAuth: authToken,
			method: "POST"
		};
		const response = request(api.facilityAdditionalServicesUrl, config, sendData);
		return response
			.then((data) => dispatch(finishedCreateFacilityAdditionalServices(data)))
			.catch((error) => 
				error.response.json()
					.then((json) => dispatch(failedCreateFacilityAdditionalServices(json)))
			);
	};
};


const startUpdateFacilityAdditionalServices = () => {
	return {type: START_UPDATE_FACILITY_SERVICES};
};


const finishedUpdateFacilityAdditionalServices = (services) => {
	return{
		type: FINISHED_UPDATE_FACILITY_SERVICES,
		services: services,
		receiveAt: Date.now()
	};
};


const failedUpdateFacilityAdditionalServices = (errors) => {
	return{
		type: FAILED_UPDATE_FACILITY_SERVICES,
		errors: errors,
		receiveAt: Date.now()
	};
};


export const fetchUpdateFacilityAdditionalServices = (servicesId, updateData) => {
	return (dispatch, getState) => {
		dispatch(startUpdateFacilityAdditionalServices());
		const { root: {authToken}} = getState();
		const config = {
			isAuth: authToken,
			method: "PUT"
		};
		const response = request(api.facilityAdditionalServicesDetailUrl.replace(":id", servicesId), config, updateData);
		return response
			.then((data) => dispatch(finishedUpdateFacilityAdditionalServices(data)))
			.catch((error) => 
				error.response.json()
					.then((json) => dispatch(failedUpdateFacilityAdditionalServices(json)))
			);
	};
};


const startDeleteFacilityAdditionalServices = () => {
	return {type: START_DELETE_FACILITY_SERVICES};
};


const finishedDeleteFacilityAdditionalServices = () => {
	return{type: FINISHED_DELETE_FACILITY_SERVICES};
};


const failedDeleteFacilityAdditionalServices = (errors) => {
	return{
		type: FAILED_DELETE_FACILITY_SERVICES,
		errors: errors,
		receiveAt: Date.now()
	};
};


export const fetchDeleteFacilityAdditionalServices = (servicesId) => {
	return (dispatch, getState) => {
		dispatch(startDeleteFacilityAdditionalServices());
		const { root: {authToken}} = getState();
		const config = {
			isAuth: authToken,
			method: "DELETE"
		};
		const response = request(api.facilityAdditionalServicesDetailUrl.replace(":id", servicesId), config, {}, true);
		return response
			.then(() => dispatch(finishedDeleteFacilityAdditionalServices()))
			.catch((error) =>
				error.response.json()
					.then((json) => dispatch(failedDeleteFacilityAdditionalServices(json)))
			);
	};
};

