import {
	CLEAR_MEMBERSHIP_ERRORS,
	START_CREATION_MEMBERSHIP,
	FINISHED_CREATION_MEMBERSHIP,
	FAILED_CREATION_MEMBERSHIP
} from "../../actions/consts";


const initialState = {
	isFetching: false,
	errors: {}
};

export default function creationMembershipReducer(state = initialState, action) {
	switch (action.type) {

	// REDUCER CREATION MEMBERSHIP
	case START_CREATION_MEMBERSHIP:
		return {
			...state,
			isFetching: true
		};

	case FINISHED_CREATION_MEMBERSHIP:
		return {
			...state,
			isFetching: false,
			errors: {}
		};

	case FAILED_CREATION_MEMBERSHIP:
		return {
			...state,
			isFetching: false,
			errors: action.errors
		};
	case CLEAR_MEMBERSHIP_ERRORS:
		return{
			...state,
			errors: {}
		};

	default:
		return state;
	}
}
