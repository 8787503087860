import {
	START_CREATION_CUSTOMER,
	FINISHED_CREATION_CUSTOMER,
	FAILED_CREATION_CUSTOMER
} from "actions/consts";
import apiUrls from "routes/api";
import frontendUrls from "routes/frontend_routes";

import { request } from "actions/utils";
import { history } from "store";


const startCreationCustomer = () => {
	return { type: START_CREATION_CUSTOMER };
};


const finishedCreationCustomer = (data) => {
	return {
		type: FINISHED_CREATION_CUSTOMER,
		data: data,
		receivedAt: Date.now()
	};
};


const failedCreationCustomer = (error) => {
	return {
		type: FAILED_CREATION_CUSTOMER,
		errors: error,
		receivedAt: Date.now()
	};
};


export function fetchCreationCustomer(customerProps){
	return (dispatch, getState) => {
		dispatch(startCreationCustomer());
		const { root: { authToken }} = getState();
		const config = {
			isAuth: authToken,
			method: "POST"
		};

		const response = request(apiUrls.createCustomerUrl, config, customerProps);
		return response
			.then((data) => {
				dispatch(finishedCreationCustomer(data));
				history.push(frontendUrls.urlDashboardCustomers);
			})
			.catch((error) => {
				error.response.json()
					.then((error) => dispatch(failedCreationCustomer(error)));
			});
	};
}