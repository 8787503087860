import ReactDOM from "react-dom";
import React from "react";
import { Provider } from "react-redux";
import { Router, Route, } from "react-router-dom";
import { StripeProvider } from "react-stripe-elements";
import * as Sentry from "@sentry/react";

import store from "./store";
import { history } from "./store";
import { stripeAppToken, sentryDsn } from "./consts";
import frontendUrls from "./routes/frontend_routes";
import App from "./containers/App";
import ErrorBoundry from "components/utils/error_boundry";


Sentry.init({dsn: sentryDsn});

ReactDOM.render(
	<Provider store={store}>
		<Router history={history}>
			<StripeProvider apiKey={stripeAppToken} stripeAccount={store.getState().utilRoot.stripeAccount.id}>
				<ErrorBoundry>
					<Route path={frontendUrls.urlRoot} component={App} />
				</ErrorBoundry>
			</StripeProvider>
		</Router>
	</Provider>, document.getElementById("root"));
