import React from "react";
import PropTypes from "prop-types";
import { Container } from "reactstrap";

import MembershipForm from "components/forms/memberships/membership_form";


const CreateMembershipComponent = ({ onSubmit, resetMembershipErrors, errors }) => {
	return (
		<Container>
			<p className="margin_top_25px margin_bottom_35px font_24_regular_black_list">Create Membership</p>
			<MembershipForm onSubmit={onSubmit} errors={errors} resetMembershipErrors={resetMembershipErrors}/>
		</Container>
	);
};

CreateMembershipComponent.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	resetMembershipErrors: PropTypes.func,
	errors: PropTypes.object,
};

export default CreateMembershipComponent;
