import {
	START_CHECKIN_BOOKING_EVENT,
	FINISH_CHECKIN_BOOKING_EVENT,
	FAIL_CHECKIN_BOOKING_EVENT
} from "actions/consts";
import { request } from "actions/utils";


// Processing fetching event
const startCheckinBookingEvent = () => {
	return { type: START_CHECKIN_BOOKING_EVENT };
};

const finishCheckinBookingEvent = (data) => {
	return {
		type: FINISH_CHECKIN_BOOKING_EVENT,
		data: data,
		receivedAt: Date.now()
	};
};

const failCheckinBookingEvent = (data) => {
	return {
		type: FAIL_CHECKIN_BOOKING_EVENT,
		errors: data,
		receivedAt: Date.now()
	};
};

export const fetchCheckinBookingEvent = (checkinUrl, sendData) => {
	return (dispatch, getState) => {
		dispatch(startCheckinBookingEvent);
		const { root: { authToken }} = getState();
		const config = {
			method: "POST",
			isAuth: authToken
		};

		const response = request(checkinUrl, config, sendData);
		
		return response
			.then((json) => {
				dispatch(finishCheckinBookingEvent(json));
			})
			.catch((error) => {
				error.response.json()
					.then((json) => {
						dispatch(failCheckinBookingEvent(json));
					});
			});
	};
};