import {
	START_GET_BOOKING_MEMBERSHIP,
	FINISH_GET_BOOKING_MEMBERSHIP,
	FAIL_GET_BOOKING_MEMBERSHIP
} from "../../../actions/consts";


const initialState = {
	isFetching: true,
	errors: {},
	selectedBookingMembership: {}
};

export default function bookingDetailMembershipReducer(state = initialState, action) {
	switch (action.type) {

	// REDUCER GET MEMBERSHIP
	case START_GET_BOOKING_MEMBERSHIP:
		return {
			...state,
			isFetching: true
		};

	case FINISH_GET_BOOKING_MEMBERSHIP:
		return {
			...state,
			isFetching: false,
			selectedBookingMembership: action.data,
			errors: {}
		};

	case FAIL_GET_BOOKING_MEMBERSHIP:
		return {
			...state,
			isFetching: false,
			errors: action.errors
		};

	default:
		return state;
	}
}
