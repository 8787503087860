import React from "react";
import PropTypes from "prop-types";

import { Form, Field } from "react-final-form";
import { Button } from "reactstrap";
import { fieldInput } from "components/forms/fields";


const validate = (values) => {
	let errors = {};
	if(!values.password){
		errors.password = "This field is required.";
	}
	if(!values.confirmPassword){
		errors.confirmPassword = "This field is required.";
	}
	return errors;
};


const ConfirmResetPasswordForm = ({ errors, onSubmit }) => {
	return(
		<Form
			validate={validate}
			onSubmit={onSubmit}
			render={({ handleSubmit, pristine, submitting }) => (
				<form onSubmit={handleSubmit}>
					<Field
						name="password"
						type="password"
						inputType="password"
						placeholder="Password"
						serverError={errors.password}
						component={fieldInput}
					/>
					<Field
						name="confirmPassword"
						type="password"
						inputType="password"
						placeholder="Confirm password"
						serverError={errors.confirm_password}
						component={fieldInput}
					/>
					{errors.non_field_errors && <p className="text-danger">{errors.non_field_errors}</p>}
					{errors.uid && <p className="text-danger">{errors.uid}</p>}
					{errors.token && <p className="text-danger">{errors.token}</p>}
					<Button disabled={submitting || pristine} type="submit"
						className="primary margin_0 height_40px width_100 bg_button_clr border_color_button_clr font_16_medium border_radius_03rem">
						Submit
					</Button>
				</form>
			)}></Form>
	);
};


ConfirmResetPasswordForm.propTypes = {
	errors: PropTypes.object.isRequired,
	onSubmit: PropTypes.func.isRequired
};


export default ConfirmResetPasswordForm;