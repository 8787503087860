import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchCategories } from "actions/util_actions";


const withCategories = (Component) => {

	class WithCategoriesComponent extends React.Component {
		componentDidMount(){
			const { dispatch, categories } = this.props;
			if(!categories.length){
				dispatch(fetchCategories());
			}
		}

		render(){
			return <Component {...this.props}/>;
		}
	}

	WithCategoriesComponent.propTypes = {
		categories: PropTypes.arrayOf(PropTypes.object),
		dispatch: PropTypes.func.isRequired
	};

	const mapStateToProps = ({utilRoot: {categories}}) => {
		return { categories };
	};

	return connect(mapStateToProps)(WithCategoriesComponent);
};


export default withCategories;