import React from "react";
import PropTypes from "prop-types";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";


const Breadcrumbs = ({ breadcrumbs }) => {
	return(
		<Breadcrumb tag="nav" listTag="div" className="pozen_breadcrumbs">
			{
				breadcrumbs.map(({match, breadcrumb}, idx) => {
					let { url } = match;
					url = url[url.length - 1] === "/" ? url : url + "/";
					return (
						<BreadcrumbItem tag="a" href={url} key={idx}>
							{breadcrumb}
						</BreadcrumbItem>
					);
				})
			}
		</Breadcrumb>
	);
};


Breadcrumbs.propTypes = {
	breadcrumbs: PropTypes.array.isRequired
};


export default Breadcrumbs;