import React, {Component} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import CreateCustomerComponent from "components/customers/create_customer";

import {fetchCreationCustomer} from "actions/customers/create_customer";


class CreateCustomerContainer extends Component{

	onSubmit = (values) => {
		const { dispatch } = this.props;
		dispatch(fetchCreationCustomer({...values, centre_owner: ""}));
	}

	render(){
		const customerProps = {
			errors: this.props.errors,
			onSubmit: this.onSubmit
		};
		return <CreateCustomerComponent {...customerProps}/>;
	}
}


CreateCustomerContainer.propTypes = {
	isFetching: PropTypes.bool,
	customer: PropTypes.object,
	errors: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired
};


const mapStateToProps = ({creationCustomerRoot}) => {
	return{
		isFetching: creationCustomerRoot.isFetching || false,
		customer: creationCustomerRoot.data || {},
		errors: creationCustomerRoot.errors || {}
	};
};


export default connect(mapStateToProps)(CreateCustomerContainer);