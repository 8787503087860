import React from "react";


export const TimeTableIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="1em" height="1.3em" viewBox="0 0 172 172" style={{fill:"#000000"}}>
			<g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" 
				strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" 
				fontSize="none" textAnchor="none" style={{mixBlendMode: "normal"}}>
				<path d="M0,172v-172h172v172z" fill="none"></path>
				<g fill="#21aef5">
					<path d="M8.0625,15.79956c-4.44067,0 -8.0625,3.62183 -8.0625,8.0625v112.875c0,4.44067 3.62183,8.0625 8.0625,
					8.0625h99.40601c6.18335,9.85767 17.12232,16.45044 29.59399,16.45044c19.26392,0 34.9375,-15.67358 34.9375,
					-34.9375c0,-14.5083 -8.89185,-26.95898 -21.5,-32.2395v-70.21094c0,-4.44067 -3.62183,-8.0625 -8.0625,
					-8.0625zM8.0625,21.17456h134.375c1.48022,0 2.6875,1.20728 2.6875,2.6875v18.8125h-139.75v-18.8125c0,
					-1.48022 1.20728,-2.6875 2.6875,-2.6875zM13.4375,26.54956c-1.49072,0 -2.6875,1.20728 -2.6875,2.6875v5.375c0,
					1.48023 1.19678,2.6875 2.6875,2.6875c1.49072,0 2.6875,-1.20727 2.6875,-2.6875v-5.375c0,-1.48022 -1.19678,
					-2.6875 -2.6875,-2.6875zM26.875,26.54956c-1.49072,0 -2.6875,1.20728 -2.6875,2.6875v5.375c0,1.48023 1.19678,
					2.6875 2.6875,2.6875c1.49072,0 2.6875,-1.20727 2.6875,-2.6875v-5.375c0,-1.48022 -1.19678,-2.6875 -2.6875,
					-2.6875zM40.3125,26.54956c-1.49072,0 -2.6875,1.20728 -2.6875,2.6875v5.375c0,1.48023 1.19678,2.6875 2.6875,
					2.6875c1.49072,0 2.6875,-1.20727 2.6875,-2.6875v-5.375c0,-1.48022 -1.19678,-2.6875 -2.6875,-2.6875zM53.75,
					26.54956c-1.49072,0 -2.6875,1.20728 -2.6875,2.6875v5.375c0,1.48023 1.19678,2.6875 2.6875,2.6875c1.49072,
					0 2.6875,-1.20727 2.6875,-2.6875v-5.375c0,-1.48022 -1.19678,-2.6875 -2.6875,-2.6875zM67.1875,26.54956c-1.49072,
					0 -2.6875,1.20728 -2.6875,2.6875v5.375c0,1.48023 1.19678,2.6875 2.6875,2.6875c1.49072,0 2.6875,-1.20727 2.6875,
					-2.6875v-5.375c0,-1.48022 -1.19678,-2.6875 -2.6875,-2.6875zM80.625,26.54956c-1.49072,0 -2.6875,1.20728 -2.6875,
					2.6875v5.375c0,1.48023 1.19678,2.6875 2.6875,2.6875c1.49072,0 2.6875,-1.20727 2.6875,-2.6875v-5.375c0,
					-1.48022 -1.19678,-2.6875 -2.6875,-2.6875zM94.0625,26.54956c-1.49072,0 -2.6875,1.20728 -2.6875,2.6875v5.375c0,
					1.48023 1.19678,2.6875 2.6875,2.6875c1.49072,0 2.6875,-1.20727 2.6875,-2.6875v-5.375c0,-1.48022 -1.19678,
					-2.6875 -2.6875,-2.6875zM107.5,26.54956c-1.49072,0 -2.6875,1.20728 -2.6875,2.6875v5.375c0,1.48023 1.19678,
					2.6875 2.6875,2.6875c1.49072,0 2.6875,-1.20727 2.6875,-2.6875v-5.375c0,-1.48022 -1.19678,-2.6875 -2.6875,
					-2.6875zM120.9375,26.54956c-1.49072,0 -2.6875,1.20728 -2.6875,2.6875v5.375c0,1.48023 1.19678,2.6875 2.6875,
					2.6875c1.49072,0 2.6875,-1.20727 2.6875,-2.6875v-5.375c0,-1.48022 -1.19678,-2.6875 -2.6875,-2.6875zM134.375,
					26.54956c-1.49072,0 -2.6875,1.20728 -2.6875,2.6875v5.375c0,1.48023 1.19678,2.6875 2.6875,2.6875c1.49072,0 2.6875,
					-1.20727 2.6875,-2.6875v-5.375c0,-1.48022 -1.19678,-2.6875 -2.6875,-2.6875zM5.375,48.04956h139.75v44.30176c-2.59302,
					-0.61938 -5.28052,-0.97632 -8.0625,-0.97632c-19.26392,0 -34.9375,15.67358 -34.9375,34.9375c0,4.64014 0.92383,
					9.05982 2.57202,13.11206h-96.63452c-1.48022,0 -2.6875,-1.20727 -2.6875,-2.6875zM29.5625,59.125c-1.49072,0 -2.6875,
					1.20728 -2.6875,2.6875v13.4375h-8.0625c-1.49072,0 -2.6875,1.20728 -2.6875,2.6875c0,1.48022 1.19678,2.6875 2.6875,
					2.6875h8.0625v26.875h-7.39062c-1.49072,0 -2.6875,1.20728 -2.6875,2.6875c0,1.48022 1.19678,2.6875 2.6875,
					2.6875h7.39063v13.4375c0,1.48022 1.19678,2.6875 2.6875,2.6875c1.49072,0 2.6875,-1.20728 2.6875,
					-2.6875v-13.4375h43v13.4375c0,1.48022 1.19678,2.6875 2.6875,2.6875c1.49072,0 2.6875,-1.20728 2.6875,
					-2.6875v-13.4375h19.26392c1.49072,0 2.6875,-1.20728 2.6875,-2.6875c0,-1.48022 -1.19678,-2.6875 -2.6875,
					-2.6875h-19.26392v-26.875h37.625v8.0625c0,1.48022 1.19678,2.6875 2.6875,2.6875c1.49072,0 2.6875,-1.20728 2.6875,
					-2.6875v-8.0625h7.84204c1.49072,0 2.6875,-1.20728 2.6875,-2.6875c0,-1.48022 -1.19678,-2.6875 -2.6875,
					-2.6875h-7.84204v-13.4375c0,-1.48022 -1.19678,-2.6875 -2.6875,-2.6875c-1.49072,0 -2.6875,1.20728 -2.6875,
					2.6875v13.4375h-37.625v-13.4375c0,-1.48022 -1.19678,-2.6875 -2.6875,-2.6875c-1.49072,0 -2.6875,1.20728 -2.6875,
					2.6875v13.4375h-43v-13.4375c0,-1.48022 -1.19678,-2.6875 -2.6875,-2.6875zM32.25,80.625h43v26.875h-43zM137.0625,
					96.75c16.30347,0 29.5625,13.25903 29.5625,29.5625c0,16.30347 -13.25903,29.5625 -29.5625,29.5625c-16.30347,
					0 -29.5625,-13.25903 -29.5625,-29.5625c0,-16.30347 13.25903,-29.5625 29.5625,-29.5625zM137.0625,104.8125c-1.49072,
					0 -2.6875,1.20728 -2.6875,2.6875v18.8125c0,0.34643 0.07349,0.70337 0.20996,1.02881c0.13647,0.32544 0.33594,
					0.61938 0.58789,0.87134l13.427,13.4375c0.5249,0.5249 1.20728,0.78735 1.90015,0.78735c0.69287,0 1.37524,
					-0.26245 1.90015,-0.78735c1.0498,-1.0498 1.0498,-2.75049 0,-3.80029l-12.65015,-12.65015v-17.69971c0,
					-1.48022 -1.19678,-2.6875 -2.6875,-2.6875z"></path>
				</g>
			</g>
		</svg>
	);
};


export const MembershipCardIcon = () => {
	return(
		<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="1em" height="1.3em" viewBox="0 0 172 172" style={{fill: "#000000"}}>
			<g transform="translate(-9.46,-9.46) scale(1.11,1.11)">
				<g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt"
					strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" 
					fontSize="none" textAnchor="none" style={{ mixBlendMode: "normal"}}>
					<path d="M0,172v-172h172v172z" fill="none"></path>
					<g fill="#21aef5">
						<path d="M23.65,34.4c-5.91146,0 -10.75,4.83854 -10.75,10.75v81.7c0,5.91146 4.83854,10.75 10.75,10.75h124.7c5.91146,
						0 10.75,-4.83854 10.75,-10.75v-81.7c0,-5.91146 -4.83854,-10.75 -10.75,-10.75zM23.65,38.7h124.7c3.58724,0 6.45,
						2.86276 6.45,6.45v81.7c0,3.58724 -2.86276,6.45 -6.45,6.45h-124.7c-3.58724,0 -6.45,-2.86276 -6.45,-6.45v-81.7c0,
						-3.58724 2.86276,-6.45 6.45,-6.45zM25.8,45.15c-1.18741,0 -2.15,0.96259 -2.15,2.15c0,1.18741 0.96259,2.15 2.15,
						2.15c1.18741,0 2.15,-0.96259 2.15,-2.15c0,-1.18741 -0.96259,-2.15 -2.15,-2.15zM34.4,45.15c-1.18741,0 -2.15,
						0.96259 -2.15,2.15c0,1.18741 0.96259,2.15 2.15,2.15c1.18741,0 2.15,-0.96259 2.15,-2.15c0,-1.18741 -0.96259,
						-2.15 -2.15,-2.15zM43,45.15c-1.18741,0 -2.15,0.96259 -2.15,2.15c0,1.18741 0.96259,2.15 2.15,2.15c1.18741,0 2.15,
						-0.96259 2.15,-2.15c0,-1.18741 -0.96259,-2.15 -2.15,-2.15zM51.6,45.15c-1.18741,0 -2.15,0.96259 -2.15,2.15c0,
						1.18741 0.96259,2.15 2.15,2.15c1.18741,0 2.15,-0.96259 2.15,-2.15c0,-1.18741 -0.96259,-2.15 -2.15,-2.15zM60.2,
						45.15c-1.18741,0 -2.15,0.96259 -2.15,2.15c0,1.18741 0.96259,2.15 2.15,2.15c1.18741,0 2.15,-0.96259 2.15,-2.15c0,
						-1.18741 -0.96259,-2.15 -2.15,-2.15zM68.8,45.15c-1.18741,0 -2.15,0.96259 -2.15,2.15c0,1.18741 0.96259,2.15 2.15,
						2.15c1.18741,0 2.15,-0.96259 2.15,-2.15c0,-1.18741 -0.96259,-2.15 -2.15,-2.15zM77.4,45.15c-1.18741,0 -2.15,
						0.96259 -2.15,2.15c0,1.18741 0.96259,2.15 2.15,2.15c1.18741,0 2.15,-0.96259 2.15,-2.15c0,-1.18741 -0.96259,
						-2.15 -2.15,-2.15zM86,45.15c-1.18741,0 -2.15,0.96259 -2.15,2.15c0,1.18741 0.96259,2.15 2.15,2.15c1.18741,0 2.15,
						-0.96259 2.15,-2.15c0,-1.18741 -0.96259,-2.15 -2.15,-2.15zM94.6,45.15c-1.18741,0 -2.15,0.96259 -2.15,2.15c0,
						1.18741 0.96259,2.15 2.15,2.15c1.18741,0 2.15,-0.96259 2.15,-2.15c0,-1.18741 -0.96259,-2.15 -2.15,-2.15zM103.2,
						45.15c-1.18741,0 -2.15,0.96259 -2.15,2.15c0,1.18741 0.96259,2.15 2.15,2.15c1.18741,0 2.15,-0.96259 2.15,-2.15c0,
						-1.18741 -0.96259,-2.15 -2.15,-2.15zM111.8,45.15c-1.18741,0 -2.15,0.96259 -2.15,2.15c0,1.18741 0.96259,2.15 2.15,
						2.15c1.18741,0 2.15,-0.96259 2.15,-2.15c0,-1.18741 -0.96259,-2.15 -2.15,-2.15zM120.4,45.15c-1.18741,0 -2.15,
						0.96259 -2.15,2.15c0,1.18741 0.96259,2.15 2.15,2.15c1.18741,0 2.15,-0.96259 2.15,-2.15c0,-1.18741 -0.96259,
						-2.15 -2.15,-2.15zM129,45.15c-1.18741,0 -2.15,0.96259 -2.15,2.15c0,1.18741 0.96259,2.15 2.15,2.15c1.18741,
						0 2.15,-0.96259 2.15,-2.15c0,-1.18741 -0.96259,-2.15 -2.15,-2.15zM137.6,45.15c-1.18741,0 -2.15,0.96259 -2.15,
						2.15c0,1.18741 0.96259,2.15 2.15,2.15c1.18741,0 2.15,-0.96259 2.15,-2.15c0,-1.18741 -0.96259,-2.15 -2.15,
						-2.15zM146.2,45.15c-1.18741,0 -2.15,0.96259 -2.15,2.15c0,1.18741 0.96259,2.15 2.15,2.15c1.18741,0 2.15,
						-0.96259 2.15,-2.15c0,-1.18741 -0.96259,-2.15 -2.15,-2.15zM58.05,56.90781c-0.84925,0 -1.61703,0.49621 -1.96103,
						1.27236l-8.86875,19.8833l-21.64697,2.28437c-0.84495,0.0903 -1.55596,0.66553 -1.81826,1.47393c-0.2623,
						0.8084 -0.02526,1.69578 0.60469,2.26338l16.17119,14.57549l-4.51416,21.29424c-0.1763,0.8299 0.15184,
						1.68479 0.83984,2.18359c0.688,0.50095 1.60377,0.54748 2.33477,0.12178l18.85869,-10.87178l18.85869,
						10.87178c0.3311,0.19135 0.701,0.28975 1.0708,0.28975c0.44505,0 0.88772,-0.13847 1.26397,-0.41152c0.68585,
						-0.4988 1.01614,-1.35154 0.83984,-2.18359l-4.51416,-21.29424l16.17119,-14.57549c0.62995,-0.56975 0.86699,
						-1.45713 0.60469,-2.26338c-0.2623,-0.80625 -0.97331,-1.38363 -1.81826,-1.47393l-21.64697,-2.28857l-8.86875,
						-19.8791c-0.344,-0.774 -1.11178,-1.27236 -1.96103,-1.27236zM58.05,64.33623l7.40742,16.60791c0.3139,
						0.7009 0.97523,1.18441 1.73848,1.26396l18.08184,1.91064l-13.50469,12.17353c-0.56975,0.51385 -0.82677,
						1.29467 -0.66767,2.04502l3.7709,17.79209l-15.75547,-9.08711c-0.3311,-0.19135 -0.69885,-0.28555 -1.0708,
						-0.28555c-0.37195,0 -0.7397,0.09205 -1.0708,0.28555l-15.75547,9.08711l3.7709,-17.79209c0.1591,-0.75035 -0.09793,
						-1.53117 -0.66767,-2.04502l-13.50469,-12.17773l18.08184,-1.91064c0.7611,-0.0817 1.42458,-0.55886 1.73848,
						-1.25976zM94.6,105.35c-1.18741,0 -2.15,0.96259 -2.15,2.15c0,1.18741 0.96259,2.15 2.15,2.15c1.18741,0 2.15,
						-0.96259 2.15,-2.15c0,-1.18741 -0.96259,-2.15 -2.15,-2.15zM103.2,105.35c-1.18741,0 -2.15,0.96259 -2.15,2.15c0,
						1.18741 0.96259,2.15 2.15,2.15c1.18741,0 2.15,-0.96259 2.15,-2.15c0,-1.18741 -0.96259,-2.15 -2.15,-2.15zM111.8,
						105.35c-1.18741,0 -2.15,0.96259 -2.15,2.15c0,1.18741 0.96259,2.15 2.15,2.15c1.18741,0 2.15,-0.96259 2.15,-2.15c0,
						-1.18741 -0.96259,-2.15 -2.15,-2.15zM120.4,105.35c-1.18741,0 -2.15,0.96259 -2.15,2.15c0,1.18741 0.96259,
						2.15 2.15,2.15c1.18741,0 2.15,-0.96259 2.15,-2.15c0,-1.18741 -0.96259,-2.15 -2.15,-2.15zM129,105.35c-1.18741,
						0 -2.15,0.96259 -2.15,2.15c0,1.18741 0.96259,2.15 2.15,2.15c1.18741,0 2.15,-0.96259 2.15,-2.15c0,-1.18741 -0.96259,
						-2.15 -2.15,-2.15zM137.6,105.35c-1.18741,0 -2.15,0.96259 -2.15,2.15c0,1.18741 0.96259,2.15 2.15,2.15c1.18741,
						0 2.15,-0.96259 2.15,-2.15c0,-1.18741 -0.96259,-2.15 -2.15,-2.15zM146.2,105.35c-1.18741,0 -2.15,0.96259 -2.15,
						2.15c0,1.18741 0.96259,2.15 2.15,2.15c1.18741,0 2.15,-0.96259 2.15,-2.15c0,-1.18741 -0.96259,-2.15 -2.15,
						-2.15zM94.6,118.25c-1.18741,0 -2.15,0.96259 -2.15,2.15c0,1.18741 0.96259,2.15 2.15,2.15c1.18741,0 2.15,
						-0.96259 2.15,-2.15c0,-1.18741 -0.96259,-2.15 -2.15,-2.15zM103.2,118.25c-1.18741,0 -2.15,0.96259 -2.15,
						2.15c0,1.18741 0.96259,2.15 2.15,2.15c1.18741,0 2.15,-0.96259 2.15,-2.15c0,-1.18741 -0.96259,-2.15 -2.15,
						-2.15zM111.8,118.25c-1.18741,0 -2.15,0.96259 -2.15,2.15c0,1.18741 0.96259,2.15 2.15,2.15c1.18741,0 2.15,
						-0.96259 2.15,-2.15c0,-1.18741 -0.96259,-2.15 -2.15,-2.15zM120.4,118.25c-1.18741,0 -2.15,0.96259 -2.15,
						2.15c0,1.18741 0.96259,2.15 2.15,2.15c1.18741,0 2.15,-0.96259 2.15,-2.15c0,-1.18741 -0.96259,-2.15 -2.15,
						-2.15zM129,118.25c-1.18741,0 -2.15,0.96259 -2.15,2.15c0,1.18741 0.96259,2.15 2.15,2.15c1.18741,0 2.15,
						-0.96259 2.15,-2.15c0,-1.18741 -0.96259,-2.15 -2.15,-2.15z"></path>
					</g>
				</g>
			</g>
		</svg>
	);
};

