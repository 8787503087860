import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Container, Button, Row, Col } from "reactstrap";
import { Spinner } from "components/utils/spinner";

import { fetchActivateAccount } from "actions/auth/register";


const ActivateAccountComponent = ({dispatch, match, errors, isFetching}) => {

	const sendActivationData = () => {
		const { token, uid } = match.params;
		dispatch(fetchActivateAccount(token, uid));
	};

	return (
		<Container className="h-100 d-flex justify-content-center align-items-center flex-column">
			<Row className="mb-3">
				<Col xs={12}>
					{
						isFetching ? <Spinner/> :
							<Button className="btn-activate-account" onClick={sendActivationData}>
								Activate my account!
							</Button>
					}
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					{ errors && <p className="text-danger font_16_medium_2c2c2d_list">{errors.uid || errors.token || errors.non_field_errors}</p>}
				</Col>
			</Row>
		</Container>
	);
};


ActivateAccountComponent.propTypes = {
	dispatch: PropTypes.func.isRequired,
	match: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired,
	isFetching: PropTypes.bool.isRequired
};


const mapStateToProps = ({activateAccountRoot: {isFetching, errors}}) => {
	return { errors, isFetching };
};


export default connect(mapStateToProps)(ActivateAccountComponent);