import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Container, Col, Row} from "reactstrap";
import { BsTagFill, BsCalendar } from "react-icons/bs";
import { AiOutlineShoppingCart } from "react-icons/ai";

import {
	MembershipSessions,
	MembershipCategories
} from "components/booking/memberships/memberships";
import frontendUrls from "routes/frontend_routes";
import { getMembershipTitle } from "components/utils";


export const Membership = ({item, buy}) => {

	const { id, description, price } = item;

	return(
		<>
			<Row className="mbshp_details">
				<Col xs="1" lg="1" className="text-md-right">
					<BsCalendar className="gliph_grey gliph_15px"/>
				</Col>
				<Col xs="10" lg="10">
					<p className="font_16_medium_2c2c2d_list">
						Monthly subscription
					</p>
				</Col>
			</Row>
			<Row className="mbshp_details">
				<Col xs="1" lg="1" className="text-md-right">
					<AiOutlineShoppingCart className="gliph_grey gliph_18px"/>
				</Col>
				<Col xs="10" lg="10">
					<MembershipSessions item={item}/>
				</Col>
			</Row>
			<Row className="mbshp_details_categories" >
				<Col xs={{size:10, offset:1}}>
					<MembershipCategories item={item} size={{p:16, span:14}}/>
				</Col>
			</Row>
			<Row className="mbshp_details_desc">
				<Col xs={{size:10, offset:1}}>
					<p className="font_16_medium_2c2c2d_list">
						Description:&nbsp;
						<span className="font_14_regular_2c2c2d_list">{description}</span>
					</p>
				</Col>
			</Row>
			<Row className="mbshp_details_price">
				<Col xs="1" className="text-md-right mt-1">
					<BsTagFill className="gliph_grey gliph_15px"/>
				</Col>
				<Col xs="6" md="8" lg="9" className="mt-1">
					<p className="font_16_medium_2c2c2d_list">
						Price:&nbsp;
						<span className="font_14_regular_2c2c2d_list">&pound;{price}</span>
					</p>
				</Col>
				{
					buy &&
					<Col xs="4" md="2" lg="1">
						<Link to={frontendUrls.urlBookingOrderMembership.replace(":id", id)}
							className="btn pkg_btn font_16_regular_white_list m-0">
							Buy
						</Link>
					</Col>
				}
			</Row>
		</>
	);
};


const BookingDetailComponent = ({ selectedBookingMembership, buy}) => {
	const { title } = selectedBookingMembership;

	return (
		<Container className="mt-5">
			<Row>
				<Col xs="12">
					<p className="font_18_medium_2c2c2d_list">
						{getMembershipTitle(title)}
					</p>
				</Col>
			</Row>
			<div className="membership_details shadow_form">
				<Membership item={selectedBookingMembership} buy={buy}/>
			</div>
		</Container>
	);
};


BookingDetailComponent.propTypes = {
	selectedBookingMembership: PropTypes.object.isRequired,
	buy: PropTypes.bool.isRequired
};


Membership.propTypes = {
	item: PropTypes.object.isRequired,
	buy: PropTypes.bool.isRequired
};


export default BookingDetailComponent;
