import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Button } from "reactstrap";
import CustomerForm from "components/forms/customers/customer_form";
import { ManageItem } from "components/packages/packages";
import ModalComponent from "components/utils/modal";
import {getMembershipTitle} from "components/utils";

import { resetEditErrors } from "actions/util_actions";


const OrderRow =({order, submitDelete}) => {
	return(
		<Row className="order">
			<Col className="order_title" xs={7} md={8} lg={10}>
				<p className="font_16_medium_black_list mb-0">
					{
						order.product_type === "event" ?
						order.product.parent.title:
						order.product_type === "membership" ?
						getMembershipTitle(order.product.title):
						order.product.title
					}
				</p>
			</Col>
			<Col className="order_manage" xs={5} md={4} lg={2}>
				<ManageItem item={order} color="grey" editUrl="" submitDelete={submitDelete}/>
			</Col>
		</Row>
	);
};


const OrdersTable = ({orders, handleOrder}) => {
	return (
		<Container>
			<Row className="customer_orders font_16_medium_a0a2a7_list d-none d-sm-none d-md-flex">
				<Col xs={7} md={8} lg={10}>Title</Col>
				<Col xs={5} md={4} lg={2}>Manage</Col>
			</Row>
			<div className="customer_orders_list">
				{
					orders.map((order) => <OrderRow order={order} key={order.id} submitDelete={handleOrder}/>)
				}
			</div>
		</Container>
	);
};


export const ManageCustomerOrdersComponent = ({orders, error, cancellOrder, handleOrder, orderToDelete, dispatch}) => {

	const [ordersList, setOrdersList] = useState([]);
	const [activeList, setActiveList] = useState();
	const [show, showModal] = useState(false);

	useEffect(() => {
		setOrdersList(orders.events);
		setActiveList("events");
		showModal(false);
	}, [orders]);

	useEffect(() => {
		switch(activeList){
		case "events":{
			setOrdersList(orders.events);
		}
			break;
		case "packages":{
			setOrdersList(orders.packages);
		}
			break;
		case "memberships":{
			setOrdersList(orders.memberships);
		}
			break;
		}
	}, [activeList]);

	useEffect(() => {
		if(error){
			dispatch(resetEditErrors());
		}
		if(orderToDelete){
			showModal(true);
		}
	}, [orderToDelete]);

	const cancellToEdit = () => {
		showModal(false);
	};

	const submitCancellOrder = () => {
		cancellOrder(orderToDelete);
		handleOrder(null);
	};

	return(
		<Container className="p-0 pl-md-1 pr-md-1">
			<Row className="orders_manage no-gutters">
				<Col xs={6} md={2}>
					<Button className={activeList === "events" ? "active-list": ""}
						onClick={() => setActiveList("events")}>
						Events
					</Button>
				</Col>
				<Col xs={6} md={2}>
					<Button className={activeList === "packages" ? "active-list": ""}
						onClick={() => setActiveList("packages")}>
						Packages
					</Button>
				</Col>
				<Col xs={12} md={2}>
					<Button className={activeList === "memberships" ? "active-list": ""}
						onClick={() => setActiveList("memberships")}>
						Membership
					</Button>
				</Col>
			</Row>
			{
				ordersList.length ?
					<OrdersTable orders={ordersList} handleOrder={handleOrder}/>:
					<p className="mt-3 mb-3 font_16_medium_2c2c2d_list">You haven&apos;t any active orders.</p>
			}
			<ModalComponent isOpen={show || error}
				header="CANCEL order"
				body={error || <p>Are you sure you want to cancel this order?</p>}>
				<Button className="btn btn-secondary" onClick={cancellToEdit}>Close</Button>
				{
					!error &&
					<Button className="btn btn-danger" onClick={submitCancellOrder}>Cancel order</Button>
				}
			</ModalComponent>
		</Container>
	);
};


export const EditCustomerComponent = ({onCustomerEdit, onCustomerDelete, initialValues, errors, children}) => {

	return(
		<Container className="mt-5 mb-5">
			<p className="font_24_regular_black_list">Edit customer</p>
			<CustomerForm edit={true}
				onCustomerSubmit={onCustomerEdit}
				onCustomerDelete={onCustomerDelete}
				initialValues={initialValues}
				errors={errors}>
				{children}
			</CustomerForm>
		</Container>
	);
};


EditCustomerComponent.propTypes = {
	onCustomerEdit: PropTypes.func.isRequired,
	onCustomerDelete: PropTypes.func.isRequired,
	initialValues: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired,
	children: PropTypes.element
};


ManageCustomerOrdersComponent.propTypes = {
	orders: PropTypes.arrayOf(PropTypes.object).isRequired,
	error: PropTypes.string,
	cancellOrder: PropTypes.func.isRequired,
	handleOrder: PropTypes.func.isRequired,
	orderToDelete: PropTypes.object,
	dispatch: PropTypes.func.isRequired
};


OrdersTable.propTypes = {
	orders: PropTypes.arrayOf(PropTypes.object).isRequired,
	handleOrder: PropTypes.func.isRequired
};


OrderRow.propTypes = {
	order: PropTypes.object.isRequired,
	submitDelete: PropTypes.func.isRequired
};