import {
	START_GETTING_ORDERS_LIST,
	RECEIVED_ORDERS_LIST,
	FAILED_RECEIVE_ORDERS_LIST
} from "actions/orders/consts";


const initialState = {
	orders: [],
	errors: {},
	isFetching: false
};


export default function ordersFilterReducer(state = initialState, action){
	switch(action.type){
	case START_GETTING_ORDERS_LIST:
		return{
			...state,
			isFetching: true,
			errors: {}
		};
	
	case RECEIVED_ORDERS_LIST:
		return{
			...state,
			errors: {},
			isFetching: false,
			orders: action.orders
		};
	
	case FAILED_RECEIVE_ORDERS_LIST:
		return{
			...state,
			isFetching: false,
			errors: action.errors
		};
	
	default:
		return state;
	}
}