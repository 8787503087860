export let NONE = "0",
	DAILY = "1",
	WEEKLY = "2",
	MONTHLY = "3";

export const eventRepeatChoices = [
	[
		NONE, "None"
	],
	[
		DAILY, "Daily"
	],
	[
		WEEKLY, "Weekly"
	],
	[MONTHLY, "Monthly"]
];