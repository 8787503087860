import {
	START_GET_PROMO,
	FINISHED_GET_PROMO,
	START_CREATE_FACILITY_PROMO,
	FINISHED_CREATE_FACILITY_PROMO,
	FAILED_CREATE_FACILITY_PROMO,
	START_UPDATE_FACILITY_PROMO,
	FINISHED_UPDATE_FACILITY_PROMO,
	FAILED_UPDATE_FACILITY_PROMO,
	START_DELETE_FACILITY_PROMO,
	FINISHED_DELETE_FACILITY_PROMO,
	FAILED_DELETE_FACILITY_PROMO
} from "actions/consts";


const getPromoObject = (promo) => {
	if(Array.isArray(promo)){
		return promo.length > 0 ? promo[0] : {};
	}
	return promo;
};


const initialState = {
	isFetching: false,
	promo: {},
	errors: {}
};


export default function facilityPromoReducer(state = initialState, action){
	switch(action.type){

	case START_GET_PROMO:
		return {
			...state,
			isFetching: true,
			errors: {}
		};

	case FINISHED_GET_PROMO:
		return {
			...state,
			isFetching: false,
			promo: getPromoObject(action.promo),
			errors: {}
		};

	case START_CREATE_FACILITY_PROMO:
		return {
			...state,
			isFetching: true,
			errors: {}
		};

	case FINISHED_CREATE_FACILITY_PROMO:
		return {
			...state,
			isFetching: false,
			promo: action.promo,
		};

	case FAILED_CREATE_FACILITY_PROMO:
		return {
			...state,
			isFetching: false,
			errors: action.errors
		};

	case START_UPDATE_FACILITY_PROMO:
		return {
			...state,
			isFetching: true,
			errors: {}
		};

	case FINISHED_UPDATE_FACILITY_PROMO:
		return {
			...state,
			isFetching: false,
			promo: action.promo
		};

	case FAILED_UPDATE_FACILITY_PROMO:
		return {
			...state,
			isFetching: false,
			errors: action.errors
		};

	case START_DELETE_FACILITY_PROMO:
		return {
			...state,
			isFetching: true,
			errors: {}
		};

	case FINISHED_DELETE_FACILITY_PROMO:
		return {
			...state,
			isFetching: false,
			promo: {}
		};

	case FAILED_DELETE_FACILITY_PROMO:
		return {
			...state,
			isFetching: false,
			errors: action.errors
		};

	default:
		return state;
	}
}