import {
	START_EDIT_PACKAGE,
	FINISHED_EDIT_PACKAGE,
	FAILED_EDIT_PACKAGE,
	START_FETCHING_PACKAGE,
	FINISHED_FETCHING_PACKAGE,
	FAILED_FETCHING_PACKAGE,
	START_DELETE_PACKAGE,
	FINISHED_DELETE_PACKAGE,
	FAILED_DELETE_PACKAGE,
	RESET_EDIT_ERRORS,
} from "actions/consts";


const initialState = {
	isFetching: false,
	errors: {},
	selectedPackage: {}
};

export default function editPackageReducer(state = initialState, action) {
	switch (action.type) {

	// REDUCER GET PACKAGE
	case START_FETCHING_PACKAGE:
		return {
			...state,
			isFetching: true
		};

	case FINISHED_FETCHING_PACKAGE:
		return {
			...state,
			isFetching: false,
			selectedPackage: action.data,
			errors: {}
		};

	case FAILED_FETCHING_PACKAGE:
		return {
			...state,
			isFetching: false,
			errors: action.errors
		};

		// REDUCER EDIT PACKAGE
	case START_EDIT_PACKAGE:
		return {
			...state,
			isFetching: true
		};

	case FINISHED_EDIT_PACKAGE:
		return {
			...state,
			isFetching: false,
			errors: {}
		};

	case FAILED_EDIT_PACKAGE:
		return {
			...state,
			isFetching: false,
			errors: action.errors
		};

	case START_DELETE_PACKAGE:
		return {
			...state,
			isFetching: true
		};

	case FINISHED_DELETE_PACKAGE:
		return {
			...state,
			isFetching: false,
			errors: {}
		};

	case FAILED_DELETE_PACKAGE:
		return {
			...state,
			isFetching: false,
			errors: action.errors
		};

	case RESET_EDIT_ERRORS:
		return{
			...state,
			isFetching: false,
			errors: {}
		};

	default:
		return state;
	}
}
