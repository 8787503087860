import {
	SEND_RESET_PASSWORD_REQUEST,
	SUCCESS_RESET_PASSWORD_REQUEST,
	FAILED_RESET_PASSWORD_REQUEST,
	START_CONFIRM_RESET_PASSWORD,
	FAILED_CONFIRM_RESET_PASSWORD,
	FINISHED_CONFIRM_RESET_PASSWORD,
	RESET_ERRORS_FROM_PASSWORD
} from "actions/consts";
import {checkLogin} from "actions/auth/login";
import { request } from "actions/utils";
import api from "routes/api";


export const resetErrorsFromPassword = () => {
	return { type: RESET_ERRORS_FROM_PASSWORD };
};

const sendResetPasswordRequest = () => {
	return { type: SEND_RESET_PASSWORD_REQUEST };
};


const receivedSuccessResetPasswordRequest = () => {
	return { type: SUCCESS_RESET_PASSWORD_REQUEST };
};


const failedResetPasswordRequest = (errors) => {
	return {
		type: FAILED_RESET_PASSWORD_REQUEST,
		errors: errors,
		receivedAt: Date.now()
	};
};


export const fetchResetPasswordRequest = (email) => {
	return (dispatch) => {
		dispatch(sendResetPasswordRequest());
		const config = {
			method: "POST"
		};

		const body = { email };

		const response = request(api.resetPassvordUrl, config, body, true);

		return response
			.then(() => dispatch(receivedSuccessResetPasswordRequest()))
			.catch((error) => {
				error.response.json()
					.then((json) => {
						dispatch(failedResetPasswordRequest(json));
					});
			});
	};
};


const startConfirmResetPassword = () => {
	return { type: START_CONFIRM_RESET_PASSWORD };
};


const finishedConfirmResetPassword = (data) => {
	return {
		type: FINISHED_CONFIRM_RESET_PASSWORD,
		data: data,
		receivedAt: Date.now()
	};
};


const failedConfirmResetPassword = (errors) => {
	return{
		type: FAILED_CONFIRM_RESET_PASSWORD,
		errors: errors,
		receivedAt: Date.now()
	};
};


export const fetchResetPasswordConfirmation = (password, confirm_password, token, uid) => {
	return (dispatch) => {
		dispatch(startConfirmResetPassword());
		const config = {
			method: "POST"
		};

		const body = {password, confirm_password, token, uid};

		const response = request(api.confirmResetPasswordUrl, config, body);

		return response
			.then((json) => {
				dispatch(finishedConfirmResetPassword(json));
				checkLogin(dispatch, json);
			})
			.catch((error) => {
				error.response.json()
					.then((json) => dispatch(failedConfirmResetPassword(json)));
			});
	};
};
