import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import MembershipsComponent from "components/memberships/memberships";
import { Spinner } from "components/utils/spinner";

import { fetchMemberships } from "actions/memberships/get_memberships_list";
import { deleteMembership } from "actions/memberships/edit_membership";
import apiUrls from "routes/api";


class MembershipsContainer extends React.Component {

	state = {
		show: false,
		membershipToDelete: {}
	};

	componentDidMount() {
		this.props.dispatch(fetchMemberships());
	}

	componentDidUpdate(prevProps, prevState){
		if(this.state.membershipToDelete !== prevState.membershipToDelete){
			this.props.dispatch(fetchMemberships());
		}
	}

	onDelete = (id) => {
		let apiUrl = apiUrls.membershipDetailUrl.replace(":id", id);
		this.props.dispatch(deleteMembership(apiUrl));
		this.handleClose();
	}

	handleShow = (membershipItem) => {
		this.setState({
			show: true,
			membershipToDelete: {
				id: membershipItem.id,
				title: membershipItem.title
			}
		});
	}

	handleClose = () => {
		this.setState({
			show: false,
			membershipToDelete: {}
		});
	}

	render() {
		const { isFetching, memberships } = this.props;
		let sendProps = {
			memberships,
			state: this.state,
			onDelete: this.onDelete,
			handleShow: this.handleShow,
			handleClose: this.handleClose
		};

		return (
			<>
				{ isFetching ? <Spinner/> : <MembershipsComponent {...sendProps} />}
			</>
		);
	}
}

MembershipsContainer.propTypes = {
	dispatch: PropTypes.func.isRequired,
	memberships: PropTypes.arrayOf(PropTypes.object),
	isFetching: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
	return {
		memberships: state.membershipsRoot.memberships || [],
		isFetching: state.membershipsRoot.isFetching || false
	};
};

const mapDispatchToProps = (dispatch) => {
	return { dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(MembershipsContainer);
