import {
	GET_ACTIVE_PACKAGES,
	RECEIVE_ACTIVE_PACKAGES,
	GET_EXPIRED_PACKAGES,
	RECEIVE_EXPIRED_PACKAGES,
} from "actions/consts";
import apiUrls from "routes/api";
import {request} from "actions/utils";


// Processing getting packages list
const getActivePackages = () => {
	return {
		type: GET_ACTIVE_PACKAGES,
		packages: []
	};
};

const receiveActivePackages = (data) => {
	return {
		type: RECEIVE_ACTIVE_PACKAGES,
		packages: data,
		receivedAt: Date.now()
	};
};

export const fetchActivePackages = () => {
	return (dispatch) => {
		dispatch(getActivePackages());
		const config = {
			method: "GET", 
			params:{
				expired: false
			} 
		};
		const response = request(apiUrls.packagesUrl, config);
		return response
			.then(json => dispatch(receiveActivePackages(json)))
			.catch( error => console.log("An error occured.", error));
	};
};

const getExpiredPackages = () => {
	return {
		type: GET_EXPIRED_PACKAGES,
		packages: []
	};
};

const receiveExpiredPackages = (data) => {
	return {
		type: RECEIVE_EXPIRED_PACKAGES,
		packages: data,
		receivedAt: Date.now()
	};
};

export const fetchExpiredPackages = () => {
	return (dispatch) => {
		dispatch(getExpiredPackages());
		const config = {
			method: "GET",
			params:{
				expired: true
			} 
		};
		const response = request(apiUrls.packagesUrl, config);
		return response
			.then(json => dispatch(receiveExpiredPackages(json)))
			.catch(error => console.log("An error occured.", error));
	};
};


export const fetchPackages = () => {
	return (dispatch) => {
		dispatch(fetchActivePackages());
		dispatch(fetchExpiredPackages());
	};
};