import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";

import { Form, Field } from "react-final-form";
import { Container, Row, Col } from "reactstrap";

import { fieldInput, fieldTextarea, uploadImageInput } from "components/forms/fields";
import ManageFormComponent from "components/forms/utils";
import frontendUrls from "routes/frontend_routes";


const FacilityPromoForm = ({initialValues, onSubmit, errors, isEditForm, onDelete}) => {

	const emptyImageUrl = "https://cdn.pixabay.com/photo/2017/01/18/17/39/cloud-computing-1990405_960_720.png";
	const [imageFile, setImageFile] = useState();
	const [coverImageUrl, setCoverImageUrl] = useState();
	const [initFormValues, setInitFormValues] = useState({});

	useEffect(() => {
		setCoverImageUrl(initialValues.image ? initialValues.image : emptyImageUrl)
		setInitFormValues({...initialValues, image: ""});
	}, [initialValues]);

	const onImageUpload = (event) => {
		const image = event.target.files[0];
		setImageFile(image);
		setCoverImageUrl(URL.createObjectURL(image));
	};

	const onFormSubmit = (submitData) => {
		submitData.image = imageFile;
		onSubmit(submitData);
	};

	return(
		<Container className="bg_white shadow_form border_radius_03rem mb-5">
			<Form
				initialValues={initFormValues}
				onSubmit={onFormSubmit}
				render={({handleSubmit, pristine, values, submitting }) => (
					<form className="bg_white pt-3" onSubmit={handleSubmit}>
						<Container>
							<Row>
								<Col xs={6}>
									<Row>
										<Col xs={12}>
											<Field
												name="title"
												label="Title"
												serverError={errors.title}
												component={fieldInput}/>
										</Col>
									</Row>
									<Row>
										<Col xs={12}>
											<Field
												name="body"
												label="Banner text"
												inputType="textarea"
												componentClass="textarea"
												className="textarea_resize_none"
												rows="10"
												serverError={errors.body}
												component={fieldTextarea}/>
										</Col>
									</Row>
								</Col>
								<Col xs={6}>
									<Field
										name="image"
										coverImageUrl={coverImageUrl}
										onChange={onImageUpload}
										serverError={errors.image}
										component={uploadImageInput}/>
								</Col>
							</Row>
						</Container>
						<ManageFormComponent
							errors={errors}
							isSubmitedForm={submitting}
							isPristineForm={pristine}
							isEditForm={isEditForm}
							product="promo"
							onDelete={onDelete}/>
					</form>
				)}
			></Form>
		</Container>
	);
};


FacilityPromoForm.propTypes = {
	initialValues: PropTypes.object.isRequired,
	isEditForm: PropTypes.bool.isRequired,
	errors: PropTypes.object.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onDelete: PropTypes.func
};


export default FacilityPromoForm;