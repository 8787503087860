import {
	START_BUY_BOOKING_PACKAGE,
	FINISH_BUY_BOOKING_PACKAGE,
	FAIL_BUY_BOOKING_PACKAGE
} from "actions/consts";
import {request} from "actions/utils";


// Processing fetching package
const startBuyBookingPackage = () => {
	return { type: START_BUY_BOOKING_PACKAGE };
};

const finishBuyBookingPackage = (data) => {
	return {
		type: FINISH_BUY_BOOKING_PACKAGE,
		data: data,
		receivedAt: Date.now()
	};
};

export const failBuyBookingPackage = (data) => {
	return {
		type: FAIL_BUY_BOOKING_PACKAGE,
		errors: data,
		receivedAt: Date.now()
	};
};

export const buyBookingPackage = (apiUrlBuyPackage, stripeToken=null) => {
	return (dispatch, getState) => {
		dispatch(startBuyBookingPackage);
		const { 
			root: { authToken }, 
			bookPackageDetailRoot: {selectedBookingPackage}
		} = getState();
		const config = {
			method: "POST",
			isAuth: authToken
		};
		let body = {};
		if(selectedBookingPackage.price > 0 && stripeToken ) {
			body = {stripe_token: stripeToken};
		}

		const response = request(apiUrlBuyPackage, config, body);
		
		return response
			.then((json) => {
				dispatch(finishBuyBookingPackage(json));
			})
			.catch((error) => {
				error.response.json()
					.then((json) => {
						dispatch(failBuyBookingPackage(json));
					});
			});
	};
};