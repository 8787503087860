import React, { Component } from "react";
import PropTypes from "prop-types";
import { Calendar as BigCalendar } from "react-big-calendar";

import { Spinner } from "components/utils/spinner";
import { MonthDateHeader, MonthHeader, MonthEvent } from "components/calendar/config/views/month";
import { WeekHeader, WeekEvent } from "components/calendar/config/views/week";
import { DayEvent } from "components/calendar/config/views/day";
import { AgendaDate, AgendaTime } from "components/calendar/config/views/agenda";
import {
	customEventPropGetter,
	customDayPropGetter,
	customSlotPropGetter
} from "components/calendar/config/prop_getters";
import { formats, views, localizer } from "components/calendar/config/consts";

import "react-big-calendar/lib/css/react-big-calendar.css";


const Calendar = (Toolbar, Popover, AgendaEvent, isFacilitySide = false) => {
	class PozenCalendar extends Component{
		state = {
			eventsList: [],
			showMore: false,
			currentView: views.MONTH,
			currentDate: new Date(),
			rangeDate: new Date(),
			selectedDate: new Date()
		};

		componentDidMount(){
			const { defaultDate, events } = this.props;
			this.setState({
				currentDate: defaultDate,
				selectedDate: defaultDate,
				eventsList: events
			});
		}

		componentDidUpdate(prevProps, prevState){
			const { events, getEvents } = this.props;
			const { currentDate, currentView, rangeDate } = this.state;
			if(prevProps.events !== events){
				this.setState({
					eventsList: events
				});
			}
			if(isFacilitySide){
				if(prevState.currentDate !== currentDate ||
					prevState.currentView !== currentView ||
					prevState.rangeDate !== rangeDate){
					const agendaRangeDate = currentView === views.AGENDA ? rangeDate : null;
					getEvents(currentView, currentDate, agendaRangeDate);
				}
			}
		}

		onShowMore = (events, date) => {
			const showMore = !this.state.showMore;
			this.setState({
				selectedDate: date,
				showMore
			});
		};

		onNavigate = (date) => {
			this.setState({
				showMore: false,
				currentDate: date
			});
		}

		onView = (view) => {
			this.setState({
				currentView: view
			});
		}

		onRangeChange = ({end}) => {
			if(isFacilitySide){
				this.setState({
					rangeDate: end
				});
			}
		}

		render(){
			const { currentView, eventsList, currentDate, showMore, selectedDate } = this.state;
			return (
				this.props.isFetching ? <Spinner/>:
					<BigCalendar events={eventsList}
						defaultDate={currentDate}
						eventPropGetter={() => customEventPropGetter(currentView)}
						dayPropGetter={customDayPropGetter}
						slotPropGetter={customSlotPropGetter}
						localizer={localizer}
						formats={formats}
						views={["agenda", "day", "week", "month"]}
						defaultView={currentView}
						drilldownView={views.MONTH}
						components={{
							toolbar: Toolbar,
							agenda:{
								date: AgendaDate,
								time: AgendaTime,
								event: AgendaEvent,
							},
							day:{
								event: DayEvent
							},
							week:{
								header: WeekHeader,
								event: WeekEvent
							},
							month:{
								header: MonthHeader,
								dateHeader: ({date, label}) => MonthDateHeader(date, label, eventsList, showMore, selectedDate, Popover.WrappComponent, Popover.EventRow, isFacilitySide),
								event: MonthEvent
							}
						}}
						onShowMore={this.onShowMore}
						onNavigate={this.onNavigate}
						onView={this.onView}
						onRangeChange={this.onRangeChange}/>
			);
		}
	}

	PozenCalendar.propTypes = {
		isFetching: PropTypes.bool,
		events: PropTypes.arrayOf(PropTypes.object).isRequired,
		defaultDate: PropTypes.instanceOf(Date),
		getEvents: PropTypes.func
	};

	return PozenCalendar;
};


export default Calendar;