import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Col, Button, Container, Collapse } from "reactstrap";
import { 
	BsTrash, BsCalendar, BsTagFill,
	BsChevronLeft, BsFillXCircleFill
} from "react-icons/bs";
import RelatedCustomers from "components/customers/customers_related";
import { withAuth, withCustomers } from "components/utils/hoc";
import ModalComponent from "components/utils/modal";

import frontendUrls from "routes/frontend_routes";
import fetchCustomers from "actions/customers/get_customers_list";
import { getDateRange, getCategoryName } from "components/utils";


const DashboardDetailComponent = ({
	handleShow, selectedEvent, handleClose, onDelete, setDashboardSelectedEventDispatched, show, eventToDelete,
	dispatch, authToken, customers
}) => {

	const [customer, openCustomers] = useState(false);
	const [isCustomers, hasCustomers] = useState(false);

	useEffect(() => hasCustomers(customers.length > 0));

	const showCustomers = () => {
		if(customer){
			openCustomers(false);
		}else{
			dispatch(fetchCustomers(authToken, {event_id: selectedEvent.id}));
			openCustomers(true);
		}
	};

	return (
		<Container className="bg_white mt-4 mb-4 eventDashboard border-top-0">
			<Row className="detail-buttons border_bottom_table">
				<Col xs={2} className="title">
					<BsChevronLeft className="cursor-pointer gliph_15px" onClick={() => {
						setDashboardSelectedEventDispatched({});
					}}/>
				</Col>
				<Col xs={10}
					className="title font_16_medium_black_list">
					{selectedEvent.title}
				</Col>
			</Row>
			<Row className="padding_top_25px">
				<Col xs={1} className="text-right">
					<BsCalendar className="gliph_grey gliph_18px" />
				</Col>
				<Col xs={10}>
					<p className="font_16_regular_black_list">
						{getDateRange(selectedEvent.from_date, selectedEvent.until_date)}
					</p>
				</Col>
			</Row>
			<Row className="mb-3">
				<Col xs={{size: 10, offset: 1}}>
					<p className="mb-0 display_inline_block font_14_medium_black_list">Instructor:&nbsp;</p>
					<p className="mb-0 display_inline_block font_14_regular_black_list">
						{selectedEvent.instructor}
					</p>
				</Col>
			</Row>
			<Row className="mb-3">
				<Col xs={{size: 10, offset: 1}}>
					<p className="mb-0 display_inline_block font_14_medium_black_list">Location:&nbsp;</p>
					<p className="mb-0 display_inline_block font_14_regular_black_list">
						{selectedEvent.location}
					</p>
				</Col>
			</Row>
			<Row>
				<Col xs={{size: 10, offset: 1}}>
					<p className="mb-0 display_inline_block font_14_medium_black_list">Category:&nbsp;</p>
					<p className="mb-0 display_inline_block font_14_regular_black_list">
						{getCategoryName(selectedEvent.category)}
					</p>
				</Col>
			</Row>
			<Row className="padding_top_25px">
				<Col xs={{size: 10, offset: 1}}>
					<p className="display_inline_block font_14_medium_black_list">
				Description: <span className="font_14_regular_black_list">
							{selectedEvent.description}
						</span>
					</p>
				</Col>
			</Row>
			<Row>
				<Col xs={{size: 10, offset: 1}}>
					<p className="display_inline_block font_14_medium_black_list">
						Cancellation policy: <span className="font_14_regular_black_list">
							{selectedEvent.cancellation_policy}
						</span>
					</p>
				</Col>
			</Row>
			<Row>
				<Col xs={1} className="text-right">
					<BsTagFill className="gliph_grey gliph_18px" />
				</Col>
				<Col xs={10}>
					<div>
						<p className="display_inline_block font_14_medium_black_list">Price:</p>
						<p className="display_inline_block margin_left_10px font_14_medium_black_list">
						£{selectedEvent.price}
						</p>
					</div>
				</Col>
			</Row>
			<Row className="d-md-none detail-buttons bg_row_btn border border_row_btn">
				<Col xs={2} sm={1}>
					<Link className="mt-4 text-center sprite_pencil"
						to={frontendUrls.urlDashboardEventEdit.replace(":id", selectedEvent.id)}>
					</Link>
				</Col>
				<Col xs={2} sm={1}>
					<BsTrash className="mt-4 text-center gliph_light_blue gliph_16px cursor-pointer"
						onClick={() => { handleShow(selectedEvent); }}
					/>
				</Col>
				<Col xs={8} className="d-flex justify-content-end align-items-center">
					<Button className="rel_customers_btn" onClick={showCustomers}>
					Customers { customer && <BsFillXCircleFill className="gliph_15px"/>}
					</Button>
				</Col>
			</Row>
			<Collapse isOpen={customer && isCustomers}>
				<RelatedCustomers customers={customers}/>
			</Collapse>
			<ModalComponent isOpen={show}
				header={`DELETE ${eventToDelete.title}`}
				body={<p>Are you sure you want to delete this event?</p>}>
				<Button className="btn btn-secondary" onClick={handleClose}>Close</Button>
				<Button className="btn btn-danger" onClick={function () { onDelete(eventToDelete.id); }}>
					Delete Event
				</Button>
			</ModalComponent>
		</Container>
	);
};

const AuthDashboardDetailComponent = withAuth(withCustomers(DashboardDetailComponent));

DashboardDetailComponent.propTypes = {
	events: PropTypes.object.isRequired,
	handleShow: PropTypes.func,
	selectedEvent: PropTypes.object,
	handleClose: PropTypes.func.isRequired,
	state: PropTypes.object.isRequired,
	onDelete: PropTypes.func,
	show: PropTypes.bool,
	eventToDelete: PropTypes.object,
	setDashboardSelectedEventDispatched: PropTypes.func,
	authToken: PropTypes.string.isRequired,
	dispatch: PropTypes.func.isRequired,
	customers: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state) => {
	return {
		selectedEvent: state.utilRoot && state.utilRoot.selectedEvent,
	};
};

export default connect(mapStateToProps, null)(AuthDashboardDetailComponent) ;