import {
	START_LOGIN,
	FINISHED_LOGIN,
	FAILED_LOGIN
} from "../../actions/consts";


const initialState = {
	loginRoot: {
		isFetching: false,
		errors: {}
	}
};

export default function loginReducer(state = initialState, action) {
	switch (action.type) {
	// REDUCER LOGIN

	case START_LOGIN:
		return {
			...state,
			isFetching: true
		};

	case FINISHED_LOGIN:
		return {
			...state,
			isFetching: false,
			errors: {}
		};

	case FAILED_LOGIN:
		return {
			...state,
			isFetching: false,
			errors: action.errors
		};

	default:
		return state;
	}
}
