import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Row, Col } from "reactstrap";
import { FaBars } from "react-icons/fa";

import UserAuthContainer from "containers/auth/user_auth";
import MenuContainer from "containers/elements/booking_pages/menu";
import {BookingBreadcrumb} from "components/elements/breadcrumbs";


const HeaderComponent = ({isMenuOpen, setToMenuOpenDispatched, setToMenuCloseDispatched}) => {
	return (
		<Fragment>
			<Row onClick={() => {
				if (isMenuOpen) {
					setToMenuCloseDispatched();
				}
			}}
			className={classNames("show-grid header position-absolute w-100", {
				"overlay": isMenuOpen === true,
			})}
			>
				<Col className="right_header shadow_right px-3">
					<Row className="justify-content-end">
						<Col >
							<div className="mx-auto mt-4 logo-img-wrapper">
								<img
									className=" logo-img "
									src="/static/logo_img.png"
									alt="Pozen logo" />
							</div>
						</Col>
						<Col xs={7} md={5} lg={7} xl={7} className="mt-3 d-none d-sm-none d-md-block pl-4">
							<BookingBreadcrumb/>
						</Col>
						<Col xs={3} className="display_inline_block">
							<UserAuthContainer />
						</Col>
						<Col xs={1} className="mt-4 mr-2 d-lg-none">
							<FaBars onClick={setToMenuOpenDispatched} className="height_20px width_20px menu_color" />
						</Col>
					</Row>
				</Col>
			</Row>
			<div
				className={classNames("side-collapse d-lg-none", {
					"open": isMenuOpen === true
				})}>
				<MenuContainer/>
			</div>
		</Fragment>
	);
};

HeaderComponent.propTypes = {
	state: PropTypes.object.isRequired,
	setToMenuOpenDispatched: PropTypes.func.isRequired,
	setToMenuCloseDispatched: PropTypes.func.isRequired,
	isMenuOpen: PropTypes.bool.isRequired,
};

export default HeaderComponent;