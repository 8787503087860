import React, {useEffect, useState} from "react";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Button } from "reactstrap";
import ModalComponent from "components/utils/modal";
import { resetHttpErrors } from "actions/util_actions";


const ErrorBoundry = ({children, httpErrors, dispatch }) => {
	
	const [isOpen, showError] = useState(false);
	const history = useHistory();

	useEffect(() => {
		if (httpErrors){
			showError(true);
		}
	}, [httpErrors]);

	const handleClose = () => {
		dispatch(resetHttpErrors());
		showError(false);
		history.go(0);
	};

	return( 
		<>
			<div className="h-100">{children}</div>
			<ModalComponent isOpen={isOpen} header="ERROR!" body={httpErrors}>
				<Button className="btn btn-secondary" onClick={handleClose}>Close</Button>
			</ModalComponent>
		</>
	);
};


ErrorBoundry.propTypes = {
	children: PropTypes.any,
	httpErrors: PropTypes.string.isRequired,
	dispatch: PropTypes.func.isRequired
};


const mapStateToProps = ({ utilRoot: {httpErrors}}) => {
	return {httpErrors};
};


export default connect(mapStateToProps)(ErrorBoundry);