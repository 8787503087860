import {
	GET_MONTHLY_FILTER_EVENTS,
	RECEIVE_MONTHLY_FILTER_EVENTS
} from "../../../actions/consts";


const initialState = {
	isFetching: false,
	monthlyEvents: []
};

export default function monthlyEventsReducer(state = initialState, action) {
	switch (action.type) {
	// REDUCER MONTHLY EVENTS

	case GET_MONTHLY_FILTER_EVENTS:
		return {
			...state,
			isFetching: true
		};

	case RECEIVE_MONTHLY_FILTER_EVENTS:
		return {
			...state,
			isFetching: false,
			monthlyEvents: action.monthlyEvents,
			lastUpdated: action.receivedAt
		};

	default:
		return state;
	}
}
