import React from "react";
import PropTypes from "prop-types";
import {
	FormGroup, InputGroup, InputGroupAddon,
	InputGroupText, Input, Label, CustomInput,
	Row, Col
} from "reactstrap";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";


export function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}


export const fieldWithLabelAndSelect = ({
	input,
	classNameFormGroup,
	classNameLabel,
	label,
	className,
	choices,
	serverError,
	meta: {
		touched,
		error
	}
}) => {
	return (
		<FormGroup className={classNameFormGroup}>
			<Label className={classNameLabel}>{label}</Label>
			<Input {...input} type="select" className={className}>
				{choices.map(value => <option key={value[0]} value={value[0]}>{value[1]}</option>)}
			</Input>
			<div className="margin_0 padding_0">
				{touched && error && <p className="font_danger">{error}</p>}
				{serverError && <p className="font_danger">{serverError}</p>}
			</div>
		</FormGroup>
	);
};

fieldWithLabelAndSelect.propTypes = {
	input: PropTypes.object.isRequired,
	classNameFormGroup: PropTypes.string,
	classNameLabel: PropTypes.string,
	label: PropTypes.string,
	className: PropTypes.string,
	serverError: PropTypes.array,
	meta: PropTypes.object.isRequired,
	choices: PropTypes.array.isRequired
};


export const fieldInput = ({
	input,
	classNameFormGroup,
	classNameLabel,
	classNameErrors,
	label,
	inputType,
	className,
	placeholder,
	serverError,
	classNameInputGroup,
	handlers,
	meta: {
		touched,
		error
	}
}) => {
	return (
		<FormGroup className={classNameFormGroup}>
			<Label className={classNameLabel}>{label}</Label>
			<InputGroup className={classNameInputGroup}>
				<Input {...input} type={inputType} placeholder={placeholder} className={className} {...handlers}/>
			</InputGroup>
			<div className={classNameErrors || "margin_0 padding_0"}>
				{touched && error && <p className="font_danger">{error}</p>}
				{serverError && <p className="font_danger">{serverError}</p>}
			</div>
		</FormGroup>
	);
};

fieldInput.propTypes = {
	input: PropTypes.object.isRequired,
	classNameFormGroup: PropTypes.string,
	classNameInputGroup: PropTypes.string,
	classNameLabel: PropTypes.string,
	label: PropTypes.string,
	inputType: PropTypes.string.isRequired,
	placeholder: PropTypes.string.isRequired,
	className: PropTypes.string,
	serverError: PropTypes.array,
	meta: PropTypes.object.isRequired,
	classNameErrors: PropTypes.string
};


export const fieldTextarea = ({
	input,
	classNameFormGroup,
	classNameLabel,
	label,
	inputType,
	placeholder,
	className,
	rows,
	serverError,
	meta: {
		touched,
		error
	}
}) => {
	return (
		<FormGroup className={classNameFormGroup}>
			<Label className={classNameLabel}>{label}</Label>
			<Input {...input} type={inputType} placeholder={placeholder} className={className} rows={rows} />
			<div className="margin_0 padding_0">
				{touched && error && <p className="font_danger">{error}</p>}
				{serverError && <p className="font_danger">{serverError}</p>}
			</div>
		</FormGroup>

	);
};

fieldTextarea.propTypes = {
	input: PropTypes.object.isRequired,
	classNameFormGroup: PropTypes.string,
	classNameLabel: PropTypes.string,
	label: PropTypes.string,
	inputType: PropTypes.string.isRequired,
	className: PropTypes.string,
	rows: PropTypes.string,
	placeholder: PropTypes.string.isRequired,
	serverError: PropTypes.array,
	meta: PropTypes.object.isRequired
};


export const fieldInputWithAddon = ({
	input,
	classNameFormGroup,
	classNameLabel,
	label,
	inputType,
	placeholder,
	className,
	classNameInputGroup,
	sprite,
	serverError,
	disabled,
	meta: {
		touched,
		error
	}
}) => {
	return (
		<FormGroup className={classNameFormGroup}>
			<Label className={classNameLabel}>{label}</Label>
			<InputGroup className={classNameInputGroup}>
				<Input {...input} type={inputType} placeholder={placeholder} className={className} disabled={disabled}/>
				<InputGroupAddon addonType="append">
					<InputGroupText>{sprite}</InputGroupText>
				</InputGroupAddon>
			</InputGroup>
			<div className="margin_0 padding_0">
				{touched && error && <p className="font_danger">{error}</p>}
				{serverError && <p className="font_danger">{serverError}</p>}
			</div>
		</FormGroup>
	);
};

fieldInputWithAddon.propTypes = {
	input: PropTypes.object.isRequired,
	classNameFormGroup: PropTypes.string,
	classNameLabel: PropTypes.string,
	classNameInputGroup: PropTypes.string,
	label: PropTypes.string,
	inputType: PropTypes.string.isRequired,
	placeholder: PropTypes.string.isRequired,
	className: PropTypes.string,
	sprite: PropTypes.object.isRequired,
	serverError: PropTypes.array,
	meta: PropTypes.object.isRequired,
	disabled: PropTypes.bool
};


export const checkboxField = ({
	input,
	classNameFormGroup,
	className,
	label,
	classNameLabel,
	classNameInputGroup,
	serverError,
	meta: {
		touched,
		error
	}
}) => {
	return (
		<FormGroup check className={classNameFormGroup}>
			<InputGroup className={classNameInputGroup}>
				<Input checked={input.value} {...input} type="checkbox" className={className} /> {" "}
			</InputGroup>
			<Label check className={classNameLabel}>
				{label}
			</Label>
			<div className="margin_0 padding_0">
				{touched && error && <p className="font_danger">{error}</p>}
				{serverError && <p className="font_danger">{serverError}</p>}
			</div>
		</FormGroup>
	);
};

checkboxField.propTypes = {
	input: PropTypes.object.isRequired,
	classNameFormGroup: PropTypes.string,
	className: PropTypes.string,
	label: PropTypes.string,
	classNameLabel: PropTypes.string,
	classNameInputGroup: PropTypes.string,
	serverError: PropTypes.array,
	meta: PropTypes.object.isRequired
};


export const phoneNumberField = ({
	input, label, placeholder, serverError,
	classNameFormGroup, classNameLabel, classNameErrors,
	classNameInputGroup, className, meta: { touched, error }
}) => {
	return (
		<FormGroup className={classNameFormGroup}>
			<Label className={classNameLabel}>{label}</Label>
			<InputGroup className={classNameInputGroup}>
				<PhoneInput {...input} defaultCountry="GB" placeholder={placeholder} className={className}/>
			</InputGroup>
			<div className={classNameErrors || "margin_0 padding_0"}>
				{touched && error && <p className="font_danger">{error}</p>}
				{serverError && <p className="font_danger">{serverError}</p>}
			</div>
		</FormGroup>
	);
};

phoneNumberField.propTypes = {
	input: PropTypes.object.isRequired,
	classNameFormGroup: PropTypes.string,
	classNameInputGroup: PropTypes.string,
	classNameLabel: PropTypes.string,
	label: PropTypes.string,
	placeholder: PropTypes.string.isRequired,
	className: PropTypes.string,
	serverError: PropTypes.array,
	meta: PropTypes.object.isRequired,
	classNameErrors: PropTypes.string
};


export const fieldSwitch = ({input: {onChange, value}, name, id, disabled}) => {
	return(
		<CustomInput type="switch"
			checked={value} name={name}
			id={`custom-switch-${id}`}
			disabled={disabled}
			onChange={() => onChange(!value)}/>
	);
};


fieldSwitch.propTypes = {
	input: PropTypes.object.isRequired,
	name: PropTypes.string.isRequired,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	disabled: PropTypes.bool.isRequired
};


export const uploadImageInput = ({
	input,
	classNameFormGroup,
	className,
	label,
	coverImageUrl,
	classNameLabel,
	classNameInputGroup,
	classNameErrors,
	serverError,
	onChange,
	meta: {
		touched,
		error
	}
}) => {
	return (
		<Row>
			<Col xs={12}>
				<Row>
					<Col xs={12}>
						<img className="img-fluid" src={coverImageUrl}/>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<FormGroup className={classNameFormGroup}>
							<Label className={classNameLabel}>{label}</Label>
							<InputGroup className={classNameInputGroup}>
								<Input {...input} type="file" className={className} onChange={onChange}/>
							</InputGroup>
							<div className={classNameErrors || "margin_0 padding_0"}>
								{touched && error && <p className="font_danger">{error}</p>}
								{serverError && <p className="font_danger">{serverError}</p>}
							</div>
						</FormGroup>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};


uploadImageInput.propTypes = {
	input: PropTypes.object.isRequired,
	classNameFormGroup: PropTypes.string,
	classNameInputGroup: PropTypes.string,
	classNameLabel: PropTypes.string,
	label: PropTypes.string,
	className: PropTypes.string,
	serverError: PropTypes.array,
	meta: PropTypes.object.isRequired,
	classNameErrors: PropTypes.string,
	coverImageUrl: PropTypes.string.isRequired,
	OnChange: PropTypes.func.isRequired
};