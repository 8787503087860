import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { Container, Row, Col, Button, Collapse } from "reactstrap";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import { ManageItem } from "components/packages/packages";
import CustomerDetails from "components/customers/customers_details";
import ModalComponent from "components/utils/modal";

import { getDateFromString, getFullName } from "components/utils";
import frontendUrls from "routes/frontend_routes";


const CustomersRow = ({item, active, openItem, submitDelete}) => {
	const editUrl = frontendUrls.urlDashboardCustomerEdit.replace(":id", item.id);
	const { first_name, last_name, email } = item;
	const { birthday, username } = item.user || {};

	const [show, showDetails] = useState(false);

	useEffect(() => showDetails(item === active));

	const openDetails = () => {
		if(show){
			openItem(null);
			showDetails(false);
		} else {
			openItem(item);
			showDetails(true);
		}
	};
	
	return(
		<>
			<Row className="customer" onClick={openDetails}>
				<Col xs={6} md={3} lg={2} className={`customer_name order-md-1 ${ show ? "order-2 order-sm-2" : ""}`}>
					<p className="font_16_medium_black_list mb-0">{getFullName(first_name, last_name)}</p>
				</Col>
				<Col xs={4} md={4} lg={3} className="customer_email order-md-2 d-none d-sm-none d-md-flex">
					<p className="font_16_medium_a0a2a7_list mb-0">{email}</p>
				</Col>
				<Col xs={2} lg={2} className="customer_username order-md-3 d-none d-sm-none d-md-none d-lg-flex">
					<p className="font_16_medium_a0a2a7_list mb-0">{username || "-"}</p>
				</Col>
				<Col xs={2} lg={2} className="customer_birthday order-md-4 d-none d-sm-none d-md-none d-lg-flex">
					<p className="font_16_medium_black_list mb-0">{getDateFromString(birthday)}</p>
				</Col>
				<Col xs={4} md={2} lg={1} className={`customer_status order-md-5 ${ show ? "order-3 order-sm-3" : ""}`}>
					<p className="font_16_medium_a0a2a7_list mb-0">Active</p>
				</Col>
				<Col xs={2} md={3} lg={2} className="customer_manage order-md-6 d-none d-sm-none d-md-block">
					<ManageItem item={item} color="grey" editUrl={editUrl} submitDelete={submitDelete}/>
				</Col>
				<Col xs={2} className={`customer_details d-flex d-sm-flex d-md-none ${ show ? "order-1 order-sm-1" : ""}`}>
					{ 
						show ? 
							<FaAngleLeft className="gliph_grey gliph_18px"/>:
							<FaAngleRight className="gliph_grey gliph_18px"/>
					}
				</Col>
			</Row>
			<div className="d-block d-sm-block d-md-none">
				<Collapse isOpen={show}>
					<CustomerDetails item={item}/>
					<Row className="dash_item_manage_vide">
						<Col xs={4}>
							<ManageItem item={item}
								editUrl={editUrl}
								color="grey"
								submitDelete={submitDelete}/>
						</Col>
					</Row>
				</Collapse>
			</div>
		</>
	);
};


const CustomersTable = ({customers, submitDelete}) => {

	const [active, openItem] = useState(null);

	return(
		<Container className="mt-4 mb-3 bg_white shadow_form">
			<Row className="customers font_16_medium_a0a2a7_list d-none d-sm-none d-md-flex">
				<Col lg={2} md={3}>Name</Col>
				<Col lg={3} md={4}>Email</Col>
				<Col lg={2} className="d-none d-sm-none d-md-none d-lg-flex">Username</Col>
				<Col lg={2} className="d-none d-sm-none d-md-none d-lg-flex">Birthday</Col>
				<Col lg={1} md={2}>Status</Col>
				<Col lg={2} md={3} className="d-none d-sm-none d-md-flex">Manage</Col>
			</Row>
			<div className="customers_list">
				{
					customers.map((item) => {
						const itemProps = {
							item, active, openItem, submitDelete
						};
						return <CustomersRow {...itemProps} key={item.id}/>;
					})
				}
			</div>
		</Container>
	);
};


const CustomersComponent = ({
	customers, showModal, show, error,
	customerToDelete, deleteCustomer, onDelete, 
}) => {

	const { first_name, last_name } = customerToDelete;

	return(
		<>
			<Container className="mt-5">
				<Row>
					<Col xs={12}>
						<p className="font_20_medium_black_list">Customers</p>
					</Col>
				</Row>
				<Row>
					<Col xs={12} className="d-flex justify-content-end">
						<Link to={frontendUrls.urlDashboardCreateCustomer}>
							<Button className="btn_customers">Add Customers</Button>
						</Link>
					</Col>
				</Row>
				<CustomersTable customers={customers} onDelete={onDelete} submitDelete={deleteCustomer}/>
			</Container>
			<ModalComponent isOpen={show || error}
				header={`DELETE ${getFullName(first_name, last_name)}`}
				body={error || <p>Are you sure you want to delete this customer?</p>}>
				<Button className="btn btn-secondary" onClick={() => {showModal(false);}}>Close</Button>
				{
					!error &&
					<Button className="btn btn-danger"
						onClick={() => onDelete(customerToDelete)}>
						Delete Customer
					</Button>
				}
			</ModalComponent>
		</>
	);
};


CustomersComponent.propTypes = {
	customers: PropTypes.arrayOf(PropTypes.object).isRequired,
	showModal: PropTypes.func.isRequired,
	show: PropTypes.bool.isRequired,
	error: PropTypes.any,
	customerToDelete: PropTypes.object.isRequired,
	deleteCustomer: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired
};


CustomersTable.propTypes = {
	customers: PropTypes.arrayOf(PropTypes.object).isRequired,
	submitDelete: PropTypes.func.isRequired
};


CustomersRow.propTypes = {
	item: PropTypes.object.isRequired,
	active: PropTypes.object,
	openItem: PropTypes.func.isRequired,
	submitDelete: PropTypes.func.isRequired
};


export default CustomersComponent;