import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";

import { DESTROY_SESSION } from "actions/consts";

import rootReducer from "./auth/root";
import loginReducer from "./auth/login";
import registerReducer from "./auth/register";
import activateAccountReducer from "reducers/auth/activate_account";
import resetPasswordReducer from "reducers/auth/reset_password";

// Customer side
import orderEventReducer from "./booking/events/eventBuy";
import bookingDetailEventReducer from "./booking/events/eventDetail";
import bookingEventsReducer from "./booking/events/events";
//filter for customer
import monthlyEventsReducer from "./booking/filters/monthly_filter_events";

import orderMembershipReducer from "./booking/memberships/membershipBuy";
import bookingDetailMembershipReducer from "./booking/memberships/membershipDetail";
import bookingMembershipsReducer from "./booking/memberships/memberships";

import orderPackageReducer from "./booking/packages/packageBuy";
import bookingDetailPackageReducer from "./booking/packages/packageDetail";
import bookingPackagesReducer from "./booking/packages/packages";

// Dashboard side
import creationEventReducer from "./events/create_event";
import editEventReducer from "./events/edit_event";
import eventsReducer from "./events/get_events_list";

import creationMembershipReducer from "./memberships/create_membership";
import editMembershipReducer from "./memberships/edit_membership";
import membershipsReducer from "./memberships/get_memberships_list";

import creationPackageReducer from "./packages/create_package";
import editPackageReducer from "./packages/edit_package";
import packagesReducer from "./packages/get_packages_list";

import utilReducer from "./util_reducers";

import ordersReducer from "./orders/get_orders_list";
import customersReducer from "reducers/customers/get_customers_list";
import creationCustomerReducer from "reducers/customers/create_customer";
import editCustomerReducer from "reducers/customers/edit_customer";
import calendarReducer from "reducers/calendar/get_events";
import orderFilterReducer from "reducers/orders/get_filtered_orders";

// Facilities
import facilityPromoReducer from "reducers/facilities/facility_promo_reducer";
import facilityAdditionalServicesReducer from "reducers/facilities/additional_services_reducer";


const appReducers = combineReducers({
	routing: routerReducer,
	root: rootReducer,
	loginRoot: loginReducer,
	registerRoot: registerReducer,
	activateAccountRoot: activateAccountReducer,
	resetPasswordRoot: resetPasswordReducer,

	// Customer side
	orderEventRoot: orderEventReducer,
	bookEventDetailRoot: bookingDetailEventReducer,
	bookingEventsRoot: bookingEventsReducer,
	//filter for customer
	monthlyEventsRoot: monthlyEventsReducer,

	orderMembershipRoot: orderMembershipReducer,
	bookMembershipDetailRoot: bookingDetailMembershipReducer,
	bookingMembershipsRoot: bookingMembershipsReducer,

	orderPackageRoot: orderPackageReducer,
	bookPackageDetailRoot: bookingDetailPackageReducer,
	bookingPackagesRoot: bookingPackagesReducer,

	// Dashboard side
	creationEventRoot: creationEventReducer,
	editEventRoot: editEventReducer,
	eventsRoot: eventsReducer,

	creationMembershipRoot: creationMembershipReducer,
	editMembershipRoot: editMembershipReducer,
	membershipsRoot: membershipsReducer,

	creationPackageRoot: creationPackageReducer,
	editPackageRoot: editPackageReducer,
	packagesRoot: packagesReducer,

	ordersRoot: ordersReducer,

	utilRoot: utilReducer,

	customersRoot: customersReducer,
	creationCustomerRoot: creationCustomerReducer,
	editCustomerRoot: editCustomerReducer,
	
	calendarRoot: calendarReducer,
	ordersFilterRoot: orderFilterReducer,

	// Facilities
	facilityPromoRoot: facilityPromoReducer,
	facilityAdditionalServicesRoot: facilityAdditionalServicesReducer
});


const rootAppReducer = (state, action) => {
	if (action.type === DESTROY_SESSION){
		state = undefined;
	}
	return appReducers(state, action);
};

export default rootAppReducer;
