import {
	START_CREATION_PACKAGE,
	FINISHED_CREATION_PACKAGE,
	FAILED_CREATION_PACKAGE,
	CLEAR_PACKAGE_ERRORS
} from "actions/consts";
import apiUrls from "routes/api";
import { history } from "store";
import { request } from "actions/utils";
import frontendUrls from "routes/frontend_routes";


// Processing register
const startCreationPackage = () => {
	return { type: START_CREATION_PACKAGE };
};

const finishCreationPackage = (data) => {
	return {
		type: FINISHED_CREATION_PACKAGE,
		data: data,
		receivedAt: Date.now()
	};
};

const failedCreationPackage = (data) => {
	return {
		type: FAILED_CREATION_PACKAGE,
		errors: data,
		receivedAt: Date.now()
	};
};


export const fetchCreationPackage = (sendData) => {
	return (dispatch, getState) => {
		dispatch(startCreationPackage());
		const { root: { authToken }} = getState();
		const config = {
			isAuth: authToken,
			method: "POST"
		};

		const response = request(apiUrls.createPackage, config, sendData);
		return response
			.then((json) => {
				dispatch(finishCreationPackage(json));
				history.push(frontendUrls.urlDashboardPackages);
			})
			.catch((error) => {
				error.response.json()
					.then((json) => {
						dispatch(failedCreationPackage(json));
					});
			});
	};
};


export const clearPackageErrors = () => {
	return {type: CLEAR_PACKAGE_ERRORS};
};