import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import { history } from "store";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Row, Container, Button } from "reactstrap";
import { BsCalendar, BsTagFill } from "react-icons/bs";
import { AiOutlineShoppingCart } from "react-icons/ai";

import { getCategoryNameForPackage, getCountDays, getDateFromString, isEmpty } from "components/utils";
import ModalComponent from "components/utils/modal";
import { buyBookingPackage } from "actions/booking/packages/packageBuy";
import { setToZeroCreatedOrder } from "actions/util_actions";
import { packageLimits } from "backend_constants/packageTimeLimit";
import { categoryLimits } from "backend_constants/categories";
import frontendUrls from "routes/frontend_routes";
import apiUrls from "routes/api";


const PackageTimeLimit = ({ selectedPackage }) => {
	const {time_limits, hard_expiry_limit, soft_expiry_limit} = selectedPackage;

	switch(time_limits){
	case packageLimits.hardExpiry:
		return (
			<div className="pkg_details_time_limits">
				<p className="font_16_medium_2c2c2d_list">Valid to {getDateFromString(hard_expiry_limit)}</p>
			</div>
		);
	case packageLimits.softExpiry:
		return(
			<div className="pkg_details_time_limits">
				<p className="font_16_medium_2c2c2d_list">Valid for {getCountDays(soft_expiry_limit)} days</p>
			</div>
		);
	default:
		return(
			<p className="font_16_medium_2c2c2d_list">Time unlimited</p>
		);
	}
};


const PackageCategories = ({selectedPackage}) => {
	const {categories, category_limits} = selectedPackage;

	return(
		<p className="font_16_medium_2c2c2d_list">
				Category:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
			{category_limits === categoryLimits.unlimited && <span className="font_14_regular_2c2c2d_list">
				Category unlimited
			</span>}
			{category_limits === categoryLimits.limited && <span className="font_14_regular_2c2c2d_list">
				{getCategoryNameForPackage(categories)}
			</span>}
		</p>
	);
};


const PackagePrice = ({item: {id, price}, isBuyPage, errors, dispatch}) => {

	const [show, showModal] = useState(false);

	useEffect(() => {
		if(!isEmpty(errors)){
			showModal(true);
		}
	}, [errors]);

	const buyPackage = () => {
		const buyPackageApiUrl = apiUrls.orderPackagesUrl.replace(":id", id);
		dispatch(buyBookingPackage(buyPackageApiUrl));
		showModal(true);
	};

	const handleClose = () => {
		dispatch(setToZeroCreatedOrder());
		if(isEmpty(errors)){
			history.push(frontendUrls.urlMyOrder);
		}
		showModal(false);
	};


	return(
		<>
			<Row className="pkg_details_price">
				<Col xs="1" className="text-md-right mt-1">
					<BsTagFill className="gliph_grey gliph_15px"/>
				</Col>
				<Col xs="6" md="8" lg="9" className="mt-1">
					<p className="font_16_medium_2c2c2d_list">
						Price:&nbsp;
						<span className="font_14_regular_2c2c2d_list">
							{price > 0 ? <span>&pound;{price}</span>: "Free"}
						</span>
					</p>
				</Col>
				{
					isBuyPage &&
					<Col xs="4" md="2" lg="1">
						{
							price > 0 ?
								<Link to={frontendUrls.urlBookingPackagesOrder.replace(":id", id)}
									className="btn pkg_btn font_16_regular_white_list m-0">
									Buy
								</Link>:
								<Button onClick={buyPackage} className="btn pkg_btn font_16_regular_white_list m-0">Buy</Button>
						}
					</Col>
				}
			</Row>
			<ModalComponent isOpen={show}
				header="Buy Package"
				body={
					isEmpty(errors)?
						<p>You have bought package successfully</p>:
						<p>{errors.message || errors.detail}</p>

				}>
				<Button className="btn close_btn" onClick={handleClose}>Close</Button>
			</ModalComponent>
		</>
	);
};


const mapStateToProps = ({orderPackageRoot: {errors, createdOrder}}) => {
	return {errors, createdOrder};
};


const ConnectedPackagePrice = connect(mapStateToProps)(PackagePrice);


export const PackageUsageLimit = ({item, isBuyPage, isPayPage, isMyOrdersPage, usageLeft, columnOffset = 2}) => {

	return(
		<>
			{
				isMyOrdersPage ?
					<Row className="pkg_details">
						<Col xs={{size: 10, offset: columnOffset}}>
							<p className="font_16_medium_2c2c2d_list">Sessions left:&nbsp;&nbsp;
								<span className="font_16_regular_2c2c2d_list">
									{
										item.usage_limits === packageLimits.unlimited ? "unlimited" :
											usageLeft > 0 ? usageLeft : "all used"
									}
								</span>
							</p>
						</Col>
					</Row> :
					<Row className="pkg_details">
						<Col xs={isBuyPage || isPayPage? 1: columnOffset} className="text-md-right">
							<AiOutlineShoppingCart className="gliph_grey gliph_18px"/>
						</Col>
						<Col xs={10}>
							<p className="font_16_medium_2c2c2d_list">Sessions:&nbsp;&nbsp;
								<span className="font_16_regular_2c2c2d_list">
									{item.usage_limits === packageLimits.unlimited ? "unlimited" : item.usage_limit}
								</span>
							</p>
						</Col>
					</Row>
			}
		</>
	);
};


export const Package = ({item, isBuyPage, isPayPage = false, isMyOrdersPage = false, usageLeft = 0, columnOffset}) => {
	return(
		<>
			<Row>
				<Col xs="1" lg="1" className="text-md-right">
					<BsCalendar className="gliph_grey gliph_15px"/>
				</Col>
				<Col xs="10" lg="10">
					<PackageTimeLimit selectedPackage={item}/>
				</Col>
			</Row>
			<PackageUsageLimit item={item}
				isBuyPage={isBuyPage}
				isPayPage={isPayPage}
				isMyOrdersPage={isMyOrdersPage}
				usageLeft={usageLeft}
				columnOffset={columnOffset}/>
			<Row className="pkg_details_categories" >
				<Col xs={{size:10, offset:1}}>
					<PackageCategories selectedPackage={item}/>
				</Col>
			</Row>
			<Row className="pkg_details_desc">
				<Col xs={{size:10, offset:1}}>
					<p className="font_16_medium_2c2c2d_list">
						Description:&nbsp;
						<span className="font_14_regular_2c2c2d_list">{item.description}</span>
					</p>
				</Col>
			</Row>
			{
				isBuyPage &&
				<Row className="pkg_details_desc">
					<Col xs={{size:10, offset:1}}>
						<p className="font_16_medium_2c2c2d_list">
							Cancellation policy:&nbsp;
							<span className="font_14_regular_2c2c2d_list">{item.cancellation_policy}</span>
						</p>
					</Col>
				</Row>
			}
			<ConnectedPackagePrice item={item} isBuyPage={isBuyPage}/>
		</>
	);
};


const BookingDetailComponent = ({ selectedBookingPackage, isBuyPage, isMyOrdersPage, usageLeft, columnOffset}) => {

	const { title } = selectedBookingPackage;

	return (
		<Container className="margin_top_30px">
			<Row>
				<Col xs="12">
					<p className="font_18_medium_2c2c2d_list">{title}</p>
				</Col>
			</Row>
			<div className="package_details shadow_form">
				<Package item={selectedBookingPackage}
					isBuyPage={isBuyPage}
					isMyOrdersPage={isMyOrdersPage}
					usageLeft={usageLeft}
					columnOffset={columnOffset}/>
			</div>
		</Container>
	);
};

BookingDetailComponent.propTypes = {
	selectedBookingPackage: PropTypes.object.isRequired,
	isBuyPage: PropTypes.bool.isRequired,
	isMyOrdersPage: PropTypes.bool,
	usageLeft: PropTypes.number,
	columnOffset: PropTypes.number,
};

PackageTimeLimit.propTypes = {
	selectedPackage: PropTypes.object.isRequired
};

PackageCategories.propTypes = {
	selectedPackage: PropTypes.object.isRequired
};

PackageUsageLimit.propTypes = {
	item: PropTypes.object.isRequired,
	isBuyPage: PropTypes.bool,
	isPayPage: PropTypes.bool,
	isMyOrdersPage: PropTypes.bool,
	usageLeft: PropTypes.number,
	columnOffset: PropTypes.number
};

PackagePrice.propTypes = {
	item: PropTypes.object.isRequired,
	isBuyPage: PropTypes.bool.isRequired,
	errors: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired
};

Package.propTypes = {
	item: PropTypes.object.isRequired,
	isBuyPage: PropTypes.bool.isRequired,
	isPayPage: PropTypes.bool,
	isMyOrdersPage: PropTypes.bool,
	usageLeft: PropTypes.number,
	columnOffset: PropTypes.number
};

export default BookingDetailComponent;
