import {
	START_ACTIVATE_ACCOUNT,
	FINISHED_ACTIVATE_ACCOUNT,
	FAILED_ACTIVATE_ACCOUNT
} from "actions/consts";


const initialState = {
	errors: {},
	isFetching: false
};


export default function activateAccountReducer(state = initialState, action){
	switch(action.type){
	case START_ACTIVATE_ACCOUNT:
		return {
			...state,
			isFetching: true
		};

	case FINISHED_ACTIVATE_ACCOUNT:
		return{
			...state,
			isFetching: false,
			errors: {}
		};
	
	case FAILED_ACTIVATE_ACCOUNT:
		return{
			...state,
			isFetching: false,
			errors: action.error
		};
	default:
		return state;
	}
}