import {
	START_GET_ORDERS,
	FINISHED_GET_ORDERS,
	FINISHED_GET_EXPIRED_ORDERS,
	FINISHED_GET_ACTIVE_EVENTS_ORDERS,
	FINISHED_GET_ACTIVE_MEMBERSHIP_ORDERS,
	FINISHED_GET_ACTIVE_PACKAGES_ORDERS,
	FINISHED_GET_EXPIRED_EVENTS_ORDERS,
	FINISHED_GET_EXPIRED_MEMBERSHIP_ORDERS,
	FINISHED_GET_EXPIRED_PACKAGES_ORDERS
} from "actions/orders/consts";
import { formatEventForCalendar } from "reducers/calendar/get_events";


const configEventsForCalendar = (orders) => {
	return orders.map((order, idx) => formatEventForCalendar(order.product, idx));
};


const initialState = {
	isFetching: false,
	isFirstPurchase: true,
	orders: {
		packages: [],
		memberships: [],
		events: []
	},
	expired: {
		packages: [],
		memberships: [],
		events: []
	},
	calendar: {
		events: [],
		defaultDate: new Date()
	}
};

export default function ordersReducer(state = initialState, action) {
	switch (action.type) {
	// REDUCER ORDERS

	case START_GET_ORDERS:
		return {
			...state,
			isFetching: true
		};

	case FINISHED_GET_ORDERS:
		return {
			...state,
			isFetching: false,
			isFirstPurchase: action.orders.length == 0,
			lastUpdated: action.receivedAt
		};

	case FINISHED_GET_EXPIRED_ORDERS:
		return {
			...state,
			isFetching: false,
			expired: action.orders,
			lastUpdated: action.receivedAt
		};

	case FINISHED_GET_ACTIVE_EVENTS_ORDERS:{
		const calendarEvents = configEventsForCalendar(action.orders);
		return {
			...state,
			isFetching: false,
			orders:{
				...state.orders,
				events: action.orders
			},
			calendar: {
				events: calendarEvents,
				defaultDate: calendarEvents[0] ? calendarEvents[0].start : new Date()
			}
		};
	}
	case FINISHED_GET_ACTIVE_MEMBERSHIP_ORDERS:
		return {
			...state,
			isFetching: false,
			orders:{
				...state.orders,
				memberships: action.orders
			}
		};

	case FINISHED_GET_ACTIVE_PACKAGES_ORDERS:
		return {
			...state,
			isFetching: false,
			orders:{
				...state.orders,
				packages: action.orders
			}
		};

	case FINISHED_GET_EXPIRED_EVENTS_ORDERS:
		return {
			...state,
			isFetching: false,
			expired:{
				...state.expired,
				events: action.orders
			}
		};

	case FINISHED_GET_EXPIRED_MEMBERSHIP_ORDERS:
		return {
			...state,
			isFetching: false,
			expired:{
				...state.expired,
				memberships: action.orders
			}
		};

	case FINISHED_GET_EXPIRED_PACKAGES_ORDERS:
		return {
			...state,
			isFetching: false,
			expired:{
				...state.expired,
				packages: action.orders
			}
		};

	default:
		return state;
	}
}
