const frontendUrls = {
	// ROOT
	urlRoot: "/",
	// Authentication APP
	urlLogin: "/login/",
	// Registration APP
	urlRegister: "/registration/",
	// Account activation
	urlActivateAccount: "/activate/:token/:uid/",
	// Reset password urls
	urlResetPassword: "/reset-password/",
	urlConfirmResetPassword: "/reset-password/:token/:uid/",
	// Packages
	urlPackages: "/packages/",
	// Memberships
	urlMemberships: "/memberships/",
	// Events
	urlEvents: "/events/",
	// My order
	urlMyOrder: "/my-orders/",
	// Dashboard
	urlDashboard: "/dashboard/",
	urlDashboardCalendar: "/dashboard/calendar/",
	urlDashboardEvents: "/dashboard/events/",
	urlDashboardCreateEvent: "/dashboard/events/create/",
	urlDashboardEventEdit: "/dashboard/events/:id/edit/",
	urlDashboardMemberships: "/dashboard/memberships/",
	urlDashboardCreateMemberships: "/dashboard/memberships/create/",
	urlDashboardMembershipsEdit: "/dashboard/memberships/:id/edit",
	urlDashboardPackages: "/dashboard/packages/",
	urlDashboardCreatePackage: "/dashboard/packages/create/",
	urlDashboardPackageEdit: "/dashboard/packages/:id/edit/",
	urlDashboardCustomers: "/dashboard/customers/",
	urlDashboardCreateCustomer: "/dashboard/customers/create",
	urlDashboardCustomerEdit: "/dashboard/customers/:id/edit/",
	// Booking
	urlBookingEvents: "/events/",
	urlBookingEventId: "/events/:id/",
	urlBookingOrderEvent: "/events/:id/order/",
	urlBookingMemberships: "/memberships/",
	urlBookingMembershipId: "/memberships/:id/",
	urlBookingOrderMembership: "/memberships/:id/order/",
	urlBookingPackages: "/packages/",
	urlBookingPackagesId: "/packages/:id/",
	urlBookingPackagesOrder: "/packages/:id/order/",
	urlBookingFaq: "/faq/",
	urlBookingMyOrders: "/my-orders/",
	// Breadcrumb
	urlBreadcrumb: "/",
	urlBreadcrumbEventId: "/events/:id/",
	urlBreadcrumbOrderEvent: "/orders/event/:id/",
	urlBreadcrumbMemberships: "/memberships/",
	urlBreadcrumbMembershipId: "/membership/:id/",
	urlBreadcrumbOrderMembership: "/orders/memberships/:id/",
	urlBreadcrumbPackages: "/packages/",
	urlBreadcrumbPackagesId: "/packages/:id/",
	urlBreadcrumbPackagesOrder: "/orders/packages/:id/",
	urlBreadcrumbMyOrders: "/my-orders/",
	// Facilities
	urlDashboardAdditionalServices: "/dashboard/settings/additional-services/",
	urlDashboardBanner: "/dashboard/settings/banner/"
};

export default frontendUrls;
