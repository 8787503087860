import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Button } from "reactstrap";
import RegisterComponent from "components/auth/register";
import ModalComponent from "components/utils/modal";

import { fetchRegister } from "actions/auth/register";
import frontendUrls from "routes/frontend_routes";
import { history } from "store";
import { isEmpty } from "components/utils";


class Register extends React.Component {

	state = {
		show: false,
	};

	onSubmit = (values) => {
		const { dispatch } = this.props;
		this.setState({show: true});
		dispatch(fetchRegister(values.firstName, values.lastName, values.email, values.password, values.birthday));
	}

	handleClose = () => {
		this.setState({show: false});
		history.push(frontendUrls.urlLogin);
	};

	render() {
		const { errors } = this.props;
		const { show } = this.state;
		let sendProps = {
			onSubmit: this.onSubmit,
			errors
		};

		return (
			<>
				<RegisterComponent {...sendProps} />
				<ModalComponent isOpen={show && isEmpty(errors)}
					header="Thank you for your registration!"
					body="We send an activate account link to your email. Please, confirm your registration.">
					<Button className="btn close_btn" onClick={this.handleClose}>Close</Button>
				</ModalComponent>
			</>
		);
	}
}

Register.propTypes = {
	dispatch: PropTypes.func.isRequired,
	errors: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
	return {
		errors: state.registerRoot.errors || {}
	};
};

const mapDispatchToProps = (dispatch) => {
	return { dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
