import React, {Component} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
	EditCustomerComponent,
	ManageCustomerOrdersComponent
} from "components/customers/edit_customer.js";
import { Spinner } from "components/utils/spinner";

import {
	fetchSelectedCustomer,
	fetchEditCustomer,
	fetchDeleteCustomer,
	fetchCustomerOrders,
	fetchDeleteCustomerOrder
} from "actions/customers/edit_customer";
import { resetEditErrors } from "actions/util_actions";


class EditCustomerContainer extends Component{

	state = {
		orderToDelete: null
	}

	componentDidMount(){
		const { dispatch, match } = this.props;
		const customerId = match.params.id;
		dispatch(fetchSelectedCustomer(customerId));
		dispatch(fetchCustomerOrders(customerId));
	}

	componentDidUpdate(prevProps, prevState){
		const { dispatch, match } = this.props;
		if(prevState.orderToDelete !== this.state.orderToDelete &&
			this.state.orderToDelete === null){
			dispatch(fetchCustomerOrders(match.params.id));
		}
	}

	onCustomerEdit = (values) => {
		const { dispatch, match } = this.props;
		dispatch(fetchEditCustomer(match.params.id, values));
	}

	onCustomerDelete = () => {
		const { dispatch, match } = this.props;
		dispatch(fetchDeleteCustomer(match.params.id));
	}

	cancellOrder = (order) => {
		const { dispatch, match } = this.props;
		dispatch(fetchDeleteCustomerOrder(match.params.id, order.id));
	}

	handleOrder = (order) => {
		this.setState({orderToDelete: order});
	}

	render(){
		const { selectedCustomer, errors, isFetching, orders, dispatch } = this.props;
		const { orderToDelete } = this.state;

		const editProps = {
			onCustomerEdit: this.onCustomerEdit,
			onCustomerDelete: this.onCustomerDelete,
			initialValues: selectedCustomer,
			isFetching,
			errors,
		};

		const ordersProps = {
			orders,
			dispatch,
			cancellOrder: this.cancellOrder,
			handleOrder: this.handleOrder,
			error: errors.detail,
			orderToDelete
		};

		return (
			isFetching && orders.isFetching ? <Spinner/>:
				<EditCustomerComponent {...editProps}>
					<ManageCustomerOrdersComponent {...ordersProps}/>
				</EditCustomerComponent>
		);
	}
}


EditCustomerContainer.propTypes = {
	dispatch: PropTypes.func.isRequired,
	isFetching: PropTypes.bool.isRequired,
	errors: PropTypes.object.isRequired,
	selectedCustomer: PropTypes.object.isRequired,
	match: PropTypes.object,
	orders: PropTypes.object
};


const mapStateToProps = ({ editCustomerRoot }) => {
	return {
		isFetching: editCustomerRoot.isFetching || false,
		errors: editCustomerRoot.errors || {},
		selectedCustomer: editCustomerRoot.selectedCustomer || {},
		orders: editCustomerRoot.orders
	};
};


export default connect(mapStateToProps)(EditCustomerContainer);