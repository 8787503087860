import {
	START_GET_BOOKING_PACKAGE,
	FINISH_GET_BOOKING_PACKAGE,
	FAIL_GET_BOOKING_PACKAGE
} from "actions/consts";
import { request } from "actions/utils";


// Processing fetching package
const startFetchingBookingPackage = () => {
	return { type: START_GET_BOOKING_PACKAGE };
};

const finishFetchingBookingPackage = (data) => {
	return {
		type: FINISH_GET_BOOKING_PACKAGE,
		data: data,
		receivedAt: Date.now()
	};
};

const failedFetchingBookingPackage = (data) => {
	return {
		type: FAIL_GET_BOOKING_PACKAGE,
		errors: data,
		receivedAt: Date.now()
	};
};

export const fetchSelectedBookingPackage = (apiUrlSelectedPackage) => {
	return (dispatch, getState) => {
		dispatch(startFetchingBookingPackage);
		const { root: { authToken }} = getState();
		const config = {
			method: "GET",
			isAuth: authToken
		};

		const response = request(apiUrlSelectedPackage, config);
		return response
			.then((json) => {
				dispatch(finishFetchingBookingPackage(json));
			})
			.catch((error) => {
				error.response.json()
					.then((json) => {
						dispatch(failedFetchingBookingPackage(json));
					});
			});
	};
};