import React, { Component } from "react";
import PropTypes from "prop-types";
import {
	injectStripe,
	CardNumberElement,
	CardExpiryElement,
	CardCVCElement
} from "react-stripe-elements";
import { Label, FormGroup, Row, Col, Input } from "reactstrap";
import { SmallSpinner, Spinner } from "components/utils/spinner";


const createOptions = (fontSize) => {
	return {
		style: {
			base: {
				fontSize,
				color: "#424770",
				letterSpacing: "0.025em",
				width: "100%",
				fontFamily: "Source Code Pro, Menlo, monospace",
				"::placeholder": {
					color: "#aab7c4",
				},
			},
			invalid: {
				color: "#9e2146",
			},
		},
	};
};


class OrderCard extends Component{
	state = {
		pay: false,
		agree: false,
		comleteForm: false
	}

	componentDidMount(){
		this.setState({completeForm: this.props.isFirstPurchase});
	}

	componentDidUpdate(prevProps){
		const { token, errors:{ message }, isFirstPurchase} = this.props;
		if(token !== prevProps.token || message !== prevProps.errors.message){
			this.setState({pay: false});
		}
		if(isFirstPurchase !== prevProps.isFirstPurchase){
			this.setState({completeForm: isFirstPurchase});
		}
	}

	submitPay = async () => {
		const { dispatch, order, stripe } = this.props;
		this.setState({pay: true});

		const {error, token} = await stripe.createToken();
		if(error){
			dispatch(order.fail(error));
		} else {
			dispatch(order.action(order.url, token.id));
		}
	}

	agreeWithTerms = () => {
		const { agree } = this.state;
		this.setState({agree: !agree});
	}

	hasCompletedForm = () => {
		const { completeForm } = this.state;
		this.setState({completeForm: !completeForm});
	}

	render(){
		const { pay, agree, completeForm } = this.state;
		const { item, loading, isFirstPurchase } = this.props;

		return (
			<div className="package_order shadow_form mb-5">
				<Row>
					<Col xs={12} lg={6}>
						{
							loading ?
								<Spinner/>:
								<>
									<p className="font_18_medium_2c2c2d_list">
										{item.title ? item.title: item.parent.title}
									</p>
									{this.props.children}
								</>
						}
					</Col>
					<Col xs={12} lg={6}>
						<form>
							<FormGroup>
								<Label className="font_14_regular_757578_list">Card number</Label>
								<CardNumberElement {...createOptions("18px")} />
							</FormGroup>
							<FormGroup>
								<Label className="font_14_regular_757578_list">Expiration date</Label>
								<CardExpiryElement {...createOptions("18px")}/>
							</FormGroup>
							<FormGroup>
								<Label className="font_14_regular_757578_list">CVC</Label>
								<CardCVCElement {...createOptions("18px")}/>
							</FormGroup>
						</form>
					</Col>
				</Row>
				<Row className="border border_row_btn d-flex justify-content-end">
					<Col xs={12} sm={8} lg={10} className="d-flex flex-column">
						<Label className="pl-4 pr-3 mb-2 mt-3 font_16_medium_2c2c2d_list">
							<Input type="checkbox" onClick={this.agreeWithTerms}/>
							I have agreed to the <a href="https://hopepilatescentre.com/tc/" target="_blank" rel="noreferrer">terms and conditions.</a>
						</Label>
						{
							!isFirstPurchase &&
							<Label className="pl-4 pr-3 mb-3 font_16_medium_2c2c2d_list">
								<Input type="checkbox" onClick={this.hasCompletedForm}/>
								I have completed the <a href="https://forms.gle/nTqp7rkcDJiW7cBu8" target="_blank" rel="noreferrer">Health Questionnaire (PARQ) form.</a>
							</Label>
						}
					</Col>
					<Col xs={12} sm={4} lg={2} className="d-flex justify-content-center align-items-center">
						<button className="mb-3 mt-3 btn pkg_btn font_16_regular_white"
							onClick={this.submitPay}
							disabled={pay ? pay: !(agree && completeForm)}>
							{ pay ? <SmallSpinner/>: "Pay" }
						</button>
					</Col>
				</Row>
			</div>
		);
	}
}


OrderCard.propTypes = {
	item: PropTypes.object.isRequired,
	order: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	token: PropTypes.string.isRequired,
	children: PropTypes.element.isRequired,
	loading: PropTypes.bool.isRequired,
	errors: PropTypes.object.isRequired,
	stripe: PropTypes.object.isRequired,
	isFirstPurchase: PropTypes.bool.isRequired
};

export default injectStripe(OrderCard);