import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import { BsChevronRight } from "react-icons/bs";

import { getCategoryName, getDate, getTime } from "components/utils";
import frontendUrls from "routes/frontend_routes";


const EventRow = ({event}) => {
	return(
		<Row className="event">
			<Col xs={2} className="event_category font_16_regular_white_list d-none d-sm-none d-md-flex order-md-1">
				{getCategoryName(event.parent.category)}
			</Col>
			<Col xs={3} md={2} className="event_title font_16_medium_black_list order-sm-1 order-md-1 order-lg-2">{event.parent.title}</Col>
			<Col xs={7} md={4} lg={5} className="event_time d-md-flex order-sm-2 order-md-2 order-lg-3">
				<Container>
					<Row>
						<Col xs={12} lg={6} className="text-center text-lg-right font_16_regular_00c3b3_list">
							{getDate(event.start_datetime)}
						</Col>
						<Col xs={12} lg={6} className="text-center text-lg-left font_16_regular_00c3b3_list">
							{getTime(event.start_datetime, event.end_datetime)}
						</Col>
					</Row>
				</Container>
			</Col>
			<Col xs={3} md={4} lg={3} className="event_details d-none d-sm-none d-md-flex order-md-4">
				<Container>
					<Row>
						<Col className="event_list_price font_16_medium_black_list">
							{ event.parent.price === "0.00" ? "Free": <>&pound;{event.parent.price}</> }
						</Col>
						<Col className="event_buy">
							<Link to={frontendUrls.urlBookingEventId.replace(":id", event.id)}
								className={`btn event_btn ${event.capacity_left ? event.available_for_checkin ? "checkin" : "" : "disabled"} font_16_regular_white_list`}>
								{event.capacity_left ? event.available_for_checkin ? "Checkin" : "Buy" : "FULL"}
							</Link>
						</Col>
					</Row>
				</Container>
			</Col>

			<Col xs={2} className="event_details d-flex d-sm-flex d-md-none order-3 order-sm-3 order-5">
				<Link to={frontendUrls.urlBookingEventId.replace(":id", event.id)}>
					<BsChevronRight className="gliph_15px"/>
				</Link>
			</Col>
		</Row>
	);
};


const EventsTable = ({events}) => {
	return(
		<Container className="shadow_form mt-2 bg_white">
			<Row className="events d-none d-sm-none d-md-flex">
				<Col xs={2} className="events_category font_13_medium_white_list d-none d-sm-none d-md-flex">Category</Col>
				<Col xs={2} className="events_title font_13_medium_a0a2a7_list d-none d-sm-none d-md-flex">Title</Col>
				<Col xs={5} className="events_time font_13_medium_a0a2a7_list d-none d-sm-none d-md-flex">Time</Col>
				<Col xs={3} className="events_details d-none d-sm-none d-md-flex">
					<Container>
						<Row>
							<Col xs={6} className="events_price font_13_medium_a0a2a7_list">Price</Col>
							<Col xs={6} className="events_buy"></Col>
						</Row>
					</Container>
				</Col>
			</Row>
			<div className="events_list">
				{
					events.map((event) => <EventRow key={event.id} event={event}/>)
				}
			</div>
		</Container>
	);
};


const BookingEventsComponent = ({ bookingEvents, next, handleGetNextPage }) => {

	return (
		<Container className="mt-5 mb-5">
			<p className="font_24_regular_black_list">Events</p>
			<EventsTable events={bookingEvents}/>
			{next &&
				<Row className="d-flex justify-content-center mt-4 mb-5">
					<Button className="btn_load_more font_16_regular_white_list" onClick={handleGetNextPage}>
						Load more
					</Button>
				</Row>
			}
		</Container>
	);
};

BookingEventsComponent.propTypes = {
	bookingEvents: PropTypes.array.isRequired,
	handleGetNextPage: PropTypes.func.isRequired,
	next: PropTypes.bool.isRequired,
	isFetching: PropTypes.bool.isRequired,
};

EventsTable.propTypes = {
	events: PropTypes.array.isRequired,
};

EventRow.propTypes = {
	event: PropTypes.object.isRequired,
};

export default BookingEventsComponent;
