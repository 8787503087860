import {
	GET_MEMBERSHIPS,
	RECEIVE_MEMBERSHIPS
} from "../../actions/consts";


const initialState = {
	membershipsRoot: {
		isFetching: false,
		memberships: []
	}
};

export default function membershipsReducer(state = initialState, action) {
	switch (action.type) {
	// REDUCER MEMBERSHIPS

	case GET_MEMBERSHIPS:
		return {
			...state,
			isFetching: true
		};

	case RECEIVE_MEMBERSHIPS:
		return {
			...state,
			isFetching: false,
			memberships: action.memberships,
			lastUpdated: action.receivedAt
		};

	default:
		return state;
	}
}
