import {
	GET_CUSTOMERS,
	RECEIVE_CUSTOMERS
} from "actions/consts";
import apiUrls from "routes/api";
import { request } from "actions/utils";


const getCustomers = () => {
	return { type: GET_CUSTOMERS };
};

const receiveCustomers = (data) => {
	return{
		type: RECEIVE_CUSTOMERS,
		customers: data.results,
		receiveAt: Date.now()
	};
};

const fetchCustomers = (authToken, params = null) => {
	return (dispatch) => {
		dispatch(getCustomers());
		const config = {
			isAuth: authToken,
			params
		};

		const response = request(apiUrls.customersUrl, config)
			.catch((error) => console.log("An error occured.", error))
			.then((json) => dispatch(receiveCustomers(json)));
		return response;
	};
};

export default fetchCustomers;