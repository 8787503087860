import {
	FINISHED_LOGIN,
	FINISHED_REGISTER,
	FAILED_LOGIN,
	FAILED_REGISTER,
	DO_LOGOUT,
	USER_ROLE_VALIDATION_FAILED,
	USER_ROLE_VALIDATION_SUCCESS
} from "actions/consts";


const initialState = {
	root: {
		authToken: "",
		first_name: "",
		last_name: "",
		role: "",
		lastUpdated: "",
		asGuest: true
	}
};

export default function rootReducer(state = initialState, action) {
	switch (action.type) {
	// REDUCER ROOT
	case FINISHED_LOGIN:
		return {
			...state,
			authToken: action.authToken,
			first_name: action.first_name,
			last_name: action.last_name,
			role: action.role,
			asGuest: action.asGuest,
			isFirstOwnerLogin: action.isFirstOwnerLogin,
			Updated: action.receivedAt
		};

	case FINISHED_REGISTER:
		return {
			...state,
			firstName: action.data.first_name,
			lastName: action.data.last_name,
			lastUpdated: action.receivedAt
		};

	case FAILED_LOGIN:
		return {
			...state,
			authToken: "",
			first_name: "",
			last_name: "",
			role: "",
			asGuest: true,
			lastUpdated: action.receivedAt
		};

	case FAILED_REGISTER:
		return {
			...state,
			authToken: "",
			lastUpdated: action.receivedAt
		};

	case DO_LOGOUT:
		return {
			...state,
			authToken: "",
			role: "",
			first_name: "",
			last_name: "",
			asGuest: true,
			lastUpdated: action.receivedAt
		};

	case USER_ROLE_VALIDATION_SUCCESS:
		return {
			...state,
			asGuest: false
		};

	case USER_ROLE_VALIDATION_FAILED:
		return {
			...state,
			asGuest: true
		};

	default:
		return state;
	}
}
