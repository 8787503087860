import {
	GET_MEMBERSHIPS,
	RECEIVE_MEMBERSHIPS
} from "actions/consts";
import apiUrls from "routes/api";
import { request } from "actions/utils";


// Processing getting memberships list
const getMemberships = () => {
	return { type: GET_MEMBERSHIPS, memberships: [] };
};

const receiveMemberships = (data) => {
	return {
		type: RECEIVE_MEMBERSHIPS,
		memberships: data,
		receivedAt: Date.now()
	};
};

export const fetchMemberships = () => {
	return (dispatch) => {
		dispatch(getMemberships());
		const config = {
			method: "GET",
			credentials: "same-origin",
			params: {
				deleted: false
			}
		}
		const response = request(apiUrls.membershipsUrl, config);
		return response.then(json => dispatch(receiveMemberships(json)));
	};
};
