import React from "react";
import PropTypes from "prop-types";
import { Container } from "reactstrap";

import { LIMITED } from "backend_constants/limitChoice";
import MembershipForm from "components/forms/memberships/membership_form";
import { withCategories } from "components/utils/hoc";


const EditMembershipComponent = ({onSubmit, onDelete, errors, initialValues, categories}) => {

	const sendValues = {
		title: initialValues.title,
		description: initialValues.description,
		usageLimitsChoice: initialValues.usage_limits,
		usageLimit: initialValues.usage_limit,
		categoryLimitsChoice: initialValues.category_limits,
		price: initialValues.price,
		cancellation_policy: initialValues.cancellation_policy,
	};

	if (initialValues.category_limits === LIMITED) {
		let categoryLimitArray = categories;
		let categoryLimit = initialValues.categories;
		categoryLimitArray.map(function (categoryArray) {
			if (categoryLimit.indexOf(categoryArray[0]) !== -1) {
				sendValues[categoryArray[1]] = true;
			}
		});
	}

	return (
		<Container>
			<p className="margin_top_25px margin_bottom_35px font_24_regular_black_list">Edit membership</p>
			<MembershipForm onSubmit={onSubmit} onDelete={onDelete} errors={errors} initialValues={sendValues} edit={true} />
		</Container>
	);
};

EditMembershipComponent.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	errors: PropTypes.object,
	initialValues: PropTypes.object,
	categories: PropTypes.array.isRequired
};

export default withCategories(EditMembershipComponent);
