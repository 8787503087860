import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { Row, Col } from "reactstrap";
import { BsTagFill, BsClock, BsCalendar } from "react-icons/bs";

import { getDateRange, getCategoryName, getTime } from "components/utils";
import frontendUrls from "routes/frontend_routes";


const EventItem = ({item, buy, open}) => {
	const { 
		parent : {from_date, until_date, category, title, price}, deleted,
		id, start_datetime, end_datetime, deleted_start_datetime, deleted_end_datetime 
	} = item;

	return(
		<div className="event_item_wrap shadow_form">
			<div className="event_item">
				<Row className="evt_title">
					<p className="font_18_medium_2c2c2d_list mb-0">
						{title}
					</p>
				</Row>

				<Row className="evt_details">
					<Col xs="2">
						<BsCalendar className="gliph_grey gliph_15px" />
					</Col>
					<Col xs="10">
						<p className="font_16_medium_2c2c2d_list">
							{getDateRange(from_date, until_date)}
						</p>
					</Col>
				</Row>

				<Row className="evt_details">
					<Col xs="2">
						<BsClock className="gliph_grey gliph_15px"/>
					</Col>
					<Col xs="10">
						<p className="font_16_regular_2c2c2d_list">
							{getTime(start_datetime || deleted_start_datetime, end_datetime || deleted_end_datetime)}
						</p>
					</Col>
				</Row>

				<Row className="evt_category">
					<Col xs={{size: 10, offset: 2}}>
						<p className="font_14_medium_2c2c2d_list">
							Category:&nbsp;&nbsp;&nbsp;&nbsp;
							<span className="font_14_regular_2c2c2d_list">{getCategoryName(category)}</span>
						</p>
					</Col>
				</Row>
			</div>

			<Row className="evt_control bg_row_btn border border_row_btn">
				<Col xs="2" className="margin_top_12px">
					<BsTagFill className="gliph_grey" />
				</Col>
				<Col xs="6" className="margin_top_12px">
					<p className=" display_inline_block font_14_medium_2c2c2d_list">
						Price:&nbsp;
						<span className="font_14_regular_2c2c2d_list"> &pound;{price}</span>
					</p>
				</Col>
				<Col xs="4">
					{
						buy &&
						<Link to={frontendUrls.urlBookingEventId.replace(":id", id)}
							className="btn evt_btn font_16_regular_white_list">
							Buy
						</Link>
					}
					{
						!deleted && open &&
						<Link to={{
							pathname: frontendUrls.urlBookingEventId.replace(":id", id),
							state: { buy: false }
						}}
						className="btn evt_btn font_16_regular_white_list">
							Open
						</Link>
					}
				</Col>
			</Row>
		</div>
	);
};


EventItem.propTypes = {
	item: PropTypes.object.isRequired,
	buy: PropTypes.bool,
	open: PropTypes.bool,
};


export default EventItem;