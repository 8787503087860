import {
	START_GET_FACILITY_SERVICES,
	FINISHED_GET_FACILITY_SERVICES ,
	START_CREATE_FACILITY_SERVICES,
	FAILED_GET_FACILITY_SERVICES ,
	FAILED_CREATE_FACILITY_SERVICES,
	FINISHED_CREATE_FACILITY_SERVICES,
	FINISHED_UPDATE_FACILITY_SERVICES,
	FAILED_UPDATE_FACILITY_SERVICES,
	START_UPDATE_FACILITY_SERVICES,
	FINISHED_DELETE_FACILITY_SERVICES,
	START_DELETE_FACILITY_SERVICES,
	FAILED_DELETE_FACILITY_SERVICES
} from "actions/consts";


const initialState = {
	isFetching: false,
	services: [],
	errors: {}
};


const getServicesObject = (services) => {
	if(Array.isArray(services)){
		return services.length > 0 ? services[0] : {};
	}
	return services;
};


export default function facilityAdditionalServicesReducer(state = initialState, action){
	switch(action.type){

	case START_GET_FACILITY_SERVICES:
		return{
			...state,
			isFetching: true,
			errors: {}
		};
	
	case FINISHED_GET_FACILITY_SERVICES:
		return{
			...state,
			isFetching: false,
			services: getServicesObject(action.services)
		};
	
	case FAILED_GET_FACILITY_SERVICES:
		return{
			...state,
			isFetching: false,
			errors: action.errors
		};

	case START_CREATE_FACILITY_SERVICES:
		return{
			...state,
			isFetching: true,
			errors: {}
		};
	
	case FINISHED_CREATE_FACILITY_SERVICES:
		return{
			...state,
			isFetching: false,
			services: action.services
		};
	
	case FAILED_CREATE_FACILITY_SERVICES:
		return{
			...state,
			isFetching: false,
			errors: action.errors
		};
	
	case START_UPDATE_FACILITY_SERVICES:
		return{
			...state,
			isFetching: true,
			errors: {}
		};
	
	case FINISHED_UPDATE_FACILITY_SERVICES:
		return{
			...state,
			isFetching: false,
			services: action.services
		};

	case FAILED_UPDATE_FACILITY_SERVICES:
		return{
			...state,
			isFetching: false,
			errors: action.errors
		};

	case START_DELETE_FACILITY_SERVICES:
		return{
			...state,
			isFetching: true,
			errors: {}
		};
	
	case FINISHED_DELETE_FACILITY_SERVICES:
		return{
			...state,
			isFetching: false,
			services: {}
		};
	
	case FAILED_DELETE_FACILITY_SERVICES:
		return{
			...state,
			isFetching: false,
			errors: action.errors
		};

	default:
		return state;
	}
}