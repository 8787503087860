import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "reactstrap";

import FacilityAdditionalServicesForm from "components/forms/facilities/additional_services";


const FacilityAdditionalServicesComponent = ({isEditPage, services, errors, submitChanges, submitDelete}) => {
	return (
		<Container className="mt-5">
			<p className="font_24_regular_black_list">Additional services</p>
			<Row>
				<Col xs={12} className="d-flex justify-content-center">
					<FacilityAdditionalServicesForm
						isEditForm={isEditPage}
						initialValues={services}
						errors={errors}
						onSubmit={submitChanges} 
						onDelete={submitDelete}/>
				</Col>
			</Row>
		</Container>
	);
};


FacilityAdditionalServicesComponent.propTypes = {
	services: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired,
	isEditPage: PropTypes.bool.isRequired,
	submitChanges: PropTypes.func.isRequired,
	submitDelete: PropTypes.func.isRequired
};


export default FacilityAdditionalServicesComponent;