import React from "react";
import PropTypes from "prop-types";

import { Form, Field } from "react-final-form";
import { Button } from "reactstrap";
import { fieldInput } from "components/forms/fields";


const validate = (values) => {
	let errors = {};
	if(!values.email){
		errors.email = "This field is required.";
	}
	return errors;
};


const ResetPasswordForm = ({ errors, onSubmit }) => {
	return (
		<Form 
			validate={validate}
			onSubmit={onSubmit}
			render={({ handleSubmit, pristine, submitting }) => (
				<form onSubmit={handleSubmit}>
					<Field
						name="email"
						spriteName="sprite_user"
						type="email"
						inputType="email"
						placeholder="Email"
						serverError={errors.email}
						component={fieldInput}
					/>
					<Button disabled={submitting || pristine} type="submit"
						className="primary margin_0 height_40px width_100 bg_button_clr border_color_button_clr font_16_medium border_radius_03rem">
						Submit
					</Button>
				</form>
			)}></Form>
	);
};


ResetPasswordForm.propTypes = {
	errors: PropTypes.object.isRequired,
	onSubmit: PropTypes.func.isRequired
};


export default ResetPasswordForm;