import {
	START_CREATION_PACKAGE,
	FINISHED_CREATION_PACKAGE,
	FAILED_CREATION_PACKAGE,
	CLEAR_PACKAGE_ERRORS,
} from "../../actions/consts";


const initialState = {
	isFetching: false,
	errors: {}
};

export default function creationPackageReducer(state = initialState, action) {
	switch (action.type) {

	// REDUCER CREATION PACKAGE
	case START_CREATION_PACKAGE:
		return {
			...state,
			isFetching: true
		};

	case FINISHED_CREATION_PACKAGE:
		return {
			...state,
			isFetching: false,
			errors: {}
		};

	case FAILED_CREATION_PACKAGE:
		return {
			...state,
			isFetching: false,
			errors: action.errors
		};

	case CLEAR_PACKAGE_ERRORS:
		return {
			...state,
			errors: {}
		};

	default:
		return state;
	}
}
