import React, {useEffect} from "react";
import {connect} from "react-redux";
import { Route, Redirect, Switch } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import classNames from "classnames";

import HeaderContainer from "containers/elements/dashboard_pages/header";
import MenuContainer from "containers/elements/dashboard_pages/menu";
import CalendarContainer from "containers/calendar/calendar";
import EventsContainer from "containers/events/events";
import CreateEventContainer from "containers/events/create_event";
import EditEventContainer from "containers/events/edit_event";
import MembershipsContainer from "containers/memberships/memberships";
import CreateMembershipContainer from "containers/memberships/create_membership";
import EditMembershipContainer from "containers/memberships/edit_membership";
import PackagesContainer from "containers/packages/packages";
import CreatePackageContainer from "containers/packages/create_package";
import EditPackageContainer from "containers/packages/edit_package";
import CustomersContainer from "containers/customers/customers";
import CreateCustomerContainer from "containers/customers/create_customer";
import EditCustomerContainer from "containers/customers/edit_customer";
import FacilityAdditionalServicesContainer from "containers/facilities/facility_additional_services";
import FacilityPromoContainer from "containers/facilities/facility_promo";

import frontendUrls from "routes/frontend_routes";
import {fetchValidateUserRoleOnFacility} from "actions/facilities/validate_user_role";


const BodyComponent = ({isMenuOpen, setToMenuCloseDispatched, isUserGuest, dispatch}) => {

	useEffect(() => {
		dispatch(fetchValidateUserRoleOnFacility());
	}, [isUserGuest]);

	return (
		<Container fluid className="h-100">
			<HeaderContainer />

			<div onClick={() => {
				if (isMenuOpen) {
					setToMenuCloseDispatched();
				}
			}} className={classNames("h-100 w-100", {
				"overlay position-absolute": isMenuOpen === true,
				"d-none": isMenuOpen !== true,
			})} />
			<Row className="h-100">
				<Col md={2} lg={2} xl={2}	className="px-0 menu-wrapper">
					<MenuContainer />
				</Col>
				<Col className="padding_top_80px shadow_right">
					<Switch>
						{
							isUserGuest ?
							<Redirect from={frontendUrls.urlDashboard} to={frontendUrls.urlMyOrder}/>:
							<>
								<Route exact path={frontendUrls.urlDashboard}>
									<Redirect to={frontendUrls.urlDashboardCalendar}/>
								</Route>
								<Route path={frontendUrls.urlDashboardCalendar} component={CalendarContainer} />
								<Route exact path={frontendUrls.urlDashboardEvents} component={EventsContainer} />
								<Route path={frontendUrls.urlDashboardCreateEvent} component={CreateEventContainer} />
								<Route path={frontendUrls.urlDashboardEventEdit} component={EditEventContainer} />
								<Route exact path={frontendUrls.urlDashboardMemberships} component={MembershipsContainer} />
								<Route path={frontendUrls.urlDashboardCreateMemberships} component={CreateMembershipContainer} />
								<Route path={frontendUrls.urlDashboardMembershipsEdit} component={EditMembershipContainer} />
								<Route exact path={frontendUrls.urlDashboardPackages} component={PackagesContainer} />
								<Route path={frontendUrls.urlDashboardCreatePackage} component={CreatePackageContainer} />
								<Route path={frontendUrls.urlDashboardPackageEdit} component={EditPackageContainer} />
								<Route exact path={frontendUrls.urlDashboardCustomers} component={CustomersContainer}/>
								<Route path={frontendUrls.urlDashboardCreateCustomer} component={CreateCustomerContainer}/>
								<Route path={frontendUrls.urlDashboardCustomerEdit} component={EditCustomerContainer}/>
								<Route path={frontendUrls.urlDashboardAdditionalServices} component={FacilityAdditionalServicesContainer}/>
								<Route path={frontendUrls.urlDashboardBanner} component={FacilityPromoContainer}/>
							</>
						}
					</Switch>
				</Col>
			</Row>
		</Container>
	);
};

BodyComponent.propTypes = {
	isMenuOpen: PropTypes.bool.isRequired,
	setToMenuCloseDispatched: PropTypes.func.isRequired,
	isUserGuest: PropTypes.bool.isRequired
};


const mapStateToProps = ({root: {asGuest}})  => {
	return { isUserGuest: asGuest };
};


export default connect(mapStateToProps)(BodyComponent);
