import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { fetchEvents } from "actions/events/get_events_list";
import { deleteEvent } from "actions/events/edit_event";
import EventsComponent from "components/events/events";
import EventDetailComponent from "components/events/eventDetail";
import apiUrls from "routes/api";
import { setDashboardSelectedEvent } from "actions/util_actions";


class EventsContainer extends React.Component {


	constructor(props) {
		super(props);
		this.state = {
			events: props.events,
			show: false,
			eventToDelete: {}
		};

		this.onDelete = this
			.onDelete
			.bind(this);

		this.handleShow = this
			.handleShow
			.bind(this);

		this.handleClose = this
			.handleClose
			.bind(this);

	}

	componentDidMount() {
		this
			.props
			.dispatch(fetchEvents());
	}

	onDelete(idDeleteEvent) {
		let apiUrl = apiUrls.eventsUrl + idDeleteEvent + "/";
		this
			.props
			.dispatch(deleteEvent(apiUrl, idDeleteEvent, false));
		this.handleClose();
	}

	handleShow(event) {
		this.setState({
			show: true,
			eventToDelete: {
				id: event.id,
				title: event.title
			}
		});
	}

	handleClose() {
		this.setState({
			show: false,
			eventToDelete: {},
		});
	}

	render() {
		const { setDashboardSelectedEventDispatched, selectedEvent, isFetching } = this.props;
		const { show, openedEvent, eventToDelete } = this.state;
		let sendProps = {
			show,
			openedEvent,
			eventToDelete,
			state: this.state,
			events: this.props.events,
			onDelete: this.onDelete,
			handleShow: this.handleShow,
			handleClose: this.handleClose,
			handleOpenEvent: this.handleOpenEvent,
			setDashboardSelectedEventDispatched,
			selectedEvent,
			isFetching,
		};
		if (selectedEvent && Object.keys(selectedEvent).length ) {
			return (<EventDetailComponent {...sendProps} />);
		}
		return (<EventsComponent {...sendProps}/>);
	}
}

EventsContainer.propTypes = {
	dispatch: PropTypes.func.isRequired,
	events: PropTypes.array.isRequired,
	setDashboardSelectedEventDispatched: PropTypes.func,
	selectedEvent: PropTypes.object,
	isFetching: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
	return {
		events: state.eventsRoot.events || [],
		selectedEvent: state.utilRoot && state.utilRoot.selectedEvent,
		isFetching: (state.eventsRoot && state.eventsRoot.isFetching) || false,
	};
};

export default connect(mapStateToProps, {
	setDashboardSelectedEventDispatched: setDashboardSelectedEvent,
	dispatch: (dispatch) => dispatch,
})(EventsContainer);