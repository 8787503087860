import React from "react";
import PropTypes from "prop-types";
import {Col} from "reactstrap";
import EventForm from "components/forms/events/event_form";


const CreateEventComponent = ({ onSubmit, resetEventErrors, errors }) => {
	let sendValues = {
		capacity: 1,
		category: 1
	};
	return (
		<Col sm="12" md="11" className="mx-auto">
			<p className="margin_top_15px margin_bottom_20px font_24_regular_black_list">Create Event</p>
			<EventForm onSubmit={onSubmit} errors={errors} initialValues={sendValues} resetEventErrors={resetEventErrors}/>
		</Col>
	);
};

CreateEventComponent.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	resetEventErrors: PropTypes.func,
	errors: PropTypes.object
};

export default CreateEventComponent;
