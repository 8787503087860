import { styles } from "components/calendar/config/consts";


export const customEventPropGetter = (view) => {
	return {
		className: `rbc-customer-${view}-event`,
		style: styles[view]
	};
};


export const customDayPropGetter = () => {
	return {
		className: "rbc-customer-day"
	};
};


export const customCalendarPropGetter = () => {
	return{
		className: "shadow_form"
	};
};


export const customSlotPropGetter = () => {
	return {
		className: "rbc-customer-time"
	};
};