import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import frontendUrls from "routes/frontend_routes";
import { history } from "store";


const withAuth = ( Wrapped ) => {
	class AuthContainer extends Component{
		componentDidMount() {
			if (!this.props.authToken) {
				history.push(frontendUrls.urlLogin);
			}
		}

		render(){
			return <Wrapped {...this.props}/>;
		}
	}

	AuthContainer.propTypes = {
		authToken: PropTypes.string,
	};

	const mapStateToProps = (state) =>{
		return {
			authToken: state.root.authToken || undefined,
		};
	};

	return connect(mapStateToProps)(AuthContainer);
};


export default withAuth;