import { connect } from "react-redux";


const withCustomers = (Component) => {
	const mapStateToProps = ({customersRoot}) => {
		return {
			customers: customersRoot.customers || []
		};
	};

	return connect(mapStateToProps)(Component);
};


export default withCustomers;