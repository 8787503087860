/* action types */
export const DESTROY_SESSION = "DESTROY_SESSION";
export const GET_FACILITY_STRIPE_ACCOUNT = "GET_FACILITY_STRIPE_ACCOUNT";
export const RECEIVE_FACILITY_STRIPE_ACCOUNT = "RECEIVE_FACILITY_STRIPE_ACCOUNT";
/* Log in */
export const START_LOGIN = "START_LOGIN";
export const FINISHED_LOGIN = "FINISHED_LOGIN";
export const FAILED_LOGIN = "FAILED_LOGIN";
/* Register */
export const START_REGISTER = "START_REGISTER";
export const FINISHED_REGISTER = "FINISHED_REGISTER";
export const FAILED_REGISTER = "FAILED_REGISTER";
/* Log out */
export const DO_LOGOUT = "DO_LOGOUT";
/* Activate account */
export const START_ACTIVATE_ACCOUNT = "START_ACTIVATE_ACCOUNT";
export const FINISHED_ACTIVATE_ACCOUNT = "FINISHED_ACTIVATE_ACCOUNT";
export const FAILED_ACTIVATE_ACCOUNT = "FAILED_ACTIVATE_ACCOUNT";
/* Reset password actions */
export const SEND_RESET_PASSWORD_REQUEST = "SEND_RESET_PASSWORD_REQUEST";
export const SUCCESS_RESET_PASSWORD_REQUEST = "SUCCESS_RESET_PASSWORD_REQUEST";
export const FAILED_RESET_PASSWORD_REQUEST = "FAILED_RESET_PASSWORD_REQUEST";
export const START_CONFIRM_RESET_PASSWORD = "START_CONFIRM_RESET_PASSWORD";
export const FAILED_CONFIRM_RESET_PASSWORD = "FAILED_CONFIRM_RESET_PASSWORD";
export const FINISHED_CONFIRM_RESET_PASSWORD = "FINISHED_CONFIRM_RESET_PASSWORD";
export const RESET_ERRORS_FROM_PASSWORD = "RESET_ERRORS_FROM_PASSWORD";
/* Facility config */
export const START_GET_PROMO = "START_GET_PROMO";
export const FINISHED_GET_PROMO = "FINISHED_GET_PROMO";
export const START_CREATE_FACILITY_PROMO = "START_CREATE_FACILITY_PROMO";
export const FINISHED_CREATE_FACILITY_PROMO = "FINISHED_CREATE_FACILITY_PROMO ";
export const FAILED_CREATE_FACILITY_PROMO = "FAILED_CREATE_FACILITY_PROMO";
export const START_UPDATE_FACILITY_PROMO = "START_UPDATE_FACILITY_PROMO";
export const FINISHED_UPDATE_FACILITY_PROMO = "FINISHED_UPDATE_FACILITY_PROMO ";
export const FAILED_UPDATE_FACILITY_PROMO = "FAILED_UPDATE_FACILITY_PROMO";
export const START_DELETE_FACILITY_PROMO = "START_DELETE_FACILITY_PROMO";
export const FINISHED_DELETE_FACILITY_PROMO = "FINISHED_DELETE_FACILITY_PROMO ";
export const FAILED_DELETE_FACILITY_PROMO = "FAILED_DELETE_FACILITY_PROMO";

export const START_GET_FACILITY_SERVICES = "START_GET_FACILITY_SERVICES";
export const FINISHED_GET_FACILITY_SERVICES = "FINISH_GET_FACILITY_SERVICES";
export const FAILED_GET_FACILITY_SERVICES = "FAILED_GET_FACILITY_SERVICES";
export const START_CREATE_FACILITY_SERVICES = "START_CREATE_FACILITY_SERVICES";
export const FINISHED_CREATE_FACILITY_SERVICES = "FINISHED_CREATE_FACILITY_SERVICES";
export const FAILED_CREATE_FACILITY_SERVICES = "FAILED_CREATE_FACILITY_SERVICES";
export const START_UPDATE_FACILITY_SERVICES = "START_UPDATE_FACILITY_SERVICES";
export const FINISHED_UPDATE_FACILITY_SERVICES = "FINISHED_UPDATE_FACILITY_SERVICES";
export const FAILED_UPDATE_FACILITY_SERVICES = "FAILED_UPDATE_FACILITY_SERVICES";
export const START_DELETE_FACILITY_SERVICES = "START_DELETE_FACILITY_SERVICES";
export const FINISHED_DELETE_FACILITY_SERVICES = "FINISHED_DELETE_FACILITY_SERVICES";
export const FAILED_DELETE_FACILITY_SERVICES = "FAILED_DELETE_FACILITY_SERVICES";

export const START_VALIDATE_USER_ROLE_ON_FACILITY = "START_VALIDATE_USER_ROLE_ON_FACILITY";
export const USER_ROLE_VALIDATION_SUCCESS = "USER_ROLE_VALIDATION_SUCCESS";
export const USER_ROLE_VALIDATION_FAILED = "USER_ROLE_VALIDATION_FAILED";

/* CUSTOMER SIDE */
/* Booking events */
export const GET_BOOKING_EVENTS = "GET_BOOKING_EVENTS";
export const RECEIVE_BOOKING_EVENTS = "RECEIVE_BOOKING_EVENTS";
/* Booking detail events */
export const START_GET_BOOKING_EVENT = "START_GET_BOOKING_EVENT";
export const FINISH_GET_BOOKING_EVENT = "FINISH_GET_BOOKING_EVENT";
export const FAIL_GET_BOOKING_EVENT = "FAIL_GET_BOOKING_EVENT";
/* Booking events buy */
export const START_BUY_BOOKING_EVENT = "START_BUY_BOOKING_EVENT";
export const FINISH_BUY_BOOKING_EVENT = "FINISH_BUY_BOOKING_EVENT";
export const FAIL_BUY_BOOKING_EVENT = "FAIL_BUY_BOOKING_EVENT";
export const SET_TO_ZERO_CREATEDORDER = "SET_TO_ZERO_CREATEDORDER";
/* Booking events checkin */
export const START_CHECKIN_BOOKING_EVENT = "START_CHECKIN_BOOKING_EVENT";
export const FINISH_CHECKIN_BOOKING_EVENT = "FINISH_CHECKIN_BOOKING_EVENT";
export const FAIL_CHECKIN_BOOKING_EVENT = "FAIL_CHECKIN_BOOKING_EVENT";
/* Booking events buy free */
export const START_BUY_FREE_BOOKING_EVENT = "START_BUY_FREE_BOOKING_EVENT";
export const FINISH_BUY_FREE_BOOKING_EVENT = "FINISH_BUY_FREE_BOOKING_EVENT";
export const FAIL_BUY_FREE_BOOKING_EVENT = "FAIL_BUY_FREE_BOOKING_EVENT";
/* Booking events pay at venue */
export const START_PAY_AT_VENUE_BOOKING_EVENT = "START_PAY_AT_VENUE_BOOKING_EVENT";
export const FINISH_PAY_AT_VENUE_BOOKING_EVENT = "FINISH_PAY_AT_VENUE_BOOKING_EVENT";
export const FAIL_PAY_AT_VENUE_BOOKING_EVENT = "FAIL_PAY_AT_VENUE_BOOKING_EVENT";

/* Booking memberships */
export const GET_BOOKING_MEMBERSHIPS = "GET_BOOKING_MEMBERSHIPS";
export const RECEIVE_BOOKING_MEMBERSHIPS = "RECEIVE_BOOKING_MEMBERSHIPS";
/* Booking detail memberships */
export const START_GET_BOOKING_MEMBERSHIP = "START_GET_BOOKING_MEMBERSHIP";
export const FINISH_GET_BOOKING_MEMBERSHIP = "FINISH_GET_BOOKING_MEMBERSHIP";
export const FAIL_GET_BOOKING_MEMBERSHIP = "FAIL_GET_BOOKING_MEMBERSHIP";
/* Booking memberships buy */
export const START_BUY_BOOKING_MEMBERSHIP = "START_BUY_BOOKING_MEMBERSHIP";
export const FINISH_BUY_BOOKING_MEMBERSHIP = "FINISH_BUY_BOOKING_MEMBERSHIP";
export const FAIL_BUY_BOOKING_MEMBERSHIP = "FAIL_BUY_BOOKING_MEMBERSHIP";

/*Booking packages*/
export const GET_BOOKING_PACKAGES = "GET_BOOKING_PACKAGES";
export const RECEIVE_BOOKING_PACKAGES = "RECEIVE_BOOKING_PACKAGES";
/* Booking detail packages */
export const START_GET_BOOKING_PACKAGE = "START_GET_BOOKING_PACKAGE";
export const FINISH_GET_BOOKING_PACKAGE = "FINISH_GET_BOOKING_PACKAGE";
export const FAIL_GET_BOOKING_PACKAGE = "FAIL_GET_BOOKING_PACKAGE";
/* Booking packages buy */
export const START_BUY_BOOKING_PACKAGE = "START_BUY_BOOKING_PACKAGE";
export const FINISH_BUY_BOOKING_PACKAGE = "FINISH_BUY_BOOKING_PACKAGE";
export const FAIL_BUY_BOOKING_PACKAGE = "FAIL_BUY_BOOKING_PACKAGE";

/* Filters */
/* Daily Filter Event */
export const GET_DAILY_FILTER_EVENTS = "GET_DAILY_FILTER_EVENTS";
export const RECEIVE_DAILY_FILTER_EVENTS = "RECEIVE_DAILY_FILTER_EVENTS";
/* Weekly Filter Event */
export const GET_WEEKLY_FILTER_EVENTS = "GET_WEEKLY_FILTER_EVENTS";
export const RECEIVE_WEEKLY_FILTER_EVENTS = "RECEIVE_WEEKLY_FILTER_EVENTS";
/* Monthly Filter Event */
export const GET_MONTHLY_FILTER_EVENTS = "GET_MONTHLY_FILTER_EVENTS";
export const RECEIVE_MONTHLY_FILTER_EVENTS = "RECEIVE_MONTHLY_FILTER_EVENTS";

/* DASHBOARD SIDE */
/* Events */
export const GET_EVENTS = "GET_EVENTS";
export const RECEIVE_EVENTS = "RECEIVE_EVENTS";
/* Create Event */
export const START_CREATION_EVENT = "START_CREATION_EVENT";
export const FINISHED_CREATION_EVENT = "FINISHED_CREATION_EVENT";
export const FAILED_CREATION_EVENT = "FAILED_CREATION_EVENT";
export const CLEAR_EVENT_ERRORS = "CLEAR_EVENT_ERRORS";
/* Edit Event */
export const START_EDIT_EVENT = "START_EDIT_EVENT";
export const FINISHED_EDIT_EVENT = "FINISHED_EDIT_EVENT";
export const FAILED_EDIT_EVENT = "FAILED_EDIT_EVENT";
export const START_FETCHING_EVENT = "START_FETCHING_EVENT";
export const FINISHED_FETCHING_EVENT = "FINISHED_FETCHING_EVENT";
export const DELETE_EVENT_FROM_STORE = "DELETE_EVENT_FROM_STORE";
export const FAILED_FETCHING_EVENT = "FAILED_FETCHING_EVENT";
/* Delete Event */
export const START_DELETE_EVENT = "START_DELETE_EVENT";
export const FINISHED_DELETE_EVENT = "FINISHED_DELETE_EVENT";
export const FAILED_DELETE_EVENT = "FAILED_DELETE_EVENT";

/* Memberships */
export const GET_MEMBERSHIPS = "GET_MEMBERSHIPS";
export const RECEIVE_MEMBERSHIPS = "RECEIVE_MEMBERSHIPS";
/* Create membership */
export const START_CREATION_MEMBERSHIP = "START_CREATION_MEMBERSHIP";
export const FINISHED_CREATION_MEMBERSHIP = "FINISHED_CREATION_MEMBERSHIP";
export const FAILED_CREATION_MEMBERSHIP = "FAILED_CREATION_MEMBERSHIP";
export const CLEAR_MEMBERSHIP_ERRORS = "CLEAR_MEMBERSHIP_ERRORS";
/* Edit membership */
export const START_EDIT_MEMBERSHIP = "START_EDIT_MEMBERSHIP";
export const FINISHED_EDIT_MEMBERSHIP = "FINISHED_EDIT_MEMBERSHIP";
export const FAILED_EDIT_MEMBERSHIP = "FAILED_EDIT_MEMBERSHIP";
export const START_FETCHING_MEMBERSHIP = "START_FETCHING_MEMBERSHIP";
export const FINISHED_FETCHING_MEMBERSHIP = "FINISHED_FETCHING_MEMBERSHIP";
export const FAILED_FETCHING_MEMBERSHIP = "FAILED_FETCHING_MEMBERSHIP";
/* Delete membership */
export const START_DELETE_MEMBERSHIP = "START_DELETE_MEMBERSHIP";
export const FINISHED_DELETE_MEMBERSHIP = "FINISHED_DELETE_MEMBERSHIP";
export const FAILED_DELETE_MEMBERSHIP = "FAILED_DELETE_MEMBERSHIP";

/* Packages */
export const GET_ACTIVE_PACKAGES = "GET_ACTIVE_PACKAGES";
export const RECEIVE_ACTIVE_PACKAGES = "RECEIVE_ACTIVE_PACKAGES";
export const GET_EXPIRED_PACKAGES = "GET_EXPIRED_PACKAGES";
export const RECEIVE_EXPIRED_PACKAGES = "RECEIVE_EXPIRED_PACKAGES";
/* Create Package */
export const START_CREATION_PACKAGE = "START_CREATION_PACKAGE";
export const FINISHED_CREATION_PACKAGE = "FINISHED_CREATION_PACKAGE";
export const FAILED_CREATION_PACKAGE = "FAILED_CREATION_PACKAGE";
export const CLEAR_PACKAGE_ERRORS = "CLEAR_PACKAGE_ERRORS";
/* Edit Package */
export const START_EDIT_PACKAGE = "START_EDIT_PACKAGE";
export const FINISHED_EDIT_PACKAGE = "FINISHED_EDIT_PACKAGE";
export const FAILED_EDIT_PACKAGE = "FAILED_EDIT_PACKAGE";
export const START_FETCHING_PACKAGE = "START_FETCHING_PACKAGE";
export const FINISHED_FETCHING_PACKAGE = "FINISHED_FETCHING_PACKAGE";
export const FAILED_FETCHING_PACKAGE = "FAILED_FETCHING_PACKAGE";
export const RESET_EDIT_ERRORS = "RESET_EDIT_ERRORS";
/* Delete Package */
export const START_DELETE_PACKAGE = "START_DELETE_PACKAGE";
export const FINISHED_DELETE_PACKAGE = "FINISHED_DELETE_PACKAGE";
export const FAILED_DELETE_PACKAGE = "FAILED_DELETE_PACKAGE";
/* Orders */
export const START_GET_ORDERS = "START_GET_ORDERS";
export const FINISHED_GET_ORDERS = "FINISHED_GET_ORDERS";
export const FINISHED_GET_EXPIRED_ORDERS = "FINISHED_GET_EXPIRED_ORDERS";

/* Menu */
export const TOGGLE_MENU = "TOGGLE_MENU";
export const CLOSE_MENU = "CLOSE_MENU";
/* Dashboard selected event */
export const DASHBOARD_SELECTED_EVENT = "DASHBOARD_SELECTED_EVENT";
/* Dashboard Customers */
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const RECEIVE_CUSTOMERS = "RECEIVE_CUSTOMERS";
/* Create customer */
export const START_CREATION_CUSTOMER = "STRAT_CREATION_CUSTOMER";
export const FINISHED_CREATION_CUSTOMER = "FINISHED_CREATION_CUSTOMER";
export const FAILED_CREATION_CUSTOMER = "FAILED_CREATION_CUSTOMER";
/* Fetch customer */
export const START_FETCHING_CUSTOMER = "START_FETCHING_CUSTOMER";
export const FINISHED_FETCHING_CUSTOMER = "FINISHED_FETCHING_CUSTOMER";
export const FAILED_FETCHING_CUSTOMER = "FAILED_FETCHING_CUSTOMER";
/* Edit customer */
export const START_EDIT_CUSTOMER = "START_EDIT_CUSTOMER";
export const FINISHED_EDIT_CUSTOMER = "FINISHED_EDIT_CUSTOMER";
export const FAILED_EDIT_CUSTOMER = "FAILED_EDIT_CUSTOMER";
export const START_FETCHING_CUSTOMER_ORDERS = "START_FETCHING_CUSTOMER_ORDERS";
export const FINISHED_FETCHING_CUSTOMER_ORDERS = "FINISHED_FETCHING_CUSTOMER_ORDERS ";
export const FAILED_FETCHING_CUSTOMER_ORDERS = "FAILED_FETCHING_CUSTOMER_ORDERS";
export const START_DELETE_CUSTOMER_ORDER = "START_DELETE_CUSTOMER_ORDER";
export const FINISHED_DELETE_CUSTOMER_ORDER = "FINISHED_DELETE_CUSTOMER_ORDER";
export const FAILED_DELETE_CUSTOMER_ORDER = "FAILED_DELETE_CUSTOMER_ORDER";
/* Delete customer*/
export const START_DELETE_CUSTOMER = "START_DELETE_CUSTOMER";
export const FINISHED_DELETE_CUSTOMER = "FINISHED_DELETE_CUSTOMER";
export const FAILED_DELETE_CUSTOMER = "FAILED_DELETE_CUSTOMER";

/* My orders actions*/
export const FINISHED_GET_ACTIVE_EVENTS_ORDERS = "FINISHED_GET_ACTIVE_EVENTS_ORDERS";
export const FINISHED_GET_ACTIVE_MEMBERSHIP_ORDERS = "FINISHED_GET_ACTIVE_MEMBERSHIP_ORDERS";
export const FINISHED_GET_ACTIVE_PACKAGES_ORDERS = "FINISHED_GET_ACTIVE_PACKAGES_ORDERS";
export const FINISHED_GET_EXPIRED_EVENTS_ORDERS = "FINISHED_GET_EXPIRED_EVENTS_ORDERS";
export const FINISHED_GET_EXPIRED_MEMBERSHIP_ORDERS = "FINISHED_GET_EXPIRED_MEMBERSHIP_ORDERS";
export const FINISHED_GET_EXPIRED_PACKAGES_ORDERS = "FINISHED_GET_EXPIRED_PACKAGES_ORDERS";

/* Utils */
export const GET_CATEGORIES = "GET_CATEGORIES";
export const RECEIVED_CATEGORIES = "RECEIVED_CATEGORIES";
export const INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR";
export const RESET_HTTP_ERRORS = "RESET_HTTP_ERRORS";

/* Calendar */
export const GET_CALENDAR_EVENTS = "GET_CALENDAR_EVENTS";
export const RECEIVE_CALENDAR_EVENTS = "RECEIVE_CALENDAR_EVENTS";


/* HTTP status codes */
export const HTTP_200_OK = 200;
export const HTTP_308_PERMANENT_REDIRECT = 308;
export const HTTP_500_INTERNAL_SERVER_ERROR = 500;
