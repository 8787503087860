import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import UserAuthComponent from "../../components/auth/user_auth";
import { doLogout } from "../../actions/auth/logout";
import { history } from "../../store";


class UserAuthContainer extends React.Component {
	constructor(props) {
		super(props);
		this.onClickLogout = this
			.onClickLogout
			.bind(this);
	}

	onClickLogout() {
		this
			.props
			.dispatch(doLogout());
		history.push("/");
	}

	render() {
		let sendProps = {
			state: this.props,
			onClickLogout: this.onClickLogout
		};
		return (<UserAuthComponent {...sendProps} />);
	}
}

UserAuthContainer.propTypes = {
	authToken: PropTypes.string.isRequired,
	first_name: PropTypes.string.isRequired,
	last_name: PropTypes.string.isRequired,
	dispatch: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
	return {
		authToken: state.root.authToken,
		first_name: state.root.first_name,
		last_name: state.root.last_name,
	};
};


const mapDispatchToProps = (dispatch) => {
	return { dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAuthContainer);
