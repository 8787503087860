import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { Button, Col, Row } from "reactstrap";

import {capitalizeFirstLetter} from "components/forms/fields";
import {isEmpty} from "components/utils";
import OneClickButton from "components/utils/buttons";
import ModalComponent from "components/utils/modal";


const ManageFormComponent = ({
	errors, isSubmitedForm, isPristineForm, isEditForm,
	cancelLink, product, onDelete, resetComponentErrors
}) => {

	const [showModalMessage, openModalWithMessage] = useState(false);
	const [errorsToShow, getErrorsToShow] = useState([]);
	const [hasErrorsToShow, setErrorsToShow] = useState(false);

	useEffect(() => {
		if(!isEmpty(errors)){
			const getError = errors.non_field_errors ? errors.non_field_errors[0] : errors.message ? errors.message : "";
			getErrorsToShow(getError);
		}
	}, [errors]);

	useEffect(() => {
		if(errorsToShow.length > 0){
			setErrorsToShow(true);
			openModalWithMessage(true);
		}
	}, [errorsToShow]);

	const handleCloseModal = () => {
		openModalWithMessage(false);
		setErrorsToShow(false);
		getErrorsToShow([]);
		if(resetComponentErrors){
			resetComponentErrors();
		}
	}


	return(
		<Row className="form_control d-flex  justify-content-center align-items-center">
			<Col xs={12} className="d-flex justify-content-around justify-content-md-end align-items-center">
				{
					cancelLink &&
					<Link className="btn_pozen btn_pozen__create btn-cancel font_16_regular_21AEF5" to={cancelLink}>
						<span>Cancel</span>
					</Link>
				}
				{
					!isEditForm &&
					<OneClickButton disabled={isPristineForm || isSubmitedForm}
						className="btn_pozen btn_pozen__create font_16_regular_white bg_button_create border_color_line_left_header ml-3"
						type="submit">
							Create
					</OneClickButton>
				}
				{
					isEditForm &&
					<OneClickButton disabled={isPristineForm || isSubmitedForm}
						className="btn_pozen btn_pozen__create font_16_regular_white bg_button_create border_color_line_left_header ml-3"
						type="submit">
							Update
					</OneClickButton>
				}
				{
					isEditForm &&
					<Button disabled={isSubmitedForm}
						className="btn-danger btn_pozen btn_pozen__create font_16_regular_white ml-3 ml-3"
						onClick={() => openModalWithMessage(true)}>
							Delete <span className="d-none d-sm-none d-md-inline-block">{product}</span>
					</Button>
				}
				<ModalComponent isOpen={showModalMessage}
					header={hasErrorsToShow ? "ERROR": "DELETE"}
					body={hasErrorsToShow ? errorsToShow : <p>Are you sure you want to delete this {product}?</p>}>
					<Button className="btn btn-secondary" onClick={handleCloseModal}>Close</Button>
					{
						!hasErrorsToShow &&
						<OneClickButton className="btn btn-danger" onClickParent={onDelete}>
							Delete {capitalizeFirstLetter(product)}
						</OneClickButton>
					}
				</ModalComponent>
			</Col>
		</Row>
	);
};


ManageFormComponent.propTypes = {
	errors: PropTypes.object,
	isPristineForm: PropTypes.bool.isRequired,
	isSubmitedForm: PropTypes.bool.isRequired,
	isEditForm: PropTypes.bool.isRequired,
	product: PropTypes.string.isRequired,
	cancelLink: PropTypes.string,
	onDelete: PropTypes.func,
	resetComponentErrors: PropTypes.func
};


export default ManageFormComponent;