import {
	START_EDIT_MEMBERSHIP,
	FINISHED_EDIT_MEMBERSHIP,
	FAILED_EDIT_MEMBERSHIP,
	START_FETCHING_MEMBERSHIP,
	FINISHED_FETCHING_MEMBERSHIP,
	FAILED_FETCHING_MEMBERSHIP,
	START_DELETE_MEMBERSHIP,
	FINISHED_DELETE_MEMBERSHIP,
	FAILED_DELETE_MEMBERSHIP
} from "actions/consts";
import { history } from "store";
import { request } from "actions/utils";
import frontendUrls from "routes/frontend_routes";


// Processing fetching membership
const startFetchingMembership = () => {
	return { type: START_FETCHING_MEMBERSHIP };
};

const finishFetchingMembership = (data) => {
	return {
		type: FINISHED_FETCHING_MEMBERSHIP,
		data: data,
		receivedAt: Date.now()
	};
};

const failedFetchingMembership = (data) => {
	return {
		type: FAILED_FETCHING_MEMBERSHIP,
		errors: data,
		receivedAt: Date.now()
	};
};

export const fetchSelectedMembership = (apiUrlSelectedMembership) => {
	return (dispatch, getState) => {
		dispatch(startFetchingMembership);
		const { root: { authToken }} = getState();
		const config = {
			isAuth: authToken
		};

		const response = request(apiUrlSelectedMembership, config);
		return response
			.then((json) => {
				dispatch(finishFetchingMembership(json));
			})
			.catch((error) => {
				error.response.json()
					.then((json) => {
						dispatch(failedFetchingMembership(json));
					});
			});
	};
};


// Processing editing Membership
const startEditMembership = () => {
	return { type: START_EDIT_MEMBERSHIP };
};

const finishEditMembership = (data) => {
	return {
		type: FINISHED_EDIT_MEMBERSHIP,
		data: data,
		receivedAt: Date.now()
	};
};

const failedEditMembership = (data) => {
	return {
		type: FAILED_EDIT_MEMBERSHIP,
		errors: data,
		receivedAt: Date.now()
	};
};

export const editMembership = (sendData, editApiUrl) => {
	return (dispatch, getState) => {
		dispatch(startEditMembership());
		const { root: { authToken } } = getState();
		const config = {
			isAuth: authToken,
			method: "PUT"
		};
		
		const response = request(editApiUrl, config, sendData);
		return response
			.then((json) => {
				dispatch(finishEditMembership(json));
				history.push(frontendUrls.urlDashboardMemberships);
			})
			.catch((error) => {
				error.response.json()
					.then((json) => {
						dispatch(failedEditMembership(json));
					});
			});
	};
};

//Processing deletion Membership
const startDeleteMembership = () => {
	return { type: START_DELETE_MEMBERSHIP };
};

const finishDeleteMembership = () => {
	return {
		type: FINISHED_DELETE_MEMBERSHIP,
		data: {},
		receivedAt: Date.now()
	};
};

const failedDeleteMembership = (data) => {
	return {
		type: FAILED_DELETE_MEMBERSHIP,
		errors: data,
		receivedAt: Date.now()
	};
};

export const deleteMembership = (editApiUrl) => {
	return (dispatch, getState) => {
		dispatch(startDeleteMembership());
		const { root: { authToken }} = getState();
		const config = {
			isAuth: authToken,
			method: "DELETE"
		};

		const response = request(editApiUrl, config, {}, true);
		return response
			.then(() => {
				dispatch(finishDeleteMembership());
				history.push(frontendUrls.urlDashboardMemberships);
			})
			.catch((error) => {
				error.response.json().then((json) => {
					dispatch(failedDeleteMembership(json));
				});
			});
	};
};
