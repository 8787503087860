import React, {useEffect} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container } from "reactstrap";
import { DashboardCalendar } from "components/calendar";
import { fetchCalendarEvents } from "actions/calendar/get_events";


const CalendarContainer = ({events, defaultDate, dispatch, isFetching}) => {

	useEffect(() => {
		dispatch(fetchCalendarEvents());
	}, []);

	const getCurrentDateEvents = (currentView, startDate, endDate) => {
		dispatch(fetchCalendarEvents(100, 1, currentView, startDate, endDate));
	};

	return( 
		<Container className="mt-5 mb-5">
			<p className="font_20_medium_black_list">Calendar</p>
			<DashboardCalendar events={events} 
				defaultDate={defaultDate}
				isFetching={isFetching} 
				getEvents={getCurrentDateEvents}/>
		</Container>
	);
};


CalendarContainer.propTypes = {
	events: PropTypes.arrayOf(PropTypes.object).isRequired,
	defaultDate:PropTypes.instanceOf(Date).isRequired,
	dispatch: PropTypes.func.isRequired,
	isFetching: PropTypes.bool.isRequired
};


const mapStateToProps = ({calendarRoot}) => {
	return{
		isFetching: calendarRoot.isFetching || false,
		events: calendarRoot.events,
		defaultDate: calendarRoot.defaultDate
	};
};


export default connect(mapStateToProps)(CalendarContainer);
