var apiUrls = {
	loginUrl: "/api/v0/accounts/api-token-auth/",
	registerUrl: "/api/v0/accounts/register-user/",
	activateAccountUrl: "/api/v0/accounts/activate/",
	resetPassvordUrl: "/api/v0/accounts/manage-password/reset/",
	confirmResetPasswordUrl: "/api/v0/accounts/manage-password/reset-confirm/",
	stripeAccountUrl: "/api/v0/accounts/stripe-account/",
	//Customer side
	eventDetailUrl: "/api/v0/events/list/:id/",
	bookEventsUrl: "/api/v0/events/list/",
	filterEventsUrl: "/api/v0/events/list/filter/",
	orderEventsUrl: "/api/v0/orders/create/event/:id/",

	membershipDetailUrl: "/api/v0/memberships/:id/",
	bookMembershipsUrl: "/api/v0/memberships/",
	bookMembershipDetailUrl: "/api/v0/memberships/:id/",
	orderMembershipsUrl: "/api/v0/orders/create/membership/:id/",

	bookPackagesUrl: "/api/v0/packages/",
	packageDetailUrl: "/api/v0/packages/:id/",
	orderPackagesUrl: "/api/v0/orders/create/package/:id/",
	myOrdersUrl: "/api/v0/orders/my-orders/",
	myOrdersPackagesUrl: "/api/v0/orders/my-orders/packages/",
	myOrdersMembershipsUrl: "/api/v0/orders/my-orders/memberships/",
	myOrdersEventsUrl: "/api/v0/orders/my-orders/events/",

	//Dashboard side
	eventsUrl: "/api/v0/events/generalevents/",
	creatEvent: "/api/v0/events/generalevents/",
	eventEditUrl: "/api/v0/events/generalevents/:id/",

	membershipsUrl: "/api/v0/memberships/",
	createMembership: "/api/v0/memberships/",

	packagesUrl: "/api/v0/packages/",
	createPackage: "/api/v0/packages/",
	ordersUrl: "/api/v0/orders/my-orders/",

	customersUrl: "/api/v0/orders/centre-customers/",
	createCustomerUrl: "/api/v0/orders/centre-customers/",
	editCustomerUrl: "/api/v0/orders/centre-customers/:id/",
	ordersCustomerUrl: "/api/v0/orders/centre-customers/:id/orders/",
	orderDetailsCustomerUrl: "/api/v0/orders/centre-customers/:id/orders/:orderId/",
	categoriesUrl: "/api/v0/events/categories/",
	orderDetailListUrl: "/api/v0/orders/all/",
	// Facilities
	facilityPromoUrl: "/api/v0/facilities/facility-promo/",
	facilityPromoDetailUrl: "/api/v0/facilities/facility-promo/:id/",
	facilityAdditionalServicesUrl: "/api/v0/facilities/facility-additional-services/",
	facilityAdditionalServicesDetailUrl: "/api/v0/facilities/facility-additional-services/:id/",
	facilityValidateUserRoleUrl: "/api/v0/facilities/check-user-role/"
};

export default apiUrls;
