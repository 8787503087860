import {
	CLEAR_MEMBERSHIP_ERRORS,
	START_CREATION_MEMBERSHIP,
	FINISHED_CREATION_MEMBERSHIP,
	FAILED_CREATION_MEMBERSHIP
} from "actions/consts";
import apiUrls from "routes/api";
import { history } from "store";
import { request } from "actions/utils";
import frontendUrls from "routes/frontend_routes";


// Processing register
const startCreationMembership = () => {
	return { type: START_CREATION_MEMBERSHIP };
};

const finishCreationMembership = (data) => {
	return {
		type: FINISHED_CREATION_MEMBERSHIP,
		data: data,
		receivedAt: Date.now()
	};
};

const failedCreationMembership = (data) => {
	return {
		type: FAILED_CREATION_MEMBERSHIP,
		errors: data,
		receivedAt: Date.now()
	};
};


export const fetchCreationMembership = (sendData) => {
	return (dispatch, getState) => {
		dispatch(startCreationMembership());
		const { root: { authToken } } = getState();
		const config = {
			isAuth: authToken,
			method: "POST"
		};

		const response = request(apiUrls.createMembership, config, sendData);

		return response
			.then((json) => {
				dispatch(finishCreationMembership(json));
				history.push(frontendUrls.urlDashboardMemberships);
			})
			.catch((error) => {
				error.response.json().then((json) => {
					dispatch(failedCreationMembership(json));
				});
			});
	};
};


export const clearMembershipErrors = () => {
	return {type: CLEAR_MEMBERSHIP_ERRORS};
};