import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Col, Row, Container } from "reactstrap";
import { BsCalendar, BsTagFill } from "react-icons/bs";

import {PackageUsageLimit} from "components/booking/packages/packageDetail";
import { getCategoryNameForPackage, getCountDays } from "components/utils";
import { packageLimits } from "backend_constants/packageTimeLimit";
import { categoryLimits } from "backend_constants/categories";
import frontendUrls from "routes/frontend_routes";


export const PackageItem = ({item, buy = true, open, isMyOrdersPage = false, usageLeft = 0}) => {
	return(
		<div className="package_item_wrap shadow_form">
			<div className="package_item">
				<Row className="pkg_title">
					<p className="font_18_medium_2c2c2d_list mb-0">
						{item.title}
					</p>
				</Row>

				<Row className="pkg_details">
					<Col xs="2">
						<BsCalendar className="gliph_grey gliph_15px" />
					</Col>
					<Col xs="10">
						{item.time_limits === packageLimits.unlimited && <span className="font_16_medium_2c2c2d_list">
								Time unlimited
						</span>}
						{item.time_limits === packageLimits.hardExpiry && <span className="font_16_medium_2c2c2d_list">
							<p className="font_16_medium_2c2c2d_list">To {item.hard_expiry_limit}</p>
						</span>}
						{item.time_limits === packageLimits.softExpiry && <span className="font_16_medium_2c2c2d_list">
							<p className="font_16_medium_2c2c2d_list">For {getCountDays(item.soft_expiry_limit)} days</p>
						</span>}
					</Col>
				</Row>
				<PackageUsageLimit item={item} isMyOrdersPage={isMyOrdersPage} usageLeft={usageLeft}/>
				<Row className="pkg_category">
					<Col xs={{size: 10, offset: 2}}>
						<p className={`font_14_medium_2c2c2d_list ${ item.categories.length > 2 && "overflow"}`}>
								Category:&nbsp;&nbsp;&nbsp;&nbsp;
							{item.category_limits === categoryLimits.unlimited && <span className="font_14_regular_2c2c2d_list">
									Category unlimited
							</span>}
							{item.category_limits === categoryLimits.limited && <span className="font_14_regular_2c2c2d_list">
								{getCategoryNameForPackage(item.categories)}
							</span>}
						</p>
					</Col>
				</Row>
			</div>

			<Row className="pkg_control bg_row_btn border border_row_btn">
				<Col xs="2" className="margin_top_12px">
					<BsTagFill className="gliph_grey" />
				</Col>
				<Col xs="6" className="margin_top_12px">
					<p className=" display_inline_block font_14_medium_2c2c2d_list">
						Price:&nbsp;
						<span className="font_14_regular_2c2c2d_list">
							{item.price > 0 ? <span>&pound;{item.price}</span>: "Free"}
						</span>
					</p>
				</Col>
				<Col xs="4">
					{
						buy &&
						<Link to={frontendUrls.urlBookingPackagesId.replace(":id", item.id)}
							className="btn pkg_btn font_16_regular_white_list">
							Buy
						</Link>
					}
					{
						open &&
						<Link to={{ 
							pathname: frontendUrls.urlBookingPackagesId.replace(":id", item.id),
							state: { buy: false, item: item, columnOffset: 1, isMyOrdersPage, usageLeft }
						}}
						className="btn mbshp_btn font_16_regular_white_list">
							Open
						</Link>
					}
				</Col>
			</Row>
		</div>
	);
};


const BookingPackagesComponent = ({ bookingPackages, isFetching }) => {
	return (
		
		<Container className="mt-5">
			<p className="font_24_regular_black_list">Packages</p>
			{isFetching &&
				<div className="margin-top20p margin-left_auto margin-right_auto width20 height100 margin_bottom210">
					<img className="text-center" src="/images/ajax-loader.gif" alt="Loading..." height={150} />
				</div>
			}
			<div className="package_list">
				{
					!isFetching && 
					bookingPackages.map((package_item) => <PackageItem key={package_item.id} item={package_item}/>)
				}
			</div>
		</Container>
	);
};

BookingPackagesComponent.propTypes = {
	bookingPackages: PropTypes.arrayOf(PropTypes.object).isRequired,
	isFetching: PropTypes.bool.isRequired,
};

PackageItem.propTypes = {
	item: PropTypes.object.isRequired,
	buy: PropTypes.bool,
	open: PropTypes.bool,
	isMyOrdersPage: PropTypes.bool,
	usageLeft: PropTypes.number
};

export default BookingPackagesComponent;
