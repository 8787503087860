import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

import RegisterForm from "../forms/auth/register";
import frontendUrls from "../../routes/frontend_routes";


const RegisterComponent = ({ onSubmit, errors }) => {
	return (
		<Container fluid className="px-0">
			<Row className="width_320px mx-auto">
				<Col className="px-sm-1">
					<img
						className="ml-xs-1 ml-sm-3 height_90px margin_top_25"
						src="/static/logo_full.png"
						alt="Pozen logo" />
					<div className="px-xs-auto  register_field bg_white shadow border_radius_03rem margin_top_40px">
						<Row className="padding_top_20px mx-0">
							<Col xs={10} md={10} lg={10} className="px-0">
								<p className="text-center font_18_medium_616976">CREATE ACCOUNT</p>
							</Col>
							<Col xs={2} md={2} lg={2} className="px-0">
								<Link to={frontendUrls.urlRoot}>
									<span className="text-center sprite_cross margin_top_5px"></span>
								</Link>
							</Col>
						</Row>
						<hr className="margin_0"/>
						<div className="padding_left_7 padding_right_7">
							<RegisterForm onSubmit={onSubmit} errors={errors} />
						</div>
					</div>
					<div className="text-center fixed-bottom margin_top_25">
						<p className="font_14_regular_666f78">© 2020 POZEN. All Rights Reserved</p>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

RegisterComponent.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	errors: PropTypes.object
};

export default RegisterComponent;
