import React from "react";


const Spinner = () => {
	return(
		<div className="d-flex justify-content-center align-items-center h-100 w-100">
			<div className="spinner"></div>
		</div>
	);
};

const SmallSpinner = () => {
	return(
		<div className="small-spinner">
			<div className="small-spinner-intro">
				<div></div>
			</div>
		</div>
	);
};


export {
	Spinner, SmallSpinner
};