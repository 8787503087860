import React from "react";
import PropTypes from "prop-types";
import { Form, Field } from "react-final-form";
import { Link } from "react-router-dom";

import {
	Row, FormGroup, Col, Input, InputGroup, InputGroupText, InputGroupAddon, Button
} from "reactstrap";
import frontendUrls from "routes/frontend_routes";


const validate = values => {
	const errors = {};

	if (!values.firstName) {
		errors.firstName = "This field is required";
	}
	if (!values.lastName) {
		errors.lastName = "This field is required";
	}
	if (!values.email) {
		errors.email = "This field is required";
	}
	if (!values.password) {
		errors.password = "This field is required";
	}
	if (!values.birthday) {
		errors.birthday = "This field is required";
	}
	return errors;
};

const InputWithAddon = ({
	input,
	spriteName,
	inputType,
	placeholder,
	serverError,
	meta: {
		touched,
		error
	}
}) => {
	let classNameInput = "form-control";
	if((touched && error) || (serverError)) {
		classNameInput = "form-control is-invalid";
	}
	return (
		<FormGroup
			className="margin_0 padding_top_20px">
			<InputGroup className="mb-3">
				<InputGroupAddon className="light" addonType="prepend">
					<InputGroupText className="bg-white">
						<i className={spriteName}></i>
					</InputGroupText>
				</InputGroupAddon>
				<Input {...input} type={inputType} className={classNameInput} placeholder={placeholder} id={input.name} name={input.name} />
			</InputGroup>

			<div className="margin_0 padding_0">
				{touched && error && <p className="font_danger">{error}</p>}
				{serverError && <p className="font_danger">{serverError}</p>}
			</div>
		</FormGroup>
	);
};

InputWithAddon.propTypes = {
	input: PropTypes.object.isRequired,
	spriteName: PropTypes.string.isRequired,
	inputType: PropTypes.string.isRequired,
	placeholder: PropTypes.string.isRequired,
	serverError: PropTypes.array,
	meta: PropTypes.object.isRequired
};

let RegisterForm = (props) => {
	const { onSubmit, errors } = props;
	return (
		<Form
			validate={validate}
			onSubmit={onSubmit}
			render={({ handleSubmit, pristine, submitting }) => (
				<form className="margin_0" onSubmit={handleSubmit}>
					<FormGroup className="margin_0">
						<Field
							name="firstName"
							spriteName="sprite_user"
							type="text"
							inputType="text"
							placeholder="First Name"
							serverError={errors.first_name}
							component={InputWithAddon} />
						<Field
							name="lastName"
							spriteName="sprite_user"
							type="text"
							inputType="text"
							placeholder="Last Name"
							serverError={errors.last_name}
							component={InputWithAddon} />
						<Field
							name="email"
							spriteName="sprite_lock"
							type="email"
							inputType="email"
							placeholder="Email"
							serverError={errors.email}
							component={InputWithAddon} />
						<Field
							name="password"
							spriteName="sprite_lock"
							type="password"
							inputType="password"
							placeholder="Password"
							serverError={errors.password}
							component={InputWithAddon} />
						<Field
							name="birthday"
							spriteName="sprite_lock"
							type="date"
							inputType="date"
							placeholder="Birthday"
							serverError={errors.birthday}
							component={InputWithAddon} />
						<Row className="inline margin_0 padding_top_20px d-none">
							<Col xs={2} className="checkbox padding_left_right_0">
								<label>
									<Input type="checkbox" data-ng-model="example.check" />
									<span className="mr-4 position-relative box"></span>
								</label>
							</Col>
							<Col xs={10} className="px-0">
								<p className="font_14_regular_5e6977" href="#">
							Keep me updated about Pozen
								</p>
							</Col>
						</Row>
						<p className="margin_0 text-center font_11_regular_b4b4b4">
							By clicking &apos;Create account&apos; you confirm that you accept
							<a href="https://hopepilatescentre.com/tc/" target="_blank" rel="noreferrer"> Terms and Conditions policy</a>
						</p>
					</FormGroup>
					<div className="text-center margin_0 padding_top_20px">
						<Button
							disabled={submitting || pristine}
							className="primary margin_0 height_40px width_100 bg_button_clr border_color_button_clr font_16_medium border_radius_03rem"
							type="submit">
						Create Account
						</Button>
						<div className="margin_0 padding_top_20px padding_bottom_25px">
							<Link to={frontendUrls.urlLogin}>
								<p className="font_14px">Already have account? Log in</p>
							</Link>
						</div>
					</div>
				</form>
			)}>
		</Form>
	);
};
RegisterForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	errors: PropTypes.object
};

export default RegisterForm;