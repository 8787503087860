import {
	START_REGISTER,
	FINISHED_REGISTER,
	FAILED_REGISTER,
} from "../../actions/consts";


const initialState = {
	registerRoot: {
		isFetching: false,
		errors: {}
	}
};

export default function registerReducer(state = initialState, action) {
	switch (action.type) {

	// REDUCER REGISTRATION
	case START_REGISTER:
		return {
			...state,
			isFetching: true,
			errors: {}
		};

	case FINISHED_REGISTER:
		return {
			...state,
			isFetching: false,
			errors: {}
		};

	case FAILED_REGISTER:
		return {
			...state,
			isFetching: false,
			errors: action.errors
		};

	default:
		return state;
	}
}
