import {
	START_FETCHING_CUSTOMER,
	FINISHED_FETCHING_CUSTOMER,
	FAILED_FETCHING_CUSTOMER,
	START_EDIT_CUSTOMER,
	FINISHED_EDIT_CUSTOMER,
	FAILED_EDIT_CUSTOMER,
	START_DELETE_CUSTOMER,
	FINISHED_DELETE_CUSTOMER,
	FAILED_DELETE_CUSTOMER,
	RESET_EDIT_ERRORS,
	START_FETCHING_CUSTOMER_ORDERS,
	FINISHED_FETCHING_CUSTOMER_ORDERS,
	FAILED_FETCHING_CUSTOMER_ORDERS,
	START_DELETE_CUSTOMER_ORDER,
	FINISHED_DELETE_CUSTOMER_ORDER,
	FAILED_DELETE_CUSTOMER_ORDER
} from "actions/consts";


const getOrdersByType = (orders) => {
	return{
		packages: orders.filter((order) => order.product_type === "package"),
		memberships: orders.filter((order) => order.product_type === "membership"),
		events: orders.filter((order) => order.product_type === "event"),
	};
};


const initialState = {
	isFetching: false,
	selectedCustomer: {},
	errors: {},
	orders: {
		packages: [],
		memberships: [],
		events: [],
		isFetching: false
	}
};


const editCustomerReducer = (state = initialState, action) => {
	switch(action.type){
	case START_FETCHING_CUSTOMER:
		return{
			...state,
			isFetching: true,
			errors: {}
		};
	case FINISHED_FETCHING_CUSTOMER:
		return{
			...state,
			isFetching: false,
			selectedCustomer: action.data
		};
	case FAILED_FETCHING_CUSTOMER:
		return{
			...state,
			isFetching: false,
			errors: action.errors
		};
	case START_EDIT_CUSTOMER:
		return{
			...state,
			isFetching: true,
			errors: {}
		};
	case FINISHED_EDIT_CUSTOMER:
		return{
			...state,
			isFetching: false,
			selectedCustomer: action.data,
		};
	case FAILED_EDIT_CUSTOMER:
		return{
			...state,
			isFetching: false,
			errors: action.errors
		};
	case START_DELETE_CUSTOMER:
		return{
			...state,
			isFetching: true,
			errors: {}
		};
	case FINISHED_DELETE_CUSTOMER:
		return{
			...state,
			isFetching: false,
			selectedCustomer: {}
		};
	case FAILED_DELETE_CUSTOMER:
		return{
			...state,
			isFetching: false,
			errors: action.errors
		};
	case RESET_EDIT_ERRORS:
		return{
			...state,
			errors: {}
		};
	case START_FETCHING_CUSTOMER_ORDERS:
		return{
			...state,
			orders:{
				...state.orders,
				isFetching: true
			}
		};
	case FINISHED_FETCHING_CUSTOMER_ORDERS:
		return{
			...state,
			orders: {
				...getOrdersByType(action.orders),
				isFetching: false
			}
		};
	case FAILED_FETCHING_CUSTOMER_ORDERS:
		return{
			...state,
			errors: action.errors,
			orders: {
				...state.orders,
				isFetching: false
			}
		};
	case START_DELETE_CUSTOMER_ORDER:
		return{
			...state,
			orders: {
				...state.orders,
				isFetching: true
			}
		};
	case FINISHED_DELETE_CUSTOMER_ORDER:
		return{
			...state,
			orders: {
				...state.orders,
				isFetching: false
			}
		};
	case FAILED_DELETE_CUSTOMER_ORDER:
		return{
			...state,
			errors: action.errors,
			orders: {
				...state.orders,
				isFetching: false
			}
		};
	default:
		return state;
	}
};


export default editCustomerReducer;