import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import fetchMyOrders from "actions/orders/orders";
import {fetchFacilityPromo} from "actions/facilities/facility_promo";
import OrdersComponent from "components/orders/orders";
import {Spinner} from "components/utils/spinner";

import { history } from "store";
import frontendUrls from "routes/frontend_routes";


class MyOrdersContainer extends React.Component {

	componentDidMount() {
		const { authToken, dispatch } = this.props;
		if (!authToken) {
			history.push(frontendUrls.urlLogin);
		} else {
			dispatch(fetchMyOrders(false));
			dispatch(fetchMyOrders(true));
			dispatch(fetchFacilityPromo());
			history.push(frontendUrls.urlMyOrders);
		}
	}

	render() {
		const { orders, expired, calendar, promo, isPromoFetching } = this.props;
		const { events, packages, memberships } = orders;
		const {
			events: expired_events,
			packages: expired_packages,
			memberships: expired_memberships
		} = expired;

		let sendProps = {
			events, packages, memberships, calendar, promo,
			expired_events, expired_packages, expired_memberships
		};
		return isPromoFetching ? <Spinner/>: <OrdersComponent {...sendProps} />;
	}
}

MyOrdersContainer.propTypes = {
	dispatch: PropTypes.func.isRequired,
	orders: PropTypes.object.isRequired,
	authToken: PropTypes.string.isRequired,
	role: PropTypes.number.isRequired,
	expired: PropTypes.object.isRequired,
	calendar: PropTypes.object.isRequired,
	promo: PropTypes.object,
	isPromoFetching: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		calendar: state.ordersRoot.calendar || [],
		orders: state.ordersRoot.orders || [],
		expired: state.ordersRoot.expired || [],
		authToken: state.root.authToken || undefined,
		role: state.root.role || 0,
		promo: state.facilityPromoRoot.promo || {},
		isPromoFetching: state.facilityPromoRoot.isFetching || false
	};
};

const mapDispatchToProps = (dispatch) => {
	return { dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyOrdersContainer);
