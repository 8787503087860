import React from "react";
import PropTypes from "prop-types";
import { Form, Field } from "react-final-form";
import { Col, Row, Container } from "reactstrap";
import { FaCalendarAlt, FaClock, FaPoundSign } from "react-icons/fa";

import { eventRepeatChoices, WEEKLY, MONTHLY } from "backend_constants/eventRepeat";
import weekDays from "backend_constants/weekDays";
import frontendUrls from "routes/frontend_routes";

import {
	capitalizeFirstLetter, checkboxField, fieldInput,
	fieldWithLabelAndSelect, fieldInputWithAddon, fieldTextarea
} from "components/forms/fields";
import ManageFormComponent from "components/forms/utils";
import { withCategories } from "components/utils/hoc";
import { getDateTime, getInitialEventDateValue } from "components/utils";


const validate = values => {
	const errors = {};
	if (!values.title) {
		errors.title = "This field is required";
	}
	if (!values.category) {
		errors.category = "This field is required";
	}
	if (!values.instructor) {
		errors.instructor = "This field is required";
	}
	if (!values.location) {
		errors.location = "This field is required";
	}
	if (!values.fromDate) {
		errors.fromDate = "This field is required";
	}
	if (!values.toDate) {
		errors.toDate = "This field is required";
	}
	if (!values.fromTime) {
		errors.fromTime = "This field is required";
	}
	if (!values.toTime) {
		errors.toTime = "This field is required";
	}
	if (!values.capacity || values.capacity < 1) {
		errors.capacity = "This field is required and can't be less then 1";
	}
	if (getDateTime(values.fromDate) > getDateTime(values.toDate)){
		errors.fromDate = "Start date can't be greater than end date";
	}
	if (getDateTime("", values.fromTime) > getDateTime("", values.toTime)){
		errors.fromTime = "Start time can't be greater than end time";
	}
	if (getDateTime("", values.fromTime) > getDateTime("", values.toTime)){
		errors.fromTime = "Start time can't be greater than end time";
	}
	if (!values.price && !values.free) {
		errors.price = "This field is required";
	}
	if (parseFloat(values.price) === 0 && !values.free){
		errors.price = "Please, mark event as free";
	}
	return errors;
};

let EventForm = ({
	onSubmit, onDelete, resetEventErrors, errors,
	edit, initialValues, categories, isTimetableEditable
}) => {

	const initFieldsValues = {
		...initialValues,
		fromDate: getInitialEventDateValue(initialValues.fromDate),
		toDate: getInitialEventDateValue(initialValues.toDate)
	};


	return (
		<Container className="bg_white shadow_form border_radius_03rem mb-5">
			<Form
				validate={validate}
				onSubmit={onSubmit}
				initialValues={initFieldsValues}
				render={({ handleSubmit, pristine, values, submitting }) => (
					<form className="bg_white" onSubmit={handleSubmit}>
						<Row>
							<Col xs={12} sm={12} md={12} lg={12} xl={6}>
								<Field
									name="title"
									classNameLabel="mt-3"
									label="Name"
									inputType="text"
									type="text"
									placeholder="Name of the event"
									serverError={errors.title}
									component={fieldInput} />
							</Col>
							<Col xs={12} sm={12} md={12} lg={12} xl={6}>
								<Field
									name="capacity"
									classNameLabel="mt-3"
									label="Capacity"
									inputType="number"
									type="number"
									placeholder="1"
									serverError={errors.capacity}
									component={fieldInput} />
							</Col>
						</Row>
						<Row>
							<Col xs={12} sm={12} md={12} lg={12} xl={6}>
								<Field
									name="instructor"
									classNameLabel="mt-5"
									label="Instructor"
									inputType="text"
									type="text"
									placeholder="Instructor name"
									serverError={errors.instructor}
									component={fieldInput} />
							</Col>
							<Col xs={12} sm={12} md={12} lg={12} xl={6}>
								<Field
									name="location"
									classNameLabel="mt-5"
									label="Location"
									inputType="text"
									type="text"
									placeholder="London"
									serverError={errors.location}
									component={fieldInput} />
							</Col>
						</Row>
						<Row>
							<Col xs={12} sm={12} md={12} lg={12} xl={6}>
								<Field
									name="category"
									classNameFormGroup="mt-5 mb-5"
									label="Category"
									serverError={errors.category}
									choices={categories}
									type="select"
									component={fieldWithLabelAndSelect} />
								<Row noGutters className="d-flex flex-column">
									<p className="d-flex align-items-center d-md-flex mb-2">Date</p>
									<Row>
										<Col xs={12} md={6}>
											<Field
												name="fromDate"
												classNameFormGroup="form-group"
												label="Date"
												classNameLabel="d-none"
												classNameInputGroup="ml-auto mt-md-2"
												inputType="date"
												type="date"
												placeholder="Start Date: DD-MM-YYYY"
												serverError={errors.from_date}
												sprite={<FaCalendarAlt />}
												component={fieldInputWithAddon} />
										</Col>
										<Col xs={12} sm={12} md={6}>
											<Field
												name="toDate"
												inputType="date"
												type="date"
												classNameFormGroup="form-group"
												classNameInputGroup="ml-auto mt-md-2"
												classNameLabel="d-none"
												placeholder="End Date: DD-MM-YYYY"
												sprite={<FaCalendarAlt />}
												serverError={errors.to_date}
												component={fieldInputWithAddon} />
										</Col>
									</Row>
								</Row>
								<Row noGutters className="d-flex flex-column">
									<p className="d-flex align-items-center d-md-flex mb-2">Time</p>
									<Row>
										<Col xs={12} sm={12} md={6}>
											<Field
												classNameLabel="d-none"
												classNameInputGroup="ml-auto mt-md-2"
												name="fromTime"
												inputType="text"
												type="text"
												placeholder="Start Time: 8:00"
												serverError={errors.from_time}
												sprite={<FaClock />}
												component={fieldInputWithAddon} />
										</Col>
										<Col xs={12} sm={12} md={6}>
											<Field
												name="toTime"
												inputType="text"
												type="text"
												classNameLabel="d-none"
												classNameInputGroup="ml-auto mt-md-2"
												placeholder="End Time: 16:00"
												sprite={<FaClock />}
												serverError={errors.to_time}
												component={fieldInputWithAddon} />
										</Col>
									</Row>
								</Row>
							</Col>
							<Col xs={12} sm={12} md={12} lg={12} xl={6}>
								<Field
									classNameLabel="mt-5"
									label="Description"
									name="description"
									inputType="textarea"
									type="textarea"
									placeholder="Text"
									componentClass="textarea"
									className="textarea_resize_none"
									rows="5"
									serverError={errors.description}
									component={fieldTextarea} />
							</Col>
						</Row>
						<Row>
							<Col xs={12} sm={12} md={12} lg={12} xl={6}>
								<Row className="mt-5">
									<Col xs={12} md={6} lg={6}>
										<Field
											name="price"
											inputType="number"
											type="number"
											label="Price"
											classNameLabel="col-2 p-0"
											classNameInputGroup="col-10 pl-2 pr-0"
											classNameFormGroup="form-inline mb-0"
											sprite={<FaPoundSign />}
											placeholder="£"
											serverError={errors.price}
											disabled={values.free}
											component={fieldInputWithAddon}/>
									</Col>
									<Col xs={12} md={2} lg={2}
										className="d-flex align-items-start justify-content-lg-end pt-2 pr-md-0 pl-md-0">
										<Field
											name="free"
											label="Free"
											classNameLabel="ml-2 ml-xl-0"
											classNameFormGroup="form-inline pl-sm-0 pl-md-2"
											type="checkbox"
											component={checkboxField} />
									</Col>
									<Col xs={12} md={4} lg={4}
										className="d-flex align-items-start justify-content-lg-end pt-2 pr-md-0 pl-md-0">
										<Field
											name="payAtVenue"
											label="Pay at venue"
											classNameLabel="ml-2 ml-xl-0 mr-lg-3"
											classNameInputGroup="ml-auto ml-xl-0"
											classNameFormGroup="form-inline pl-xl-0 pl-sm-0"
											type="checkbox"
											component={checkboxField} />
									</Col>
								</Row>
								<Row className="mt-2 mt-md-5">
									{
										edit && !isTimetableEditable &&
										<Col xs={12} className="mb-3">
											<p className="font_danger">If you edit this event repeat settings,
											the bought orders will be marked as deleted, but no auto refund will happen.
											Please consider this before proceeding.</p>
										</Col>
									}
									<Col xs={12}>
										<Field
											name="repeats"
											label="This event repeats"
											classNameLabel="ml-2"
											type="checkbox"
											component={checkboxField} />
									</Col>
								</Row>
								<Row noGutters>
									{values.repeats && <Col xs={12}><Field
										classNameLabel="mt-3"
										name="repeatChoice"
										label="Repeat"
										type="select"
										serverError={errors.repeat_times}
										choices={eventRepeatChoices}
										component={fieldWithLabelAndSelect} />
									</Col>}
								</Row>
								<Row noGutters>
									{values.repeats && values.repeatChoice === WEEKLY && (
										<>
											{weekDays.map(
												(dayArray, i) =>
													<Col xs={12} sm={6} md={3} lg={1} xl={3} key={i}>
														<Field
															classNameFormGroup="mt-3"
															name={dayArray[1]}
															label={capitalizeFirstLetter(dayArray[1])}
															type="checkbox"
															component={checkboxField}
														/>
													</Col>
											)}
										</>
									)}
									{values.repeats && values.repeatChoice === MONTHLY && ( <Col xs={12}>
										<Field
											classNameLabel="mt-3"
											name="daysOfTheMonth"
											label="Selected days of the month"
											inputType="text"
											type="text"
											placeholder="Comma-separated days, eg: 1,3,4,7 etc"
											component={fieldInput} />
									</Col>)
									}
									{
										errors.repeat && <p className="font_danger">{errors.repeat}</p>
									}
								</Row>
							</Col>
							<Col xs={12} sm={12} md={12} lg={12} xl={6}>
								<Field
									label="Cancellation policy"
									name="cancellationPolicy"
									inputType="textarea"
									type="textarea"
									placeholder="Text"
									componentClass="textarea"
									className="textarea_resize_none"
									classNameLabel="mt-5"
									rows="4"
									serverError={errors.cancellation_policy}
									component={fieldTextarea} />
							</Col>
						</Row>
						<ManageFormComponent
							errors={errors}
							isSubmitedForm={submitting}
							isPristineForm={pristine}
							isEditForm={edit}
							cancelLink={frontendUrls.urlDashboardEvents}
							product="event"
							onDelete={onDelete}
							resetComponentErrors={resetEventErrors}/>
					</form>
				)}>
			</Form>
		</Container>
	);
};

EventForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	onDelete: PropTypes.func,
	resetEventErrors: PropTypes.func,
	errors: PropTypes.object,
	edit: PropTypes.bool,
	initialValues: PropTypes.object,
	categories: PropTypes.array.isRequired,
	isTimetableEditable: PropTypes.bool.isRequired
};

export default withCategories(EventForm);