import React, { useState } from "react";
import PropTypes from "prop-types";

import { Container, Row, Col, Collapse } from "reactstrap";
import { FaAngleRight, FaAngleDown } from "react-icons/fa";
import { ManageItem } from "components/packages/packages";
import CustomerDetails from "components/customers/customers_details";

import frontendUrls from "routes/frontend_routes";
import { getFullName } from "components/utils";


const RelatedCustomer = ({item}) => {
	const editUrl = frontendUrls.urlDashboardCustomerEdit.replace(":id", item.id);
	const { first_name, last_name, email } = item;

	const [show, openDetails] = useState(false);

	return(
		<>
			<Row className="rel_customer" onClick={() => openDetails(!show)}>
				<Col xs={7} md={4} className="rel_customer_name">
					<p className="font_16_medium_black_list mb-0">
						{getFullName(first_name, last_name)}
					</p>
				</Col>
				<Col xs={5} className="rel_customer_email d-none d-sm-none d-md-flex">
					<p className="font_16_medium_a0a2a7_list mb-0">{email}</p>
				</Col>
				<Col xs={3}  md={3} className="rel_customer_status">
					<p className="font_16_medium_a0a2a7_list mb-0">ACTIVE</p>
				</Col>
				<Col xs={2} className="rel_customer_manage d-none d-sm-none d-md-none">
					<ManageItem item={item} editUrl={editUrl} color="grey"/>
				</Col>
				<Col xs={2} className="rel_customer_details d-flex d-sm-flex d-md-none">
					{ 
						show ? 
							<FaAngleDown className="gliph_grey gliph_18px"/> :
							<FaAngleRight className="gliph_grey gliph_18px"/>
					}
				</Col>
			</Row>
			<div className="d-block d-sm-block d-md-none pb-2">
				<Collapse isOpen={show}>
					<CustomerDetails item={item}/>
					<Row className="d-flex justify-content-end">
						<Col xs={4}>
							<ManageItem item={item}
								editUrl={editUrl}
								color="blue"/>
						</Col>
					</Row>
				</Collapse>
			</div>
		</>
	);
};


const RelatedCustomers = ({customers}) => {
	return(
		<Container className="pl-0 pr-0 pl-md-3 pr-md-3 pl-lg-3 pr-lg-3 pb-3 mt-3">
			<Row className="font_16_medium_a0a2a7_list d-none d-sm-none d-md-flex">
				<Col xs={4} className="rel_customers_name">Name</Col>
				<Col xs={5} className="rel_customers_email">Email</Col>
				<Col xs={3} className="rel_customers_status">Status</Col>
				<Col xs={2} className="d-none rel_customers_manage">Manage</Col>
			</Row>
			<div className="rel_customers">
				{
					customers.map((item) => <RelatedCustomer item={item} key={item.id}/>)
				}
			</div>
		</Container>
	);
};


RelatedCustomers.propTypes = {
	customers: PropTypes.arrayOf(PropTypes.object).isRequired,
};


RelatedCustomer.propTypes = {
	item: PropTypes.object.isRequired
};


export default RelatedCustomers;