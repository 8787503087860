import {
	GET_BOOKING_EVENTS,
	RECEIVE_BOOKING_EVENTS
} from "actions/consts";


const getShowBookingEvents = (current, loadMore, page) => {
	if (current !== [] && page > 1){
		return current.concat(loadMore);
	}
	return loadMore;
};


const initialState = {
	isFetching: false,
	bookingEvents: {
		results: [],
	},
	eventListToShow: []
};


export default function bookingEventsReducer(state = initialState, action) {
	switch (action.type) {
	// REDUCER BOOKING EVENTS

	case GET_BOOKING_EVENTS:
		return {
			...state,
			isFetching: true
		};

	case RECEIVE_BOOKING_EVENTS:{
		const { bookingEvents: { results: current }} = state;
		const { bookingEvents: { results: loadMore }, page } = action;
		return {
			...state,
			isFetching: false,
			bookingEvents: action.bookingEvents,
			eventListToShow: getShowBookingEvents(current, loadMore, page),
			lastUpdated: action.receivedAt,
		};
	}

	default:
		return state;
	}
}
