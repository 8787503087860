import React from "react";
import PropTypes from "prop-types";

import { Container, Row, Col } from "reactstrap";

import { getDateFromString } from "components/utils";
import { MALE } from "backend_constants/userTypes";


const CustomerInfo = ({name, value}) => {
	return(
		<Row>
			<Col xs={5}>
				<p className="font_14_regular_2c2c2d_list mb-1">{name}:</p>
			</Col>
			<Col xs={7}>
				<p className="font_14_regular_2c2c2d_list mb-1 text-truncate">{value}</p>
			</Col>
		</Row>
	);
};


const CustomerDetails = ({item}) => {
	const { email } = item;
	const { username, birthday, sex, address } = item.user || {};
	const details = [
		{name: "Email", value: email},
		{name: "Username", value: username || "-"},
		{name: "Address", value: address || "-"},
		{name: "Sex", value: sex ? sex === MALE ? "Male" : "Female" : "-"},
		{name: "Birthday", value: getDateFromString(birthday)},
	];

	return(
		<Container className="mt-3 mb-3 p-0">
			{
				details.map((info, idx) => <CustomerInfo {...info} key={item.id + idx}/>)
			}
		</Container>
	);
};


CustomerDetails.propTypes = {
	item: PropTypes.object.isRequired,
};


CustomerInfo.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
};


export default CustomerDetails;