import React from "react";
import PropTypes from "prop-types";

import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Portal } from "react-portal";


const ModalComponent = ({isOpen, header, body, children, extraProps}) => {
	return(
		<Portal>
			<Modal isOpen={isOpen} {...extraProps}>
				<ModalHeader tag="p"className="font_16_medium_2c2c2d_list p-3">
					{ header }
				</ModalHeader>
				<ModalBody className="font_16_regular_2c2c2d_list">
					{ body }
				</ModalBody>
				<ModalFooter className="font_16_regular_white p-1">
					{ children }
				</ModalFooter>
			</Modal>
		</Portal>
	);
};


ModalComponent.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	header: PropTypes.any.isRequired,
	body: PropTypes.any.isRequired,
	children: PropTypes.node.isRequired,
	extraProps: PropTypes.object
};


export default ModalComponent;