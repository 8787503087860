import React from "react";
import PropTypes from "prop-types";
import { Row, Col, ButtonGroup, Button } from "reactstrap";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { navigate } from "components/calendar/config/consts";


const CalendarViews = ({view, views, onView, messages}) => {
	if(views.length > 1){
		return(
			<ButtonGroup>
				{
					views.map((name, idx) => (
						<Button key={idx} color="" 
							className={ `rbc-toolbar-view ${view === name ? "active": ""}`}
							onClick={() => onView(name)}>
							{messages[name]}
						</Button>
					))
				}
			</ButtonGroup>
		);
	}
};


CalendarViews.propTypes = {
	view: PropTypes.string.isRequired,
	views: PropTypes.arrayOf(PropTypes.string).isRequired,
	messages: PropTypes.object.isRequired,
	onView: PropTypes.func.isRequired
};


const CalendarDateNavigator = ({onNavigate, label}) => {

	return(
		<Row className="w-100">
			<Col xs={3} className="d-flex justify-content-end">
				<FaAngleLeft className="rbc-nav-prev" onClick={() => onNavigate(navigate.PREVIOUS)}/>
			</Col>
			<Col xs={6} className="d-flex justify-content-center">
				<p className="font_16_regular_2c2c2d_list mb-0 text-center">
					{label}
				</p>
			</Col>
			<Col xs={3} className="d-flex justify-content-start">
				<FaAngleRight className="rbc-nav-next text-left" onClick={() => onNavigate(navigate.NEXT)}/>
			</Col>
		</Row>
	);
};


CalendarDateNavigator.propTypes = {
	onNavigate: PropTypes.func.isRequired,
	label: PropTypes.string.isRequired
};


export const CustomerToolbarComponent = ({label, localizer: {messages}, views, view, onView, onNavigate}) => {
	const viewsProps = { view, views, onView, messages };
	const navProps = { label, onNavigate };
	return(
		<Row className="rbc-toolbar mb-3 mt-3">
			<Col lg={4} className="justify-content-start d-none d-md-none d-lg-flex">
				<p className="font_18_medium_2c2c2d_list mb-0">My Events, Packages, Membership</p>
			</Col>
			<Col lg={4} md={6} className="rbc-navigator justify-content-center">
				<CalendarDateNavigator {...navProps}/>
			</Col>
			<Col lg={4} md={6} className="rbc-views justify-content-center justify-content-md-end mt-2 mt-md-0">
				<CalendarViews {...viewsProps}/>
			</Col>
		</Row>
	);
};


CustomerToolbarComponent.propTypes = {
	label: PropTypes.string.isRequired,
	onNavigate: PropTypes.func.isRequired,
	localizer: PropTypes.object.isRequired,
	view: PropTypes.string.isRequired,
	views: PropTypes.arrayOf(PropTypes.string).isRequired,
	onView: PropTypes.func.isRequired
};


export const DashboardToolbarComponent = ({label, localizer: {messages}, views, view, onView, onNavigate}) => {
	const viewsProps = { view, views, onView, messages };
	const navProps = { label, onNavigate };
	return(
		<Row className="rbc-toolbar mb-3 mt-3">
			<Col md={6} className="rbc-navigator justify-content-center">
				<CalendarDateNavigator {...navProps}/>
			</Col>
			<Col md={6} className="rbc-views justify-content-center justify-content-md-end mt-2 mt-md-0">
				<CalendarViews {...viewsProps}/>
			</Col>
		</Row>
	);
};


DashboardToolbarComponent.propTypes = {
	label: PropTypes.string.isRequired,
	onNavigate: PropTypes.func.isRequired,
	localizer: PropTypes.object.isRequired,
	view: PropTypes.string.isRequired,
	views: PropTypes.arrayOf(PropTypes.string).isRequired,
	onView: PropTypes.func.isRequired
};
