import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { Container, Row, Col } from "reactstrap";
import ResetPasswordForm from "components/forms/auth/reset_password";
import frontendUrls from "routes/frontend_routes";
import { resetErrorsFromPassword } from "actions/auth/reset_password";


const ResetPasswordComponent = ({onSubmit, errors, dispatch}) => {

	const resetErrors = () => {
		dispatch(resetErrorsFromPassword());
	};
	
	return(
		<Container fluid className="px-0">
			<Row className="width_320px mx-auto">
				<Col className="px-sm-1">
					<img
						className="ml-sm-3 height_90px margin_top_25"
						src="/static/logo_full.png"
						alt="Pozen logo" />
					<div className="login_field bg_white shadow border_radius_03rem margin_top_40px">
						<Row className="padding_top_20px mx-0">
							<Col xs={10} md={10} lg={10} className="px-0">
								<p className="text-center font_18_medium_616976">RESET PASSWORD</p>
							</Col>
							<Col xs={2} md={2} lg={2} className="px-0">
								<Link to={frontendUrls.urlLogin} onClick={resetErrors}>
									<span className="text-center sprite_cross margin_top_5px"></span>
								</Link>
							</Col>
						</Row>
						<hr className="m-0"/>
						<div className="pl-4 pr-4 pb-4">
							<p className="margin_0 padding_top_25px font_14_regular_86939e">Please, enter your actual email and we&#39;ll send to this one email with reset link.</p>
							<ResetPasswordForm onSubmit={onSubmit} errors={errors} />
						</div>
					</div>
					<div className="text-center fixed-bottom">
						<p className="font_14_regular_666f78">© 2020 POZEN. All Rights Reserved</p>
					</div>
				</Col>
			</Row>
		</Container>
	);
};


ResetPasswordComponent.propTypes = {
	errors: PropTypes.object.isRequired,
	onSubmit: PropTypes.func.isRequired,
	dispatch: PropTypes.func.isRequired
};


export default ResetPasswordComponent;