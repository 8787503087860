import {
	START_GET_BOOKING_MEMBERSHIP,
	FINISH_GET_BOOKING_MEMBERSHIP,
	FAIL_GET_BOOKING_MEMBERSHIP
} from "actions/consts";
import { request } from "actions/utils";


// Processing fetching membership
const startFetchingBookingMembership = () => {
	return { type: START_GET_BOOKING_MEMBERSHIP };
};

const finishFetchingBookingMembership = (data) => {
	return {
		type: FINISH_GET_BOOKING_MEMBERSHIP,
		data: data,
		receivedAt: Date.now()
	};
};

const failedFetchingBookingMembership = (data) => {
	return {
		type: FAIL_GET_BOOKING_MEMBERSHIP,
		errors: data,
		receivedAt: Date.now()
	};
};

export const fetchSelectedBookingMembership = (apiUrlSelectedMembership) => {
	return (dispatch, getState) => {
		dispatch(startFetchingBookingMembership);
		const { root: { authToken }} = getState();
		const config = {
			method: "GET",
			isAuth: authToken
		};

		const response = request(apiUrlSelectedMembership, config);

		return response
			.then((json) => {
				dispatch(finishFetchingBookingMembership(json));
			})
			.catch((error) => {
				error.response.json()
					.then((json) => {
						dispatch(failedFetchingBookingMembership(json));
					});
			});
	};
};