import React, {Component} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Elements } from "react-stripe-elements";

import { Membership } from "components/booking/memberships/membershipDetail";
import OrderCard from "components/booking/orders/order";
import OrderContainer from "containers/booking/orders/order";

import apiUrls from "routes/api";
import frontendUrls from "routes/frontend_routes";
import { buyBookingMembership, failBuyBookingMembership } from "actions/booking/memberships/membershipBuy";
import { fetchSelectedBookingMembership } from "actions/booking/memberships/membershipDetail";
import { fetchCustomerOrders } from "actions/orders/orders";
import { stripeLocale } from "consts";


class MembershipCheckout extends Component {

	componentDidMount(){
		const { orderMembership, dispatch, match } = this.props;
		const id = match.params.id;
		dispatch(fetchCustomerOrders());
		if(orderMembership.id !== id) {
			dispatch(fetchSelectedBookingMembership(apiUrls.bookMembershipDetailUrl.replace(":id", id)));
		}
	}

	render(){
		const { authToken, stripeToken, dispatch, orderMembership, errors, loading, isFirstPurchase } = this.props;
		const apiUrlBooking = apiUrls.orderMembershipsUrl.replace(":id", orderMembership.id);

		const orderProps = {
			isFirstPurchase,
			authToken,
			dispatch,
			errors,
			token: stripeToken,
			redirect: frontendUrls.urlMyOrder,
			orderType: "membership",
		};

		const orderCardProps = {
			item: orderMembership,
			dispatch: dispatch,
			token: stripeToken,
			order:{
				url: apiUrlBooking,
				action: buyBookingMembership,
				fail: failBuyBookingMembership
			},
			loading,
			errors,
			isFirstPurchase
		};

		return (
			<OrderContainer {...orderProps}>
				<Elements locale={stripeLocale}>
					<OrderCard {...orderCardProps}>
						<Membership item={orderMembership} buy={false}/>
					</OrderCard>
				</Elements>
			</OrderContainer>
		);
	}
}


MembershipCheckout.propTypes = {
	authToken: PropTypes.string.isRequired,
	match: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	errors: PropTypes.array,
	orderMembership: PropTypes.object.isRequired,
	stripeToken: PropTypes.string,
	loading: PropTypes.bool.isRequired,
	isFirstPurchase: PropTypes.bool.isRequired
};


const mapStateToProps = (state) => {
	return {
		authToken: state.root.authToken || undefined,
		isFirstPurchase: state.ordersRoot.isFirstPurchase,
		errors: state.orderMembershipRoot.errors || [],
		orderMembership: state.bookMembershipDetailRoot.selectedBookingMembership || {},
		stripeToken: state.orderMembershipRoot.createdOrder.stripe_token || "",
		loading: state.bookMembershipDetailRoot.isFetching || false,
	};
};

const mapDispatchToProps = (dispatch) => {
	return { dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(MembershipCheckout);