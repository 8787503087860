import {
	GET_CALENDAR_EVENTS,
	RECEIVE_CALENDAR_EVENTS
} from "actions/consts";
import { request } from "actions/utils";
import { formatDateTime } from "components/utils";
import api from "routes/api";


const getCalendarEvents = () => {
	return { type: GET_CALENDAR_EVENTS };
};


const receivedCalendarEvents = (data) => {
	return{
		type: RECEIVE_CALENDAR_EVENTS,
		events: data.results,
		receivedAt: Date.now()
	};
};


export const fetchCalendarEvents = (page_size=100, page=1, mode="month", startDate=new Date(), endDate=null) => {
	return (dispatch, getState) => {
		dispatch(getCalendarEvents());
		const { root: { authToken }} = getState();
		let config = {
			isAuth: authToken,
			method: "GET",
			params: {
				page_size,
				page,
				mode,
				date: formatDateTime(startDate)
			}
		};

		if(endDate){
			config.params.range_date = formatDateTime(endDate);
		}

		const response = request(api.filterEventsUrl, config);

		return response
			.then((data) => dispatch(receivedCalendarEvents(data)))
			.catch((error) => console.log("An error occured.", error));
	};
};
