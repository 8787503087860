const [FREE_EVENT, STRIPE, PAY_AT_VENUE, CHECKIN_PACKAGE, CHECKIN_MEMBERSHIP] = [0, 1, 2, 3, 4, 5];

export const paidWithChoices = [
	"Free event",
	"Paid",
	"Pay at venue",
	"Paid with package credit",
	"Paid with membership credit",
];

export const paidWith = {
	free: FREE_EVENT,
	stripe: STRIPE,
	payAtVenue: PAY_AT_VENUE,
	checkinPackage: CHECKIN_PACKAGE,
	checkinMembership: CHECKIN_MEMBERSHIP
};
