import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Col, Row, Container, Collapse } from "reactstrap";

import { MembershipItem } from "components/booking/memberships/memberships";
import { PackageItem } from "components/booking/packages/packages";
import EventItem from "components/booking/events/event";
import { CustomerCalendar } from "components/calendar";

import frontendUrls from "routes/frontend_routes";


const ExpiredOrderElement = ({title, children}) => {

	const [show, openExpired] = useState(false);
	const [hasExpired, setExpired] = useState(false);

	useEffect(() => setExpired(children.length > 0));

	const showExpired = () => {
		openExpired(!show);
		const expired = document.getElementById(`collapse-${title}`);
		expired.scrollIntoView({behavior: "smooth"});
	};

	return(
		<>
			<Row className="m-0 mb-3">
				<a href={`#collapse-${title}`}
					className="btn-link font_14_regular_2c2c2d_list p-0 m-0"
					onClick={showExpired}>
					{show ? "Hide" : "Show"} my expired <span className="text-lowercase">{title}</span>
				</a>
			</Row>
			<Collapse isOpen={show} id={`collapse-${title}`}>
				<Row className="m-0">
					{
						hasExpired ? children :
							<p className="font_14_regular_2c2c2d_list">
								You have not any expired
								<span className="text-lowercase"> {title}</span>
							</p>
					}
				</Row>
			</Collapse>
		</>
	);
};


const OrderElement = ({title, buyPage, children}) => {

	return(
		<>
			<Row>
				<Col xs={12}>
					<p className="font_18_medium_2c2c2d_list">
						My {title}
					</p>
				</Col>
			</Row>
			<Row className="m-0 mb-1">
				{
					children.length > 0 ? children :
						<Link to={buyPage} className="btn-link font_14_regular_2c2c2d_list">Go to booking page</Link>
				}
			</Row>
		</>
	);
};


const MyOrderPackages = ({packages, expired}) => {

	return(
		<>
			<OrderElement title="Packages"
				buyPage={frontendUrls.urlBookingPackages}>
				{
					packages.map(({product, product_usage_left}) => 
						<PackageItem key={product.id}
							item={product}
							buy={false}
							open={true}
							isMyOrdersPage={true}
							usageLeft={product_usage_left}/>)
				}
			</OrderElement>
			<ExpiredOrderElement title="Packages">
				{
					expired.map(({product, product_usage_left}) => 
						<PackageItem key={product.id}
							item={product}
							buy={false}
							open={true}
							isMyOrdersPage={true}
							usageLeft={product_usage_left}/>) 
				}
			</ExpiredOrderElement>
		</>
	);
};


const MyOrderEvents = ({events, expired}) => {

	return(
		<>
			<OrderElement title="Events"
				buyPage={frontendUrls.urlBookingEvents}>
				{
					events.map(({product}) => 
						<EventItem key={product.id}
							item={product}
							buy={false}
							open={true}/>)
				}
			</OrderElement>
			<ExpiredOrderElement title="Events">
				{
					expired.map(({product}) => 
						<EventItem key={product.id}
							item={product}
							buy={false}
							open={true}/>)
				}
			</ExpiredOrderElement>
		</>
	);
};


const MyOrderMemberships = ({memberships, expired}) => {

	return(
		<>
			<OrderElement title="Memberships"
				buyPage={frontendUrls.urlBookingMemberships}>
				{
					memberships.map(({product, product_usage_left}) => 
						<MembershipItem key={product.id}
							item={product}
							buy={false}
							open={true}
							isMyOrdersPage={true}
							usageLeft={product_usage_left}/>)
				}
			</OrderElement>
			<ExpiredOrderElement title="Memberships">
				{
					expired.map(({product, product_usage_left}) => 
						<MembershipItem key={product.id}
							item={product}
							buy={false}
							open={true}
							isMyOrdersPage={true}
							usageLeft={product_usage_left}/>)
				}
			</ExpiredOrderElement>
		</>
	);
};


const MediaComponent = ({title, image, children}) => {
	return(
		<Row className="pt-3 mb-3">
			<Col xs={12} md={12} lg={6}>
				<p className="font_18_medium_2c2c2d_list">{title}</p>
				<p className="font_14_regular_2c2c2d_list">{children}</p>
			</Col>
			<Col xs={12} md={12} lg={6}>
				<img className="img-fluid" src={image} alt="Dashboard" />
			</Col>
		</Row>
	);
};


const OrdersComponent = ({ 
	events, packages, memberships, calendar, promo,
	expired_events, expired_packages, expired_memberships }) => {

	return (
		<Container className="mt-3 mb-5">
			{
				promo.title && promo.image && promo.body &&
					<MediaComponent title={promo.title} image={promo.image}>
						{promo.body}
					</MediaComponent>
			}
			<MyOrderEvents events={events} expired={expired_events}/>
			<MyOrderPackages packages={packages} expired={expired_packages}/>
			<MyOrderMemberships memberships={memberships} expired={expired_memberships}/>
			<CustomerCalendar {...calendar}/>
		</Container>
	);
};


OrdersComponent.propTypes = {
	events: PropTypes.array.isRequired,
	packages: PropTypes.array.isRequired,
	memberships: PropTypes.array.isRequired,
	expired_events: PropTypes.array.isRequired,
	expired_packages: PropTypes.array.isRequired,
	expired_memberships: PropTypes.array.isRequired,
	calendar: PropTypes.object.isRequired,
	promo: PropTypes.object
};


OrderElement.propTypes = {
	title: PropTypes.string.isRequired,
	buyPage: PropTypes.string.isRequired,
	children: PropTypes.any
};


MyOrderEvents.propTypes = {
	events: PropTypes.array.isRequired,
	expired: PropTypes.array.isRequired,
};


MyOrderPackages.propTypes = {
	packages: PropTypes.array.isRequired,
	expired: PropTypes.array.isRequired,
};


MyOrderMemberships.propTypes = {
	memberships: PropTypes.array.isRequired,
	expired: PropTypes.array.isRequired,
};


MediaComponent.propTypes = {
	title: PropTypes.string.isRequired,
	image: PropTypes.string.isRequired,
	children: PropTypes.any
};


ExpiredOrderElement.propTypes = {
	title: PropTypes.string.isRequired,
	children: PropTypes.any
};


export default OrdersComponent;
