import {
	GET_BOOKING_MEMBERSHIPS,
	RECEIVE_BOOKING_MEMBERSHIPS
} from "actions/consts";
import apiUrls from "routes/api";
import { request } from "actions/utils";


// Processing getting memberships list
const getBookingMemberships = () => {
	return {
		type: GET_BOOKING_MEMBERSHIPS,
		bookingMemberships: []
	};
};

const receiveBookingMemberships = (data) => {
	return {
		type: RECEIVE_BOOKING_MEMBERSHIPS,
		bookingMemberships: data,
		receivedAt: Date.now()
	};
};

export const fetchBookingMemberships = () => {
	return (dispatch) => {
		dispatch(getBookingMemberships());
		const config = {
			method: "GET",
			params: {
				deleted: false
			}
		};
		const response = request(apiUrls.bookMembershipsUrl, config);
		return response
			.then(json => dispatch(receiveBookingMemberships(json)))
			.catch(error => console.log("An error occured.", error));
	};
};
