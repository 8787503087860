import React, {Component} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { fetchBookingPackages } from "actions/booking/packages/packages";
import BookingPackagesComponent from "components/booking/packages/packages";
import { Spinner } from "components/utils/spinner";


class BookingPackagesContainer extends Component {

	componentDidMount() {
		this.props.dispatch(fetchBookingPackages());
	}

	render() {
		const { isFetching, bookingPackages } = this.props;
		let sendProps = { isFetching, bookingPackages};
		return	isFetching ? <Spinner/> : <BookingPackagesComponent {...sendProps}/>;
	}
}

BookingPackagesContainer.propTypes = {
	dispatch: PropTypes.func.isRequired,
	bookingPackages: PropTypes.array,
	isFetching: PropTypes.bool,
};

const mapStateToProps = (state) => {
	return {
		bookingPackages: state.bookingPackagesRoot.bookingPackages || [],
		isFetching: state.bookingPackagesRoot.isFetching || false,
	};
};

const mapDispatchToProps = (dispatch) => {
	return { dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingPackagesContainer);
