import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import PackagesComponent from "components/packages/packages";
import { Spinner } from "components/utils/spinner";

import { fetchPackages } from "actions/packages/get_packages_list";
import { deletePackage } from "actions/packages/edit_package";
import { resetEditErrors } from "actions/util_actions";
import apiUrls from "routes/api";


class PackagesContainer extends React.Component {

	state = {
		error: null,
		show: false,
		packageToDelete: {}
	};

	componentDidMount() {
		this.props.dispatch(fetchPackages());
	}

	componentDidUpdate(prevProps, prevState){
		const { dispatch, errors } = this.props;
		if(errors !== prevProps.errors){
			this.setState({error: errors.message});
		}
		if (this.state.packageToDelete !== prevState.packageToDelete){
			dispatch(fetchPackages());
		}
	}

	onDelete = (id) => {
		let apiUrl = apiUrls.packageDetailUrl.replace(":id", id);
		this.props.dispatch(deletePackage(apiUrl));
		this.handleClose();
	}

	handleShow = (packageItem) => {
		this.setState({
			show: true,
			packageToDelete: {
				id: packageItem.id,
				title: packageItem.title
			}
		});
	}

	handleClose = () => {
		const { errors, dispatch } = this.props;
		this.setState({
			show: false,
			packageToDelete: {}
		});

		if(errors){
			dispatch(resetEditErrors());
		}
	}

	render() {

		const { isFetching, activePackages, expiredPackages } = this.props;
		let sendProps = {
			activePackages,
			expiredPackages,
			state: this.state,
			onDelete: this.onDelete,
			handleShow: this.handleShow,
			handleClose: this.handleClose
		};

		return isFetching ? <Spinner/> : <PackagesComponent {...sendProps} />;
	}
}

PackagesContainer.propTypes = {
	dispatch: PropTypes.func.isRequired,
	activePackages: PropTypes.arrayOf(PropTypes.object).isRequired,
	expiredPackages: PropTypes.arrayOf(PropTypes.object).isRequired,
	isFetching: PropTypes.bool.isRequired,
	errors: PropTypes.object,
};

const mapStateToProps = (state) => {
	return {
		isFetching: state.packagesRoot.isFetching || false,
		activePackages: state.packagesRoot.active || [],
		expiredPackages: state.packagesRoot.expired || [],
		errors: state.editPackageRoot.errors || {},
	};
};

const mapDispatchToProps = (dispatch) => {
	return { dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(PackagesContainer);
