import {
	START_EDIT_EVENT,
	FINISHED_EDIT_EVENT,
	FAILED_EDIT_EVENT,
	START_FETCHING_EVENT,
	FINISHED_FETCHING_EVENT,
	FAILED_FETCHING_EVENT,
	START_DELETE_EVENT,
	FINISHED_DELETE_EVENT,
	FAILED_DELETE_EVENT
} from "../../actions/consts";


const initialState = {
	isFetching: false,
	errors: {},
	selectedEvent: {}
};

export default function editEventReducer(state = initialState, action) {
	switch (action.type) {

	// REDUCER GET EVENT
	case START_FETCHING_EVENT:
		return {
			...state,
			isFetching: true
		};

	case FINISHED_FETCHING_EVENT:
		return {
			...state,
			isFetching: false,
			selectedEvent: action.data,
			errors: {}
		};

	case FAILED_FETCHING_EVENT:
		return {
			...state,
			isFetching: false,
			errors: action.errors
		};

		// REDUCER EDIT EVENT
	case START_EDIT_EVENT:
		return {
			...state,
			isFetching: true
		};

	case FINISHED_EDIT_EVENT:
		return {
			...state,
			isFetching: false,
			errors: {}
		};

	case FAILED_EDIT_EVENT:
		return {
			...state,
			isFetching: false,
			errors: action.errors
		};

	case START_DELETE_EVENT:
		return {
			...state,
			isFetching: true
		};

	case FINISHED_DELETE_EVENT:
		return {
			...state,
			isFetching: false,
			errors: {}
		};

	case FAILED_DELETE_EVENT:
		return {
			...state,
			isFetching: false,
			errors: action.errors
		};

	default:
		return state;
	}
}
