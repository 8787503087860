import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { fetchBuyFreeBookingEvent } from "actions/booking/events/eventBuyFree";
import { fetchCheckinBookingEvent } from "actions/booking/events/eventCheckin";
import { fetchSelectedBookingEvent } from "actions/booking/events/eventDetail";
import { fetchPayAtVenueBookingEvent } from "actions/booking/events/eventPayAtVenue";
import BookingDetailComponent from "components/booking/events/eventDetail";
import { withAuth } from "components/utils/hoc";
import { Spinner } from "components/utils/spinner";

import apiUrls from "routes/api";
import { history } from "store";
import frontendUrls from "routes/frontend_routes";


class BookEventDetailContainer extends React.Component {

	state = {
		show: false
	}

	componentDidMount() {
		const { match, dispatch } = this.props;
		let apiUrl = apiUrls.eventDetailUrl.replace(":id",  match.params.id);
		dispatch(fetchSelectedBookingEvent(apiUrl));
	}

	handleShow = () => {
		this.setState({ show: true });
	}

	startCheckin = () => {
		const { selectedBookingEvent, match, dispatch } = this.props;
		let checkinUrl = apiUrls.orderEventsUrl.replace(":id", match.params.id);
		let sendData = {};
		if (selectedBookingEvent.available_packages.length) {
			const choosedCreditId = selectedBookingEvent.available_packages[0][0];
			sendData.choosed_credit_id = choosedCreditId;
			sendData.with_usercredit = true;
		} else if (selectedBookingEvent.available_membership.length) {
			const choosedCreditId = selectedBookingEvent.available_membership[0][0];
			sendData.choosed_credit_id = choosedCreditId;
			sendData.use_membership_checkin = true;
		}
		dispatch(fetchCheckinBookingEvent(checkinUrl, sendData));
		this.handleShow();
	}

	startBuy = () => {
		const { selectedBookingEvent, match, dispatch } = this.props;
		let createOrderUrl = apiUrls.orderEventsUrl.replace(":id", match.params.id);
		let price = parseFloat(selectedBookingEvent.parent.price);
		if (price === 0) {
			dispatch(fetchBuyFreeBookingEvent(createOrderUrl));
			this.handleShow();
		} else if (selectedBookingEvent.parent.pay_at_venue) {
			dispatch(fetchPayAtVenueBookingEvent(createOrderUrl));
			this.handleShow();
		} else {
			history.push(frontendUrls.urlBookingOrderEvent.replace(":id", match.params.id));
		}
	}

	handleClose = () => {
		this.setState({ show: false });
		history.push("/");
	}

	render() {
		const { isFetching, selectedBookingEvent, orderEventRoot, location: { state } } = this.props;

		return (
			isFetching ? <Spinner/>:
				<BookingDetailComponent
					show={this.state.show}
					selectedBookingEvent={selectedBookingEvent}
					orderEventRoot={orderEventRoot}
					startCheckin={this.startCheckin}
					startBuy={this.startBuy}
					handleClose={this.handleClose}
					buy={ state ? state.buy : true}
				/>
		);

	}
}

const AuthBookEventDetailContainer = withAuth(BookEventDetailContainer);

BookEventDetailContainer.propTypes = {
	match: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	startCheckin: PropTypes.func,
	startBuy: PropTypes.func,
	selectedBookingEvent: PropTypes.object.isRequired,
	orderEventRoot: PropTypes.object,
	isFetching: PropTypes.bool,
	location: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
	return {
		errors: state.bookEventDetailRoot.errors || {},
		selectedBookingEvent: state.bookEventDetailRoot.selectedBookingEvent || {},
		isFetching: state.bookEventDetailRoot.isFetching,
		orderEventRoot: state.orderEventRoot || {},
	};
};

const mapDispatchToProps = (dispatch) => {
	return { dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthBookEventDetailContainer);
