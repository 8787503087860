import {
	START_CREATION_EVENT,
	FINISHED_CREATION_EVENT,
	FAILED_CREATION_EVENT,
	CLEAR_EVENT_ERRORS
} from "../../actions/consts";


const initialState = {
	isFetching: false,
	errors: {}
};

export default function creationEventReducer(state = initialState, action) {
	switch (action.type) {

	// REDUCER CREATION EVENT
	case START_CREATION_EVENT:
		return {
			...state,
			isFetching: true,
			errors: {}
		};

	case FINISHED_CREATION_EVENT:
		return {
			...state,
			isFetching: false,
			errors: {}
		};

	case FAILED_CREATION_EVENT:
		return {
			...state,
			isFetching: false,
			errors: action.errors
		};

	case CLEAR_EVENT_ERRORS:
		return {
			...state,
			errors: {}
		};

	default:
		return state;
	}
}
