import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { Container, Row, Col, Button } from "reactstrap";

import { fieldInput, phoneNumberField } from "components/forms/fields";
import ManageFormComponent from "components/forms/utils";
import OneClickButton from "components/utils/buttons";

import frontendUrls from "routes/frontend_routes";


const validate = (values) => {
	let errors = {};
	if (!values.first_name){
		errors.first_name = "This field is required!";
	}
	if (!values.last_name){
		errors.last_name = "This field is required!";
	}
	if (!values.email){
		errors.email = "This field is required!";
	}
	return errors;
};


const CustomerFormField = ({name, label, placeholder = "", error}) => {
	return(
		<Field
			name={name}
			label={label}
			placeholder={placeholder}
			serverError={error}
			type="text"
			inputType="text"
			classNameLabel="font_16_medium_2c2c2d_list col col-sm-2 p-0"
			classNameFormGroup="form-inline"
			classNameInputGroup="col-9 col-sm-10 pr-0"
			className="w-100 h-100"
			classNameErrors="col-9 offset-3 col-sm-10 offset-sm-2"
			component={ name === "phone" ? phoneNumberField : fieldInput}/>
	);
};


const CustomerFormView = ({ handleSubmit, pristine, submitting, errors, edit, children, onDelete }) => {

	return(
		<form onSubmit={handleSubmit}>
			<Row className="pl-sm-2 pr-sm-2 pt-4 pb-4 d-flex justify-content-center">
				<Col lg={8}>
					<CustomerFormField name="first_name" label="Name" error={errors.first_name}/>
					<CustomerFormField name="last_name" label="Surname" error={errors.last_name}/>
					<CustomerFormField name="email" label="Email" error={errors.email}/>
					<CustomerFormField name="phone" label="Phone" error={errors.phone}/>
				</Col>
			</Row>
			{children}
			<ManageFormComponent
				errors={errors}
				isSubmitedForm={submitting}
				isPristineForm={pristine}
				isEditForm={edit}
				cancelLink={frontendUrls.urlDashboardCustomers}
				product="customer"
				onDelete={onDelete}/>
		</form>
	);
};


const CustomerForm = ({ initialValues, errors, edit, children, onCustomerSubmit, onCustomerDelete }) => {
	return(
		<Container className="mt-5 mb-5 shadow_form bg_white">
			<Form initialValues={initialValues}
				validate={validate}
				onSubmit={onCustomerSubmit}>
				{
					props =>
						<CustomerFormView {...props} onDelete={onCustomerDelete} edit={edit} errors={errors}>
							{children}
						</CustomerFormView>
				}
			</Form>
		</Container>
	);
};


CustomerFormField.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	error: PropTypes.string
};


CustomerFormView.propTypes = {
	edit: PropTypes.bool,
	errors: PropTypes.object.isRequired,
	pristine: PropTypes.bool,
	submitting: PropTypes.bool,
	handleSubmit: PropTypes.func.isRequired,
	onCustomerDelete: PropTypes.func,
	children: PropTypes.element
};


CustomerForm.propTypes = {
	edit: PropTypes.bool,
	initialValues: PropTypes.object,
	errors: PropTypes.object.isRequired,
	onCustomerSubmit: PropTypes.func.isRequired,
	onCustomerDelete: PropTypes.func,
	children: PropTypes.element
};


export default CustomerForm;