import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { 
	Row, Col, Popover,
	PopoverHeader, PopoverBody 
} from "reactstrap";
import { FaTimes } from "react-icons/fa";
import { getTimeRange, getMonthName } from "components/utils";
import frontendUrls from "routes/frontend_routes";


export const PozenEvent = ({event, fullInfo = false}) => {
	return(
		<Row className="pozen-event">
			<Col xs={12}>
				<p className="pozen-event-title">{event.title}</p>
			</Col>
			<Col xs={12}>
				<p className="pozen-event-time">{getTimeRange(event.start, event.end)}</p>
			</Col>
			{
				fullInfo &&
				<Col xs={12}>
					<p className="pozen-event-desc">{event.desc}</p>
				</Col>
			}
		</Row>
	);
};


PozenEvent.propTypes = {
	event: PropTypes.object.isRequired,
	fullInfo: PropTypes.bool
};


export const PozenEventsPopover = ({isOpen, target, toggle, children, title, placement = "left", isFacilitySide = false}) => {
	return (
		<Popover isOpen={isOpen} className="pozen-events-details" placement={placement} target={target}>
			<PopoverHeader className="pozen-events-title">
				<Row>
					<Col xs={10}>
						<p className="mb-0">{title}</p>
						{
							isFacilitySide && 
									<Link className="create-pozen-event mt-3" 
										to={frontendUrls.urlDashboardCreateEvent}>
										Create event
									</Link>
						}
					</Col>
					<Col xs={2}>
						<FaTimes className="close-popover" onClick={toggle}/>
					</Col>
				</Row>
			</PopoverHeader>
			<PopoverBody className="pozen-events-info">
				{children}
			</PopoverBody>
		</Popover>
	);
};


PozenEventsPopover.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	target: PropTypes.any,
	toggle: PropTypes.func.isRequired,
	children: PropTypes.element,
	title: PropTypes.any.isRequired,
	placement: PropTypes.string,
	isFacilitySide: PropTypes.bool
};


export const PozenEventFullPopover = ({event, toggle, target, isOpen}) => {
	return(
		<PozenEventsPopover isOpen={isOpen}
			placement="top" 
			target={target}
			toggle={toggle}
			title={`${event.start.getDate()} ${getMonthName(event.start.getMonth())}`}>
			<PozenEvent event={event} fullInfo={true}/>
		</PozenEventsPopover>
	);
};


PozenEventFullPopover.propTypes = {
	event: PropTypes.object.isRequired,
	toggle: PropTypes.func.isRequired,
	target: PropTypes.any,
	isOpen: PropTypes.bool.isRequired
};


export const PozenDashboardEvent = ({event}) => {
	return (
		<Row className="pozen-event">
			<Col xs={7}>
				<Row>
					<Col xs={12}>
						<p className="pozen-event-title">{event.title}</p>
					</Col>
					<Col xs={12}>
						<p className="pozen-event-time">{getTimeRange(event.start, event.end)}</p>
					</Col>
				</Row>
			</Col>
			<Col xs={5} className="pozen-spots">
				<Row>
					<Col xs={12}>
						<p className="pozen-event-spots-number">{event.freePlaces} ({event.totalPlaces})</p>
					</Col>
					<Col xs={12}>
						<p className="pozen-event-spots-status">spots available</p>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};


PozenDashboardEvent.propTypes = {
	event: PropTypes.object.isRequired
};