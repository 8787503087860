import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Container, Row, Col } from "reactstrap";
import ModalComponent from "components/utils/modal";
import {Spinner} from "components/utils/spinner";
import { isEmpty, getFullName } from "components/utils";
import {
	getAgendaDetailEventData, 
	getAgendaDetailEventTimeRange
} from "components/calendar/config/consts";
import {fetchOrdersDetailInfo} from "actions/orders/orders";
import {paidWith, paidWithChoices} from "backend_constants/paidSourceTypes";


export const AgendaDate = ({label}) => {
	return <div className="rbc-agenda-date">{label}</div>;
};


AgendaDate.propTypes = {
	label: PropTypes.string.isRequired
};


export const AgendaTime = ({label}) => {
	return <div className="rbc-agenda-time">{label}</div>;
};


AgendaTime.propTypes = {
	label: PropTypes.string.isRequired
};


export const CustomerAgendaEvent = ({event}) => {
	return(
		<div className="rbc-agenda-event">
			<p className="rbc-agenda-event-title">{event.title}</p>
			<p className="rbc-agenda-event-desc">{event.desc}</p>
		</div>
	);
};


CustomerAgendaEvent.propTypes = {
	event: PropTypes.object.isRequired
};


const EventOrderDetailRow = ({order}) => {

	const { customer, paid_with } = order;
	return(
		<Row className="customer">
			<Col className="customer_name" xs={6} md={3}>
				<p className="font_16_medium_black_list mb-0">
					{getFullName(customer.first_name, customer.last_name)}
				</p>
			</Col>
			<Col className="customer_email d-none d-sm-none d-md-flex" xs={5}>
				<p className="font_16_medium_a0a2a7_list mb-0">{customer.email}</p>
			</Col>
			<Col className="customer_order_status text-uppercase" xs={6} md={4}
				style={{color: `${paid_with == paidWith.payAtVenue? "red" : "green"}`}}>
				{ paid_with == paidWith.free && paidWithChoices[paidWith.free] }
				{ paid_with == paidWith.stripe && paidWithChoices[paidWith.stripe] }
				{ paid_with == paidWith.payAtVenue && paidWithChoices[paidWith.payAtVenue] }
				{ paid_with == paidWith.checkinPackage && paidWithChoices[paidWith.checkinPackage] }
				{ paid_with == paidWith.checkinMembership && paidWithChoices[paidWith.checkinMembership] }
			</Col>
		</Row>
	);
};


EventOrderDetailRow.propTypes = {
	order: PropTypes.object
};


const EventOrderDetailTable = ({orders}) => {
	return(
		<Container>
			<Row className="customers font_16_medium_a0a2a7_list d-none d-sm-none d-md-flex">
				<Col xs={3}>Name</Col>
				<Col xs={5}>Email</Col>
				<Col xs={4}>Status</Col>
			</Row>
			<div className="customers_list">
				{	
					orders.map(order => <EventOrderDetailRow key={order.id} order={order}/>)
				}
			</div>
		</Container>
	);
};


EventOrderDetailTable.propTypes = {
	orders: PropTypes.arrayOf(PropTypes.object).isRequired
};


const FacilityAgendaEvent = ({ event, dispatch, orders, errors, isFetching }) => {

	const [show, showEventInfo] = useState(false);
	
	useEffect(() => {
		if(show){
			dispatch(fetchOrdersDetailInfo({"event_id": event.id}));
		}
	}, [show]);

	const handleShow = () => {
		showEventInfo(!show);
	};

	const props = {
		centered: true,
		scrollable: true,
		toggle: handleShow,
		size: "lg"
	};

	const header = (
		<div className="rbc-agenda-event-detail-header">
			<p className="rbc-agenda-event-title mb-1">{event.title}</p>
			<p className="rbc-agenda-event-date mb-1">{getAgendaDetailEventData(event.start)}</p>
			<p className="rbc-agenda-event-time mb-1">{getAgendaDetailEventTimeRange(event.start, event.end)}</p>
			<p className="rbc-agenda-event-spots mb-1">{event.freePlaces} ({event.totalPlaces}) sports available</p>
		</div>
	);

	const body = (
		<div>
			{
				isEmpty(errors)?
					isFetching ? <Spinner/> : 
						isEmpty(orders) ? <p className="mb-2">No customers.</p> : 
							<EventOrderDetailTable orders={orders}/>:
					<p>{errors.detail}</p>
			}
		</div>
	);

	return(
		<>
			<div className="rbc-agenda-event">
				<p className="rbc-agenda-event-title cursor-pointer" onClick={handleShow}>{event.title}</p>
				<p className="rbc-agenda-event-desc">{event.desc}</p>
			</div>
			<ModalComponent className="rbc-event-customers" isOpen={show} extraProps={props} header={header} body={body}/>
		</>
	);
};

FacilityAgendaEvent.propTypes = {
	event: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	orders: PropTypes.arrayOf(PropTypes.object).isRequired,
	errors: PropTypes.object,
	isFetching: PropTypes.bool.isRequired
};


const mapStateToProps = ({ordersFilterRoot: {orders, errors, isFetching}}) => {
	return { orders, errors, isFetching };
};


export const DashboardAgendaEvent = connect(mapStateToProps)(FacilityAgendaEvent);
