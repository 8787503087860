import urls from "routes/frontend_routes";


const DashboardRoutes = [
	{ path: urls.urlRoot, breadcrumb: null},
	{ path: urls.urlDashboard, breadcrumb: "Dashboard"},
	{ path: urls.urlDashboardCalendar, breadcrumb: "Calendar"},
	{ path: urls.urlDashboardCustomers, breadcrumb: "Customers"},
	{ path: urls.urlDashboardCreateCustomer, breadcrumb: "Create"},
	{ path: urls.urlDashboardCustomerEdit, breadcrumb: "Manage"},

	{ path: urls.urlDashboardPackages, breadcrumb: "Packages"},
	{ path: urls.urlDashboardCreatePackage, breadcrumb: "Create"},
	{ path: urls.urlDashboardPackageEdit, breadcrumb: "Manage"},

	{ path: urls.urlDashboardEvents, breadcrumb: "Events"},
	{ path: urls.urlDashboardCreateEvent, breadcrumb: "Create"},
	{ path: urls.urlDashboardEventEdit, breadcrumb: "Manage"},

	{ path: urls.urlDashboardMemberships, breadcrumb: "Memberships"},
	{ path: urls.urlDashboardCreateMemberships, breadcrumb: "Create"},
	{ path: urls.urlDashboardMembershipsEdit, breadcrumb: "Manage"},
	{ path: urls.urlDashboardAdditionalServices, breadcrumb: "Additional services"},
	{ path: urls.urlDashboardBanner, breadcrumb: "Banner"}
];


export default DashboardRoutes;