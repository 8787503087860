import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Spinner } from "components/utils/spinner";
import EditMembershipComponent from "components/memberships/edit_membership";
import { withCategories } from "components/utils/hoc";

import {
	fetchSelectedMembership, editMembership, deleteMembership
} from "actions/memberships/edit_membership";
import { LIMITED } from "backend_constants/limitChoice";
import apiUrls from "routes/api";


class EditMembershipContainer extends React.Component {

	componentDidMount() {
		const { dispatch, match } = this.props;
		let apiUrl = apiUrls.membershipDetailUrl.replace(":id", match.params.id);
		dispatch(fetchSelectedMembership(apiUrl));
	}

	onDelete = () => {
		const { dispatch, match } = this.props;
		let apiUrl = apiUrls.membershipDetailUrl.replace(":id", match.params.id);
		dispatch(deleteMembership(apiUrl));
	}

	onSubmit = (values) => {
		const { dispatch, match, categories } = this.props;
		let sendData = {
			title: values.title,
			description: values.description,
			price: values.price,
			category_limits: values.categoryLimitsChoice,
			usage_limits: values.usageLimitsChoice,
			cancellation_policy: values.cancellation_policy
		};

		if (values.categoryLimitsChoice === LIMITED) {
			sendData.categories = categories.map(function (categoryArray) {
				if (values[categoryArray[1]]) {
					return categoryArray[0];
				}
				return -1;
			})
				.filter(function (value) {
					return value > -1;
				});
		}

		if (values.usageLimitsChoice === LIMITED) {
			sendData.usage_limit = values.usageLimit;
		}

		let apiUrl = apiUrls.membershipDetailUrl.replace(":id", match.params.id);
		dispatch(editMembership(sendData, apiUrl));
	}

	render() {
		const { isFetching, errors, initialValues } = this.props;

		let sendProps = {
			onSubmit: this.onSubmit,
			onDelete: this.onDelete,
			errors,
			initialValues
		};
		return	isFetching ? <Spinner/> : <EditMembershipComponent {...sendProps}/>;
	}
}

EditMembershipContainer.propTypes = {
	match: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	errors: PropTypes.object.isRequired,
	initialValues: PropTypes.object,
	isFetching: PropTypes.bool,
	categories: PropTypes.array.isRequired
};

const mapStateToProps = (state) => {
	return {
		errors: state.editMembershipRoot.errors || {},
		initialValues: state.editMembershipRoot.selectedMembership || {},
		isFetching: state.editMembershipRoot.isFetching || false,
	};
};

const mapDispatchToProps = (dispatch) => {
	return { dispatch };
};

export default connect(
	mapStateToProps, mapDispatchToProps
)(withCategories(EditMembershipContainer));
