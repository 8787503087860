import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import FacilityPromoComponent from "components/facilities/facility_promo";
import {Spinner} from "components/utils/spinner";
import {isEmpty} from "components/utils";

import {
	fetchFacilityPromo,
	fetchCreateFacilityPromo,
	fetchUpdateFacilityPromo,
	fetchDeleteFacilityPromo,
} from "actions/facilities/facility_promo";


const FacilityPromoContainer = ({dispatch, promo, errors, isFetching}) => {

	const [isEditPage, setEditPage] = useState(false);

	useEffect(() => {dispatch(fetchFacilityPromo());}, []);
	useEffect(() => setEditPage(!isEmpty(promo)), [promo]);

	const submitChanges = (updateData) => {
		const promoForm = new FormData();
		promoForm.append("title", updateData.title || "");
		promoForm.append("body", updateData.body || "");
		if(updateData.image){
			promoForm.append("image", updateData.image);
		}

		if(isEditPage){
			dispatch(fetchUpdateFacilityPromo(promo.id, promoForm));
		}else{
			dispatch(fetchCreateFacilityPromo(promoForm));
		}
	};

	const submitDelete = () => {
		dispatch(fetchDeleteFacilityPromo(promo.id));
	};

	const clearValues = {
		title: "",
		body: "",
		image: null,
	};

	const componentProps = {
		promo: isEditPage ? promo : clearValues,
		errors, isEditPage, submitChanges, submitDelete
	};


	return isFetching ? <Spinner/> : <FacilityPromoComponent {...componentProps}/>;
};


FacilityPromoContainer.propTypes = {
	dispatch: PropTypes.func.isRequired,
	promo: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired,
	isFetching: PropTypes.bool.isRequired
};


const mapStateToProps = ({facilityPromoRoot: {isFetching, errors, promo}}) => {
	return { isFetching, errors, promo };
};


export default connect(mapStateToProps)(FacilityPromoContainer);