import React from "react";
import { Route, Switch } from "react-router-dom";

import "styles/main.css";
import DashboardBodyContainer from "containers/elements/dashboard_pages/dashboard_body";
import BookingBodyContainer from "containers/elements/booking_pages/booking_body";
import LoginContainer from "containers/auth/login";
import RegisterContainer from "containers/auth/register";
import ActivateAccountContainer from "containers/auth/activate_account";
import ResetPasswordContainer from "containers/auth/reset_password";
import ConfirmResetPasswordContainer from "containers/auth/confirm_reset_password";
import frontendUrls from "routes/frontend_routes";


const App = () => {
	return (
		<Switch>
			<Route exact path={frontendUrls.urlLogin} component={LoginContainer} />
			<Route exact path={frontendUrls.urlRegister} component={RegisterContainer} />
			<Route exact path={frontendUrls.urlActivateAccount} component={ActivateAccountContainer}/>
			<Route exact path={frontendUrls.urlResetPassword} component={ResetPasswordContainer}/>
			<Route exact path={frontendUrls.urlConfirmResetPassword} component={ConfirmResetPasswordContainer}/>
			<Route path={frontendUrls.urlDashboard} component={DashboardBodyContainer} />
			<Route path={frontendUrls.urlRoot} component={BookingBodyContainer} />
		</Switch>
	);
};

export default App;
