import React, {useState, useRef} from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { PozenEventFullPopover } from "components/calendar/config/views/utils";


export const DayEvent = ({event, title}) => {

	const [show, showEventDetails] = useState(false);
	const dayEvent = useRef(null);

	const getTarget = () => {
		return ReactDOM.findDOMNode(dayEvent.current) //eslint-disable-line
	};

	const toggle = () => showEventDetails(!show);

	return (
		<div ref={dayEvent} className="rbc-day-event" onClick={toggle}>
			<p className="mb-0">{title}</p>
			<PozenEventFullPopover isOpen={show} event={event} target={getTarget} toggle={toggle}/>
		</div>
	);
};


DayEvent.propTypes = {
	event: PropTypes.object.isRequired,
	title: PropTypes.string.isRequired
};