import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { Button, ButtonToolbar, Col, Row, Container, Collapse } from "reactstrap";
import { BsChevronDown, BsChevronUp, BsFillXCircleFill } from "react-icons/bs";
import { ManageItem } from "components/packages/packages";
import RelatedCustomers from "components/customers/customers_related";
import { withAuth, withCustomers } from "components/utils/hoc";
import ModalComponent from "components/utils/modal";

import fetchCustomers from "actions/customers/get_customers_list";
import { getCategoryNameForPackage } from "components/utils";
import frontendUrls from "routes/frontend_routes";
import { limits } from "backend_constants/limitChoice";
import { categoryLimits } from "backend_constants/categories";


const MembershipDetailsRow = ({name, value}) => {
	return(
		<Row>
			<Col xs={4} md={2} lg={1} className="pr-2">
				<p className="font_14_regular_2c2c2d_list mb-2">{name}</p>
			</Col>
			<Col xs={8} md={10} lg={7}>
				<p className="font_14_medium_black_list mb-2">{value}</p>
			</Col>
		</Row>
	);
};

const MembershipDetails = ({item}) => {
	const {
		category_limits, categories,
		usage_limits, usage_limit, price, description
	} = item;

	return(
		<>
			<Container className="p-0 mt-3">
				<MembershipDetailsRow name="Valid for:"
					value={category_limits === categoryLimits.unlimited ? "All categories" : getCategoryNameForPackage(categories)}/>
				<MembershipDetailsRow name="Sessions:"
					value={usage_limits === limits.unlimited ? "Unlimited" : usage_limit}/>
				<MembershipDetailsRow name="Expres:" value="Monthly subscription"/>
				<MembershipDetailsRow name="Price:" value={<>&pound;{price}</>}/>
				<Row>
					<Col xs={12} md={10}>
						<p className="font_14_medium_black_list">
							Description:&nbsp;&nbsp;&nbsp;
							<span className="font_14_regular_2c2c2d_list">{description}</span>
						</p>
					</Col>
				</Row>
			</Container>
		</>
	);
};


const MembershipRow = ({item, submitDelete, active, openItem, dispatch, authToken, customers}) => {
	
	const editUrl = frontendUrls.urlDashboardMembershipsEdit.replace(":id", item.id);
	
	const [show, openDetails] = useState(false);
	const [open, openCustomers] = useState(false);
	const [hasCustomers, showCustomers] = useState(true);

	useEffect(() => openDetails(item===active));
	useEffect(() => showCustomers(customers.length > 0));

	const showDetails = () => {
		if(show){
			openDetails(false);
			openItem(null);
		} else {
			dispatch(fetchCustomers(authToken, {membership_id: item.id}));
			openDetails(true);
			openItem(item);
		}
	};

	return (
		<div className="bg_white shadow_form container-fluid mb-md-3">
			<Row className="dash_membership" onClick={showDetails}>
				<Col xs={2} md={2} lg={1}className="dash_membership_control">
					{ show ? <BsChevronUp/> : <BsChevronDown/>}
				</Col>
				<Col xs={10} md={8} lg={9} className="dash_membership_title">
					<p className="font_16_medium_black_list m-0">{item.title}</p>
				</Col>
				<Col xs={2} md={2} className="dash_membership_manage d-none d-sm-none d-md-block">
					<ManageItem item={item}
						submitDelete={submitDelete}
						editUrl={editUrl}
						color="blue"/>
				</Col>
			</Row>
			<Collapse isOpen={show}>
				<MembershipDetails item={item}/>
				<Row className="dash_item_manage_vide d-flex d-sm-flex d-md-none">
					<Col xs={4} md={2}>
						<ManageItem item={item}
							submitDelete={submitDelete}
							editUrl={editUrl}
							color="grey"/>
					</Col>
					{
						hasCustomers &&
						<Col xs={8} md={10} className="d-flex justify-content-end">
							<Button data-toggle="related_customers w-100" data-target="#customers" aria-expanded="true" aria-controls="#customers"
								className="rel_customers_btn" onClick={() => openCustomers(!open)}>
									Customers { open && <BsFillXCircleFill className="gliph_15px"/>}							</Button>
						</Col>
					}
				</Row>
				{ 
					hasCustomers &&
					<div id="customers" className={`related_customers collapse ${open ? "show" : ""}`}>
						<RelatedCustomers customers={customers}/>
					</div>
				}
			</Collapse>
		</div>
	);
};


const AuthMembershipRow = withCustomers(withAuth(MembershipRow));


const MembershipsTable = ({memberships, submitDelete}) => {

	const [active, openItem] = useState(null);

	return(
		<Container className="dash_memberships p-0 mb-3">
			{
				memberships.map((item) => {
					const rowProps = { item, submitDelete, active, openItem };
					return <AuthMembershipRow {...rowProps} key={item.id}/>;
				})
			}
		</Container>
	);
};


const MembershipsComponent = ({ state, memberships, onDelete, handleShow, handleClose }) => {

	const {membershipToDelete, show} = state;

	return (
		<>
			<Container className="mt-5">
				<p className="font_20_medium_black_list">Memberships</p>
				<ButtonToolbar className="mt-3 mb-3 p-0 d-flex justify-content-end">
					<Link to={frontendUrls.urlDashboardCreateMemberships} >
						<Button className="btn_customers font_16_regular_21AEF5">
							Create Membership
						</Button>
					</Link>
				</ButtonToolbar>
				<MembershipsTable memberships={memberships} submitDelete={handleShow}/>
			</Container>
			<ModalComponent isOpen={show}
				header={`DELETE ${membershipToDelete.title}`}
				body={<p>Are you sure you want to delete this membership plan?</p>}>
				<Button className="btn btn-secondary" onClick={handleClose}>Close</Button>
				<Button className="btn btn-danger"
					onClick={() => onDelete(membershipToDelete.id)}>
					Delete Plan
				</Button>
			</ModalComponent>
		</>
	);
};


MembershipsComponent.propTypes = {
	state: PropTypes.object.isRequired,
	onDelete: PropTypes.func.isRequired,
	handleShow: PropTypes.func.isRequired,
	handleClose: PropTypes.func.isRequired,
	memberships: PropTypes.arrayOf(PropTypes.object),
};


MembershipsTable.propTypes = {
	memberships: PropTypes.arrayOf(PropTypes.object).isRequired,
	submitDelete: PropTypes.func.isRequired,
};


MembershipRow.propTypes = {
	item: PropTypes.object.isRequired,
	submitDelete: PropTypes.object.isRequired,
	active: PropTypes.object.isRequired,
	openItem: PropTypes.func.isRequired,
	dispatch: PropTypes.func.isRequired,
	authToken: PropTypes.string.isRequired,
	customers: PropTypes.arrayOf(PropTypes.object).isRequired,
};


MembershipDetails.propTypes = {
	item: PropTypes.object.isRequired,
};


MembershipDetailsRow.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.any.isRequired
};


export default MembershipsComponent;
