import {
	START_VALIDATE_USER_ROLE_ON_FACILITY,
	USER_ROLE_VALIDATION_SUCCESS,
	USER_ROLE_VALIDATION_FAILED

} from "actions/consts";
import {request} from "actions/utils";
import api from "routes/api";


export const startValidateUserRole = () => {
	return {type: START_VALIDATE_USER_ROLE_ON_FACILITY};
};


export const userRoleValidationSuccess = () => {
	return {type: USER_ROLE_VALIDATION_SUCCESS};
};


export const userRoleValidationFailed = () => {
	return {type: USER_ROLE_VALIDATION_FAILED};
};


export const fetchValidateUserRoleOnFacility = () => {
	return (dispatch, getState) => {
		dispatch(startValidateUserRole());
		const {root: {authToken}} = getState();
		const config = {
			method: "GET",
			isAuth: authToken
		};
		const response = request(api.facilityValidateUserRoleUrl, config, true);
		return response
			.then(() => {dispatch(userRoleValidationSuccess())})
			.catch(() => {dispatch(userRoleValidationFailed())});
	};
};