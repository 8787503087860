import React, {useState} from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Collapse } from "reactstrap";
import { BsPeople, BsGrid, BsChevronDown, BsCalendar, BsGear } from "react-icons/bs";
import frontendUrls from "routes/frontend_routes";


const MenuComponent = () => {
	const [isOpenManage, setIsOpenManage] = useState(false);
	const [isOpenSettings, setIsOpenSettings] = useState(false);
	const toggleManageMenu = () => setIsOpenManage(!isOpenManage);
	const toggleSettingsMenu = () => setIsOpenSettings(!isOpenSettings);

	return (
		<div className="px-0">
			<div className="btn_menu">
				<div className="mx-auto mt-3 logo-img-wrapper w-75 h-50">
					<img
						className="logo-img"
						src="/static/logo_img.png"
						alt="Pozen logo" />
				</div>
				<div className="mt-5">
					<Link className="menu_item" to={frontendUrls.urlDashboardCalendar}>
						<BsCalendar className="display_inline_block margin_right_30px gliph_blue gliph_18px" />
						<p className="font_16_medium_2c2c2d_list">Calendar</p>
					</Link>
					<a className="menu_item" onClick={toggleManageMenu}>
						<BsGrid className="gliph_blue"/>
						<p className="font_16_medium_2c2c2d_list">Manage</p>
						<BsChevronDown className="gliph_grey gliph_15px"/>
					</a>
					<Collapse isOpen={isOpenManage} id="side-menu">
						<Link className="menu_item" to={frontendUrls.urlDashboardEvents}>
							<p className="font_16_medium_2c2c2d_list">Events</p>
						</Link>
						<Link className="menu_item" to={frontendUrls.urlDashboardPackages}>
							<p className="font_16_medium_2c2c2d_list">Packages</p>
						</Link>
						<Link className="menu_item" to={frontendUrls.urlDashboardMemberships}>
							<p className="font_16_medium_2c2c2d_list">Membership</p>
						</Link>
					</Collapse>
					<Link className="menu_item" to={frontendUrls.urlDashboardCustomers}>
						<BsPeople className="display_inline_block margin_right_30px gliph_blue gliph_18px" />
						<p className="font_16_medium_2c2c2d_list">Customers</p>
					</Link>
					<a className="menu_item" onClick={toggleSettingsMenu}>
						<BsGear className="gliph_blue"/>
						<p className="font_16_medium_2c2c2d_list">Settings</p>
						<BsChevronDown className="gliph_grey gliph_15px"/>
					</a>
					<Collapse isOpen={isOpenSettings} id="side-menu">
						<Link className="menu_item" to={frontendUrls.urlDashboardBanner}>
							<p className="font_16_medium_2c2c2d_list">Banner</p>
						</Link>
						<Link className="menu_item" to={frontendUrls.urlDashboardAdditionalServices}>
							<p className="font_16_medium_2c2c2d_list">Additional services</p>
						</Link>
					</Collapse>
				</div>
			</div>
		</div>
	);
};

MenuComponent.propTypes = {
	state: PropTypes.object.isRequired,
	onClickDropdown: PropTypes.func.isRequired
};

export default MenuComponent;
