import {monthNamesShort, monthNames} from "backend_constants/month";
import store from "store";


export function getCategoryName(categoryNumber) {
	const state = store.getState();
	const { categories } = state.utilRoot;
	let categoriesNames = categories.filter((category) => {
		return category[0] === categoryNumber;
	});
	let categoryName = categoriesNames.length > 0 ? categoriesNames[0][1] : "Not defined";
	return categoryName;
}

export function getCategoryNameForPackage(categoryNumberArray) {
	const state = store.getState();
	const { categories } = state.utilRoot;
	let categoryName = categoryNumberArray.map(function (categoryNumber) {
		let categoriesNames = categories.filter((category) => {
			return category[0] === categoryNumber;
		});
		const categoryName = categoriesNames.length > 0 ? categoriesNames[0][1] : "Not defined";
		return categoryName;
	});
	categoryName = categoryName.join(", ");
	return categoryName;
}

export function getDate(startDateTime) {
	let [day, month, year] = startDateTime.split("T")[0].split("-");
	let startDateFormated = day + " " + monthNamesShort[parseInt(month, 10) - 1] + " " + year;
	return (startDateFormated);
}

export function getDateRange(startDate, endDate){
	return getDate(startDate) + " - " + getDate(endDate);
}

export function getTime(startDateTime, endDateTime) {
	let startTime = startDateTime.split("T")[1];
	let startTimeArray = startTime.split(":");
	let startHours = startTimeArray[0];
	let startMinutes = startTimeArray[1];
	let endTime = endDateTime.split("T")[1];
	let endTimeArray = endTime.split(":");
	let endHours = endTimeArray[0];
	let endMinutes = endTimeArray[1];
	return (startHours + ":" + startMinutes + " - " + endHours + ":" + endMinutes);
}

export function getCountDays(countDaysInJSFormat){
	let countDays = countDaysInJSFormat.split(" ")[0];
	return countDays;
}

export function getDateFromString(date){
	if(date){
		const [year, month, day] = date.split("-");
		return (day + " " + monthNamesShort[parseInt(month) - 1] + " " + year);
	}
	return "-";
}

export function getFullName(firstName = null, lastName = null){
	return firstName && lastName ? firstName.concat(" ", lastName) : "-";
}

export function getDateTime(date = "", time = ""){
	let dateTime = new Date();
	if(date !== ""){
		dateTime = new Date(date.replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"));
	}
	if(time !== ""){
		const [ hours = 0, minutes = 0, seconds = 0 ] = time.split(":").map((value) => parseInt(value));
		dateTime.setHours(hours, minutes, seconds);
	}
	return dateTime;
}

export function formatDate(inputDate){
	const [ year, month, date ] = inputDate.split("-");
	return (date + "-" + month + "-" + year);
}

export function getTimeRange(startDateTime = new Date(), endDateTime = new Date()){

	const getTime = (datetime) => {
		return datetime.toString().match(/(\d+:\d+)/)[0];
	};

	return (getTime(startDateTime) + " - " + getTime(endDateTime));
}

export function getMonthName(month){
	return monthNames[month];
}

export function formatDateTime(dateTime){
	return formatDate(dateTime.toISOString().split("T")[0]);
}

export function reverseDate(inputDate){
	const [ day, month, year ] = inputDate.split("-");
	return (year + "-" + month + "-" + day);
}

export function getInitialEventDateValue(date){
	return date ? reverseDate(date) : "";
}

export function isEmpty(obj){
	for(var key in obj){
		if(Object.prototype.hasOwnProperty.call(obj, key)){
			return false;
		}
	}
	return true;
}

export function getMembershipTitle(title){
	const is_deleted_membership = title.match(/^\d+\-(.+)\-\D\E\L\E\T\E\D$/);
	if (is_deleted_membership){
		return is_deleted_membership[1];
	}
	return title;
}