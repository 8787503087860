import {
	START_GET_BOOKING_PACKAGE,
	FINISH_GET_BOOKING_PACKAGE,
	FAIL_GET_BOOKING_PACKAGE
} from "../../../actions/consts";


const initialState = {
	isFetching: true,
	errors: {},
	selectedBookingPackage: {}
};

export default function bookingDetailPackageReducer(state = initialState, action) {
	switch (action.type) {

	// REDUCER GET PACKAGE
	case START_GET_BOOKING_PACKAGE:
		return {
			...state,
			isFetching: true
		};

	case FINISH_GET_BOOKING_PACKAGE:
		return {
			...state,
			isFetching: false,
			selectedBookingPackage: action.data,
			errors: {}
		};

	case FAIL_GET_BOOKING_PACKAGE:
		return {
			...state,
			isFetching: false,
			errors: action.errors
		};

	default:
		return state;
	}
}
