import React, {useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { getMonthName } from "components/utils";


export const MonthHeader = ({label}) => {
	return <div className="rbc-header-item">{label}</div>;
};


MonthHeader.propTypes = {
	label: PropTypes.string.isRequired
};


export const MonthEvent = (props) => {
	return <div className="rbc-month-event-title">{props.title}</div>;
};


MonthEvent.propTypes = {
	title: PropTypes.string.isRequired
};



export const MonthDateHeader = (date, label, events, showMore, selectedDate, PopoverView, PopoverEventView, isFacilitySide = false) => {
	const [show, showDateDetails] = useState(false);
	const pozenEvents = useRef(null);

	useEffect(() => {
		if(date.getDate() === selectedDate.getDate()){
			showDateDetails(showMore);
		}
	}, [selectedDate]);
	
	const getTarget = () => {
		return ReactDOM.findDOMNode(pozenEvents.current); //eslint-disable-line
	};

	const toggle = () => {
		showDateDetails(!show);
	};

	const isCurrentEvent = (event) => (
		event.start.getDate() === date.getDate() && 
		event.start.getMonth() === date.getMonth()
	);

	const eventsList = events.filter(event => isCurrentEvent(event));
	const dateWithEvents = Boolean(eventsList.length);
	const title = `${date.getDate()} ${getMonthName(date.getMonth())}`;

	return(
		<>
			<div className={`${dateWithEvents ? "rbc-date-with-event" : "rbc-date"}`}>
				<div ref={pozenEvents} onClick={toggle}>{label}</div>
			</div>
			<PopoverView isOpen={show && dateWithEvents} placement="top" target={getTarget}
				title={title} toggle={toggle} isFacilitySide={isFacilitySide}>
				{
					eventsList.map((event) => <PopoverEventView event={event} key={event.id}/>)
				}
			</PopoverView>
		</>
	);
};