import {
	START_GET_BOOKING_EVENT,
	FINISH_GET_BOOKING_EVENT,
	FAIL_GET_BOOKING_EVENT
} from "../../../actions/consts";


const initialState = {
	isFetching: true,
	errors: {},
	selectedBookingEvent: {}
};

export default function bookingDetailEventReducer(state = initialState, action) {
	switch (action.type) {

	// REDUCER GET EVENT
	case START_GET_BOOKING_EVENT:
		return {
			...state,
			isFetching: true
		};

	case FINISH_GET_BOOKING_EVENT:
		return {
			...state,
			isFetching: false,
			selectedBookingEvent: action.data,
			errors: {}
		};

	case FAIL_GET_BOOKING_EVENT:
		return {
			...state,
			isFetching: false,
			errors: action.errors
		};

	default:
		return state;
	}
}
