import {raiseInternalServerError} from "actions/util_actions";
import store from "store";
import {
	HTTP_308_PERMANENT_REDIRECT,
	HTTP_500_INTERNAL_SERVER_ERROR
} from "actions/consts";


export default function getDateFromJS() {
	let today = new Date();
	let dd = today.getDate();
	if (dd < 10) {
		dd = "0" + dd;
	}
	let mm = today.getMonth() + 1; //January is 0!
	if (mm < 10) {
		mm = "0" + mm;
	}
	let yyyy = today.getFullYear();
	let todayFormated = yyyy + "-" + mm + "-" + dd;
	return todayFormated;
}


export const getQueryParams = (params) => {
	return Object.keys(params)
		.map(k => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
		.join("&");
};


export const checkStatus = (response) => {
	if (response.ok || response.status === HTTP_308_PERMANENT_REDIRECT) {
		return response;
	} else {
		if(response.status === HTTP_500_INTERNAL_SERVER_ERROR){
			store.dispatch(raiseInternalServerError());
		}
		let error = new Error(response.statusText);
		error.response = response;
		throw error;
	}
};

export const parseJson = (response) => {
	return response.json();
};


export const request = (apiPath, config, body = {}, notParseJson = false, notCheckStatus = false) => {
	// eslint-disable-next-line
	config.method = config.method || "GET";
	// eslint-disable-next-line
	config.isAuth = config.isAuth || "";
	// eslint-disable-next-line
	const requestHeaders = {
		// eslint-disable-next-line
		"credentials": "same-origin",
		"Content-Type": "application/json",
	};

	if (config.isAuth !== "") {
		// eslint-disable-next-line
		requestHeaders["Authorization"] = `Token ${config.isAuth}`;
	}

	if(config.params){
		apiPath += (apiPath.indexOf("?") === -1 ? "?" : "&") + getQueryParams(config.params);
	}

	let requestToServer;
	if (config.method !== "GET") {
		requestToServer = fetch(apiPath, {
			method: config.method,
			headers: requestHeaders,
			body: JSON.stringify(body),
			mode: "cors",
		});
	} else {
		requestToServer = fetch(apiPath, {
			method: config.method,
			headers: requestHeaders,
			mode: "cors",
		});
	}

	if (notCheckStatus) {
		return requestToServer;
	}

	if (notParseJson) {
		return requestToServer.then(checkStatus);
	}

	return requestToServer
		.then(checkStatus)
		.then(parseJson);
};
