import React from "react";
import PropTypes from "prop-types";
import { Form, Field } from "react-final-form";
import { Col, Row, FormGroup, Container } from "reactstrap";
import { FaPoundSign } from "react-icons/fa";
import {
	capitalizeFirstLetter,
	fieldInputWithAddon,
	fieldWithLabelAndSelect,
	fieldInput,
	checkboxField,
	fieldTextarea
} from "components/forms/fields";
import ManageFormComponent from "components/forms/utils";
import { withCategories } from "components/utils/hoc";

import { limitsChoices, LIMITED } from "backend_constants/limitChoice";
import frontendUrls from "routes/frontend_routes";


const validate = values => {
	const errors = {};
	if (!values.title) {
		errors.title = "This field is required.";
	}

	if (!values.price){
		errors.price = "This field is required.";
	}

	if (!values.description){
		errors.description = "This field is required.";
	}
	return errors;
};


let MembershipForm = ({
	onSubmit, onDelete, resetMembershipErrors, errors, edit, initialValues, categories
}) => {

	return (
		<Container className="bg_white shadow_form border_radius_03rem mb-5">
			<Form
				validate={validate}
				onSubmit={onSubmit}
				initialValues={initialValues}
				render={({ handleSubmit, pristine, values, submitting }) => (
					<form onSubmit={handleSubmit}>
						<Row>
							<Col xs={12} sm={12} md={6} lg={6}>
								<Field
									name="title"
									label="Name"
									type="text"
									inputType="text"
									placeholder="Membership title"
									classNameFormGroup="ml-auto mb-0 mt-5"
									serverError={errors.title}
									component={fieldInput} />

								<Field
									name="usageLimitsChoice"
									label="Sessions"
									serverError={errors.usage_limits}
									choices={limitsChoices}
									classNameFormGroup="ml-auto mb-0 mt-5"
									component={fieldWithLabelAndSelect} />

								{(values.usageLimitsChoice === LIMITED || values.usageLimitsChoice === LIMITED.toString()) && (<Field
									name="usageLimit"
									label="Number of sessions"
									type="number"
									inputType="number"
									placeholder="Number of session"
									classNameFormGroup="ml-auto mb-0 mt-5"
									serverError={errors.usage_limit}
									component={fieldInput} />)
								}

								<Field
									name="categoryLimitsChoice"
									label="Category limits"
									classNameFormGroup="ml-auto mb-0 mt-5"
									serverError={errors.category_limits}
									choices={limitsChoices}
									component={fieldWithLabelAndSelect} />

								{(values.categoryLimitsChoice === LIMITED || values.categoryLimitsChoice === LIMITED.toString()) && (
									<FormGroup >
										{categories.map(
											(categoryArray, i) =>
												<Field
													key={i}
													classNameFormGroup="mt-3"
													type="checkbox"
													name={categoryArray[1]}
													label={capitalizeFirstLetter(categoryArray[1])}
													component={checkboxField}
												/>
										)}
									</FormGroup>
								)}

								<Field
									name="price"
									inputType="number"
									type="number"
									label="Price"
									classNameFormGroup="ml-auto mb-0 mt-5 mb-md-5"
									sprite={<FaPoundSign />}
									placeholder="£"
									serverError={errors.price}
									disabled={edit}
									component={fieldInputWithAddon} />
							</Col>
							<Col xs={12} sm={12} md={6} lg={6}>
								<Field
									label="Description"
									name="description"
									type="text"
									inputType="textarea"
									placeholder="Text"
									className="textarea_resize_none"
									classNameFormGroup="ml-auto mb-5 mt-5"
									rows="6"
									serverError={errors.description}
									component={fieldTextarea} />
								<Field
									label="Cancellation policy"
									name="cancellation_policy"
									type="text"
									inputType="textarea"
									placeholder="Text"
									className="textarea_resize_none"
									classNameFormGroup="ml-auto mb-5 mt-5"
									rows="6"
									serverError={errors.cancellation_policy}
									component={fieldTextarea} />
							</Col>
						</Row>
						<ManageFormComponent
							errors={errors}
							isSubmitedForm={submitting}
							isPristineForm={pristine}
							isEditForm={edit}
							cancelLink={frontendUrls.urlDashboardMemberships}
							product="plan"
							onDelete={onDelete}
							resetComponentErrors={resetMembershipErrors}/>
					</form>
				)}>
			</Form>
		</Container>
	);
};


MembershipForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	onDelete: PropTypes.func,
	errors: PropTypes.object,
	edit: PropTypes.bool,
	initialValues: PropTypes.object,
	categories: PropTypes.array.isRequired
};


export default withCategories(MembershipForm);