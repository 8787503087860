import React, {useState} from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Container, Row, Col, Button, Label, Input } from "reactstrap";
import { BsTagFill, BsPersonFill } from "react-icons/bs";
import { MdLocationOn } from "react-icons/md";
import ModalComponent from "components/utils/modal";

import { getCategoryName, getDate, getTime, isEmpty } from "components/utils";
import frontendUrls from "routes/frontend_routes";


export const Event = ({item, isBuyPage, checkin, pay}) => {
	const {
		parent: {
			category, description,
			cancellation_policy, price,
			pay_at_venue, instructor, location
		},
		id, start_datetime, end_datetime, available_for_checkin
	} = item;

	const [agreeToPayAtVenue, setAgree] = useState(false);

	const checkinPayAtVenueEvent = () => {
		setAgree(!agreeToPayAtVenue);
	};

	return(
		<>
			<div className="event_info">
				<Row className="event_details_date">
					{
						isBuyPage?
							<Col xs={{size:1, offset: 1}} sm={{size: 1, offset: 0}} className="d-flex justify-content-end p-0">
								<BsPersonFill className="gliph_grey gliph_18px"/>
							</Col> :
							<Col xs={1}>
								<p className="font_14_medium_2c2c2d_list">Instructor: </p>
							</Col>
					}
					<Col xs={{size: 9, offset: `${isBuyPage ? 0 : 1}`}}>
						<p className={`font_14_${isBuyPage? "medium": "regular"}_2c2c2d_list`}>{instructor}</p>
					</Col>
				</Row>
				<Row className="event_details_date">
					{
						isBuyPage?
							<Col xs={{size:1, offset: 1}} sm={{size: 1, offset: 0}} className="d-flex justify-content-end p-0">
								<MdLocationOn className="gliph_grey gliph_18px"/>
							</Col>:
							<Col xs={1}>
								<p className="font_14_medium_2c2c2d_list">Location: </p>
							</Col>
					}
					<Col xs={{size: 9, offset: `${isBuyPage ? 0 : 1}`}}>
						<p className={`font_14_${isBuyPage? "medium": "regular"}_2c2c2d_list`}>{location}</p>
					</Col>
				</Row>
				<Row className="event_details_date">
					<Col xs={{size: 9, offset: `${ isBuyPage ? 1 : 0}`}} lg={{size: `${ isBuyPage ? 8 : 9}`}} className="text-left">
						<p className="font_14_medium_2c2c2d_list">
							Selected Date:&nbsp;&nbsp;
							<span className="font_14_regular_2c2c2d_list">
								{getDate(start_datetime)}, {getTime(start_datetime, end_datetime)}
							</span>
						</p>
					</Col>
				</Row>
				<Row className="event_details_category">
					<Col xs={{size: 9, offset: `${ isBuyPage ? 1 : 0}`}}>
						<p className="font_14_medium_2c2c2d_list">
							Category:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<span className="font_14_regular_2c2c2d_list">
								{getCategoryName(category)}
							</span>
						</p>
					</Col>
				</Row>
				<Row className="event_details_desc">
					<Col xs={{size: 9, offset: `${ isBuyPage ? 1 : 0}`}}>
						<p className="font_14_medium_2c2c2d_list">
							Description:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<span className="font_14_regular_2c2c2d_list">{description}</span>
						</p>
					</Col>
				</Row>
				{
					isBuyPage &&
					<Row className="event_details_policy">
						<Col xs={{size: 9, offset: 1}}>
							<p className="font_14_medium_2c2c2d_list">
								Cancellation policy:&nbsp;&nbsp;
								<span className="font_14_regular_2c2c2d_list">{cancellation_policy}</span>
							</p>
						</Col>
					</Row>
				}
			</div>
			<div className={`${ isBuyPage && "event_control"} d-flex`}>
				<Col xs={1} className={`${ isBuyPage && "mt-3"} text-lg-right d-none d-md-block`}>
					<BsTagFill className="gliph_grey" />
				</Col>
				<Col xs={6} lg={6} className={`${ isBuyPage && "mt-3"}`}>
					<p className="event_price font_14_medium_2c2c2d_list">
						Price:&nbsp;
						<span className="font_14_regular_2c2c2d_list"> &pound;{price}</span>
					</p>
				</Col>
				{
					isBuyPage &&
					<Col xs={6} md={5} className="font_16_regular_white_list mt-2 mb-2 d-flex justify-content-end">
						{
							available_for_checkin ?
								<Button className="btn event_info_btn checkin" onClick={checkin}>Checkin</Button>:
								parseFloat(price) === 0 || pay_at_venue ?
									<Button className="btn event_info_btn" onClick={pay} disabled={!agreeToPayAtVenue && pay_at_venue}>Buy</Button>:
									<Link to={frontendUrls.urlBookingOrderEvent.replace(":id", id)}>
										<Button className="btn event_info_btn">Buy</Button>
									</Link>
						}
					</Col>
				}
			</div>
			{
				isBuyPage && pay_at_venue && !available_for_checkin &&
				<div className="event_control border-top-0 pl-3">
					<Label className="pl-4 pr-3 font_14_medium_2c2c2d_list">
						<Input type="checkbox" onClick={checkinPayAtVenueEvent}/>
						I agree to pay this amount at Hope Pilates.
					</Label>
				</div>
			}
		</>
	);
};


const BookingDetailComponent = ({ show, selectedBookingEvent, orderEventRoot, startCheckin, startBuy, handleClose, buy }) => {
	const { parent: {title}, available_for_checkin} = selectedBookingEvent;
	const { errors: orderErrors } = orderEventRoot;

	return (
		<>
			<Container className="mt-5 mb-5">
				<Row>
					<Col xs="12">
						<p className="font_24_regular_black_list">
							{title}
						</p>
					</Col>
				</Row>
				<div className="event_info_wrap shadow_form bg_white">
					<Event item={selectedBookingEvent}
						isBuyPage={buy}
						checkin={startCheckin}
						pay={startBuy}/>
				</div>
			</Container>
			<ModalComponent isOpen={show}
				header={`${available_for_checkin ? "Checkin" : "Buy"} Event`}
				body={
					isEmpty(orderErrors)?
						<p>You have just bought an event successfully</p>:
						<p>{orderErrors.non_field_errors || orderErrors.detail || orderErrors.message}</p>

				}>
				<Button onClick={handleClose} className="btn event_info_btn">Close</Button>
			</ModalComponent>
		</>
	);
};

BookingDetailComponent.propTypes = {
	show: PropTypes.bool.isRequired,
	selectedBookingEvent: PropTypes.object.isRequired,
	orderEventRoot: PropTypes.object.isRequired,
	startCheckin: PropTypes.func,
	startBuy: PropTypes.func,
	handleClose: PropTypes.func,
	buy: PropTypes.object.isRequired
};

Event.propTypes = {
	item: PropTypes.object.isRequired,
	isBuyPage: PropTypes.bool.isRequired,
	checkin: PropTypes.func,
	pay: PropTypes.func,
};

export default BookingDetailComponent;
