import {
	START_EDIT_PACKAGE,
	FINISHED_EDIT_PACKAGE,
	FAILED_EDIT_PACKAGE,
	START_FETCHING_PACKAGE,
	FINISHED_FETCHING_PACKAGE,
	FAILED_FETCHING_PACKAGE,
	START_DELETE_PACKAGE,
	FINISHED_DELETE_PACKAGE,
	FAILED_DELETE_PACKAGE
} from "actions/consts";
import { history } from "store";
import { request } from "actions/utils";
import frontendUrls from "routes/frontend_routes";


// Processing fetching package
const startFetchingPackage = () => {
	return { type: START_FETCHING_PACKAGE };
};

const finishFetchingPackage = (data) => {
	return {
		type: FINISHED_FETCHING_PACKAGE,
		data: data,
		receivedAt: Date.now()
	};
};

const failedFetchingPackage = (data) => {
	return {
		type: FAILED_FETCHING_PACKAGE,
		errors: data,
		receivedAt: Date.now()
	};
};

export const fetchSelectedPackage = (apiUrlSelectedPackage) => {
	return (dispatch, getState) => {
		dispatch(startFetchingPackage);
		const { root: { authToken }} = getState();
		const config = {
			isAuth: authToken
		};

		const response = request(apiUrlSelectedPackage, config);
		return response
			.then((json) => {
				dispatch(finishFetchingPackage(json));
			})
			.catch((error) => {
				error.response.json()
					.then((json) => {
						dispatch(failedFetchingPackage(json));
					});
			});
	};
};


// Processing editing Package
const startEditPackage = () => {
	return { type: START_EDIT_PACKAGE };
};

const finishEditPackage = (data) => {
	return {
		type: FINISHED_EDIT_PACKAGE,
		data: data,
		receivedAt: Date.now()
	};
};

const failedEditPackage = (data) => {
	return {
		type: FAILED_EDIT_PACKAGE,
		errors: data,
		receivedAt: Date.now()
	};
};

export const editPackage = (sendData, editApiUrl) => {
	return (dispatch, getState) => {
		dispatch(startEditPackage());
		const { root: { authToken }} = getState();
		const config = {
			isAuth: authToken,
			method: "PUT"
		};

		const response = request(editApiUrl, config, sendData);
		return response
			.then((json) => {
				dispatch(finishEditPackage(json));
				history.push(frontendUrls.urlDashboardPackages);
			})
			.catch((error) => {
				error.response.json()
					.then((json) => {
						dispatch(failedEditPackage(json));
					});
			});
	};
};

//Processing deletion Package
const startDeletePackage = () => {
	return { type: START_DELETE_PACKAGE };
};

const finishDeletePackage = () => {
	return {
		type: FINISHED_DELETE_PACKAGE,
		data: {},
		receivedAt: Date.now()
	};
};

const failedDeletePackage = (data) => {
	return {
		type: FAILED_DELETE_PACKAGE,
		errors: data,
		receivedAt: Date.now()
	};
};


export const deletePackage = (editApiUrl) => {
	return (dispatch, getState) => {
		dispatch(startDeletePackage());
		const { root: { authToken }} = getState();
		const config = {
			isAuth: authToken,
			method: "DELETE"
		};

		const response = request(editApiUrl, config, {}, true);
		return response
			.then(() => {
				dispatch(finishDeletePackage());
				history.push(frontendUrls.urlDashboardPackages);
			})
			.catch((error) => {
				error.response.json()
					.then((json) => {
						dispatch(failedDeletePackage(json));
					});
			});
	};
};
