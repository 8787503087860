import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { fetchSelectedEvent, editEvent, deleteEvent } from "actions/events/edit_event";
import { WEEKLY, MONTHLY, DAILY, NONE } from "backend_constants/eventRepeat";
import weekDays from "backend_constants/weekDays";
import EditEventComponent from "components/events/edit_event";
import { formatDate, getDateTime } from "components/utils";
import { Spinner } from "components/utils/spinner";
import apiUrls from "routes/api";


class EditEventContainer extends React.Component {

	componentDidMount() {
		const { dispatch, match } = this.props;
		const apiUrl = apiUrls.eventEditUrl.replace(":id", match.params.id);
		dispatch(fetchSelectedEvent(apiUrl));
	}

	onDelete = () => {
		const { dispatch, match } = this.props;
		const apiUrl = apiUrls.eventEditUrl.replace(":id", match.params.id);
		dispatch(deleteEvent(apiUrl));
	}

	onSubmit = (values) => {
		// we need to calculate duration
		let now = new Date();
		let [startHour,
			startMinute] = values
			.fromTime
			.split(":");
		let startDateTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), startHour, startMinute);
		let [finishHour,
			finishMinute] = values
			.toTime
			.split(":");
		let finishDateTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), finishHour, finishMinute);
		// we need duration in minutes, but difference between datetimes is in the
		// milliseconds
		let duration = (finishDateTime - startDateTime) / 1000 / 60;

		let sendData = {
			title: values.title,
			category: values.category,
			capacity: values.capacity,
			pay_at_venue: values.payAtVenue,
			cancellation_policy: values.cancellationPolicy,
			description: values.description,
			from_date: formatDate(values.fromDate),
			until_date: formatDate(values.toDate),
			duration: duration,
			location: values.location,
			instructor: values.instructor
		};
		if (!values.free) {
			sendData.price = values.price;
		} else {
			sendData.price = 0;
		}
		if (!values.repeats) {
			sendData.repeat = NONE;
			sendData.repeat_times = [
				{
					start_time: values.fromTime
				}
			];
		} else {
			sendData.repeat = values.repeatChoice;
			if (values.repeatChoice === WEEKLY) {
				sendData.repeat_times = [
					{
						start_time: values.fromTime,
						weekly_days: weekDays.map(function (weeklyArray) {
							if (values[weeklyArray[1]]) {
								return weeklyArray[0];
							}
							return -1;
						})
							.filter(function (value) {
								return value > -1;
							})
					}
				];
			} else if (values.repeatChoice === MONTHLY) {
				let days = values
					.daysOfTheMonth
					.split(",");
				sendData.repeat_times = [
					{
						start_time: values.fromTime,
						days: days.map((day) => parseInt(day))
					}
				];
			} else if (values.repeatChoice === DAILY) {
				sendData.repeat_times = [
					{
						start_time: values.fromTime
					}
				];
			}

			const {initialValues} = this.props;
			if (getDateTime(initialValues.until_date) < now){
				sendData.repeat_times = [
					{start_time: initialValues.repeat_times[0]["start_time"], removed : true},
					...sendData.repeat_times
				]
			}
		}
		const { dispatch, match } = this.props;
		const apiUrl = apiUrls.eventEditUrl.replace(":id", match.params.id);
		dispatch(editEvent(sendData, apiUrl));
	}

	render() {
		let sendProps = {
			onSubmit: this.onSubmit,
			onDelete: this.onDelete,
			errors: this.props.errors,
			initialValues: this.props.initialValues,
			isTimetableEditable: this.props.initialValues.avaliable_to_edit_timetable
		};
		return this.props.isFetching ? <Spinner/> : <EditEventComponent {...sendProps} />;
	}
}

EditEventContainer.propTypes = {
	match: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	errors: PropTypes.object.isRequired,
	initialValues: PropTypes.object,
	isFetching: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		errors: state.editEventRoot.errors || {},
		initialValues: state.editEventRoot.selectedEvent || {},
		isFetching: state.editEventRoot.isFetching || false
	};
};

const mapDispatchToProps = (dispatch) => {
	return { dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditEventContainer);
