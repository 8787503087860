import Calendar from "components/calendar/calendar";
import { CustomerToolbarComponent, DashboardToolbarComponent } from "components/calendar/config/toolbars";
import { PozenEventsPopover, PozenEvent, PozenDashboardEvent } from "components/calendar/config/views/utils";
import { CustomerAgendaEvent, DashboardAgendaEvent } from "components/calendar/config/views/agenda";


const CustomerPopover = {
	WrappComponent: PozenEventsPopover,
	EventRow: PozenEvent
};


const CustomerCalendar = Calendar(CustomerToolbarComponent, CustomerPopover, CustomerAgendaEvent);


const DashboardPopover = {
	WrappComponent: PozenEventsPopover,
	EventRow: PozenDashboardEvent
};


const DashboardCalendar = Calendar(DashboardToolbarComponent, DashboardPopover, DashboardAgendaEvent, true);


export { CustomerCalendar, DashboardCalendar };