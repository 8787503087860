import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Spinner } from "components/utils/spinner";
import EditPackageComponent from "components/packages/edit_package";
import { withCategories } from "components/utils/hoc";

import {
	fetchSelectedPackage, editPackage, deletePackage
} from "actions/packages/edit_package";
import { LIMITED } from "backend_constants/limitChoice";
import { HARD_EXPIRY, SOFT_EXPIRY } from "backend_constants/packageTimeLimit";
import apiUrls from "routes/api";



class EditPackageContainer extends React.Component {

	componentDidMount() {
		const { dispatch, match } = this.props;
		let apiUrl = apiUrls.packageDetailUrl.replace(":id", match.params.id);
		dispatch(fetchSelectedPackage(apiUrl));
	}


	onDelete = () => {
		const { dispatch, match } = this.props;
		let apiUrl = apiUrls.packageDetailUrl.replace(":id", match.params.id);
		dispatch(deletePackage(apiUrl));
	}

	onSubmit = (values) => {
		const { dispatch, match, categories } = this.props;
		let sendData = {
			title: values.title,
			description: values.description,
			price: values.price,
			time_limits: values.timeLimitsChoice,
			category_limits: values.categoryLimitsChoice,
			usage_limits: values.usageLimitsChoice,
			cancellation_policy: values.cancellation_policy
		};
		let soft_expiry_limit = values.softExpiryLimit * 24 * 60 * 60;

		if (values.timeLimitsChoice === HARD_EXPIRY || values.timeLimitsChoice === HARD_EXPIRY.toString()) {
			sendData.hard_expiry_limit = values.hardExpiryLimit;
		} else if (values.timeLimitsChoice === SOFT_EXPIRY.toString()) {
			sendData.soft_expiry_limit = soft_expiry_limit;
		}

		if (values.categoryLimitsChoice === LIMITED || values.categoryLimitsChoice === LIMITED.toString()) {
			sendData.categories = categories.map(function (categoryArray) {
				if (values[categoryArray[1]]) {
					return categoryArray[0];
				}
				return -1;
			})
				.filter(function (value) {
					return value > -1;
				});
		}

		if (values.usageLimitsChoice === LIMITED || values.usageLimitsChoice === LIMITED.toString()) {
			sendData.usage_limit = values.usageLimit;
		}

		let apiUrl = apiUrls.packageDetailUrl.replace(":id", match.params.id);
		dispatch(editPackage(sendData, apiUrl));
	}

	render() {
		const { isFetching, errors, initialValues } = this.props;
		let sendProps = {
			onSubmit: this.onSubmit,
			onDelete: this.onDelete,
			errors,
			initialValues
		};
		return	isFetching ? <Spinner/> : <EditPackageComponent {...sendProps}/>;
	}
}

EditPackageContainer.propTypes = {
	match: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	errors: PropTypes.object.isRequired,
	initialValues: PropTypes.object,
	isFetching: PropTypes.bool,
	categories: PropTypes.array.isRequired
};

const mapStateToProps = (state) => {
	return {
		errors: state.editPackageRoot.errors || {},
		initialValues: state.editPackageRoot.selectedPackage || {},
		isFetching: state.editPackageRoot.isFetching || false,
	};
};

const mapDispatchToProps = (dispatch) => {
	return { dispatch };
};

export default connect(
	mapStateToProps, mapDispatchToProps
)(withCategories(EditPackageContainer));
