/*action types*/
export const START_GET_ORDERS = "START_GET_ORDERS";
export const FINISHED_GET_ORDERS = "FINISHED_GET_ORDERS";
export const FINISHED_GET_EXPIRED_ORDERS = "FINISHED_GET_EXPIRED_ORDERS";
export const FINISHED_GET_ACTIVE_EVENTS_ORDERS = "FINISHED_GET_ACTIVE_EVENTS_ORDERS";
export const FINISHED_GET_ACTIVE_MEMBERSHIP_ORDERS = "FINISHED_GET_ACTIVE_MEMBERSHIP_ORDERS";
export const FINISHED_GET_ACTIVE_PACKAGES_ORDERS = "FINISHED_GET_ACTIVE_PACKAGES_ORDERS";
export const FINISHED_GET_EXPIRED_EVENTS_ORDERS = "FINISHED_GET_EXPIRED_EVENTS_ORDERS";
export const FINISHED_GET_EXPIRED_MEMBERSHIP_ORDERS = "FINISHED_GET_EXPIRED_MEMBERSHIP_ORDERS";
export const FINISHED_GET_EXPIRED_PACKAGES_ORDERS = "FINISHED_GET_EXPIRED_PACKAGES_ORDERS";
export const START_GETTING_ORDERS_LIST = "START_GETTING_ORDERS_LIST";
export const RECEIVED_ORDERS_LIST = "RECEIVED_ORDERS_LIST";
export const FAILED_RECEIVE_ORDERS_LIST = "FAILED_RECEIVE_ORDERS_LIST";