import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { fetchSelectedBookingMembership } from "actions/booking/memberships/membershipDetail";
import BookingDetailComponent from "components/booking/memberships/membershipDetail";
import { withAuth } from "components/utils/hoc";
import { Spinner } from "components/utils/spinner";

import apiUrls from "routes/api";


class BookMembershipDetailContainer extends React.Component {
	componentDidMount() {
		const { match, dispatch } = this.props;
		let apiUrl = apiUrls.membershipDetailUrl.replace(":id", match.params.id);
		dispatch(fetchSelectedBookingMembership(apiUrl));
	}

	render() {
		const { isFetching, selectedBookingMembership, location: {state} } = this.props;

		return(
			isFetching ? <Spinner/> :
				<BookingDetailComponent selectedBookingMembership={selectedBookingMembership}
					buy={state ? state.buy : true}/>
		);
	}
}

const AuthBookMembershipDetailContainer = withAuth(BookMembershipDetailContainer);

BookMembershipDetailContainer.propTypes = {
	match: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	selectedBookingMembership: PropTypes.object.isRequired,
	isFetching: PropTypes.bool,
	location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
	return {
		errors: state.bookMembershipDetailRoot.errors || {},
		selectedBookingMembership: state.bookMembershipDetailRoot.selectedBookingMembership || {},
		isFetching: state.bookMembershipDetailRoot.isFetching,
	};
};

const mapDispatchToProps = (dispatch) => {
	return { dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthBookMembershipDetailContainer);
