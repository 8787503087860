import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import FacilityAdditionalServicesComponent from "components/facilities/facility_additional_services";
import {Spinner} from "components/utils/spinner";
import {isEmpty} from "components/utils";

import {
	fetchFacilityAdditionalServices,
	fetchCreateFacilityAdditionalServices,
	fetchUpdateFacilityAdditionalServices,
	fetchDeleteFacilityAdditionalServices
} from "actions/facilities/additional_services";


const FacilityAdditionalServicesContainer = ({dispatch, services, errors, isFetching}) => {

	const [isEditPage, setEditPage] = useState(false);

	useEffect(() => {dispatch(fetchFacilityAdditionalServices());}, []);
	useEffect(() => setEditPage(!isEmpty(services)), [services]);

	const submitChanges = (updateData) => {
		const preparedData = {
			...updateData,
			events_workshop_url: updateData.events_workshop_url || "",
			massage_url: updateData.massage_url || "",
			shop_url: updateData.shop_url || "",
			parq_form_url: updateData.parq_form_url || ""
		};

		if(isEditPage){
			dispatch(fetchUpdateFacilityAdditionalServices(services.id, preparedData));
		}else{
			dispatch(fetchCreateFacilityAdditionalServices(preparedData));
		}
	};

	const submitDelete = () => {
		dispatch(fetchDeleteFacilityAdditionalServices(services.id));
	};

	const clearValues = {
		events_workshop_url: "",
		show_events_workshop_url: false,
		massage_url: "",
		show_massage_url: false,
		shop_url: "",
		show_shop_url: false,
		parq_form_url: "",
		show_parq_form_url: false
	};

	const componentProps = {
		services: isEditPage ? services : clearValues,
		errors, isEditPage, submitChanges, submitDelete
	};
	return isFetching ? <Spinner/> : <FacilityAdditionalServicesComponent {...componentProps}/>;
};


FacilityAdditionalServicesContainer.propTypes = {
	dispatch: PropTypes.func.isRequired,
	services: PropTypes.arrayOf(PropTypes.object).isRequired,
	errors: PropTypes.object.isRequired,
	isFetching: PropTypes.bool.isRequired
};


const mapStateToProps = ({facilityAdditionalServicesRoot: {isFetching, errors, services}}) => {
	return { isFetching, errors, services };
};


export default connect(mapStateToProps)(FacilityAdditionalServicesContainer);