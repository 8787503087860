import {
	START_REGISTER,
	FINISHED_REGISTER,
	FAILED_REGISTER,
	START_ACTIVATE_ACCOUNT,
	FINISHED_ACTIVATE_ACCOUNT,
	FAILED_ACTIVATE_ACCOUNT,
} from "actions/consts";
import { checkLogin } from "actions/auth/login";
import { request } from "actions/utils";
import apiUrls from "routes/api";


// Processing register
const startRegister = () => {
	return { type: START_REGISTER, authToken: "" };
};

const finishRegister = (data) => {
	return {
		type: FINISHED_REGISTER,
		data: data,
		receivedAt: Date.now()
	};
};

const failedRegister = (data) => {
	return {
		type: FAILED_REGISTER,
		errors: data,
		receivedAt: Date.now()
	};
};


export const fetchRegister = (first_name, last_name, email, password, birthday) => {
	return (dispatch) => {
		dispatch(startRegister());
		const config = {
			method: "POST"
		};
		const body = {
			first_name: first_name,
			last_name: last_name,
			email: email,
			password: password,
			birthday: birthday
		};

		const response = request(apiUrls.registerUrl, config, body);

		return response
			.then(json => dispatch(finishRegister(json)))
			.catch((error) => {
				error.response.json()
					.then((json) => {
						dispatch(failedRegister(json));
					});
			});
	};
};


const startActivateAccount = () => {
	return {type: START_ACTIVATE_ACCOUNT};
};

const finishActivateAccount = () => {
	return {type: FINISHED_ACTIVATE_ACCOUNT};
};

const failedActivateAccount = (error) => {
	return {
		type: FAILED_ACTIVATE_ACCOUNT,
		error: error,
		receivedAt: Date.now()
	};
};


export const fetchActivateAccount = (token, uid) => {
	return (dispatch) => {
		dispatch(startActivateAccount());
		const config = {
			method: "POST"
		};

		const body = {token, uid};

		const response = request(apiUrls.activateAccountUrl, config, body, true);

		return response
			.then(response => {
				const status = response.status;
				response.json().then(json => {
					dispatch(finishActivateAccount());
					checkLogin(dispatch, json, status);
				})
			})
			.catch((error) => {
				error.response.json()
					.then((json) => {
						dispatch(failedActivateAccount(json));
					});
			});

	};
};