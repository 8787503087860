import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { doLogout } from "actions/auth/logout";
import BookingBodyComponent from "components/elements/booking_pages/booking_body";
import { setToMenuClose } from "actions/util_actions";


class BookingBodyContainer extends React.Component {
	constructor(props) {
		super(props);
		this.onClickLogout = this
			.onClickLogout
			.bind(this);
	}

	onClickLogout() {
		this
			.props
			.dispatch(doLogout());
	}

	render() {
		const { isMenuOpen, setToMenuCloseDispatched } = this.props;
		let sendProps = {
			isMenuOpen,
			setToMenuCloseDispatched,
			onClickLogout: this.onClickLogout
		};
		return (<BookingBodyComponent {...sendProps} />);
	}
}

BookingBodyContainer.propTypes = {
	dispatch: PropTypes.func.isRequired,
	isMenuOpen: PropTypes.bool.isRequired,
	setToMenuCloseDispatched: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
	return {
		isMenuOpen: state.utilRoot.isMenuOpen,
	};
};


export default connect(mapStateToProps, {
	setToMenuCloseDispatched: setToMenuClose,
})(BookingBodyContainer);
