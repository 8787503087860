import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import MenuComponent from "../../../components/elements/dashboard_pages/menu";


class MenuContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false
		};
		this.onClickDropdown = this
			.onClickDropdown
			.bind(this);
	}

	onClickDropdown() {
		this.setState({
			open: !this.state.open
		});
	}

	render() {
		let sendProps = {
			state: this.state,
			onClickDropdown: this.onClickDropdown
		};
		return (<MenuComponent {...sendProps} />);
	}
}

MenuContainer.propTypes = {
	dispatch: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return { dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuContainer);
