import {
	SET_TO_ZERO_CREATEDORDER,
	TOGGLE_MENU,
	CLOSE_MENU,
	DASHBOARD_SELECTED_EVENT,
	GET_CATEGORIES,
	RECEIVED_CATEGORIES,
	INTERNAL_SERVER_ERROR,
	RESET_HTTP_ERRORS, 
	GET_FACILITY_STRIPE_ACCOUNT,
	RECEIVE_FACILITY_STRIPE_ACCOUNT
} from "actions/consts";


const initialState = {
	isFetching: false,
	errors: {},
	createdOrder: {},
	isMenuOpen: false,
	selectedEvent: {},
	categories: [],
	httpErrors: null,
	stripeAccount: {}
};

export default function utilReducer(state = initialState, action) {
	switch (action.type) {

	case SET_TO_ZERO_CREATEDORDER:
		return {
			...state,
			isFetching: false,
			createdOrder: {},
			errors: {}
		};

	case TOGGLE_MENU:
		return {
			...state,
			isMenuOpen: !state.isMenuOpen,
		};

	case CLOSE_MENU:
		return {
			...state,
			isMenuOpen: !state.isMenuOpen,
		};

	case DASHBOARD_SELECTED_EVENT:
		return {
			...state,
			selectedEvent: action.data,
		};
	
	case GET_CATEGORIES:
		return{
			...state,
		};

	case RECEIVED_CATEGORIES:
		return{
			...state,
			categories: action.categories
		};

	case INTERNAL_SERVER_ERROR:
		return{
			...state,
			httpErrors: action.error
		};
	
	case RESET_HTTP_ERRORS:
		return{
			...state,
			httpErrors: null
		};
	
	case GET_FACILITY_STRIPE_ACCOUNT:
		return {
			...state,
			stripeAccount: {}
		};
	
	case RECEIVE_FACILITY_STRIPE_ACCOUNT:
		return {
			...state,
			stripeAccount: action.account
		};
	
	default:
		return state;
	}
}
