import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { fetchCreationPackage, clearPackageErrors } from "actions/packages/create_package";
import { HARD_EXPIRY, SOFT_EXPIRY } from "backend_constants/packageTimeLimit";
import { LIMITED } from "backend_constants/limitChoice";

import CreatePackageComponent from "components/packages/create_package";
import { withCategories } from "components/utils/hoc";
import { Spinner } from "components/utils/spinner";


class CreatePackageContainer extends React.Component {

	componentDidMount(){
		this.resetPackageErrors();
	}

	resetPackageErrors = () => {
		this.props.dispatch(clearPackageErrors());
	}

	onSubmit = (values) => {
		let sendData = {
			title: values.title,
			description: values.description,
			price: values.price,
			time_limits: values.timeLimitsChoice,
			category_limits: values.categoryLimitsChoice,
			usage_limits: values.usageLimitsChoice,
			cancellation_policy: values.cancellation_policy
		};
		let soft_expiry_limit = values.softExpiryLimit * 24 * 60 * 60;

		if (values.timeLimitsChoice === HARD_EXPIRY.toString()) {
			sendData.hard_expiry_limit = values.hardExpiryLimit;
		} else if (values.timeLimitsChoice === SOFT_EXPIRY.toString()) {
			sendData.soft_expiry_limit = soft_expiry_limit;
		}

		if (values.categoryLimitsChoice === LIMITED.toString()) {
			sendData.categories = this.props.categories.map(function (categoryArray) {
				if (values[categoryArray[1]]) {
					return categoryArray[0];
				}
				return -1;
			})
				.filter(function (value) {
					return value > -1;
				});
		}

		if (values.usageLimitsChoice === LIMITED.toString()) {
			sendData.usage_limit = values.usageLimit;
		}

		this.props.dispatch(fetchCreationPackage(sendData));
	}

	render() {
		const { errors, isFetching } = this.props;
		let sendProps = {
			errors,
			onSubmit: this.onSubmit,
			resetPackageErrors: this.resetPackageErrors
		};
		return isFetching ? <Spinner/> : <CreatePackageComponent {...sendProps} />;
	}
}

CreatePackageContainer.propTypes = {
	dispatch: PropTypes.func.isRequired,
	errors: PropTypes.object.isRequired,
	categories: PropTypes.array.isRequired,
	isFetching: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		errors: state.creationPackageRoot.errors || {},
		isFetching: state.creationPackageRoot.isFetching || false
	};
};

const mapDispatchToProps = (dispatch) => {
	return { dispatch };
};

export default connect(
	mapStateToProps, mapDispatchToProps
)(withCategories(CreatePackageContainer));
