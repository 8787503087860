import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { fetchSelectedBookingPackage } from "actions/booking/packages/packageDetail";
import BookingDetailComponent from "components/booking/packages/packageDetail";
import { withAuth } from "components/utils/hoc";
import { Spinner } from "components/utils/spinner";

import apiUrls from "routes/api";


class BookPackageDetailContainer extends React.Component {
	componentDidMount() {
		const { match, dispatch } = this.props;
		let apiUrl = apiUrls.packageDetailUrl.replace(":id", match.params.id);
		dispatch(fetchSelectedBookingPackage(apiUrl));
	}

	render() {
		const {isFetching, selectedBookingPackage, location: { state }} = this.props;

		return(
			isFetching ? <Spinner/> : 
				<BookingDetailComponent 
					selectedBookingPackage={ state ? state.item : selectedBookingPackage}
					isBuyPage={state ? state.buy : true} 
					isMyOrdersPage={state ? state.isMyOrdersPage : false}
					usageLeft={state ? state.usageLeft : 0}
					columnOffset={state ? state.columnOffset: 2}/>
		);
	}
}

const AuthBookPackageDetailContainer = withAuth(BookPackageDetailContainer);

BookPackageDetailContainer.propTypes = {
	match: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	selectedBookingPackage: PropTypes.object.isRequired,
	isFetching: PropTypes.bool,
	location: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
	return {
		errors: state.bookPackageDetailRoot.errors || {},
		selectedBookingPackage: state.bookPackageDetailRoot.selectedBookingPackage || {},
		isFetching: state.bookPackageDetailRoot.isFetching,
	};
};

const mapDispatchToProps = (dispatch) => {
	return { dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthBookPackageDetailContainer);
