import React from "react";
import PropTypes from "prop-types";
import {Col} from "reactstrap";
import PackageForm from "components/forms/packages/package_form";


const CreatePackageComponent = ({ onSubmit, resetPackageErrors, errors }) => {
	return (
		<Col sm="12" md="11" className="mx-auto">
			<p className="margin_top_15px margin_bottom_20px font_24_regular_black_list">Create Packages</p>
			<PackageForm onSubmit={onSubmit} errors={errors} resetPackageErrors={resetPackageErrors}/>
		</Col>
	);
};

CreatePackageComponent.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	resetPackageErrors: PropTypes.func,
	errors: PropTypes.object
};

export default CreatePackageComponent;
