import React from "react";
import PropTypes from "prop-types";

import { Form, Field } from "react-final-form";
import { Container, Row, Col } from "reactstrap";

import { fieldInput, fieldSwitch } from "components/forms/fields";
import ManageFormComponent from "components/forms/utils";
import frontendUrls from "routes/frontend_routes";


const FacilityAdditionalServicesForm = ({initialValues, isEditForm, errors, onSubmit, onDelete}) => {

	return(
		<Container className="bg_white shadow_form border_radius_03rem mb-5">
			<Form
				initialValues={initialValues}
				onSubmit={onSubmit}
				render={({handleSubmit, pristine, values, submitting }) => (
					<form className="bg_white pt-3" onSubmit={handleSubmit}>
						<Row>
							<Col xs={10}>
								<Field
									name="events_workshop_url"
									label="Events-Workshop"
									serverError={errors.events_workshop_url}
									component={fieldInput}/>
							</Col>
							<Col xs={2} className="d-flex justify-content-center align-items-center">
								<Field id={0}
									name="show_events_workshop_url"
									disabled={!values.events_workshop_url}
									component={fieldSwitch}/>
							</Col>
						</Row>
						<Row>
							<Col xs={10}>
								<Field
									name="massage_url"
									label="Massage"
									serverError={errors.massage_url}
									component={fieldInput}/>
							</Col>
							<Col xs={2} className="d-flex justify-content-center align-items-center">
								<Field id={1} name="show_massage_url" component={fieldSwitch} disabled={!values.massage_url}/>
							</Col>
						</Row>
						<Row>
							<Col xs={10}>
								<Field
									name="shop_url"
									label="Shop"
									serverError={errors.shop_url}
									component={fieldInput}/>
							</Col>
							<Col xs={2} className="d-flex justify-content-center align-items-center">
								<Field id={2} name="show_shop_url" component={fieldSwitch} disabled={!values.shop_url}/>
							</Col>
						</Row>
						<Row>
							<Col xs={10}>
								<Field
									name="parq_form_url"
									label="PARQ form"
									serverError={errors.parq_form_url}
									component={fieldInput}/>
							</Col>
							<Col xs={2} className="d-flex justify-content-center align-items-center">
								<Field id={3} name="show_parq_form_url" component={fieldSwitch} disabled={!values.parq_form_url}/>
							</Col>
						</Row>
						<ManageFormComponent
							errors={errors}
							isSubmitedForm={submitting}
							isPristineForm={pristine}
							isEditForm={isEditForm}
							product="services"
							onDelete={onDelete}/>
					</form>

				)}
			></Form>
		</Container>
	);
};


FacilityAdditionalServicesForm.propTypes = {
	initialValues: PropTypes.object.isRequired,
	isEditForm: PropTypes.bool.isRequired,
	errors: PropTypes.object.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onDelete: PropTypes.func
};


export default FacilityAdditionalServicesForm;
