import React, {Component} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import CustomersComponent from "components/customers/customers";
import { withAuth } from "components/utils/hoc";
import { Spinner } from "components/utils/spinner";

import fetchCustomers from "actions/customers/get_customers_list";
import { fetchDeleteCustomer } from "actions/customers/edit_customer";
import { resetEditErrors } from "actions/util_actions";


class CustomersContainer extends Component {

	state = {
		error: null,
		show: false,
		customerToDelete: {}
	}

	componentDidMount(){
		const { dispatch, authToken } = this.props;
		dispatch(fetchCustomers(authToken));
	}

	componentDidUpdate(prevProps, prevState){
		const { dispatch, errors, authToken } = this.props;
		if(prevProps.errors !== errors){
			this.setState({error: errors.message});
			if(errors === {}){
				this.setState({customerToDelete: {}});
			}
		}
		if(prevState.customerToDelete !== this.state.customerToDelete){
			dispatch(fetchCustomers(authToken));
		}
	}
	
	onDelete = (customer) => {
		this.props.dispatch(fetchDeleteCustomer(customer.id));
		this.setState({customerToDelete: {}});
		this.showModal(false);
	}

	deleteCustomer = (customer) => {
		this.setState({customerToDelete: customer});
		this.showModal(true);
	}

	showModal = (show) => {
		this.setState({show: show});
		if (!show){
			this.props.dispatch(resetEditErrors());
		}
	}

	render(){
		const { show, customerToDelete, error } = this.state;
		const { isFetching, customers } = this.props;

		const customersProps = {
			show,
			error,
			customers,
			customerToDelete,
			showModal: this.showModal,
			deleteCustomer: this.deleteCustomer,
			onDelete: this.onDelete
		};
		
		return isFetching ? <Spinner/> : <CustomersComponent {...customersProps}/>;
	}

}


CustomersContainer.propTypes = {
	authToken: PropTypes.string.isRequired,
	dispatch: PropTypes.func.isRequired,
	customers: PropTypes.arrayOf(PropTypes.object),
	isFetching: PropTypes.bool,
	errors: PropTypes.object
};


const mapStateToProps = ({customersRoot, editCustomerRoot}) => {
	return{
		customers: customersRoot.customers || [],
		isFetching: customersRoot.isFetching || false,
		errors: editCustomerRoot.errors || {}
	};
};


export default connect(mapStateToProps)(withAuth(CustomersContainer));