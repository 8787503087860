import React, {Component} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { fetchBookingMemberships } from "actions/booking/memberships/memberships";
import BookingMembershipsComponent from "components/booking/memberships/memberships";
import { Spinner } from "components/utils/spinner";


class BookingMembershipsContainer extends Component {

	componentDidMount() {
		this.props.dispatch(fetchBookingMemberships());
	}

	render() {
		const { isFetching, bookingMemberships } = this.props;
		const sendProps = { bookingMemberships, isFetching};
		return	isFetching ? <Spinner/> : <BookingMembershipsComponent {...sendProps}/>;
	}
}

BookingMembershipsContainer.propTypes = {
	dispatch: PropTypes.func.isRequired,
	bookingMemberships: PropTypes.array,
	isFetching: PropTypes.bool,
};

const mapStateToProps = (state) => {
	return {
		bookingMemberships: state.bookingMembershipsRoot.bookingMemberships || [],
		isFetching: state.bookingMembershipsRoot.isFetching || false,
	};
};

const mapDispatchToProps = (dispatch) => {
	return { dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingMembershipsContainer);
