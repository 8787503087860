import {
	START_FETCHING_CUSTOMER,
	FINISHED_FETCHING_CUSTOMER,
	FAILED_FETCHING_CUSTOMER,
	START_EDIT_CUSTOMER,
	FINISHED_EDIT_CUSTOMER,
	FAILED_EDIT_CUSTOMER,
	START_DELETE_CUSTOMER,
	FINISHED_DELETE_CUSTOMER,
	FAILED_DELETE_CUSTOMER,
	START_FETCHING_CUSTOMER_ORDERS,
	FINISHED_FETCHING_CUSTOMER_ORDERS,
	FAILED_FETCHING_CUSTOMER_ORDERS,
	START_DELETE_CUSTOMER_ORDER,
	FINISHED_DELETE_CUSTOMER_ORDER,
	FAILED_DELETE_CUSTOMER_ORDER
} from "actions/consts";
import { history } from "store";
import { request } from "actions/utils";
import apiUrls from "routes/api";
import frontendUrls from "routes/frontend_routes";


const startFetchingCustomer = () => {
	return { type: START_FETCHING_CUSTOMER };
};


const finishedFetchingCustomer = (data) => {
	return {
		type: FINISHED_FETCHING_CUSTOMER,
		data: data,
		receivedAt: Date.now()
	};
};


const failedFetchingCustomer = (errors) => {
	return {
		type: FAILED_FETCHING_CUSTOMER,
		errors: errors,
		receivedAt: Date.now()
	};
};


export const fetchSelectedCustomer = (customerId) => {
	return (dispatch, getState) => {
		dispatch(startFetchingCustomer());
		const { root: {authToken}} = getState();
		const config = {
			isAuth: authToken,
			method: "GET",
		};

		const detailsUrl = apiUrls.editCustomerUrl.replace(":id", customerId);
		const response = request(detailsUrl, config);
		return response
			.then((json) => dispatch(finishedFetchingCustomer(json)))
			.catch((errors) => dispatch(failedFetchingCustomer(errors)));
	};
};


const startEditCustomer = () => {
	return { type: START_EDIT_CUSTOMER };
};


const finishedEditCustomer = (data) => {
	return {
		type: FINISHED_EDIT_CUSTOMER,
		data: data,
		receivedAt: Date.now()
	};
};


const failedEditCustomer = (errors) => {
	return {
		type: FAILED_EDIT_CUSTOMER,
		errors: errors,
		receivedAt: Date.now()
	};
};


export const fetchEditCustomer = (customerId, editData) => {
	return (dispatch, getState) => {
		dispatch(startEditCustomer());
		const { root: { authToken }} = getState();
		const config = {
			isAuth: authToken,
			method: "PATCH"
		};

		const editUrl = apiUrls.editCustomerUrl.replace(":id", customerId);
		const response = request(editUrl, config, editData);
		return response
			.then((data) => {
				dispatch(finishedEditCustomer(data));
				history.push(frontendUrls.urlDashboardCustomers);
			})
			.catch((error) =>
				error.response.json()
					.then((json) => {
						dispatch(failedEditCustomer(json));
					})
			);
	};
};


const startDeleteCustomer = () => {
	return { type: START_DELETE_CUSTOMER };
};


const finishedDeleteCustomer = () => {
	return {
		type: FINISHED_DELETE_CUSTOMER,
		data: {},
		receivedAt: Date.now()
	};
};


const failedDeleteCustomer = (errors) => {
	return {
		type: FAILED_DELETE_CUSTOMER,
		errors: errors,
		receivedAt: Date.now()
	};
};


export const fetchDeleteCustomer = (customerId) => {
	return (dispatch, getState) => {
		dispatch(startDeleteCustomer());
		const { root: { authToken }} = getState();
		const config = {
			isAuth: authToken,
			method: "DELETE"
		};

		const deleteUrl = apiUrls.editCustomerUrl.replace(":id", customerId);
		const response = request(deleteUrl, config, {}, true);
		return response
			.then(() => {
				dispatch(finishedDeleteCustomer());
				history.push(frontendUrls.urlDashboardCustomers);
			})
			.catch((error) => {
				error.response.json()
					.then((json) => {
						dispatch(failedDeleteCustomer(json));
					});
			});
	};
};


const startFetchingCustomerOrders = () => {
	return { type: START_FETCHING_CUSTOMER_ORDERS };
};


const finishedFetchingCustomerOrders = (data) => {
	return {
		type: FINISHED_FETCHING_CUSTOMER_ORDERS,
		orders: data,
		receivedAt: Date.now()
	};
};


const failedFetchingCustomerOrders = (errors) => {
	return {
		type: FAILED_FETCHING_CUSTOMER_ORDERS,
		errors: errors,
		receivedAt: Date.now()
	};
};


export const fetchCustomerOrders = (customerId) => {
	return (dispatch, getState) => {
		dispatch(startFetchingCustomerOrders());
		const { root: { authToken }} = getState();
		const config = {
			isAuth: authToken,
			method: "GET",
			params:{
				"cancelled": false
			}
		};
		const ordersUrl = apiUrls.ordersCustomerUrl.replace(":id", customerId);

		const response = request(ordersUrl, config);

		return response
			.then((data) => dispatch(finishedFetchingCustomerOrders(data)))
			.catch((error) => dispatch(failedFetchingCustomerOrders(error)));
	};
};


const startDeleteCustomerOrder = () => {
	return { type: START_DELETE_CUSTOMER_ORDER };
};


const finishedDeleteCustomerOrder = () => {
	return { type: FINISHED_DELETE_CUSTOMER_ORDER };
};


const failedDeleteCustomerOrder = (errors) => {
	return {
		type: FAILED_DELETE_CUSTOMER_ORDER,
		errors: errors,
		receivedAt: Date.now()
	};
};


export const fetchDeleteCustomerOrder = (customerId, orderId) => {
	return (dispatch, getState) => {
		dispatch(startDeleteCustomerOrder());
		const { root: { authToken }} = getState();
		const config = {
			isAuth: authToken,
			method: "DELETE"
		};
		const deteleOrderUrl = apiUrls.orderDetailsCustomerUrl.replace(":id", customerId).replace(":orderId", orderId);

		const response = request(deteleOrderUrl, config, {}, true);

		return response
			.then(() => dispatch(finishedDeleteCustomerOrder()))
			.catch((error) => {
				error.response.json()
					.then((error) => dispatch(failedDeleteCustomerOrder(error)));
			});
	};
};