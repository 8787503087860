import {
	GET_EVENTS,
	RECEIVE_EVENTS
} from "../consts";
import apiUrls from "../../routes/api";


// Processing getting events list
const getEvents = () => {
	return { type: GET_EVENTS};
};

const receiveEvents = (data) => {
	return {
		type: RECEIVE_EVENTS,
		events: data,
		receivedAt: Date.now()
	};
};

export const fetchEvents = () => {
	return (dispatch) => {
		dispatch(getEvents());
		const request = fetch(apiUrls.eventsUrl, { credentials: "same-origin" });
		return request.then(response => response.json(), error => console.log("An error occured.", error)).then(json => dispatch(receiveEvents(json)));
	};
};
