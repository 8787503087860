import React from "react";
import PropTypes from "prop-types";

import { Container } from "reactstrap";
import CustomerForm from "components/forms/customers/customer_form";


const CreateCustomerComponent = ({ onSubmit, errors }) => {
	return(
		<Container className="mt-5 mb-5">
			<p className="font_24_regular_black_list">Create customer</p>
			<CustomerForm onCustomerSubmit={onSubmit} errors={errors}/>
		</Container>
	);
};


CreateCustomerComponent.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	errors: PropTypes.object.isRequired
};


export default CreateCustomerComponent;