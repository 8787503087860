import React, {Component} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { history } from "store";
import frontendUrls from "routes/frontend_routes";
import { CUSTOMER } from "backend_constants/userTypes";


const withAdminRole = (Wrapped) => {
	class AdminContainer extends Component{
		componentDidMount() {
			const { authToken, role } = this.props;
			if (!authToken) {
				history.push(frontendUrls.urlLogin);
			} else if (role === CUSTOMER) {
				history.push("/");
			}
		}

		render(){
			return <Wrapped {...this.props}/>;
		}
	}

	AdminContainer.propTypes = {
		authToken: PropTypes.string,
		role: PropTypes.number.isRequired,
	};

	const mapStateToProps = (state) => {
		return {
			authToken: state.root.authToken || undefined,
			role: state.root.role || CUSTOMER,
		};
	};

	return connect(mapStateToProps)(AdminContainer);
};


export default withAdminRole;