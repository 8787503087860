import React from "react";
import PropTypes from "prop-types";
import { Form, Field } from "react-final-form";
import { Link } from "react-router-dom";

import {
	Row, FormGroup, Input, Col, InputGroup, InputGroupText, InputGroupAddon, Button
} from "reactstrap";
import frontendUrls from "routes/frontend_routes";


const validate = values => {
	const errors = {};
	if (!values.emailOrUsername) {
		errors.emailOrUsername = "This field is required";
	}
	if (!values.password) {
		errors.password = "This field is required";
	}
	return errors;
};

function InputWithAddon({
	input,
	spriteName,
	inputType,
	placeholder,
	serverError,
	meta: {
		touched,
		error
	}
}) {
	let classNameInput = "form-control";
	if((touched && error) || (serverError)) {
		classNameInput = "form-control is-invalid";
	}
	return (
		<FormGroup
			className="margin_0 padding_top_20px">
			<InputGroup className='mb-3'>
				<InputGroupAddon className="light" addonType="prepend">
					<InputGroupText className="bg-white">
						<i className={spriteName}></i>
					</InputGroupText>
				</InputGroupAddon>
				<Input {...input} type={inputType} className={classNameInput} placeholder={placeholder} id={input.name} name={input.name} />
			</InputGroup>
			<div className="margin_0 padding_0">
				{touched && error && <p className="font_danger">{error}</p>}
				{serverError && <p className="font_danger">{serverError}</p>}
			</div>
		</FormGroup>
	);
}

InputWithAddon.propTypes = {
	input: PropTypes.object.isRequired,
	spriteName: PropTypes.string.isRequired,
	inputType: PropTypes.string.isRequired,
	placeholder: PropTypes.string.isRequired,
	serverError: PropTypes.array,
	meta: PropTypes.object.isRequired
};

let LoginForm = (props) => {
	const { onSubmit, errors } = props;
	return (
		<Form
			validate={validate}
			onSubmit={onSubmit}
			render={({ handleSubmit, pristine, submitting }) => (
				<form className="margin_0" onSubmit={handleSubmit}>
					<Field
						name="emailOrUsername"
						spriteName="sprite_user"
						type="email"
						inputType="email"
						placeholder="Email"
						serverError={errors.username}
						component={InputWithAddon} />
					<Field
						name="password"
						spriteName="sprite_lock"
						type="password"
						inputType="password"
						placeholder="Password"
						serverError={errors.password}
						component={InputWithAddon} />
					{errors.non_field_errors && <p className="font_danger">{errors.non_field_errors}</p>}
					<Row className="inline margin_0 pt-2">
						<Col xs={6} className="checkbox padding_left_right_0 d-none">
							<label className="font_14_regular_5e6977">
								<Input type="checkbox" aria-label="Checkbox for following text input" />
								<span className="mr-4 position-relative box"></span>
								Remember me
							</label>
						</Col>
						<Col xs={12} className="d-flex justify-content-end">
							<Link to={frontendUrls.urlResetPassword} className="justify-content-center font_14_regular">
								I forgot my password
							</Link>
						</Col>
					</Row>
					<div className="text-center margin_0 padding_top_30px">
						<Button
							disabled={submitting || pristine}
							type="submit"
							className="primary margin_0 height_40px width_100 bg_button_clr border_color_button_clr font_16_medium border_radius_03rem">
						Login
						</Button>
						<div className="margin_0 padding_top_20px padding_bottom_25px">
							<Link to={frontendUrls.urlRegister}>
								<p className="font_14px">Sign up to your account</p>
							</Link>
						</div>
					</div>
				</form>
			)}
		>
		</Form>
	);
};
LoginForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	errors: PropTypes.object
};

export default LoginForm;