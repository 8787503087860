import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { Row, Col, Container } from "reactstrap";
import PropTypes from "prop-types";
import classNames from "classnames";

import HeaderContainer from "containers/elements/booking_pages/header";
import MenuContainer from "containers/elements/booking_pages/menu";
import BookingEventsContainer from "containers/booking/events/events";
import BookEventDetailContainer from "containers/booking/events/eventDetail";
import EventCheckout from "containers/booking/events/eventBuy";
import BookingMembershipsContainer from "containers/booking/memberships/memberships";
import BookMembershipDetailContainer from "containers/booking/memberships/membershipDetail";
import MembershipCheckout from "containers/booking/memberships/membershipBuy";
import BookingPackagesContainer from "containers/booking/packages/packages";
import BookPackageDetailContainer from "containers/booking/packages/packageDetail";
import PackageCheckout from "containers/booking/packages/packageBuy";
import MyOrdersContainer from "containers/orders/MyOrdersContainer";
import frontendUrls from "routes/frontend_routes";


const BookingBodyComponent = ({isMenuOpen, setToMenuCloseDispatched}) => {
	return (
		<Container fluid className="h-100">
			<HeaderContainer />

			<div onClick={() => {
				if (isMenuOpen) {
					setToMenuCloseDispatched();
				}
			}} className={classNames("h-100 w-100", {
				"overlay position-absolute": isMenuOpen === true,
				"d-none": isMenuOpen !== true,
			})} />
			<Row className="h-100">
				<Col md={2} lg={2} xl={2}	className="px-0 menu-wrapper">
					<MenuContainer />
				</Col>
				<Col className="padding_top_80px shadow_right">
					<Switch>
						<Redirect exact from={frontendUrls.urlRoot} to={frontendUrls.urlMyOrder}/>
						<Route exact path={frontendUrls.urlBookingEvents} component={BookingEventsContainer} />
						<Route exact path={frontendUrls.urlBookingEventId} component={BookEventDetailContainer} />
						<Route path={frontendUrls.urlBookingOrderEvent} component={EventCheckout} />
						<Route exact path={frontendUrls.urlBookingMemberships} component={BookingMembershipsContainer} />
						<Route exact path={frontendUrls.urlBookingMembershipId} component={BookMembershipDetailContainer} />
						<Route path={frontendUrls.urlBookingOrderMembership} component={MembershipCheckout} />
						<Route exact path={frontendUrls.urlPackages} component={BookingPackagesContainer} />
						<Route exact path={frontendUrls.urlBookingPackagesId} component={BookPackageDetailContainer} />
						<Route path={frontendUrls.urlBookingPackagesOrder} component={PackageCheckout} />
						<Route path={frontendUrls.urlBookingFaq} component={BookingEventsContainer} />
						<Route exact path={frontendUrls.urlBookingMyOrders} component={MyOrdersContainer} />
					</Switch>
				</Col>
			</Row>
		</Container>
	);
};

BookingBodyComponent.propTypes = {
	isMenuOpen: PropTypes.bool.isRequired,
	setToMenuCloseDispatched: PropTypes.func.isRequired,
};

export default BookingBodyComponent;
