export let UNLIMITED = 0,
	LIMITED = 1;

export const limits = {
	unlimited: UNLIMITED,
	limited: LIMITED
};

export const limitsChoices = [
	[
		UNLIMITED, "Unlimited"
	],
	[
		LIMITED, "Limited"
	],
];