import React, {useRef, useEffect} from "react";
import PropTypes from "prop-types";

import {Button} from "reactstrap";


const useDisabledElement = (disabled) => {
	const ref = useRef();

	const setDisabled = (value) => {
		if(ref.current){
			ref.current.disabled = value;
		}
	}

	useEffect(() => { setDisabled(disabled);}, [disabled]);
	return [ref, setDisabled];
};


const OneClickButton = ({type, className, disabled, onClickParent, children}) => {
	const [btnRef, setBtnDisabled] = useDisabledElement(disabled);

	const hanldeClick = () => {
		setBtnDisabled(true);
		if(onClickParent){
			onClickParent();
		}
	}

	return(
		<Button ref={btnRef} type={type} className={className} onClick={hanldeClick}>
			{children}
		</Button>
	);
};


OneClickButton.propTypes = {
	type: PropTypes.string,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	children: PropTypes.any,
	onClickParent: PropTypes.func
};


export default OneClickButton;