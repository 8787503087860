import {
	START_EDIT_EVENT,
	FINISHED_EDIT_EVENT,
	FAILED_EDIT_EVENT,
	START_FETCHING_EVENT,
	FINISHED_FETCHING_EVENT,
	DELETE_EVENT_FROM_STORE,
	FAILED_FETCHING_EVENT,
	START_DELETE_EVENT,
	FINISHED_DELETE_EVENT,
	FAILED_DELETE_EVENT,
} from "actions/consts";
import { history } from "store";
import frontendUrls from "routes/frontend_routes";
import { request } from "actions/utils";


// Processing fetching event
const startFetchingEvent = () => {
	return { type: START_FETCHING_EVENT };
};

const finishFetchingEvent = (data) => {
	return {
		type: FINISHED_FETCHING_EVENT,
		data: data,
		receivedAt: Date.now()
	};
};

const failedFetchingEvent = (data) => {
	return {
		type: FAILED_FETCHING_EVENT,
		errors: data,
		receivedAt: Date.now()
	};
};

export const fetchSelectedEvent = (apiUrlSelectedEvent) => {
	return (dispatch, getState) => {
		dispatch(startFetchingEvent);
		const { root: { authToken }} = getState();
		const config = {
			isAuth: authToken
		};

		const response = request(apiUrlSelectedEvent, config);
		return response
			.then((json) => {
				dispatch(finishFetchingEvent(json));
			})
			.catch((error) => {
				error.response.json()
					.then((json) => {
						dispatch(failedFetchingEvent(json));
					});
			});
	};
};


// Processing editing event
const startEditEvent = () => {
	return { type: START_EDIT_EVENT };
};

const finishEditEvent = (data) => {
	return {
		type: FINISHED_EDIT_EVENT,
		data: data,
		receivedAt: Date.now()
	};
};

const failedEditEvent = (data) => {
	return {
		type: FAILED_EDIT_EVENT,
		errors: data,
		receivedAt: Date.now()
	};
};

export const editEvent = (sendData, editApiUrl) => {
	return (dispatch, getState) => {
		dispatch(startEditEvent());
		const { root: { authToken }} = getState();
		const config = {
			isAuth: authToken,
			method: "PUT"
		};
		
		const response = request(editApiUrl, config, sendData);
		return response
			.then((json) => {
				dispatch(finishEditEvent(json));
				history.push(frontendUrls.urlDashboardEvents);
			})
			.catch((error) => {
				error.response.json()
					.then((json) => {
						dispatch(failedEditEvent(json));
					});
			});
	};
};

//Processing deletion event
const startDeleteEvent = () => {
	return { type: START_DELETE_EVENT };
};

const finishDeleteEvent = () => {
	return {
		type: FINISHED_DELETE_EVENT,
		data: {},
		receivedAt: Date.now()
	};
};

const deleteEventFromStore=(idDeleteEvent, getState) => {
	let oldlistEvents=getState().eventsRoot.events;
	let newlistEvents=oldlistEvents.filter(item => item.id !== idDeleteEvent);
	return {
		type: DELETE_EVENT_FROM_STORE,
		data: newlistEvents,
		receivedAt: Date.now()
	};
};

const failedDeleteEvent = (data) => {
	return {
		type: FAILED_DELETE_EVENT,
		errors: data,
		receivedAt: Date.now()
	};
};

export const deleteEvent = (editApiUrl, idDeleteEvent) => {
	return (dispatch, getState) => {
		dispatch(startDeleteEvent());
		const { root: { authToken }} = getState();
		const config = {
			isAuth: authToken,
			method: "DELETE"
		};
		
		const response = request(editApiUrl, config, {}, true);
		return response
			.then(() => {
				dispatch(finishDeleteEvent());
				dispatch(deleteEventFromStore(idDeleteEvent, getState));
				history.push(frontendUrls.urlDashboardEvents);
			})
			.catch((error) => {
				error.response.json()
					.then((json) => {
						dispatch(failedDeleteEvent(json));
					});
			});
	};
};
